import React from "react";

import Banners from "../../../components/banner";

function Banner(props) {

  return <>{props.banners.length > 0 ? <Banners banners={props.banners} /> : ""}</>;
}

export default Banner;
