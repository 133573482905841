import React, { useEffect, useState } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import GetAddress from "../getaddress";

import useGetData from "../../../customhooks/getdata";
import SelectLocation from "../selectlocation";

import { BiCurrentLocation } from "react-icons/bi";
import { toast } from "react-hot-toast";

function ChooseLocation(props) {
  const [isOpenGetAddress, setIsOpenGetAddress] = useState(false);

  const [addressInfo, setAddressInfo] = useState({
    city: "",
    state: "",
    pinCode: "",
    country: "",
  });

  const [slideChooseLocation, setSlideChooseLocation] = useState({});

  const [gotAddress, AddressError, getAddress] = useGetData();

  const [isOpenChange, setIsOpenChange] = useState(false);

  useEffect(() => {
    setSlideChooseLocation({
      transform: "translateY(0%)",
    });
  }, []);

  const [latlng, setLatlng] = useState({
    lat: "",
    lng: "",
  });

  useEffect(() => {
    if (latlng.lng && latlng.lng) {
      getAddress({
        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${latlng.lat},${latlng.lng}&key=${process.env.REACT_APP_MAP_APIKEY}`,
        headers: {},
      });
    }
  }, [latlng.lat && latlng.lng]);

  const back = () => {
    props.setIsOpenBookAddress(true);

    setSlideChooseLocation({
      transform: "translateY(100%)",
    });
    setTimeout(() => {
      props.setIsOpenChooseLocation(false);
    }, 1000);
  };

  useEffect(() => {
    gotAddress &&
      gotAddress.data.results &&
      gotAddress.data.results.forEach((addresses) => {
        addresses.address_components.forEach((address) => {
          address.types.forEach((item) => {
            if (item === "locality") {
              setAddressInfo((addressInfo) => {
                return { ...addressInfo, city: address.long_name };
              });
            }
            if (item === "postal_code") {
              setAddressInfo((addressInfo) => {
                return { ...addressInfo, pinCode: address.long_name };
              });
            }
            if (item === "administrative_area_level_1") {
              setAddressInfo((addressInfo) => {
                return { ...addressInfo, state: address.long_name };
              });
            }
            if (item === "country") {
              setAddressInfo((addressInfo) => {
                return { ...addressInfo, country: address.long_name };
              });
            }
          });
        });
      });
  }, [gotAddress]);

  useEffect(() => {
    if (props.isAddressForEdit) {
      setLatlng({
        lat: props.AddressForEdit.lat,
        lng: props.AddressForEdit.lng,
      });
    } else {
      setLatlng({
        lat: sessionStorage.getItem("lat"),
        lng: sessionStorage.getItem("lng"),
      });
    }
  }, [props]);

  const centerMoved = (mapProps, map) => {
    setLatlng({
      lat: map.center.lat(),
      lng: map.center.lng(),
    });
  };

  const getcurrentlocation = () => {
    window.navigator.geolocation.getCurrentPosition(
      (coords) => {
        setLatlng({
          lat: coords.coords.latitude,
          lng: coords.coords.longitude,
        });
      },
      (err) => {
        props.setPopUpMessage("Please allow location from setting");
        props.setIsPopUp(true);
        console.log(err);
      }
    );
  };

  const handleClickOnEnterAddress = () => {
    if (
      addressInfo.city &&
      addressInfo.state &&
      addressInfo.pinCode &&
      addressInfo.country
    ) {
      setIsOpenGetAddress(true);
    } else {
      toast.error("Please allow current location or select location.");
    }
  };

  return (
    <>
      <div style={slideChooseLocation} className="choose-location d-grid">
        <div className="header-container d-flex align-items-center">
          <div>
            <button
              onClick={() => {
                back();
              }}
              className="border-0 bg-white"
            >
              <img src="/assets/common/back.svg" alt="Back" />
            </button>
          </div>
          <div className="w-100 text-center">
            <p className="mb-0">Choose Location</p>
          </div>
        </div>
        <Map
          className="map"
          google={window.google}
          zoom={15}
          initialCenter={{
            lat: latlng.lat,
            lng: latlng.lng,
          }}
          center={{
            lat: latlng.lat,
            lng: latlng.lng,
          }}
          disableDefaultUI={true}
          draggable={true}
          onDragend={centerMoved}
        >
          <div className="currentlocation-icon">
            <button
              onClick={() => {
                getcurrentlocation();
              }}
            >
              <BiCurrentLocation />
            </button>
          </div>
          <div className="marker">
            <img src="/assets/common/location.svg" alt="location" />
          </div>
          {/* <Marker position={{ lat: latlng.lat, lng: latlng.lng, }} /> */}
        </Map>
        <div className="bg-white d-flex flex-column justify-content-between">
          <div className="current-location-container w-100 border-0 d-flex justify-content-between">
            <div className="current-location d-flex ">
              <div className="location-icon d-grid">
                <img src="/assets/location/marker.svg" />
              </div>
              <div>
                <p className="location-text text-start">{addressInfo.city}</p>
                <p className="location text-start mb-0">
                  {gotAddress &&
                    gotAddress.data.results[0].formatted_address.substring(
                      0,
                      35
                    )}
                </p>
              </div>
            </div>
            <div className="change">
              <button
                onClick={() => {
                  setIsOpenChange(true);
                }}
                className="border-0"
              >
                CHANGE
              </button>
            </div>
          </div>
          <div className="enter-complete-address">
            <button
              onClick={() => {
                handleClickOnEnterAddress();
              }}
              className="border-0"
            >
              Enter Complete Address
            </button>
          </div>
        </div>
      </div>
      {isOpenGetAddress ? (
        <GetAddress
          addressInfo={addressInfo}
          latlng={latlng}
          AddressForEdit={props.AddressForEdit}
          isAddressForEdit={props.isAddressForEdit}
          setIsOpenBookAddress={props.setIsOpenBookAddress}
          setIsOpenChooseLocation={props.setIsOpenChooseLocation}
          setIsOpenGetAddress={setIsOpenGetAddress}
        />
      ) : (
        ""
      )}

      {isOpenChange ? (
        <SelectLocation
          setPopUpMessage={props.setPopUpMessage}
          setIsPopUp={props.setIsPopUp}
          setLatlng={setLatlng}
          setIsOpenChange={setIsOpenChange}
        />
      ) : (
        ""
      )}
    </>
  );
}

const LoadingContainer = () => <div></div>;

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_MAP_APIKEY}`,
  LoadingContainer: LoadingContainer,
})(ChooseLocation);
