import React, { useEffect, useRef } from "react";

function PaymentMethod(props) {

  const codInput = useRef()

  useEffect(() => {

    if (sessionStorage.getItem("isQrCode") == "true") {
      codInput.current.checked = true;
      props.setPaymentMethod(codInput.current.value)
    }

  }, [])

  return (
    <div className="payment-method-section bg-white">
      <div className="title-container">
        <p className="mb-0">Payment methods</p>
      </div>

      {props.isPaymentFormembership=='true' ? "" :
        <label className="cod-payment-method-container d-flex justify-content-between align-items-center">
          <div className="cod-payment-method d-flex align-items-center">
            <div className="image d-grid justify-content-center align-items-center">
              <img src="/assets/payment/money.svg" alt="Method" />
            </div>
            <div>
              <p className="mb-0">{sessionStorage.getItem("isQrCode") == "true" ? "Pay after service" : "COD"}</p>
            </div>
          </div>
          <div>
            <label className="container">
              <input value="COD"
                onChange={(e) => {
                  props.setPaymentMethod(e.target.value);
                }}
                type="radio"
                name="method"
                ref={codInput}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </label>}
      {/* {sessionStorage.getItem("isQrCode") == "true" ? "" :
        <label className="payu-payment-method-container d-flex justify-content-between align-items-center">
          <div className="payu-payment-method d-flex align-items-center">
            <div className="image d-grid justify-content-center align-items-center">
              <img src="/assets/payment/payu.svg" alt="Method" />
            </div>
            <div>
              <p className="mb-0">PayU</p>
            </div>
          </div>
          <div>
            <label className="container">
              <input
                value="PayU"
                onChange={(e) => {
                  props.setPaymentMethod(e.target.value);
                }}
                type="radio"
                name="method"
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </label>} */}
    </div>
  );
}

export default PaymentMethod;
