import React from "react"

import CompanyInfo from "./companyinfo"
import ScheduleForm from "./scheduleform"

function Footer(props) {
    return <div className="business-footer">

        <ScheduleForm setIsPopUp = {props.setIsPopUp} setPopUpMessage={props.setPopUpMessage} />
        <CompanyInfo />
        <footer>
            <div className="copyright-section">
                <p className="mb-0">© {new Date().getFullYear()} Groome | All Rights Reserved.</p>
            </div>

        </footer>
    </div>
}

export default Footer