import React from "react";

function OfferDeals(props) {

    const handleClick = (item) => {
        props.setIsShowOfferDetails(true)
        props.setOfferDetails({
            title: item.title,
            description: item.description,
            offerDetail: item.offerDetails
        })
    }

    return <div className="offerdeals-section d-flex align-items-center">
        {props.salon.data.salon.offers.map((item) => {
            return <div key={item._id}>
                <button onClick={() => { handleClick(item) }} className="offerdeals d-flex border-0 bg-white">
                    <div className="image-container d-flex justify-content-center align-items-center">
                        <img src="/assets/salon/discount.svg" />
                    </div>
                    <div className="offerr-deals-info d-flex flex-column justify-content-start align-items-start">
                        <p title={item.title} className="title mb-0">{item.title.substring(0, 10)}{item.title.length > 10 ? "..." : ""}</p>
                        <p className="description mb-0">{item.description.substring(0, 10)}{item.description.length > 10 ? "..." : ""}</p>
                    </div>
                </button>
            </div>
        })}

    </div>
}

export default OfferDeals