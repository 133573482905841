import React, { useEffect, useState } from "react";

import { CgClose } from "react-icons/cg";

function FilterSalons(props) {
  const [isEarliestAppointment, setIsEarliestAppointment] = useState("");

  // const [isMen, setIsMen] = useState("");

  // const [isWomen, setIsWomen] = useState("");

  const [gender, setGender] = useState("");

  const [isRating, setIsRating] = useState("");

  return (
    <div className="filter-salons-container d-flex align-items-center">
      <div>
        {isEarliestAppointment ? (
          <div className="cancel-filter d-flex justify-content-between align-items-center">
            <p className="mb-0">Earliest Appointment</p>
            <button
              onClick={() => {
                props.setPage(1);
                window.scrollTo(0, 0);
                setIsEarliestAppointment("");
                props.setEarliest(false);
              }}
              className="border-0 d-grid"
            >
              <CgClose />
            </button>
          </div>
        ) : (
          <button
            onClick={() => {
              props.setPage(1);
              window.scrollTo(0, 0);
              setIsEarliestAppointment(true);
              props.setEarliest(true);
            }}
            className="filter"
          >
            Earliest Appointment
          </button>
        )}
      </div>

      <div>
        {props.gender == "unisex" ? (
          <div className="cancel-filter d-flex justify-content-between align-items-center">
            <p className="mb-0">Unisex</p>
            <button
              onClick={() => {
                props.setPage(1);
                window.scrollTo(0, 0);
                // setGender("");
                props.setGender(null);
              }}
              className="border-0 d-grid"
            >
              <CgClose />
            </button>
          </div>
        ) : (
          <button
            onClick={() => {
              props.setPage(1);
              window.scrollTo(0, 0);
              // setGender("unisex");
              props.setGender("unisex");
            }}
            className="filter"
          >
            Unisex
          </button>
        )}
      </div>

      <div>
        {props.gender == "male" ? (
          <div className="cancel-filter d-flex justify-content-between align-items-center">
            <p className="mb-0">Men</p>
            <button
              onClick={() => {
                props.setPage(1);
                window.scrollTo(0, 0);
                // setGender("");
                props.setGender(null);
              }}
              className="border-0 d-grid"
            >
              <CgClose />
            </button>
          </div>
        ) : (
          <button
            onClick={() => {
              props.setPage(1);
              window.scrollTo(0, 0);
              // setGender("men");
              props.setGender("male");
            }}
            className="filter"
          >
            Men
          </button>
        )}
      </div>

      <div>
        {props.gender == "female" ? (
          <div className="cancel-filter d-flex justify-content-between align-items-center">
            <p className="mb-0">Women</p>
            <button
              onClick={() => {
                props.setPage(1);
                window.scrollTo(0, 0);
                // setGender("");
                props.setGender(null);
              }}
              className="border-0 d-grid"
            >
              <CgClose />
            </button>
          </div>
        ) : (
          <button
            onClick={() => {
              props.setPage(1);
              window.scrollTo(0, 0);
              // setGender("women");
              props.setGender("female");
            }}
            className="filter"
          >
            Women
          </button>
        )}
      </div>
      <div>
        {isRating ? (
          <div className="cancel-filter d-flex justify-content-between align-items-center">
            <p className="mb-0">Rating 4.0+</p>
            <button
              onClick={() => {
                props.setPage(1);
                window.scrollTo(0, 0);
                setIsRating("");
                props.setRating(false);
              }}
              className="border-0 d-grid"
            >
              <CgClose />
            </button>
          </div>
        ) : (
          <button
            onClick={() => {
              props.setPage(1);
              window.scrollTo(0, 0);
              setIsRating(true);
              props.setRating(true);
            }}
            className="filter"
          >
            Rating 4.0+
          </button>
        )}
      </div>
    </div>
  );
}

export default FilterSalons;
