import React from "react";
import { useNavigate } from "react-router-dom";

function NoSalonActive() {
  const navigate = useNavigate();

  return (
    <div className="no-salon-active-page d-grid">
      <div className="p-3 salon-page-back-button-container d-flex align-items-center">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="border-0 bg-white"
        >
          <img src="/assets/common/back.svg" alt="Back" />
        </button>
        <p className="mb-0 ms-3">Back</p>
      </div>

      <div className="not-found text-center d-flex flex-column justify-content-center align-items-center">
        <img src="/assets/salonsbysearch/not_found.png" alt="Not found" />
        <p className="mb-0">Sorry! No salon active</p>
      </div>
    </div>
  );
}

export default NoSalonActive;
