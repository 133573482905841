import React, { useRef } from "react";

function Search(props) {

  const ref = useRef()

  return (
    <div className="search-salons-container">
      <div className="search-salons d-flex justify-content-start align-items-center">
        <input
          ref={ref}
          className="border-0 w-100"
          placeholder={`Enter ${sessionStorage.getItem("isQrCode") == "true" ? "" : "Salon name,"} OrderId`}
          onChange={(e) => { props.getOrderList(e.target.value) }}
        />
        <button onClick = {()=>{ref.current.focus()}} className="border-0 bg-white"><img src="/assets/common/search_icon.svg" alt="Search" /></button>
      </div>
    </div>
  );
}

export default Search
