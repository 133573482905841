import { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import postRequest from "../../functions/apicall/postrequest";

const useUpdateData = () => {
  const [data, setData] = useState("");
  const [error, setError] = useState("");

  const updateData = async (params) => {
    try {
      const updatedData = await axios.put(params.url, params.body, {
        headers: params.headers,
      });

      setData(updatedData);

      return updatedData
    } catch (err) {
      setError(err);
      console.log(err);

      // if (error.response.data && error.response.data.message == "Cart does not exist") {
      //   // const createCartRes = createCart()

      //   // createCartRes.then((res) => {
      //   //   if (res) {
      //   //     // localStorage.setItem("cartId", res.data.genericCart._id)
      //   //     console.log("res", res)
      //   //   }
      //   // })
      // }

      if (err && err.response && err.response.data && err.response.data.message) {
        toast.error(err && err.response && err.response.data && err.response.data.message);
      }

      // return error
    }
  };

  return [data, error, updateData];
};

export default useUpdateData;
