import { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

const usePostData = () => {
  const [data, setData] = useState("");
  const [error, setError] = useState("");

  const postData = async (params) => {
    try {
      const postedData = await axios.post(
        params.url,
        params.body,
        { headers: params.headers }
      );

      setData(postedData);

      return postedData
    } catch (err) {
      setError(err);
      console.log(err);

      if (err && err.response && err.response.data && err.response.data.message) {
        toast.error(err && err.response && err.response.data && err.response.data.message);
      }

      // return error
    }
  };

  return [data, error, postData];
};

export default usePostData;
