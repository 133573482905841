import React from "react";
import { useState, useEffect } from "react";

import Header from "./header";
import Search from "./search";
import FilterSalons from "../../components/filtersalons";
import Banner from "./banner";
import BookAgain from "./bookagain";
import Categories from "./categories";
import SalonsAround from "./salonsaround";
import Footer from "../../components/footer";

import Location from "../../components/location";

import SearchSalons from "../../components/searchsalons";

import useGetData from "../../customhooks/getdata";

import useDeleteData from "../../customhooks/deletedata";

import useIsLoader from "../../customhooks/useisloader";

import Discard from "./discard";
import CartAlert from "./cartalert";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import ViewMoreCategories from "./morecategories";

import PopUp from "../../components/popup";

import useIsPopUp from "../../customhooks/ispopup";
import moment from "moment";

import Loader from "../../components/loader";
import { Browser } from "@capacitor/browser";
import { gtm } from "../gtm";

function Home() {
  const [isSkeleton, setIsSkeleton] = useIsLoader(true);

  const [isScrollLoader, setIsScrollLoader] = useIsLoader();

  const [isLoader, setIsLoader] = useIsLoader();

  const [salonsAround, setSalonsAround] = useState([]);

  let [perpage, setPerpage] = useState(10);

  let [page, setPage] = useState(1);

  const [salons, setSalons] = useState(null);

  const [data, dataError, fetchData] = useGetData();

  const [latlng, setLatlng] = useState({
    lat: "",
    lat: "",
  });

  const [city, setCity] = useState("");

  const [country, setCountry] = useState("");

  const [earliest, setEarliest] = useState("");
  // const [men, setMen] = useState("");
  // const [women, setWomen] = useState("");

  const [gender, setGender] = useState(null);

  const [rating, setRating] = useState("");

  const [isShowDiscard, setIsShowDiscard] = useState(false);

  const [isSearchSalons, setIsSearchSalons] = useState(false);

  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [popUpMessage, setPopUpMessage] = useState("");

  const [isOpenSelectLocation, setIsOpenSelectLocation] = useState(false);

  const [isShowViewMoreSection, setIsShowViewMoreSection] = useState(false);

  const [isToBeHiddeOverflow, setIsToBeHiddeOverflow] = useState(false);

  const [topBanners, setTopBanners] = useState([]);

  const [userAddress, setUserAddress] = useState(null);

  const [middleBanners, setMiddleBanners] = useState([]);

  const [bottomBanners, setBottomBanners] = useState([]);

  const [styleOnSalonsAroundLoader, setStyleOnSalonsAroundLoader] = useState({
    marginBottom: "79px",
  });

  const [salonsCategories, setSlonCategories] = useState([]);

  const [userProfile, setUserProfile] = useState(null);

  const [genericCart, setGenericCart] = useState(null);

  const [cart, setCart] = useState(null);

  const [categoriesData, setCategoriesData] = useState(null);

  const [bookAgainData, setBookAgainData] = useState(null);

  const [orders, setOrders] = useState([]);

  const bookAgainSalons = new Set();

  const salonObjects = [];

  const [deletedata, deleteError, deleteData] = useDeleteData();

  useEffect(() => {
    const getBookAgain = async () => {
      const res = await fetchData({
        url: `${process.env.REACT_APP_API_URL
          }/app/order/search?user._id=${localStorage.getItem("userId")}`,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return Promise.resolve(res);
    };

    const getUserProfile = async (userId) => {
      const res = fetchData({
        url: `${process.env.REACT_APP_API_URL}/app/user/${userId}`,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return Promise.resolve(res);
    };

    if (localStorage.getItem("userId")) {
      const p1 = getUserProfile(localStorage.getItem("userId"));

      const p2 = getBookAgain();

      Promise.all([p1, p2]).then((res) => {
        const [userProfile, bookAgain] = res;

        if (userProfile) {
          setUserProfile(userProfile);
        }

        if (bookAgain) {
          setBookAgainData(bookAgain);

          bookAgain.data.orders.forEach((item) => {
            if (item.salon && item.salon.status == 1) {
              if (!bookAgainSalons.has(item.salon._id)) {
                bookAgainSalons.add(item.salon._id);
                salonObjects.push(item.salon);
              }
            }
          });

          setOrders(salonObjects);
        }
      });
    }

    const getCategory = async () => {
      const res = await fetchData({
        url: `${process.env.REACT_APP_API_URL}/app/salonCategory/search?status=1&sort_by=order&sort_order=desc`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      return Promise.resolve(res);
    };

    const p1 = getCategory();

    p1.then((res) => {
      if (res) {
        setCategoriesData(res);

        setSlonCategories(res.data.salonCategories);
      }
    });
  }, []);

  const getBanners = async (latParam, lngParam, city) => {
    const res = await fetchData({
      url: `${process.env.REACT_APP_API_URL
        }/app/banner/search?status=1&city=${city}&distance_radius=7&sort_by=order&sort_order=desc${city == "Other" ? "" : `&lat=${latParam}&lng=${lngParam}`
        }`,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return Promise.resolve(res);
  };

  const handleGetSalons = async (
    latParam,
    lngParam,
    cityParam,
    countryParam
  ) => {
    const res = await fetchData({
      url:
        `${process.env.REACT_APP_API_URL
        }/app/salon/search?nearBy=${cityParam},${countryParam}&lat=${latParam}&lng=${lngParam}&status=1&salonStatus=verified&isActive=true&earliest=${moment()
          .format("YYYY-MM-DDTHH:mm")
          .split("T")[1]
        }&sort_by=${earliest ? "appointmentIn" : "distanceInKm"
        }&per_page=${perpage}&page=${page}` +
        `${gender ? `&type=${gender}` : ""
        // ? `&type=unisex`
        // : men
        // ? `&type=male`
        // : women
        // ? `&type=female`
        // : ``
        }` +
        `${rating ? `&ratingGt=4` : ``}`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return Promise.resolve(res);
  };

  useEffect(() => {
    if (latlng.lat && latlng.lng && !isLoader) {
      const getAddress = async () => {
        const res = await fetchData({
          url: `https://maps.googleapis.com/maps/api/geocode/json?address=${latlng.lat},${latlng.lng}&key=${process.env.REACT_APP_MAP_APIKEY}`,
          headers: {},
        });

        return Promise.resolve(res);
      };

      const addressResRef = getAddress();

      addressResRef.then((res) => {
        if (res) {
          let city;
          let country;

          setPage(1);

          setIsLoader(true);

          setUserAddress(res);

          res.data.results.forEach((addresses) => {
            addresses.address_components.forEach((address) => {
              address.types.forEach((item) => {
                if (item === "locality") {
                  setCity(address.long_name);
                  sessionStorage.setItem("city", address.long_name);

                  city = address.long_name;
                }

                if (item === "country") {
                  sessionStorage.setItem("country", address.long_name);
                  sessionStorage.setItem("lat", latlng.lat);
                  sessionStorage.setItem("lng", latlng.lng);

                  setCountry(address.long_name);

                  country = address.long_name;
                }
              });
            });
          });

          const bannersResRef = getBanners(latlng.lat, latlng.lng, city);

          bannersResRef.then((bannerRes) => {
            if (bannerRes) {
              if (bannerRes.data.results == 0) {
                const otherBannersResRef = getBanners(
                  sessionStorage.getItem("lat"),
                  sessionStorage.getItem("lng"),
                  "Other"
                );

                otherBannersResRef.then((otherBannerRes) => {
                  if (otherBannerRes) {
                    // banners
                    setTopBanners(
                      otherBannerRes.data.banners.filter((item) => {
                        if (item.placement == "Home Top") {
                          return item;
                        }
                      })
                    );

                    setMiddleBanners(
                      otherBannerRes.data.banners.filter((item) => {
                        if (item.placement == "Page Middle") {
                          return item;
                        }
                      })
                    );

                    setBottomBanners(
                      otherBannerRes.data.banners.filter((item) => {
                        if (item.placement == "Page Bottom") {
                          return item;
                        }
                      })
                    );

                    // salons
                    const salonsResRef = handleGetSalons(
                      sessionStorage.getItem("lat"),
                      sessionStorage.getItem("lng"),
                      city,
                      country
                    );

                    salonsResRef.then((salonsRes) => {
                      if (salonsRes) {
                        setSalonsAround(salonsRes.data.salons);
                        setSalons(salonsRes);
                        setIsLoader(false);
                      } else {
                        setSalonsAround([]);
                        setSalons(null);
                        setIsLoader(false);
                      }
                    });
                  } else {
                    const salonsResRef = handleGetSalons(
                      sessionStorage.getItem("lat"),
                      sessionStorage.getItem("lng"),
                      city,
                      country
                    );

                    salonsResRef.then((salonsRes) => {
                      if (salonsRes) {
                        setSalonsAround(salonsRes.data.salons);
                        setSalons(salonsRes);
                        setIsLoader(false);
                      } else {
                        setSalonsAround([]);
                        setSalons(null);
                        setIsLoader(false);
                      }
                    });
                  }
                });
              } else {
                // banners
                setTopBanners(
                  bannerRes.data.banners.filter((item) => {
                    if (item.placement == "Home Top") {
                      return item;
                    }
                  })
                );

                setMiddleBanners(
                  bannerRes.data.banners.filter((item) => {
                    if (item.placement == "Page Middle") {
                      return item;
                    }
                  })
                );

                setBottomBanners(
                  bannerRes.data.banners.filter((item) => {
                    if (item.placement == "Page Bottom") {
                      return item;
                    }
                  })
                );

                const salonsResRef = handleGetSalons(
                  sessionStorage.getItem("lat"),
                  sessionStorage.getItem("lng"),
                  city,
                  country
                );

                salonsResRef.then((salonsRes) => {
                  if (salonsRes) {
                    setSalonsAround(salonsRes.data.salons);
                    setSalons(salonsRes);
                    setIsLoader(false);
                  } else {
                    setSalonsAround([]);
                    setSalons(null);
                    setIsLoader(false);
                  }
                });
              }
            } else {
              const salonsResRef = handleGetSalons(
                sessionStorage.getItem("lat"),
                sessionStorage.getItem("lng"),
                city,
                country
              );

              salonsResRef.then((salonsRes) => {
                if (salonsRes) {
                  setSalonsAround(salonsRes.data.salons);
                  setSalons(salonsRes);
                  setIsLoader(false);
                } else {
                  setSalonsAround([]);
                  setSalons(null);
                  setIsLoader(false);
                }
              });
            }
          });
        }
      });
    }
  }, [latlng.lat && latlng.lng]);

  useEffect(() => {
    if (latlng.lat && latlng.lng && city && country && !isLoader) {
      setIsLoader(true);

      const p1 = handleGetSalons(latlng.lat, latlng.lng, city, country);

      p1.then((results) => {
        if (results) {
          setSalonsAround(results.data.salons);

          setSalons(results);
        } else {
          setSalonsAround([]);

          setSalons(null);
        }

        setIsLoader(false);
      });
    }
  }, [gender, rating, earliest]);

  const getCartList = async () => {
    const res = fetchData({
      url: `${process.env.REACT_APP_API_URL
        }/app/cart?user=${localStorage.getItem("userId")}`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return Promise.resolve(res);
  };

  const getGenericCart = async () => {
    const res = fetchData({
      url: `${process.env.REACT_APP_API_URL
        }/app/genericCart/${localStorage.getItem("cartId")}`,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });

    return Promise.resolve(res);
  };

  const moveGenericCart = async () => {
    const res = await deleteData({
      url: `${process.env.REACT_APP_API_URL
        }/app/genericCart/${localStorage.getItem("cartId")}/move`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: {},
    });

    return Promise.resolve(res);
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      if (localStorage.getItem("cartId")) {
        const genericCartResRef = getGenericCart();

        genericCartResRef.then((res) => {
          if (res) {
            if (
              res.data.genericCart &&
              res.data.genericCart.cartCalculations.overall.totalItems > 0
            ) {
              const moveGenericCartResRef = moveGenericCart();

              moveGenericCartResRef.then((res) => {

                if (res) {

                  const p1 = getCartList();

                  p1.then((res) => {
                    if (res) {
                      setCart(res);
                    }
                  });

                  const genericCartResRef2 = getGenericCart();

                  genericCartResRef2.then((res) => {
                    if (res) {
                      setGenericCart(res);
                    }
                  });

                  localStorage.removeItem("cartId")
                }
              });
            } else {
              const cartResRef = getCartList();

              cartResRef.then((res) => {
                if (res) {
                  setCart(res);
                }
              });
            }
          }
        });
      } else {
        const cartResRef2 = getCartList();

        cartResRef2.then((res) => {
          if (res) {
            setCart(res);
          }
        });
      }
    } else if (localStorage.getItem("cartId")) {
      const genericCartResRef2 = getGenericCart();

      genericCartResRef2.then((res) => {
        if (res) {
          setGenericCart(res);
        }
      });
    }
  }, []);

  const increasePage = () => {
    // setTimeout(() => {

    if (!isLoader && salons && salons.data.totalResults >= perpage * page) {
      setPage((page += 1));

      setIsScrollLoader(true);

      const p1 = handleGetSalons(latlng.lat, latlng.lng, city, country);

      p1.then((res) => {
        if (res) {
          if (res.data.totalResults > 0) {
            setSalonsAround((prev) => [...prev, ...res.data.salons]);

            setSalons(res);
          } else {
            setSalonsAround([]);
            setSalons(null);
          }
        } else {
          setSalonsAround([]);
          setSalons(null);
        }
      });

      setIsScrollLoader(false);
    }
    // }, 1000);
  };

  const openDiscard = () => {
    setIsShowDiscard(true);
    document.body.style.overflow = "hidden";
  };

  const closeDiscard = () => {
    document.body.style.overflow = "unset";
    setIsShowDiscard(false);
  };

  const getcurrentlocation = () => {
    window.navigator.geolocation.getCurrentPosition(
      (coords) => {
        setLatlng({
          lat: coords.coords.latitude,
          lng: coords.coords.longitude,
        });
      },
      (err) => {
        openSelectLocation();

        // setPopUpMessage("Please allow location from setting")
        // setIsPopUp(true)

        // setLatlng({
        //   lat: 12.9715987,
        //   lng: 77.5945627,
        // });
      }
    );
  };

  useEffect(() => {
    if (!sessionStorage.getItem("isConfirm")) {
      getcurrentlocation();
    } else {
      setLatlng({
        lat: sessionStorage.getItem("lat"),
        lng: sessionStorage.getItem("lng"),
      });
    }
  }, []);

  const getLatlng = (latlng) => {
    setLatlng({
      lat: latlng.latlng.lat,
      lng: latlng.latlng.lng,
    });
  };

  const openSelectLocation = () => {
    document.body.style.overflow = "hidden";

    setIsOpenSelectLocation(true);
  };

  const closeSelectLocation = () => {
    document.body.style.overflow = "unset";

    setIsOpenSelectLocation(false);
  };

  useEffect(() => {
    if (
      userProfile ||
      (userAddress && salons && categoriesData) ||
      genericCart ||
      cart ||
      bookAgainData
    ) {
      setIsSkeleton(false);
    }
  }, [
    userProfile,
    userAddress,
    cart,
    salons,
    genericCart,
    categoriesData,
    bookAgainData,
  ]);

  useEffect(() => {
    if (
      (cart &&
        cart.data.cart &&
        cart.data.cart.cartCalculations.overall.totalItems > 0) ||
      (genericCart &&
        genericCart.data.genericCart &&
        genericCart.data.genericCart.cartCalculations.overall.totalItems > 0)
    ) {
      setStyleOnSalonsAroundLoader({
        paddingBottom: "133px",
      });
    } else {
      setStyleOnSalonsAroundLoader({
        paddingBottom: "79px",
      });
    }
  }, [genericCart, cart]);

  useEffect(() => {
    gtm.page();
    // ReactPixel.pageView();

    // analytics.track("buttonSubmit");

    // window.dataLayer.push({
    //   event: "pageview",
    // });
    // analytics.track("buttonClick");
  }, []);



  return (
    <>
      {isShowDiscard ? (
        <Discard
          getGenericCart={getGenericCart}
          closeDiscard={closeDiscard}
          getCartList={getCartList}
          setCart={setCart}
          setGenericCart={setGenericCart}
        />
      ) : (
        ""
      )}

      <div className="home-page d-flex flex-column justify-content-between">
        <div>
          {isSkeleton ? (
            <div className="d-flex justify-content-between align-items-center p-3 pb-0">
              <Skeleton width={100} />
              <Skeleton height={36} width={36} circle={true} />
            </div>
          ) : (
            <Header
              userProfile={userProfile}
              city={city}
              userAddress={userAddress}
              openSelectLocation={openSelectLocation}
            />
          )}

          {isSkeleton ? (
            <div className="search-filter-skeleton p-3 d-flex flex-column">
              <Skeleton />
              <Skeleton />
            </div>
          ) : (
            <div className="search-filter-container">
              <Search setIsSearchSalons={setIsSearchSalons} />
              <FilterSalons
                setEarliest={setEarliest}
                // setWomen={setWomen}
                // setMen={setMen}
                setRating={setRating}
                setGender={setGender}
                gender={gender}
                setPage={setPage}
              />
            </div>
          )}

          {isSkeleton ? (
            // <div className="m-3 mt-0">
            //   <Skeleton width={300} height={150} />
            // </div>
            ""
          ) : (
            <Banner banners={topBanners} />
          )}

          {isSkeleton ? (
            <div className="book-again-skeleton d-flex justify-content-between align-items-center px-3 pb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Skeleton width={52} height={52} />
                </div>
                <div className="d-flex flex-column ps-2">
                  <Skeleton
                    containerClassName="salon-name"
                    height={10}
                    width={70}
                  />
                  <Skeleton
                    containerClassName="salon-address"
                    height={10}
                    width={70}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <Skeleton width={52} height={52} />
                </div>
                <div className="d-flex flex-column ps-2">
                  <Skeleton
                    containerClassName="salon-name"
                    height={10}
                    width={70}
                  />
                  <Skeleton
                    containerClassName="salon-address"
                    height={10}
                    width={70}
                  />
                </div>
              </div>
            </div>
          ) : (
            <BookAgain orders={orders} />
          )}

          {isSkeleton ? (
            <div className="d-flex justify-content-between align-items-center px-3">
              <Skeleton height={50} width={50} circle />
              <Skeleton height={50} width={50} circle />
              <Skeleton height={50} width={50} circle />
              <Skeleton height={50} width={50} circle />
            </div>
          ) : (
            <Categories
              setIsShowViewMoreSection={setIsShowViewMoreSection}
              categories={salonsCategories}
              gotCategories={categoriesData}
            />
          )}

          {isSkeleton || isLoader ? (
            <>
              {isSkeleton ? (
                <div className="p-3">
                  <Skeleton height={200} />

                  <div className="d-flex justify-content-between align-items-center py-2">
                    <Skeleton width={50} />
                    <Skeleton width={20} />
                  </div>
                  <Skeleton />
                </div>
              ) : (
                <>
                  {" "}
                  {isLoader ? (
                    <div
                      style={styleOnSalonsAroundLoader}
                      className="salon-around-loader-container"
                    >
                      <Loader width={60} height={60} color={"#772286"} />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          ) : (
            <SalonsAround
              cart={cart}
              genericCart={genericCart}
              salons={salons}
              isScrollLoader={isScrollLoader}
              increasePage={increasePage}
              salonsAround={salonsAround}
              middleBanners={middleBanners}
              bottomBanners={bottomBanners}
            />
          )}
        </div>

        {isSkeleton ? (
          <div>
            <div className="d-flex justify-content-between align-items-center px-3 pb-3">
              <Skeleton height={40} width={40} circle />
              <Skeleton height={40} width={40} circle />
              <Skeleton height={40} width={40} circle />
              <Skeleton height={40} width={40} circle />
            </div>
          </div>
        ) : (
          <div className="footer-cart-container">
            {(cart &&
              cart.data.cart &&
              cart.data.cart.cartCalculations.overall.totalItems > 0) ||
              (genericCart &&
                genericCart.data.genericCart &&
                genericCart.data.genericCart.cartCalculations.overall.totalItems >
                0) ? (
              <CartAlert
                gotGenericCart={genericCart}
                gotCartList={cart}
                openDiscard={openDiscard}
              />
            ) : (
              ""
            )}

            <Footer />
          </div>
        )}
      </div>

      {isSearchSalons ? (
        <SearchSalons setIsSearchSalons={setIsSearchSalons} />
      ) : (
        ""
      )}

      {isOpenSelectLocation ? (
        <Location
          setIsToBeHiddeOverflow={setIsToBeHiddeOverflow}
          setPopUpMessage={setPopUpMessage}
          setIsPopUp={setIsPopUp}
          getLatlng={getLatlng}
          closeSelectLocation={closeSelectLocation}
        />
      ) : (
        ""
      )}

      {isShowViewMoreSection ? (
        <ViewMoreCategories
          setIsShowViewMoreSection={setIsShowViewMoreSection}
          categories={salonsCategories}
          gotCategories={categoriesData}
        />
      ) : (
        ""
      )}

      {isPopUp ? (
        <PopUp
          isToBeHiddeOverflow={isToBeHiddeOverflow}
          setPopUpMessage={setPopUpMessage}
          setIsPopUp={setIsPopUp}
          message={popUpMessage}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Home;
