import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useGetData from "../../customhooks/getdata";
import useUpdateData from "../../customhooks/updatedata";
import Header from "./header";

import moment from "moment";

import { isEmail, isName, isMobile, isAlternateMobile, isDate } from "../../functions";

import { authFormHeaders, authJsonHeaders } from "../../constants";
import { gtm } from "../gtm";

function ViewProfile() {
  const navigate = useNavigate();
  const [gotUserProfile, userProfileError, getUserProfile] = useGetData();

  const [userInfo, setUserInfo] = useState({
    name: "",
    email: '',
    dob: "",
    alternateNumber: "",
    image: "",
    url: "",
    updateImage: "null"
  });

  const [imageMessage, setImageMessage] = useState("")
  const [nameMessage, setNameMessage] = useState("")
  const [mobileMessage, setMobileMessage] = useState("")
  const [emailMessage, setEmailMessage] = useState("")
  const [dobMessage, setDobMessage] = useState("")
  const [alternateNumberMessage, setAlternateNumberMessage] = useState("")

  const [updatedUserInfo, UserInfoError, updateUserInfo] = useUpdateData();

  const [isInputDisabled, setIsInputDisabled] = useState(true)

  const [message, setMessage] = useState("")

  const formData = new FormData();

  formData.append("name", userInfo.name)
  formData.append("email", userInfo.email)
  formData.append("mobile", userInfo.mobile)
  formData.append("dob", userInfo.dob ? userInfo.dob : "")
  formData.append("alternateNumber", userInfo.alternateNumber ? userInfo.alternateNumber : "")
  formData.append("image", userInfo.image && userInfo.image[0])
  formData.append("updateImage", userInfo.updateImage)

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getUserProfile({
        url: `${process.env.REACT_APP_API_URL}/app/user/${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      gotUserProfile != undefined &&
      gotUserProfile != null &&
      gotUserProfile != ""
    ) {
      setUserInfo({
        name: gotUserProfile.data.user && gotUserProfile.data.user.name ? gotUserProfile.data.user.name : "",
        email: gotUserProfile.data.user && gotUserProfile.data.user.email ? gotUserProfile.data.user.email : "",
        mobile: gotUserProfile.data.user && gotUserProfile.data.user.mobile ? gotUserProfile.data.user.mobile : "",
        dob: gotUserProfile.data.user && gotUserProfile.data.user.dob ? gotUserProfile.data.user.dob : "",
        alternateNumber: gotUserProfile.data.user.alternateNumber ? gotUserProfile.data.user.alternateNumber : "",
        image: gotUserProfile.data.user.image,
        updateImage: "null",
        url: gotUserProfile.data.user.image ? `${process.env.REACT_APP_IMAGE_URL}${gotUserProfile.data.user.image}` : ""
      });
    }
  }, [gotUserProfile]);

  useEffect(() => {
    if (
      updatedUserInfo != undefined &&
      updatedUserInfo != null &&
      updatedUserInfo != ""
    ) {
      navigate(-1);
    }
  }, [updatedUserInfo]);

  const checkValidation = () => {

    let nameTobeCheck = userInfo.name.split(" ").join("-")

    let validated = true

    if (userInfo.name == "") {
      validated = false
      setNameMessage("Please provide name.")
    }

    if (isName(userInfo.name.split(" ").join(""))) {
      setNameMessage("")
      setUserInfo({ ...userInfo, name: nameTobeCheck.split("-").join(" ") });
    }
    else {
      validated = false
      setNameMessage("Please enter valid name")
    }

    if (userInfo.email.length > 0) {
      if (isEmail(userInfo.email)) {
        setEmailMessage("")
        setUserInfo({ ...userInfo, email: userInfo.email });
      }
      else {
        validated = false
        setEmailMessage("Please enter valid email")
      }
    }

    if (userInfo.mobile.length > 0) {
      if (isMobile(userInfo.mobile)) {
        setMobileMessage("")
        setUserInfo({ ...userInfo, mobile: userInfo.mobile });
      }
      else {
        validated = false
        setMobileMessage("Please enter valid mobile")
      }
    }

    if (userInfo.alternateNumber.length > 0) {
      if (isAlternateMobile(userInfo.alternateNumber)) {
        setAlternateNumberMessage("")
        setUserInfo({ ...userInfo, alternateNumber: userInfo.alternateNumber });
      }
      else {
        validated = false
        setAlternateNumberMessage("Please enter valid alternate mobile")
      }
    }

    if (userInfo.dob.length > 0) {
      if (moment().isAfter(moment(userInfo.dob).format("YYYY/MM/DD"))) {
        if (isDate(moment(userInfo.dob).format("YYYY/MM/DD"))) {
          setDobMessage("")
          setUserInfo({ ...userInfo, dob: userInfo.dob });
        }
        else {
          validated = false
          setDobMessage("Please enter valid DOB.")
        }
      }
      else {
        validated = false
        setDobMessage("Please enter valid DOB.")
      }

    }

    return validated
  }

  const handleClick = () => {

    if (checkValidation()) {
      updateUserInfo({
        url: `${process.env.REACT_APP_API_URL}/app/user/${localStorage.getItem("userId")
          }/form`,
        //headers: authFormHeaders,
        headers: {
          "Content-type": "multipart/form-data",
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formData,
      });
    }

  };

  const handleOnChangeImage = (e) => {
    setMessage("")

    let valid = true;

    const files = Array.from(e.target.files)

    if (e.target.files) {
      files.map((file) => {
        const size = file.size / 1024;
        if (size > 100) {
          valid = false;
          setMessage("Please provide maximum 100kb size")
          setUserInfo({
            ...userInfo, image: "", url: ""
          })
        }
        if (
          !["application/jpg", "image/jpeg", "image/png"].includes(file.type)
        ) {
          valid = false;
          setMessage("Please provide correct file type from these jpg, jpeg, png")
          setUserInfo({
            ...userInfo, image: "", url: ""
          })
        }
      });
    }

    if (valid) {
      setUserInfo({
        ...userInfo, image: files, url: URL.createObjectURL(files[0]), updateImage: gotUserProfile.data.user.image ? gotUserProfile.data.user.image : "null"
      })
    }
  }

  const handleOnChangeName = (e) => {

    let nameTobeCheck = e.target.value.split(" ").join("-")

    if (e.target.value.length == 0) {
      setNameMessage("")
      setUserInfo({ ...userInfo, name: "" });
    }

    else if (e.target.value.length > 0) {
      if (isName(e.target.value.split(" ").join(""))) {
        setNameMessage("")
        setUserInfo({ ...userInfo, name: nameTobeCheck.split("-").join(" ") });
      }
      else {
        setNameMessage("Please enter valid name")
      }
    }
  }

  const handleOnChangeMobile = (e) => {

    if (e.target.value.length == 0) {
      setMobileMessage("")
      setUserInfo({ ...userInfo, mobile: "" });
    }
    else if (e.target.value.length > 0) {
      if (isMobile(e.target.value)) {
        setMobileMessage("")
        setUserInfo({ ...userInfo, mobile: e.target.value });
      }
      else {
        setMobileMessage("Please enter valid mobile")
      }
    }
  }

  const handleOnChangeAlternateMobile = (e) => {

    if (e.target.value.length == 0) {
      setAlternateNumberMessage("")
      setUserInfo({ ...userInfo, alternateNumber: "" });
    }
    else if (e.target.value.length > 0) {
      if (isAlternateMobile(e.target.value)) {
        setAlternateNumberMessage("")
        setUserInfo({ ...userInfo, alternateNumber: e.target.value });
      }
      else {
        // setUserInfo({ ...userInfo, alternateNumber: e.target.value });
        setAlternateNumberMessage("Please enter valid alternate mobile")
      }
    }
  }

  const handleOnChangeDob = (e) => {

    if (e.target.value.length == 0) {
      setDobMessage("")
      setUserInfo({ ...userInfo, dob: "" });
    }
    else if (e.target.value.length > 0) {
      if (moment().isAfter(moment(e.target.value).format("YYYY/MM/DD"))) {
        if (isDate(moment(e.target.value).format("YYYY/MM/DD"))) {

          setDobMessage("")
          setUserInfo({ ...userInfo, dob: e.target.value });
        }
        else {
          setDobMessage("Please enter valid DOB.")
        }
      }
      else {
        setDobMessage("Please enter valid DOB.")
      }
    }
  }

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <div className="view-profile-page">
      <Header isInputDisabled={isInputDisabled} />

      <div className="user-image-container text-center position-relative">
        <div className="user-image">
          {
            gotUserProfile && gotUserProfile.data.user ? <>{userInfo.url ? <img src={userInfo.url} alt="User" /> : <img src="/assets/common/user.svg" />}</> : ""
          }
        </div>
        {isInputDisabled ? "" :
          <div className="user-image-input-container d-flex justify-content-center align-items-center m-auto">
            <label>
              <input
                disabled={isInputDisabled}
                className="border-0"
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  handleOnChangeImage(e)

                }}
              />
              <div className="d-flex justify-content-center align-items-center">
                <img src="assets/common/camera.svg" alt="Camera" />
              </div>
            </label>
          </div>
        }

      </div>
      {message ? <p className="message">{message}</p> : ""}

      <div className="name-input-container">
        <div className="d-flex justify-content-between">
          <p className="mb-0">Name*</p>
          {nameMessage ? <p className="message">{nameMessage}</p> : ""}
        </div>
        <input
          disabled={isInputDisabled}
          type="text"
          onChange={(e) => {
            handleOnChangeName(e)
          }}
          value={userInfo.name}
          className="border-0 bg-white"
        />
      </div>

      <div className="mobile-input-container">
        <div className="d-flex justify-content-between">
          <p className="mb-0">Mobile No.</p>
          {mobileMessage ? <p className="message">{mobileMessage}</p> : ""}
        </div>
        <input
          disabled
          type="tel"
          maxLength={15}
          onChange={(e) => {
            handleOnChangeMobile(e)
          }}
          value={userInfo.mobile}
          className="border-0 bg-white"
        />
      </div>

      <div className="email-input-container">
        <div className="d-flex justify-content-between">
          <p className="mb-0">Email id</p>
          {emailMessage ? <p className="message">{emailMessage}</p> : ""}
        </div>
        <input
          disabled={isInputDisabled}
          type="text"
          onChange={(e) => {
            setUserInfo({ ...userInfo, email: e.target.value });
          }}
          value={userInfo.email}
          className="border-0 bg-white"
        />
      </div>

      <div className="birthday-input-container">
        <div className="d-flex justify-content-between">
          <p className="mb-0">Your Birthday</p>
          {dobMessage ? <p className="message">{dobMessage}</p> : ""}
        </div>
        <input
          disabled={isInputDisabled}
          type="date"
          onChange={(e) => {
            handleOnChangeDob(e)
          }}
          value={moment(userInfo.dob).format("YYYY-MM-DD")}
          className="border-0 bg-white"
        />
        <p className="mb-0 birthday-text">
          (Please provide your correct birthday and year. We have free
          services and offers on Birthdays)
        </p>
      </div>

      <div className="alternate-input-container">
        <div className="d-flex justify-content-between">
          <p className="mb-0">Alternate No.</p>
          {alternateNumberMessage ? <p className="message">{alternateNumberMessage}</p> : ""}
        </div>
        <input
          disabled={isInputDisabled}
          type="tel"
          maxLength={15}
          onChange={(e) => {
            handleOnChangeAlternateMobile(e)
          }}
          value={userInfo.alternateNumber}
          className="border-0 bg-white"
        />
      </div>
      {
        isInputDisabled ? <div className="edit-profile-container">
          <button
            onClick={() => {
              setIsInputDisabled(false);
            }}
            className="border-0"
          >
            Edit Profile
          </button>
        </div> : <div className="save-cancel-container">
          <button
            onClick={() => {
              handleClick();
            }}
            className="save border-0"
          >
            Save Changes
          </button>

          <button
            onClick={() => {
              setIsInputDisabled(true);
            }}
            className="cancel"
          >
            Cancel
          </button>
        </div>
      }

    </div>
  );
}

export default ViewProfile;
