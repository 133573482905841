import React, { useState } from "react"

import { GiHamburgerMenu } from 'react-icons/gi'

import {GrClose} from 'react-icons/gr'
import { Link } from "react-router-dom"

function Header() {

    const [isShowNavigation, setIsShowNavigation] = useState(false)

    const handleClickOnHamberger = () => {

        if (isShowNavigation) {
            setIsShowNavigation(false)
        }
        else {
            setIsShowNavigation(true)
        }

    }

    return <header>
        <div className="business-header d-flex justify-content-between align-items-center w-100">
            <div className="logo-container">
                <img src="/assets/common/logo.svg" />
            </div>

            <div className="menu-container d-flex align-items-center">
                <Link to="/" className="border-0 bg-white home">Home</Link>
                {/* <button className="border-0 bg-white">Skin Clinic</button> */}
                <a href="#contact" className="border-0 bg-white">Contact Us</a>
                <a href="tel:8884877662" className="border-0 bg-white"><img src="https://respark.in/wp-content/uploads/2023/01/imageedit_1_7558982530.webp" /></a>
            </div>

            <div className="hamberger-section d-flex align-items-center">

                <div className="video-container">
                    <a href="tel:8884877662" className="border-0 bg-white"><img src="https://respark.in/wp-content/uploads/2023/01/imageedit_1_7558982530.webp" /></a>
                </div>

                <div className="hamberger-container">
                    <button onClick={()=>{handleClickOnHamberger()}} className="border-0 bg-white">

                        {isShowNavigation ? <GrClose/> : <GiHamburgerMenu /> }
                        
                    </button>
                </div>
            </div>
        </div>

        {
            isShowNavigation ? <div className="side-bar d-flex flex-column justify-content-start align-items-start">
                <Link to="/" className="border-0 bg-white home">Home</Link>
                {/* <button className="border-0 bg-white">Skin Clinic</button> */}
                <a href="#contact" className="border-0 bg-white">Contact Us</a>
            </div> : ""
        }
    </header>

}

export default Header