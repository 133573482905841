import React, { useCallback, useEffect, useState } from "react";

import { CgClose } from "react-icons/cg"

function ProductMaleFemale(props) {

  const [isMaleFemale, setIsMaleFemale] = useState("");

  const callback = useCallback(() => {
    props.setIsProductMale(false)
  })

  useEffect(() => {
    if (props.salon && props.salon.data.salon.type == "unisex") {
      setIsMaleFemale("female")
      props.setIsProductMale(false)
      props.setIsProductFemale(true)
    }

    return callback

  }, []);

  return (
    <>
      {props.salon && props.salon.data.salon.type == "unisex" ?
        <div className="product-male-female-container d-flex align-items-center">
          <div>
            <button
              onClick={() => {
                setIsMaleFemale("female")
                props.setIsProductFemale(true)
                props.setIsProductMale(false)
              }}
              className={isMaleFemale == "female" ? "cancel" : "toggle"}
            >
              Female
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                setIsMaleFemale("male")
                props.setIsProductMale(true)
                props.setIsProductFemale(false)
              }}
              className={isMaleFemale == "male" ? "cancel" : "toggle"}
            >
              Male
            </button>
          </div>

        </div> : ""}
    </>
  );
}

export default ProductMaleFemale;
