import React from "react";

function Analytics() {
  return (
    <div id="analytics" className="product d-grid">
      <div className="info-container">
        <h3 className="title mb-0 ">Analytics Billing Software</h3>

        <h4 className="statement mb-0">User-Friendly, Cloud-Based Analytics</h4>

        <p className="paragraph mb-0">
          Groome gives simple, concise and clear analytic service. Perceive
          everything you require on any device and know the well-being of your
          salon and spa business. Take further steps for its expansion &
          all-round development
        </p>

        <a href="#create-free-account" className="border-0">
          Create Free Account
        </a>
      </div>

      <div className="image-container">
        <img src="/assets/business/analytics.png" />
      </div>
    </div>
  );
}

export default Analytics;
