import React from "react";

function InventoryManagement() {
  return (
    <div id="inventory" className="product d-grid">
      <div className="info-container">
        <h3 className="title mb-0 ">Inventory Management</h3>

        <h4 className="statement mb-0">Incentivise Loyal Customers</h4>

        <p className="paragraph mb-0">
          Groome inventory is intelligent and helpful. You can track the
          inventory of your products and check the insights from the compelling
          reports. Groome automatically deducts the low quantity products and
          shows you on the panel. You can also track all the transactions.
        </p>

        <a href="#create-free-account" className="border-0">
          Create Free Account
        </a>
      </div>

      <div className="image-container">
        <img src="/assets/business/inventory.png" />
      </div>
    </div>
  );
}

export default InventoryManagement;
