import React from "react";

function OfferDeal(props) {

  const handleClickInformationIcon = (item) => {

    props.setCouponCodeDesc({
      couponCode: item.couponCode,
      description: item.description
    })

    props.setIsShowOfferInformation(true)
  }

  const handleClickCopyIcon = (item) => {
    props.setIsCopiedCouponCode(true)
    navigator.clipboard.writeText(item.couponCode);

    setTimeout(() => {
      props.setIsCopiedCouponCode(false)
    }, 1000)
  }

  return (<div className="offers-deals-container">
    {
      props.categories.map((category) => {
        return <div key={category._id}>
          <div className="title">
            <p className="mb-0">{category.name}</p>
          </div>
          {props.offerDeals.map((item) => {
            return (
              <div key={item._id}>
                {item.category.name == category.name ?
                  <div className="offer-deal-container">
                    <div className="offer-deal bg-white">
                      <div className="offer-deal-image">
                        <img src={item.image ? `${process.env.REACT_APP_IMAGE_URL}${item.image}` : "/assets/offersdeals/offersdeals.png"} alt="Offer" />
                      </div>
                      <div className="coupon-code-container d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <p className="coupon-code-text mb-0">COUPON CODE:</p>
                          <p className="coupon-code mb-0">{item.couponCode}</p>
                          <button
                            onClick={() => {
                              handleClickCopyIcon(item)
                            }}
                            className="copy-code border-0 d-grid justify-content-center align-items-center"
                          >
                            <img src="/assets/offersdeals/copy.svg" alt="Copy" />
                          </button>
                        </div>
                        <div>
                          <button onClick={() => { handleClickInformationIcon(item) }} className="info border-0 d-grid justify-content-center align-items-center">
                            <img src="/assets/offersdeals/info.svg" alt="Info" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> : ""}
              </div>
            );
          })}
        </div>
      })
    }
  </div>
  );
}

export default OfferDeal;
