import React from "react";
import Loader from "../../../components/loader";

function PayNow(props) {

  return (
    <div onClick={() => {
      props.handleClick();
    }} className="pay-container d-flex justify-content-between align-items-center">
      <div>
        <p className="grand-total">Grand Total</p>
        {props.isPaymentFormembership=='true' ? <p className="price mb-0">{props.planInfo.price}</p> :
          <p className="price mb-0">
            {props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.services.length > 0 ? String.fromCharCode(props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.services[0].service.currencySymbol) : props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.products.length > 0 ? String.fromCharCode(props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.products[0].product.currencySymbol) : ""}{Math.round(props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.grandTotal)}
          </p>}
      </div>

      {props.isLoader ? <Loader width={16} height={16} color={"#FFFFFF"} /> :
        <div className="pay d-flex justify-content-center align-items-center">

          <span>Confirm</span>
          <img src="/assets/common/white_right.svg" alt="Right" />
        </div>}
    </div>
  );
}

export default PayNow