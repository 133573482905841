import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import useGetData from "../../../customhooks/getdata";

import { IoLogoWhatsapp } from "react-icons/io";

function Header(props) {
  const navigate = useNavigate();

  const [memberships, setMemberships] = useState([]);

  const [discountOnMembership, setDiscountOnMembership] = useState("");

  const [
    gotMembershipsPlan,
    membershipsPlanError,
    getMembershipsPlan,
  ] = useGetData();

  const [gotMembership, membershipError, getMembership] = useGetData();

  useEffect(() => {
    getMembershipsPlan({
      url: `${process.env.REACT_APP_API_URL}/app/membership/search?status=1`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getMembership({
        url: `${process.env.REACT_APP_API_URL
          }/app/order/membership/search?status=1&user._id=${localStorage.getItem(
            "userId"
          )}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (gotMembershipsPlan) {
      setMemberships(
        gotMembershipsPlan.data.memberships.sort((a, b) => {
          return a.price - b.price;
        })
      );
    }
  }, [gotMembershipsPlan]);

  useEffect(() => {
    if (
      ((props.gotCartList && props.gotCartList.data.cart) ||
        (props.gotGenericCart && props.gotGenericCart.data.genericCart)) &&
      memberships.length > 0
    ) {
      let serviceDiscount = "";

      let productDiscount = "";

      if (props.gotCartList && props.gotCartList.data.cart) {
        serviceDiscount =
          ((props.gotCartList.data.cart.cartCalculations.services.total -
            props.gotCartList.data.cart.cartCalculations.services.discount) *
            memberships[0].serviceDiscount) /
          100;

        productDiscount =
          ((props.gotCartList.data.cart.cartCalculations.products.total -
            props.gotCartList.data.cart.cartCalculations.products.discount) *
            memberships[0].productDiscount) /
          100;
      }

      if (props.gotGenericCart && props.gotGenericCart.data.genericCart) {
        serviceDiscount =
          ((props.gotGenericCart.data.genericCart.cartCalculations.services
            .total -
            props.gotGenericCart.data.genericCart.cartCalculations.services
              .discount) *
            memberships[0].serviceDiscount) /
          100;

        productDiscount =
          ((props.gotGenericCart.data.genericCart.cartCalculations.products
            .total -
            props.gotGenericCart.data.genericCart.cartCalculations.products
              .discount) *
            memberships[0].productDiscount) /
          100;
      }

      setDiscountOnMembership(serviceDiscount + productDiscount);
    }
  }, [memberships, props.gotCartList, props.gotGenericCart]);

  const handleClickOnback = () => {
    if (localStorage.getItem("userId")) {

      props.handleRemoveCoupon()
    }

    navigate(-1);
  }

  return (
    <div className="header-container">
      <div className="header d-flex align-items-center justify-content-between">
        <button
          onClick={() => {
            handleClickOnback()
          }}
          className="border-0 bg-white"
        >
          <img src="/assets/common/back.svg" alt="Back" />
        </button>

        <p className="mb-0">Your Cart</p>
        <div className="whatsapp-image">
          <a href="//api.whatsapp.com/send?phone=918884877662&text=">
            <IoLogoWhatsapp />
          </a>
        </div>
      </div>

      {/* {sessionStorage.getItem("isQrCode") != "true" ? (
        <>
          {((props.gotCartList && props.gotCartList.data.cart) ||
            (props.gotGenericCart && props.gotGenericCart.data.genericCart)) &&
          gotMembershipsPlan &&
          gotMembershipsPlan.data.memberships.length > 0 ? (
            <>
              {gotMembership &&
              gotMembership.data.membershipOrders.length > 0 &&
              gotMembership.data.membershipOrders[0].status == 1 ? (
                ""
              ) : (
                <div className="get-membership-container d-flex justify-content-between align-items-center">
                  <div>
                    <p className="mb-0">
                      You can save{" "}
                      {props.gotCartList ? (
                        <>
                          {props.gotCartList.data.cart.services.length > 0
                            ? String.fromCharCode(
                                props.gotCartList.data.cart.services[0].service
                                  .currencySymbol
                              )
                            : props.gotCartList.data.cart.products.length > 0
                            ? String.fromCharCode(
                                props.gotCartList.data.cart.products[0].product
                                  .currencySymbol
                              )
                            : ""}
                          {discountOnMembership}
                        </>
                      ) : props.gotGenericCart ? (
                        <>
                          {props.gotGenericCart.data.genericCart.services
                            .length > 0
                            ? String.fromCharCode(
                                props.gotGenericCart.data.genericCart
                                  .services[0].service.currencySymbol
                              )
                            : props.gotGenericCart.data.genericCart.products
                                .length > 0
                            ? String.fromCharCode(
                                props.gotGenericCart.data.genericCart
                                  .products[0].product.currencySymbol
                              )
                            : ""}
                          {discountOnMembership}
                        </>
                      ) : (
                        ""
                      )}{" "}
                      on this order with Groome membership{" "}
                    </p>
                  </div>
                  <div>
                    <Link to="/membership">Get Membership</Link>
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )} */}
    </div>
  );
}

export default Header;
