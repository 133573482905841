import React, { useCallback, useEffect, useState } from "react";

import Loader from "../../components/loader";
import useIsLoader from "../../customhooks/useisloader";

import Address from "../../components/address";
import PaymentDetails from "./paymentdetails";
import AddInstructions from "./addinstructions";
import Proceed from "./proceed";
import Product from "./product";
import Service from "./service";
import Header from "./header";
import Instruction from "./instructions";
import AppliedCoupon from "./appliedcoupon";
import AddServiceProducts from "./addservicesproducts";

import PopUp from "../../components/popup";

import AppliedCouponFromCoupons from "./apppliedcouponfromcoupons";

import Coupons from "../coupons";

import useIsPopUp from "../../customhooks/ispopup";

import Slot from "../slot";

import Payment from "../payment";

import { authJsonHeaders, headers } from "../../constants";
import moment from "moment";
import getRequest from "../../functions/apicall/getrequest";
import deleteRequest from "../../functions/apicall/deleterequest";
import putRequest from "../../functions/apicall/putrequest";
import { useNavigate } from "react-router-dom";
import { gtm } from "../gtm";

function Cart() {
  const navigate = useNavigate();

  const [isLoader, setIsLoader] = useIsLoader(true);

  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [popUpMessage, setPopUpMessage] = useState("");

  const [cartList, setCartList] = useState(null);

  const [userAddress, setUserAddress] = useState(null);

  const [genericCart, setGenericCart] = useState(null);

  const [isInsruction, setIsInstruction] = useState(false);

  const [isOpenAddressBook, setIsOpenAddressBook] = useState(false);

  const [isOpenCoupon, setIsOpenCoupon] = useState(false);

  const [isAppliedCoupon, setIsAppliedCoupon] = useState(false);

  const [isProceed, setIsProceed] = useState(false);

  const moveGenericCart = () => {
    const res = deleteRequest({
      url: `${
        process.env.REACT_APP_API_URL
      }/app/genericCart/${localStorage.getItem("cartId")}/move`,
      body: {},
    });

    return Promise.resolve(res);
  };

  const getGenericCart = async () => {
    const res = await getRequest(
      `${process.env.REACT_APP_API_URL}/app/genericCart/${localStorage.getItem(
        "cartId"
      )}`
    );

    return Promise.resolve(res);
  };

  const getCartList = async () => {
    const res = await getRequest(
      `${process.env.REACT_APP_API_URL}/app/cart?user=${localStorage.getItem(
        "userId"
      )}`
    );

    return Promise.resolve(res);
  };

  const removeCoupon = async () => {
    const res = await putRequest({
      url: `${process.env.REACT_APP_API_URL}/app/coupon/removeCoupon`,
      body: {},
    });

    return Promise.resolve(res);
  };

  const getAddress = async () => {
    const res = await getRequest(
      `${
        process.env.REACT_APP_API_URL
      }/app/userAddress/search?user=${localStorage.getItem("userId")}`
    );

    return Promise.resolve(res);
  };

  const callbackRemoveCoupon = useCallback(() => {
    if (localStorage.getItem("userId")) {
      removeCoupon({
        url: `${process.env.REACT_APP_API_URL}/app/coupon/removeCoupon`,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: {},
      });
    }
  }, []);

  const handleRemoveCoupon = async () => {
    const res = await removeCoupon({
      url: `${process.env.REACT_APP_API_URL}/app/coupon/removeCoupon`,
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: {},
    });

    return Promise.resolve(res);
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      const addressResRef = getAddress();

      addressResRef.then((res) => {
        if (res) {
          setUserAddress(res);
        }
      });
    }
  }, []);

  const checkCouponToBeRemove = (res) => {
    if (res.data.cart && res.data.cart.appliedCoupon) {
      if (
        res.data.cart.appliedCoupon &&
        res.data.cart.cartCalculations.overall.totalItems == 0
      ) {
        const removeCouponResRef1 = handleRemoveCoupon();

        removeCouponResRef1.then((res1) => {
          if (res1) {
            const carResRef1 = getCartList();

            carResRef1.then((res) => {
              if (res) {
                setCartList(res);
              }
            });
          }
        });
      }

      if (
        moment().isAfter(
          res &&
            res.data.cart.appliedCoupon &&
            res.data.cart.appliedCoupon.validTo
        )
      ) {
        const removeCouponResRef2 = handleRemoveCoupon();

        removeCouponResRef2.then((res1) => {
          if (res1) {
            const carResRef2 = getCartList();

            carResRef2.then((res) => {
              if (res) {
                setCartList(res);
              }
            });
          }
        });
      }

      if (
        (res.data.cart.appliedCoupon &&
          !isAppliedCoupon &&
          res.data.cart.appliedCoupon.minValue >=
            res.data.cart.cartCalculations.overall.grandTotal) ||
        res.data.cart.cartCalculations.overall.grandTotal == 0
      ) {
        const removeCouponResRef3 = handleRemoveCoupon();

        removeCouponResRef3.then((res1) => {
          if (res1) {
            const carResRef3 = getCartList();

            carResRef3.then((res) => {
              if (res) {
                setCartList(res);
              }
            });
          }
        });

        setPopUpMessage(
          `Sorry! Your grand total should be greater than ${res.data &&
            res.data.cart &&
            res.data.cart.appliedCoupon &&
            res.data.cart.appliedCoupon.minValue}.`
        );
        setIsPopUp(true);
      }
    }
  };

  useEffect(() => {
    setIsLoader(true);

    if (localStorage.getItem("userId")) {
      if (localStorage.getItem("cartId")) {
        const genericCartResRef = getGenericCart();

        genericCartResRef.then((res) => {
          if (res) {
            if (
              res.data.genericCart &&
              res.data.genericCart.cartCalculations.overall.totalItems > 0
            ) {
              const moveGenericCartResRef = moveGenericCart();

              moveGenericCartResRef.then((res) => {
                if (res) {

                  const p1 = getCartList();

                  p1.then((res) => {
                    if (res) {
                      setCartList(res);
                      checkCouponToBeRemove(res);
                    }
                  });

                  const genericCartResRef2 = getGenericCart();

                  genericCartResRef2.then((res) => {
                    if (res) {
                      setGenericCart(res);
                      setIsLoader(false);
                    }
                  });

                  localStorage.removeItem("cartId")

                  setIsLoader(true);
                } else {
                  setIsLoader(true);
                }
              });
            } else {
              const cartResRef = getCartList();

              cartResRef.then((res) => {
                if (res) {
                  setCartList(res);
                  checkCouponToBeRemove(res);
                  setIsLoader(false);
                } else {
                  setIsLoader(false);
                }
              });
            }
          } else {
            setIsLoader(false);
          }
        });
      } else {
        const cartResRef2 = getCartList();

        cartResRef2.then((res) => {
          if (res) {
            setCartList(res);
            checkCouponToBeRemove(res);
            setIsLoader(false);
          }
        });
      }
    } else if (localStorage.getItem("cartId")) {
      const genericCartResRef2 = getGenericCart();

      genericCartResRef2.then((res) => {
        if (res) {
          setGenericCart(res);
          setIsLoader(false);
        }
      });
    }
  }, []);

  const openAddInstruction = () => {
    setIsInstruction(true);
    document.body.style.overflow = "hidden";
  };

  const closeAddInstruction = () => {
    setIsInstruction(false);
    document.body.style.overflow = "unset";
  };

  const openAddressPage = () => {
    document.body.style.overflow = "hidden";

    setIsOpenAddressBook(true);
  };

  const closeAddressPage = () => {
    document.body.style.overflow = "unset";

    setIsOpenAddressBook(false);
  };

  // useEffect(() => {
  //   return callbackRemoveCoupon;
  // }, []);

  useEffect(() => {
    if (isProceed) {
      if (cartList.data.cart.services.length > 0) {
        sessionStorage.setItem("slotPayment", true);
        navigate("/slot");
      }

      if (
        cartList.data.cart.services.length == 0 &&
        cartList.data.cart.products.length > 0
      ) {
        sessionStorage.setItem("slotPayment", true);
        navigate("/payment/false");
      }
    }
  }, [isProceed]);

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <>
      {isOpenCoupon || isProceed ? (
        ""
      ) : (
        <div className="cart-page d-grid">
          <Header handleRemoveCoupon = {handleRemoveCoupon} gotGenericCart={genericCart} gotCartList={cartList} />
          {isLoader ? (
            <Loader height={60} width={60} color={"#772286"} />
          ) : (
            <div className="info-container d-flex flex-column justify-content-between">
              <div className="sub-info-container">
                {(cartList &&
                  cartList.data.cart &&
                  cartList.data.cart.products.length > 0) ||
                (genericCart &&
                  genericCart.data.genericCart &&
                  genericCart.data.genericCart.products.length > 0) ? (
                  <Product
                    checkCouponToBeRemove={checkCouponToBeRemove}
                    setCartList={setCartList}
                    setGenericCart={setGenericCart}
                    gotGenericCart={genericCart}
                    getGenericCart={getGenericCart}
                    setIsPopUp={setIsPopUp}
                    setPopUpMessage={setPopUpMessage}
                    gotCartList={cartList}
                    getCartList={getCartList}
                  />
                ) : (
                  ""
                )}

                {(cartList &&
                  cartList.data.cart &&
                  cartList.data.cart.services.length > 0) ||
                (genericCart &&
                  genericCart.data.genericCart &&
                  genericCart.data.genericCart.services.length > 0) > 0 ? (
                  <Service
                    checkCouponToBeRemove={checkCouponToBeRemove}
                    setCartList={setCartList}
                    setGenericCart={setGenericCart}
                    gotGenericCart={genericCart}
                    getGenericCart={getGenericCart}
                    gotCartList={cartList}
                    getCartList={getCartList}
                  />
                ) : (
                  ""
                )}

                {(cartList &&
                  cartList.data.cart &&
                  cartList.data.cart.cartCalculations.overall.totalItems > 0) ||
                (genericCart &&
                  genericCart.data.genericCart &&
                  genericCart.data.genericCart.cartCalculations.overall
                    .totalItems > 0) ? (
                  <Instruction
                    setCartList={setCartList}
                    openAddInstruction={openAddInstruction}
                    gotCartList={cartList}
                    getCartList={getCartList}
                  />
                ) : (
                  ""
                )}

                <AddServiceProducts />

                {(cartList &&
                  cartList.data.cart &&
                  cartList.data.cart.cartCalculations.overall.totalItems > 0) ||
                (genericCart &&
                  genericCart.data.genericCart &&
                  genericCart.data.genericCart.cartCalculations.overall
                    .totalItems > 0) ? (
                  <AppliedCoupon
                    handleRemoveCoupon={handleRemoveCoupon}
                    gotCartList={cartList}
                    getCartList={getCartList}
                    setCartList={setCartList}
                    setIsOpenCoupon={setIsOpenCoupon}
                  />
                ) : (
                  ""
                )}
                {(cartList &&
                  cartList.data.cart &&
                  cartList.data.cart.cartCalculations.overall.totalItems > 0) ||
                (genericCart &&
                  genericCart.data.genericCart &&
                  genericCart.data.genericCart.cartCalculations.overall
                    .totalItems > 0) ? (
                  <PaymentDetails
                    gotGenericCart={genericCart}
                    gotCartList={cartList}
                  />
                ) : (
                  ""
                )}
              </div>

              {(cartList &&
                cartList.data.cart &&
                cartList.data.cart.cartCalculations.overall.totalItems > 0) ||
              (genericCart &&
                genericCart.data.genericCart &&
                genericCart.data.genericCart.cartCalculations.overall
                  .totalItems > 0) ? (
                <Proceed
                  gotGenericCart={genericCart}
                  setIsProceed={setIsProceed}
                  setIsPopUp={setIsPopUp}
                  setPopUpMessage={setPopUpMessage}
                  openAddressPage={openAddressPage}
                  gotAddress={userAddress}
                  gotCartList={cartList}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      )}

      {isInsruction ? (
        <AddInstructions
          setCartList={setCartList}
          getCartList={getCartList}
          closeAddInstruction={closeAddInstruction}
        />
      ) : (
        ""
      )}

      {isOpenAddressBook ? (
        <Address
          setUserAddress={setUserAddress}
          getAddress={getAddress}
          closeAddressPage={closeAddressPage}
        />
      ) : (
        ""
      )}

      {isOpenCoupon ? (
        <Coupons
          setCartList={setCartList}
          gotCartList={cartList}
          getCartList={getCartList}
          setIsOpenCoupon={setIsOpenCoupon}
          setIsAppliedCoupon={setIsAppliedCoupon}
        />
      ) : (
        ""
      )}

      {isAppliedCoupon && cartList && cartList.data.cart.appliedCoupon ? (
        <AppliedCouponFromCoupons
          setIsAppliedCoupon={setIsAppliedCoupon}
          gotCartList={cartList}
        />
      ) : (
        ""
      )}
      {/* 
      {isProceed &&
      cartList &&
      cartList.data.cart &&
      cartList.data.cart.services.length > 0 ? (
        <Slot gotCartList={cartList} setIsProceed={setIsProceed} />
      ) : (
        ""
      )}
      {isProceed &&
      cartList &&
      cartList.data.cart &&
      cartList.data.cart.services.length == 0 &&
      cartList &&
      cartList.data.cart &&
      cartList.data.cart.products.length > 0 ? (
        <Payment gotCartList={cartList} setIsProceed={setIsProceed} />
      ) : (
        ""
      )} */}

      {isPopUp ? (
        <PopUp
          setPopUpMessage={setPopUpMessage}
          setIsPopUp={setIsPopUp}
          message={popUpMessage}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Cart;
