import React from "react";

import { RWebShare } from "react-web-share";

function Functionality(props) {

    return (

        <div className="functionality-container d-grid align-items-center">
            <div>
                <a href={`tel:${props.gotSalon && props.gotSalon.data.salon.contactNumber}`}
                    className="functionality d-flex align-items-center"
                >
                    <img src="/assets/common/call.svg" />
                    <p className="mb-0">Phone</p>

                </a>
            </div>
            <div>
                <a href={`//api.whatsapp.com/send?phone=${props.gotSalon && props.gotSalon.data.salon.contactNumber}&text=`}
                    className="functionality d-flex align-items-center"
                >
                    <img src="/assets/common/whatsapp.svg" />
                    <p className="mb-0">WhatsApp</p>

                </a>
            </div>
            <div>
                <a
                    href={`https://www.google.com/maps/dir/?api=1&origin=${sessionStorage.getItem("lat")},${sessionStorage.getItem("lng")}&destination=${props.gotSalon && props.gotSalon.data.salon.location.lat},${props.gotSalon && props.gotSalon.data.salon.location.lng}&travelmode=driving`}
                    className="functionality d-flex align-items-center"
                >
                    <img src="/assets/common/marker2.svg" />
                    <p className="mb-0">Location</p>

                </a>
            </div>
            {/* <div>
                <RWebShare data={{
                    text: "",
                    url: "",
                    title: "Share",
                }}>
                    <a
                        className="functionality d-flex align-items-center"
                    >
                        <img src="/assets/common/share.svg" />
                        <p className="mb-0">Share</p>
                    </a>
                </RWebShare>

            </div> */}
        </div>
    );
}

export default Functionality;
