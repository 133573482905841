import React, { useState, useEffect, useCallback } from "react";

import PaymentDetails from "./paymentdetails";
import PaymentMethod from "./paymentmethod";
import Header from "./header";
import UserAddress from "./useraddress";
import PayNow from "./paynow";
import Address from "../../components/address";
import SalonAddress from "./salonaddress";

import PopUp from "../../components/popup";
import useIsPopUp from "../../customhooks/ispopup";
import { useNavigate, useParams } from "react-router-dom";
import PaymentSuccess from "../paymentsuccess";
import PaymentFailed from "../paymentfailed";
import useIsLoader from "../../customhooks/useisloader";

import { authJsonHeaders } from "../../constants";

import useRazorpay from "react-razorpay";

import { Browser } from "@capacitor/browser";
import { getAddress, getCartList } from "../../functions";
import getRequest from "../../functions/apicall/getrequest";
import postRequest from "../../functions/apicall/postrequest";
import putRequest from "../../functions/apicall/putrequest";
import { gtm } from "../gtm";

function Payment(props) {
  const Razorpay = useRazorpay();

  const params = useParams();

  const navigate = useNavigate();

  const [isLoader, setIsLoader] = useIsLoader(false);

  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [popUpMessage, setPopUpMessage] = useState("");

  const [addressId, setAddressId] = useState();

  const [isOpenAddressBook, setIsOpenAddressBook] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(null);

  const [userAddress, setUserAddress] = useState(null);

  const [transactionId, setTransactionId] = useState(null);

  const [membershipTransactionId, setMembershipTransactionId] = useState(null);

  const [cart, setCart] = useState(null);

  const [userWallet, setUserWallet] = useState(null);

  const [isWalletChecked, setIsWalletChecked] = useState(false);

  const dateTime = JSON.parse(sessionStorage.getItem("dateTime"));

  const membershipItem = JSON.parse(sessionStorage.getItem("membershipItem"));


  const getUserWallet = async () => {
    const res = await getRequest(
      `${process.env.REACT_APP_API_URL
      }/app/wallet/user/search?user=${localStorage.getItem("userId")}`
    );

    return Promise.resolve(res);
  };

  const order = async () => {

    let body = {}

    if (cart.data && cart.data.cart && cart.data.cart.serviceType && cart.data.cart.serviceType === "At Salon") {
      body = {
        slotDate: dateTime && dateTime.date,
        slotTime: dateTime && dateTime.time,
        qrCode: sessionStorage.getItem("isQrCode") == "true" ? true : false,
        paymentMode: paymentMethod,
        wallet:
          sessionStorage.getItem("isQrCode") == "true"
            ? 0
            : isWalletChecked
              ? userWallet && cart && userWallet.data.userWallets.length > 0
                ? userWallet.data.userWallets[0].user.wallet >
                  cart.data.cart.cartCalculations.overall.grandTotal
                  ? cart.data.cart.cartCalculations.overall.grandTotal
                  : userWallet.data.userWallets[0].user.wallet
                : 0
              : 0,
      }
    }

    else if (cart.data && cart.data.cart && cart.data.cart.serviceType && cart.data.cart.serviceType === "At Home") {
      body = {
        address: addressId,
        slotDate: dateTime && dateTime.date,
        slotTime: dateTime && dateTime.time,
        qrCode: sessionStorage.getItem("isQrCode") == "true" ? true : false,
        paymentMode: paymentMethod,
        wallet:
          sessionStorage.getItem("isQrCode") == "true"
            ? 0
            : isWalletChecked
              ? userWallet && cart && userWallet.data.userWallets.length > 0
                ? userWallet.data.userWallets[0].user.wallet >
                  cart.data.cart.cartCalculations.overall.grandTotal
                  ? cart.data.cart.cartCalculations.overall.grandTotal
                  : userWallet.data.userWallets[0].user.wallet
                : 0
              : 0,
      }
    }

    else {
      body = {
        address: addressId,
        qrCode: sessionStorage.getItem("isQrCode") == "true" ? true : false,
        paymentMode: paymentMethod,
        wallet:
          sessionStorage.getItem("isQrCode") == "true"
            ? 0
            : isWalletChecked
              ? userWallet && cart && userWallet.data.userWallets.length > 0
                ? userWallet.data.userWallets[0].user.wallet >
                  cart.data.cart.cartCalculations.overall.grandTotal
                  ? cart.data.cart.cartCalculations.overall.grandTotal
                  : userWallet.data.userWallets[0].user.wallet
                : 0
              : 0,
      }
    }

    const res = await postRequest({
      url: `${process.env.REACT_APP_API_URL}/app/order`,
      body: body,
    });

    return Promise.resolve(res);
  };

  const orderMembership = async () => {
    const res = await postRequest({
      url: `${process.env.REACT_APP_API_URL}/app/order/membership`,
      body: {
        membership: membershipItem._id,
        wallet: isWalletChecked
          ? userWallet && userWallet.data.userWallets.length > 0
            ? userWallet.data.userWallets[0].user.wallet > membershipItem.price
              ? membershipItem.price
              : userWallet.data.userWallets[0].user.wallet
            : 0
          : 0,
      },
    });

    return Promise.resolve(res);
  };

  const completeTransactions = async (orderId) => {
    const res = await postRequest({
      url: `${process.env.REACT_APP_API_URL}/app/transaction`,
      body: {
        order: orderId,
        paymentMode: paymentMethod,
        payment: paymentMethod === "COD" ? "COD" : "Online",
      },
    });

    return Promise.resolve(res);
  };

  const completeMembershipTransactions = async (orderId) => {
    const res = await postRequest({
      url: `${process.env.REACT_APP_API_URL}/app/transaction/membership`,
      body: {
        membershipOrder: orderId,
        paymentMode: paymentMethod,
        payment: paymentMethod === "COD" ? "COD" : "Online",
      },
    });

    return Promise.resolve(res);
  };

  const updateTransactions = async (id, updateTransactionBody) => {
    const res = await putRequest({
      url: `${process.env.REACT_APP_API_URL}/app/transaction/${id}`,
      body: updateTransactionBody,
    });

    return Promise.resolve(res);
  };

  const updateMembershipTransactions = async (id, updateTransactionBody) => {
    const res = await putRequest({
      url: `${process.env.REACT_APP_API_URL}/portal/transaction/membership/${id}`,
      body: updateTransactionBody,
    });

    return Promise.resolve(res);
  };

  const emptyCart = async () => {
    const res = await putRequest({
      url: `${process.env.REACT_APP_API_URL}/app/cart/empty`,
      body: {},
    });

    return Promise.resolve(res);
  };

  useEffect(() => {
    const cartResProm = getCartList();

    const addressResProm = getAddress();

    const walletResProm = getUserWallet();

    Promise.all([cartResProm, addressResProm, walletResProm]).then((res) => {
      const [cartResRef, addressResRef, walletResRef] = res;

      if (addressResRef) {
        addressResRef.data.userAddresses.forEach((item) => {
          if (item.isDefault) {
            setAddressId(item._id);
          }
        });
        setUserAddress(addressResRef);
      }

      if (cartResRef) {
        setCart(cartResRef);
      }

      if (walletResRef) {
        setUserWallet(walletResRef);
      }
    });
  }, []);

  const handleClickOnRazResponse = (
    razOrderid,
    razPaymentId,
    razSignature,
    reason,
    id
  ) => {
    let updateTransactionBody = {};

    if (
      process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED == "true" &&
      paymentMethod == "PayU"
    ) {
      if (razOrderid && razPaymentId && razSignature && reason == "success") {
        updateTransactionBody = {
          paymentStatus: "Success",
          razorpay_order_id: razOrderid,
          razorpay_payment_id: razPaymentId,
          razorpay_signature: razSignature,
        };
      } else if (
        razOrderid &&
        razPaymentId &&
        !razSignature &&
        (reason == "payment_failed" || reason == "")
      ) {
        updateTransactionBody = {
          paymentStatus: "Failed",
          razorpay_order_id: razOrderid,
          razorpay_payment_id: razPaymentId,
        };
      } else if (
        !razOrderid &&
        !razPaymentId &&
        !razSignature &&
        (reason == "payment_cancelled" || reason == "")
      ) {

        updateTransactionBody = {
          paymentStatus: "Cancelled",
        };
      }
    } else {
      updateTransactionBody = {
        paymentStatus: "Success",
      };
    }

    if (params.isMembership == "true") {
      const updateMembershipResProm = updateMembershipTransactions(
        id,
        updateTransactionBody
      );

      updateMembershipResProm.then((res) => {
        if (res) {
          sessionStorage.removeItem("slotPayment");
          if (res.data.membershipTransaction.paymentStatus == "Success") {
            sessionStorage.removeItem("slotPayment");
            if (process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED == "true") {
              navigate(
                `/membershippaymentsuccess/${res.data.membershipTransaction.membershipOrder._id}`
              );
            } else {
              navigate("/membership");
            }

            // window.location.replace("/membership")
          } else if (res.data.membershipTransaction.paymentStatus == "Failed") {
            if (process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED == "true") {
              sessionStorage.removeItem("slotPayment");
              window.location.replace(
                `/membershippaymentfailed/${res.data.membershipTransaction.membershipOrder._id}`
              );
            } else {
              navigate("/membership");
            }
          } else if (
            res.data.membershipTransaction.paymentStatus == "Cancelled"
          ) {
            if (process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED == "true") {
              sessionStorage.removeItem("slotPayment");
              window.location.replace(
                `/membershippaymentfailed/${res.data.membershipTransaction.membershipOrder._id}`
              );
            }
          }
        }
        else {
          setIsLoader(false);
        }
      });
    } else {
      const updateResRef = updateTransactions(id, updateTransactionBody);

      updateResRef.then((res) => {
        if (res) {
          if (sessionStorage.getItem("origin") != "portal") {
            if (res.data.transaction.paymentStatus == "Success") {
              const emptyCartResProm = emptyCart();

              emptyCartResProm.then((emptyCartRes) => {
                if (emptyCartRes) {
                  sessionStorage.removeItem("slotPayment");

                  if (
                    process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED == "true" &&
                    paymentMethod == "PayU"
                  ) {
                    navigate(
                      `/paymentsuccess/${res.data.transaction.order._id}`
                    );
                  } else {
                    navigate(`/bookingsorders`);
                  }
                }
              });
            } else if (res.data.transaction.paymentStatus == "Failed") {
              if (process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED == "true") {
                sessionStorage.removeItem("slotPayment");
                window.location.replace(
                  `/paymentfailed/${res.data.transaction.order._id}`
                );
              }
            } else if (res.data.transaction.paymentStatus == "Cancelled") {
              if (process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED == "true") {
                sessionStorage.removeItem("slotPayment");
                window.location.replace(
                  `/paymentfailed/${res.data.transaction.order._id}`
                );
              }
            }
          } else {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/ordersuccess");
            Browser.open({
              url: `${process.env.REACT_APP_BASE_URl}ordersuccess`,
            });
          }
        }
        else {
          setIsLoader(false);
        }
      });
    }
  };

  const openAddressPage = () => {
    document.body.style.overflow = "hidden";

    setIsOpenAddressBook(true);
  };

  const closeAddressPage = () => {
    document.body.style.overflow = "unset";

    setIsOpenAddressBook(false);
  };

  const handleClick = () => {
    if (params.isMembership == "true") {
      if (paymentMethod) {
        setIsLoader(true);

        const memebershipResProm = orderMembership();

        memebershipResProm.then((res) => {
          if (res) {
            const membershipTransResProm = completeMembershipTransactions(
              res.data.membershipOrder._id
            );

            membershipTransResProm.then((membershipTransRes) => {
              if (membershipTransRes) {

                setMembershipTransactionId(
                  membershipTransRes.data.membershipTransaction._id
                );

                if (
                  process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED == "true" &&
                  paymentMethod == "PayU" &&
                  sessionStorage.getItem("isQrCode") != "true"
                ) {
                  if (membershipTransRes.data.membershipTransaction) {
                    handlePayment({
                      name:
                        membershipTransRes.data.membershipTransaction.user.name,
                      email:
                        membershipTransRes.data.membershipTransaction.user
                          .email,
                      mobile:
                        membershipTransRes.data.membershipTransaction.user
                          .mobile,
                      orderId:
                        membershipTransRes.data.membershipTransaction
                          .razorPayDetails.orderId,
                      amount:
                        membershipTransRes.data.membershipTransaction.amount,
                      transactionId:
                        membershipTransRes.data.membershipTransaction._id,
                    });
                  }
                } else {
                  handleClickOnRazResponse(
                    false,
                    false,
                    false,
                    false,
                    membershipTransRes.data.membershipTransaction._id
                  );
                }
              }
            });
          } else {
            setIsLoader(false);
          }
        });
      } else {
        setIsPopUp(true);
        setPopUpMessage("Please select payment method.");
      }
    } else {
      if (paymentMethod) {
        setIsLoader(true);

        if (cart.data.cart.serviceType === "At Salon") {
          const orderResProm = order();

          orderResProm.then((res) => {
            if (res) {
              const transactionResProm = completeTransactions(
                res.data.order._id
              );

              transactionResProm.then((transactionRes) => {
                if (transactionRes) {
                  setTransactionId(transactionRes.data.transaction._id);

                  if (
                    process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED == "true" &&
                    paymentMethod == "PayU"
                  ) {
                    if (transactionRes.data.transaction) {
                      handlePayment({
                        name: transactionRes.data.transaction.user.name,
                        email: transactionRes.data.transaction.user.email,
                        mobile: transactionRes.data.transaction.user.mobile,
                        orderId:
                          transactionRes.data.transaction.razorPayDetails
                            .orderId,
                        amount: transactionRes.data.transaction.amount,
                        transactionId: transactionRes.data.transaction._id,
                      });
                    }
                  } else {
                    handleClickOnRazResponse(
                      false,
                      false,
                      false,
                      false,
                      transactionRes.data.transaction._id
                    );
                  }
                } else {
                  setIsLoader(false);
                }
              });
            } else {
              setIsLoader(false);
            }
          });
        } else if (cart.data.cart.serviceType === "At Home") {
          let isUserAddress = false;

          if (userAddress.data) {
            userAddress.data.userAddresses.forEach((item) => {
              if (item.isDefault) {
                isUserAddress = true;
              }
            });
          }

          if (!isUserAddress) {
            openAddressPage();
          } else {
            const orderResProm = order();

            orderResProm.then((res) => {
              if (res) {
                const transactionResProm = completeTransactions(
                  res.data.order._id
                );

                transactionResProm.then((transactionRes) => {
                  if (transactionRes) {
                    setTransactionId(transactionRes.data.transaction._id);

                    if (
                      process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED ==
                      "true" &&
                      paymentMethod == "PayU"
                    ) {
                      if (transactionRes.data.transaction) {
                        handlePayment({
                          name: transactionRes.data.transaction.user.name,
                          email: transactionRes.data.transaction.user.email,
                          mobile: transactionRes.data.transaction.user.mobile,
                          orderId:
                            transactionRes.data.transaction.razorPayDetails
                              .orderId,
                          amount: transactionRes.data.transaction.amount,
                          transactionId: transactionRes.data.transaction._id,
                        });
                      }
                    } else {
                      handleClickOnRazResponse(
                        false,
                        false,
                        false,
                        false,
                        transactionRes.data.transaction._id
                      );
                    }
                  } else {
                    setIsLoader(false);
                  }
                });
              }
              else {
                setIsLoader(false);
              }
            });
          }
        } else {
          if (sessionStorage.getItem("isQrCode") == "true") {
            const orderResProm = order();

            orderResProm.then((res) => {
              if (res) {
                const transactionResProm = completeTransactions(
                  res.data.order._id
                );

                transactionResProm.then((transactionRes) => {
                  if (transactionRes) {
                    handleClickOnRazResponse(
                      false,
                      false,
                      false,
                      false,
                      transactionRes.data.transaction._id
                    );
                  } else {
                    setIsLoader(false);
                  }
                });
              }
              else {
                setIsLoader(false);
              }
            });
          } else {
            let isUserAddress = false;

            if (userAddress.data) {
              userAddress.data.userAddresses.forEach((item) => {
                if (item.isDefault) {
                  isUserAddress = true;
                }
              });
            }

            if (!isUserAddress) {
              openAddressPage();
            } else {
              const orderResProm = order();

              orderResProm.then((res) => {
                if (res) {
                  const transactionResProm = completeTransactions(
                    res.data.order._id
                  );

                  transactionResProm.then((transactionRes) => {
                    if (transactionRes) {
                      setTransactionId(transactionRes.data.transaction._id);

                      if (
                        process.env.REACT_APP_PAYMENT_GATEWAY_REQUIRED ==
                        "true" &&
                        paymentMethod == "PayU"
                      ) {
                        if (transactionRes.data.transaction) {
                          handlePayment({
                            name: transactionRes.data.transaction.user.name,
                            email: transactionRes.data.transaction.user.email,
                            mobile: transactionRes.data.transaction.user.mobile,
                            orderId:
                              transactionRes.data.transaction.razorPayDetails
                                .orderId,
                            amount: transactionRes.data.transaction.amount,
                            transactionId: transactionRes.data.transaction._id,
                          });
                        }
                      } else {
                        handleClickOnRazResponse(
                          false,
                          false,
                          false,
                          false,
                          transactionRes.data.transaction._id
                        );
                      }
                    } else {
                      setIsLoader(false);
                    }
                  });
                }
                else {
                  setIsLoader(false);
                }
              });
            }
          }
        }
      } else {
        setIsPopUp(true);
        setPopUpMessage("Please select payment method.");
      }
    }
  };

  const handlePayment = (response) => {
    const id = response.transactionId;
    // const order = await createOrder(params); //  Create order on your backend

    const options = {
      key: "rzp_test_sBSpw2AJZSiWZz", // Enter the Key ID generated from the Dashboard
      amount: response.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Groome",
      description: "Test Transaction",
      image: "",
      // callback_url : "https://groome.in/",
      // redirect: true,
      order_id: response.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        handleClickOnRazResponse(
          response.razorpay_order_id,
          response.razorpay_payment_id,
          response.razorpay_signature,
          "success",
          id
        );
        // alert(response.razorpay_payment_id, response.razorpay_payment_id, response.razorpay_signature);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: response.name,
        email: response.email,
        contact: response.mobile,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#772286",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {

      if (response.error.reason == "payment_failed") {
        handleClickOnRazResponse(
          response.error.metadata.order_id,
          response.error.metadata.payment_id,
          false,
          "payment_failed",
          id
        );
      }
      if (response.error.reason == "") {

        handleClickOnRazResponse(false, false, false, "", id);
      }
      if (
        response.error.reason == "payment_failed" &&
        Object.keys(response.error.metadata).length == 0
      ) {
        handleClickOnRazResponse(false, false, false, "payment_failed", id);
      }

      if (response.error.reason == "payment_cancelled") {
        handleClickOnRazResponse(
          response.error.metadata.order_id,
          response.error.metadata.payment_id,
          false,
          "payment_cancelled",
          id
        );
      }

      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };

  useEffect(() => {
    gtm.page();
  }, [])

  return (
    <>
      <div className="payment-page d-flex flex-column justify-content-between">
        <div className="info-container">
          <Header
            isPaymentFormembership={params.isMembership}
            setIsPayment={props.setIsPayment}
            setTime={props.setTime}
            setIsProceed={props.setIsProceed}
            gotCartList={cart}
            planInfo={membershipItem}
          />

          <PaymentMethod
            isPaymentFormembership={params.isMembership}
            setPaymentMethod={setPaymentMethod}
          />

          {sessionStorage.getItem("isQrCode") == "true" ? (
            ""
          ) : (
            <div className="wallet-discount-container d-flex align-items-center bg-white">
              <label className="container">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    setIsWalletChecked(e.target.checked);
                  }}
                />
                <span className="checkmark"></span>
                {params.isMembership == "true" ? (
                  <p className="mb-0">
                    Apply Wallet Discount? (₹
                    {userWallet && userWallet.data.userWallets.length > 0
                      ? userWallet.data.userWallets[0].user.wallet >
                        membershipItem.price
                        ? membershipItem.price
                        : userWallet.data.userWallets[0].user.wallet
                      : 0}
                    )
                  </p>
                ) : (
                  <p className="mb-0">
                    Apply Wallet Discount? (₹
                    {userWallet &&
                      cart &&
                      userWallet.data.userWallets.length > 0
                      ? userWallet.data.userWallets[0].user.wallet >
                        cart.data.cart.cartCalculations.overall.grandTotal
                        ? cart.data.cart.cartCalculations.overall.grandTotal
                        : userWallet.data.userWallets[0].user.wallet
                      : 0}
                    )
                  </p>
                )}
              </label>
            </div>
          )}

          {cart && params.isMembership != "true" ? (
            <>
              {cart.data.cart.serviceType == "At Salon" ||
                (cart.data.cart.serviceType == null &&
                  cart.data.cart.products.length > 0 &&
                  sessionStorage.getItem("isQrCode") == "true") ? (
                <SalonAddress gotCartList={cart} />
              ) : (
                <UserAddress
                  gotAddress={userAddress}
                  openAddressPage={openAddressPage}
                />
              )}
            </>
          ) : (
            ""
          )}

          <PaymentDetails
            isPaymentFormembership={params.isMembership}
            planInfo={membershipItem}
            gotCartList={cart}
          />
        </div>

        <PayNow
          isLoader={isLoader}
          isPaymentFormembership={params.isMembership}
          planInfo={membershipItem}
          gotCartList={cart}
          handleClick={handleClick}
        />
      </div>
      {isOpenAddressBook ? (
        <Address
          setUserAddress={setUserAddress}
          getAddress={getAddress}
          closeAddressPage={closeAddressPage}
        />
      ) : (
        ""
      )}

      {isPopUp ? <PopUp setIsPopUp={setIsPopUp} message={popUpMessage} /> : ""}
    </>
  );
}

export default Payment;
