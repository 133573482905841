import React from "react";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {

  const navigate = useNavigate();

  return (
    <div className="h-100 not-found-page text-center d-grid">
      <div className="p-3 salon-page-back-button-container d-flex align-items-center">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="border-0 bg-white"
        >
          <img src="/assets/common/back.svg" alt="Back" />
        </button>
        <p className="mb-0 ms-3">Back</p>
      </div>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img src="/assets/salonsbysearch/not_found.png" alt="Not found" />
        <h1>404 Not found</h1>
      </div>
    </div>
  );
}

export default NotFoundPage;
