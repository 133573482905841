import React from "react";

function DigitalCatalog() {
  return (
    <div id="digital-catalog" className="product d-grid">
      <div className="info-container">
        <h3 className="title mb-0 ">Digital Catalog</h3>

        <h4 className="statement mb-0">
          Attract & Engage Your Clients with Interactive Website
        </h4>

        <p className="paragraph mb-0">
          Groome digital catalog makes it easier than ever to create, manage and
          update your customized digital store. You can use your digital catalog
          to list all your services, products, and special promotions. With
          Groome user-friendly salon management software, you can seamlessly
          update pricing, booking availability, and images in real-time and stay
          on top of your business.
        </p>

        <a href="#create-free-account" className="border-0">
          Create Free Account
        </a>
      </div>

      <div className="image-container">
        <img src="/assets/business/digitalcatalog.png" />
      </div>
    </div>
  );
}

export default DigitalCatalog;
