import React, { useEffect, useState } from "react";

function PaymentDetails(props) {

  const [total, setTotal] = useState(0)

  const [tax, setTax] = useState(0)

  useEffect(() => {
    if (props.gotCartList && props.gotCartList.data.cart) {
      setTotal(props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.grandTotal / 1.18)


      setTax(props.gotCartList.data.cart.cartCalculations.overall.grandTotal - props.gotCartList.data.cart.cartCalculations.overall.grandTotal / 1.18)
    }

    if (props.gotGenericCart && props.gotGenericCart.data.genericCart) {
      setTotal(props.gotGenericCart && props.gotGenericCart.data.genericCart && props.gotGenericCart.data.genericCart.cartCalculations.overall.grandTotal / 1.18)

      setTax(props.gotGenericCart.data.genericCart.cartCalculations.overall.grandTotal - props.gotGenericCart.data.genericCart.cartCalculations.overall.grandTotal / 1.18)
    }

  }, [props])

  return (
    <div className="payment-details-container bg-white">
      <div className="title-container text-center">
        <p className="mb-0">Payment Details</p>
      </div>
      <div className="subtotal d-flex justify-content-between align-items-center">
        <p className="mb-0">Subtotal</p>
        <p className="mb-0">
          {
            props.gotCartList ? <>{(String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol))}{props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.total}</> : props.gotGenericCart ? <>{String.fromCharCode(props.gotGenericCart.data.genericCart.salon.country.htmlSymbol)}{props.gotGenericCart && props.gotGenericCart.data.genericCart && props.gotGenericCart.data.genericCart.cartCalculations.overall.total}</> : ""
          }
        </p>
      </div>

      {(props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.deliveryCharges > 0 || props.gotGenericCart && props.gotGenericCart.data.genericCart && props.gotGenericCart.data.genericCart.cartCalculations.overall.deliveryCharges.deliveryCharges > 0) ?
        <div className="delivery-charges d-flex justify-content-between align-items-center">
          <p className="mb-0">Delivery Charges:</p>
          <p className="mb-0">
            {
              props.gotCartList ? <>{String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol)}
                {props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.deliveryCharges}</> : props.gotGenericCart ? <>- {String.fromCharCode(props.gotGenericCart.data.genericCart.salon.country.htmlSymbol)}
                  {props.gotGenericCart && props.gotGenericCart.data.genericCart && props.gotGenericCart.data.genericCart.cartCalculations.overall.deliveryCharges}</> : ""
            }

          </p>
        </div> : ""}
      <div className="discount d-flex justify-content-between align-items-center">
        <p className="mb-0">Discount:</p>
        <p className="mb-0">
          {
            props.gotCartList ? <>{String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol)}{props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.discount}</> : props.gotGenericCart ? <>{String.fromCharCode(props.gotGenericCart.data.genericCart.salon.country.htmlSymbol)}{props.gotGenericCart && props.gotGenericCart.data.genericCart && props.gotGenericCart.data.genericCart.cartCalculations.overall.discount}</> : ""
          }

        </p>
      </div>

      {props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.membershipDiscount > 0 ?
        <div className="membership d-flex justify-content-between align-items-center">
          <p className="mb-0">Membership Discount:</p>
          <p className="mb-0">

            {String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol)}{Math.round(props.gotCartList.data.cart.cartCalculations.overall.membershipDiscount)}

          </p>
        </div> : ""}

      <div className="coupon-discount d-flex justify-content-between align-items-center">
        <p className="mb-0">Coupon Discount:</p>
        <p className="mb-0">
          {
            props.gotCartList ? <> {String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol)}
              {props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.couponDiscount}</> : props.gotGenericCart ? <> {String.fromCharCode(props.gotGenericCart.data.genericCart.salon.country.htmlSymbol)}
                {props.gotGenericCart && props.gotGenericCart.data.genericCart && props.gotGenericCart.data.genericCart.cartCalculations.overall.couponDiscount}</> : ""
          }

        </p>
      </div>

      <div className="total d-flex justify-content-between align-items-center">
        <p className="mb-0">Total:</p>
        <p className="mb-0">

          {
            props.gotCartList ? <>{String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol)}
              {total.toFixed(2)}</> : props.gotGenericCart ? <> {String.fromCharCode(props.gotGenericCart.data.genericCart.salon.country.htmlSymbol)}
                {total.toFixed(2)}</> : ""
          }

        </p>
      </div>

      <div className="tax d-flex justify-content-between align-items-center">
        <p className="mb-0">Tax (GST):</p>
        <p className="mb-0">

          {
            props.gotCartList ? <>{String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol)}
              {tax.toFixed(2)}</> : props.gotGenericCart ? <> {String.fromCharCode(props.gotGenericCart.data.genericCart.salon.country.htmlSymbol)}
                {tax.toFixed(2)}</> : ""
          }

        </p>
      </div>
      <div className="grand-total d-flex justify-content-between align-items-center">
        <p className="mb-0">Grand Total:</p>
        <p className="mb-0">

          {
            props.gotCartList ? <>{String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol)}
              {Math.round(props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.grandTotal)}</> : props.gotGenericCart ? <> {String.fromCharCode(props.gotGenericCart.data.genericCart.salon.country.htmlSymbol)}
                {Math.round(props.gotGenericCart && props.gotGenericCart.data.genericCart && props.gotGenericCart.data.genericCart.cartCalculations.overall.grandTotal)}</> : ""
          }

        </p>
      </div>
    </div>
  );
}

export default PaymentDetails;