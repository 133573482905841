import React from "react";

function OnlineAppointment() {
  return (
    <div id="online-appointment" className="product d-grid">
      <div className="desktop-image-container image-container">
        <img src="/assets/business/appointment.png" />
      </div>
      <div className="info-container">
        <h3 className="title mb-0 ">Online Appointments & Booking</h3>

        <h4 className="statement mb-0">Easy To Book. Easier To Schedule</h4>

        <p className="paragraph mb-0">
          Groome salon and spa scheduling and booking system provides a secure
          platform for all walk-in and online booking appointments. Our solution
          enables you to cater to your customers more efficiently through
          WhatsApp integrated reminders and e- confirmations, ultimately
          minimizing no- shows. Our integrated dashboard salon booking system is
          what makes us the most easy-to-use and convenient salon and spa
          management software.
        </p>

        <a href="#create-free-account" className="border-0">
          Create Free Account
        </a>
      </div>
      <div className="mobile-image-container image-container">
        <img src="/assets/business/appointment.png" />
      </div>
    </div>
  );
}

export default OnlineAppointment;
