import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useGetData from "../../customhooks/getdata";

import { authJsonHeaders } from "../../constants";
import { gtm } from "../gtm";

function Faqs() {
  const navigate = useNavigate();

  const [faqs, setFaqs] = useState([]);

  const [gotFaqs, faqsError, getFaqs] = useGetData();

  const [activeFaqs, setActiveFaqs] = useState([]);

  const defaultStyle = {
    transform: "rotate(0deg)",
  };

  const [buttonStyle, setButtonStyle] = useState({});

  useEffect(() => {
    getFaqs({
      url: `${process.env.REACT_APP_API_URL}/app/faq/list?isActive=true&status=1`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }, []);

  useEffect(() => {
    if (gotFaqs) {
      setFaqs(gotFaqs.data.faqs);
    }
  }, [gotFaqs]);

  const handleClick = (item) => {
    if (activeFaqs.includes(item)) {
      setActiveFaqs(
        activeFaqs.filter((activeItem) => {
          if (activeItem != item) {
            return activeItem;
          }
        })
      );
    } else {
      setButtonStyle({
        transform: "rotate(-180deg)",
      });
      setActiveFaqs((prev) => [...prev, item]);
    }
  };

  const ConvertStringToHTML = (str) => {
    return { __html: str };
  };

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <div className="faqs-page">
      <div className="header-container d-flex align-items-center">
        <div>
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="border-0 bg-white"
          >
            <img src="/assets/common/back.svg" alt="Back" />
          </button>
        </div>
        <div className="w-100 text-center">
          <p className="mb-0">FAQs</p>
        </div>
      </div>

      <div className="faqs-container">
        {gotFaqs && gotFaqs.data ? (
          <>
            {gotFaqs.data.faqs.length > 0 ? (
              faqs.map((item) => {
                return (
                  <div key={item._id} className="faq-container">
                    <div className="faq d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="dot-image">
                          <img src="/assets/faqs/ellipse.svg" alt="Ellipse" />
                        </div>
                        <p className="mb-0">{item.title}</p>
                      </div>
                      <button
                        onClick={() => {
                          handleClick(item._id);
                        }}
                        className="border-0 bg-white"
                      >
                        <img
                          style={
                            activeFaqs.includes(item._id)
                              ? buttonStyle
                              : defaultStyle
                          }
                          src="/assets/common/down.svg"
                          alt="Down"
                        />
                      </button>
                    </div>
                    {activeFaqs.includes(item._id) ? (
                      <div className="description-container">
                        <p
                          className="mb-0"
                          dangerouslySetInnerHTML={ConvertStringToHTML(
                            item.description
                          )}
                        ></p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })
            ) : (
              <p className="message mb-0">No Faqs</p>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Faqs;
