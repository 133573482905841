import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { FaRegUserCircle } from "react-icons/fa"

import useGetData from "../../customhooks/getdata";
import Address from "../../components/address";

import moment from "moment";

import Logout from "./logout";

import { authJsonHeaders } from "../../constants";
import { gtm } from "../gtm";

const MyProfile = () => {

  const [isOpenAddressBook, setIsOpenAddressBook] = useState(false);

  const [dynamicPagesTitle, setDynamicPagesTitle] = useState([]);

  const navigate = useNavigate();

  const [gotUserProfile, userProfileError, getUserProfile] = useGetData();

  const [gotDynamicPagesTitle, dynamicPagesTitleError, getDynamicPagesTitle] = useGetData();

  const [isShowLogout, setIsShowLogout] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getUserProfile({
        url: `${process.env.REACT_APP_API_URL}/app/user/${localStorage.getItem("userId")
          }?status=1`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [])

  useEffect(() => {
    getDynamicPagesTitle({
      url: `${process.env.REACT_APP_API_URL}/app/dynamicPage/search?status=1`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }, [])

  useEffect(() => {
    if (gotDynamicPagesTitle) {
      setDynamicPagesTitle(gotDynamicPagesTitle.data.dynamicPages)
    }

  }, [gotDynamicPagesTitle])

  const openAddressPage = () => {
    if (localStorage.getItem("userId")) {
      document.body.style.overflow = "hidden";

      setIsOpenAddressBook(true);
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login")
    }
  };

  const closeAddressPage = () => {
    document.body.style.overflow = "unset";

    setIsOpenAddressBook(false);
  };

  const handleClickOnLike = () => {
    if (localStorage.getItem("userId")) {
      navigate("/favourites")
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login")
    }
  }

  const handleClickOnViewProfile = () => {
    if (localStorage.getItem("userId")) {
      navigate("/viewprofile")
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login")
    }
  }

  const handleClickOnWallet = () => {
    if (localStorage.getItem("userId")) {
      navigate("/wallet")
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login")
    }
  }

  const handleClickOnBookingOrder = () => {
    if (localStorage.getItem("userId")) {
      navigate("/bookingsorders")
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login")
    }
  }

  const handleClickOnOfferDeals = () => {
    if (localStorage.getItem("userId")) {
      navigate("/offersdeals")
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login")
    }
  }

  const handleClickOnMembership = () => {
    if (localStorage.getItem("userId")) {
      navigate("/membership")
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login")
    }
  }

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <>
      <div className="myprofile-page">
        <div className="header-container d-flex align-items-center bg-white">
          <div>
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="border-0"
            >
              <img src="/assets/common/back.svg" alt="Back" />
            </button>
          </div>
          <div className="w-100 text-center">
            <p className="mb-0">My Profile</p>
          </div>
        </div>

        {localStorage.getItem("userId") ? <div className="user-info-container d-flex justify-content-between align-items-center bg-white">
          <div className="user-info">
            <p className="name mb-0">
              {gotUserProfile && gotUserProfile.data && gotUserProfile.data.user && gotUserProfile.data.user.name}
            </p>
            {gotUserProfile && gotUserProfile.data && gotUserProfile.data.user && gotUserProfile.data.user.createdAt ?
              <div className="member-since d-flex align-items-center">
                <p className="mb-0">Member Since: </p>
                <p className="mb-0">{moment(gotUserProfile.data.user.createdAt).format("DD/MM/YYYY")}</p>
              </div> : ""}
            <div className="phone-number d-flex align-items-center">
              <div className="image d-flex align-items-center justify-content-center">
                <img src="assets/myprofile/mobile.svg" alt="Mobile" />
              </div>
              <p className="mb-0">
                {gotUserProfile && gotUserProfile.data && gotUserProfile.data.user && gotUserProfile.data.user.mobile}
              </p>
            </div>
          </div>
          <div className="user-profile-image">
            {
              gotUserProfile && gotUserProfile.data.user ? <>{gotUserProfile.data.user.image ? <img src={`${process.env.REACT_APP_IMAGE_URL}${gotUserProfile.data.user.image}`} alt="User" /> : <img src="/assets/common/user.svg" />}</> : ""
            }
          </div>
        </div> : ""}
        {!localStorage.getItem("userId") ?
          <div className="profile-without-login">
            <p>Log in to view your profile.</p>
            <Link to="/login">Continue</Link>
          </div> : ""}

        {sessionStorage.getItem("isQrCode") == "true" ? <><div onClick={() => { handleClickOnViewProfile() }} className="view-profile-container d-flex justify-content-between align-items-center bg-white">
          <div className="d-flex align-items-center">
            <div className="image d-grid justify-content-center align-items-center">
              <img
                src="assets/myprofile/view_profile.svg"
                alt="Img Not Found"
              />
            </div>
            <div className="view-profile">
              <p className="mb-0">View Profile</p>
            </div>
          </div>

          <div className="right-image">
            <img src="assets/common/purple_right.svg" alt="Right" />
          </div>
        </div>

          <div onClick={() => { handleClickOnOfferDeals() }} className="offer-deals-container d-flex justify-content-between align-items-center bg-white">
            <div className="d-flex align-items-center">
              <div className="image d-grid justify-content-center align-items-center">
                <img src="assets/myprofile/offer_deals.svg" alt="Img Not Found" />
              </div>
              <div className="offer-deals">
                <p className="mb-0">Offer & Deals</p>
              </div>
            </div>

            <div className="right-image">
              <img src="assets/common/purple_right.svg" alt="Right" />
            </div>
          </div></> :
          <div className="user-liked-offer-wallet d-flex justify-content-between">
            <div className="liked bg-white text-center">
              <button onClick={() => { handleClickOnLike() }}>
                <img src="assets/common/unlike.svg" alt="Img Not Found" />
                <p className="mb-0">Liked</p>
              </button>
            </div>

            <div className="offers bg-white text-center">
              <button onClick={() => { handleClickOnOfferDeals() }}>
                <img src="assets/myprofile/offer_deals.svg" alt="Img Not Found" />
                <p className="mb-0">Offer & Deals</p>
              </button>
            </div>

            <div className="view-profile bg-white text-center">
              <button onClick={() => { handleClickOnViewProfile() }}>
                <img
                  src="assets/myprofile/view_profile.svg"
                  alt="Img Not Found"
                />
                <p className="mb-0">View Profile</p>
              </button>
            </div>

            <div className="wallet bg-white text-center">
              <button onClick={() => { handleClickOnWallet() }}>
                <img src="assets/common/wallet.svg" alt="Img Not Found" />
                <p className="mb-0 ">Wallet</p>
              </button>
            </div>
          </div>}

        <div className="order-details-section">
          <div className="title">
            <p className="mb-0">Order Details</p>
          </div>
          <div onClick={() => { handleClickOnBookingOrder() }} className="booking-order-container d-grid align-items-baseline">
            <div className="image d-grid justify-content-center align-items-center">
              <img
                src="assets/myprofile/salon_booking.svg"
                alt="Salon Booking"
              />
            </div>
            <div className="booking-order d-grid justify-content-between">
              <p className="mb-0">Bookings & Orders</p>
              <div className="right-image">
                <img src="assets/common/purple_right.svg" alt="Right" />
              </div>
            </div>
          </div>

          <div onClick={() => {
            openAddressPage();
          }} className="address-booking-container d-grid align-items-baseline pb-0">
            <div className="image d-grid justify-content-center align-items-center">
              <img src="assets/myprofile/address_book.svg" alt="Address Book" />
            </div>
            <div className="address-booking d-grid justify-content-between">
              <p className="mb-0">Address Book</p>
              <div className="right-image">
                <img src="assets/common/purple_right.svg" alt="Right" />
              </div>
            </div>
          </div>
        </div>
        {/* {sessionStorage.getItem("isQrCode") == "true" ? "" :
          <div onClick={() => {
            handleClickOnMembership();
          }} className="membership-container d-flex justify-content-between align-items-center bg-white">
            <div className="d-flex align-items-center">
              <div className="image d-grid justify-content-center align-items-center">
                <img src="assets/myprofile/membership.svg" alt="Membership" />
              </div>
              <div className="membership">
                <p className="mb-0">Your Membership</p>
              </div>
            </div>

            <div className="right-image">
              <img src="assets/common/purple_right.svg" alt="Right" />
            </div>
          </div>} */}

        <div>
          {dynamicPagesTitle.map((item) => {
            return <Link to={`/${item.slug}`} key={item._id} className="dynamic-pages-title-container d-flex justify-content-between align-items-center bg-white">
              <div className="d-flex align-items-center">
                <div className="image d-grid justify-content-center align-items-center">
                  <img src="assets/common/info.svg" alt="About us" />
                </div>
                <div className="dynamic-pages-title">
                  <p className="mb-0">{item.title}</p>
                </div>
              </div>

              <div className="right-image">
                <img src="assets/common/purple_right.svg" alt="Right" />
              </div>
            </Link>
          })}
        </div>

        <Link to="/faqs" className="faqs-container d-flex justify-content-between align-items-center bg-white">
          <div className="d-flex align-items-center">
            <div className="image d-grid justify-content-center align-items-center">
              <img src="assets/myprofile/faq.svg" alt="About us" />
            </div>
            <div className="faqs">
              <p className="mb-0">FAQs</p>
            </div>
          </div>

          <div className="right-image">
            <img src="assets/common/purple_right.svg" alt="Right" />
          </div>
        </Link>

        {
          localStorage.getItem("userId") ?
            <div onClick={() => { setIsShowLogout(true) }} className="logout-container d-flex justify-content-between align-items-center bg-white">
              <div className="d-flex align-items-center">
                <div className="image d-grid justify-content-center align-items-center">
                  <img src="assets/myprofile/logout.svg" alt="About us" />
                </div>
                <div className="logout">
                  <p className="mb-0">Logout</p>
                </div>
              </div>
              <div className="right-image">
                <img src="assets/common/purple_right.svg" alt="Right" />
              </div>

            </div> : ""
        }

      </div>
      {isOpenAddressBook ? <Address closeAddressPage={closeAddressPage} /> : ""}

      {isShowLogout ? <Logout setIsShowLogout={setIsShowLogout} /> : ""}
    </>
  );
};

export default MyProfile;