import React from "react";
import { Link } from "react-router-dom";
import NotFound from "../../../components/notfound";

function Categories(props) {
  const handleClickOnViewMore = () => {
    props.setIsShowViewMoreSection(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <div className="categories-container">
      <div className="title">
        <p className="title mb-0">Shop by Category</p>
      </div>

      {props.gotCategories && props.gotCategories.data ? (
        <>
          {" "}
          {props.gotCategories.data.salonCategories.length == 0 ? (
            <NotFound />
          ) : (
            <>
              <div className="categories d-grid justify-content-between">
                {props.gotCategories.data.salonCategories
                  .slice(0, 8)
                  .map((item) => {
                    return (
                      <div key={item._id} className="category text-center">
                        <Link
                          className="d-block h-100"
                          to={`/salonscategory/${item._id}`}
                        >
                          <img
                            src={
                              item.image
                                ? `${process.env.REACT_APP_IMAGE_URL}${item.image}`
                                : "/assets/common/serviceproduct.png"
                            }
                            alt="Category"
                          />
                          <p className="mb-0">{item.name}</p>
                        </Link>
                      </div>
                    );
                  })}
                {props.categories.length > 8 ? (
                  <div className="view-more">
                    <button
                      onClick={() => {
                        handleClickOnViewMore();
                      }}
                    >
                      <div className="image-container">
                        <img src="/assets/common/down.svg" alt="Viewmore" />
                      </div>
                      <p className="mb-0">View more</p>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Categories;
