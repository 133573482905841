import React from "react";
import { useEffect, useCallback } from "react";

import usePostData from "../../../customhooks/postdata";
import { useNavigate, useParams } from "react-router-dom";

import { authJsonHeaders } from "../../../constants";

function ConfirmReviewRating(props) {

  const orderId = useParams();

  const [postedData, postError, postData] = usePostData();

  const callback = useCallback(() => {
    document.body.style.overflow = "unset";
  })

  const handleClickOnYes = () => {

    postData({
      url: `${process.env.REACT_APP_API_URL}/app/review-and-rating/order/${props.gotOrderDetails && props.gotOrderDetails.data.order._id
        }`,

      body: {
        rating: props.rating,
        review: props.review,
      },
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

  };

  useEffect(() => {
    if (postedData != undefined && postedData != null && postedData != "") {
      props.getOrderDetails({
        url: `${process.env.REACT_APP_API_URL}/app/order/${orderId.orderId}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      props.setIsConfirmReviewRating(false);
      document.body.style.overflow = "unset";
    }

    return callback
  }, [postedData]);

  return (
    <div className="confirmreviewrating-section-container d-flex justify-content-center align-items-center">
      <div className="confirmreviewrating-section d-flex flex-column justify-content-center align-items-center">
        <p className="mb-0 message text-center">Are you sure you want to post this review?</p>
        <div className="d-flex justify-content-between align-items-center w-100">
          <button
            onClick={() => {
              props.setIsConfirmReviewRating(false);
            }}
            className="cancel"
          >
            No
          </button>
          <button
            onClick={() => {
              handleClickOnYes();
            }}
            className="discard"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmReviewRating;
