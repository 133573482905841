import React from "react";

import { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import Loader from "../../../components/loader";

import useUpdateData from "../../../customhooks/updatedata";
import useIsLoader from "../../../customhooks/useisloader";

import { authJsonHeaders } from "../../../constants";

function AddInstructions(props) {
  const [isLoader, setIsLoader] = useIsLoader(false);

  const [animateInstruction, setAnimateInstruction] = useState({});

  const [
    addedInstructions,
    instructionsError,
    addInstructions,
  ] = useUpdateData();

  const [instructions, setInstructions] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    setAnimateInstruction({
      transform: "translateY(0%)",
    });
  }, []);

  const cancel = () => {
    setAnimateInstruction({
      transform: "translateY(100%)",
    });

    setTimeout(() => {
      props.closeAddInstruction();
    }, 1000);
  };

  const handleClick = () => {
    if (instructions) {
      setIsLoader(true);

      addInstructions({
        url: `${process.env.REACT_APP_API_URL}/app/cart`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: {
          instructions: instructions,
        },
      });
    } else {
      setMessage("Please add instructions.");
    }
  };

  useEffect(() => {
    if (
      addedInstructions != undefined &&
      addedInstructions != null &&
      addedInstructions != ""
    ) {
      const cartResRef = props.getCartList();

      cartResRef.then((res) => {
        if (res) {
          props.setCartList(res);
        }
      });

      cancel();
    }
  }, [addedInstructions]);

  const handleOnChange = (e) => {
    if (e.target.value.length <= 100) {
      setMessage("");
      setInstructions(e.target.value);
    } else {
      setMessage("You can not enter greater than 100 characters.");
    }
  };

  return (
    <div style={animateInstruction} className="add-intstructions-page d-grid">
      <div
        onClick={() => {
          cancel();
        }}
        className="cancel-page"
      ></div>
      <div className="instructions-section">
        <div className="title-textarea-container">
          <div className="title d-flex justify-content-between align-items-center">
            <p className="mb-0">Add instruction</p>
            <button
              onClick={() => {
                cancel();
              }}
              className="border-0 bg-white"
            >
              <CgClose />
            </button>
          </div>
          <div className="text-area-container">
            <div className="d-flex justify-content-between align-items-center">
              <p className="start-typing">Start typing...</p>
              <p className="message">{message}</p>
            </div>
            <textarea
              onChange={(e) => {
                handleOnChange(e);
              }}
              placeholder="Please bring extra facial wipes"
              value={instructions}
            ></textarea>
            <p className="number-of-character mb-0 text-end">
              {instructions.length}/100
            </p>
            <p className="statement mb-0">
              The Salon will try its best to follow your instructions. However,
              no cancellation or refund will be possible if your request is not
              met.
            </p>
          </div>
        </div>

        <div className="add-container d-flex justify-content-center align-items-center">
          {isLoader ? (
            <Loader width={16} height={16} color={"#FFFFFF"} />
          ) : (
            <button
              onClick={() => {
                handleClick();
              }}
              className="border-0 d-grid justify-content-center align-items-center"
            >
              Add
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddInstructions;
