import React, { useEffect, useState } from "react";

import Rating from "../../../components/rating";
import useUpdateData from "../../../customhooks/updatedata";
import { AiOutlineClockCircle } from "react-icons/ai";

import { authJsonHeaders, headers } from "../../../constants";
import postRequest from "../../../functions/apicall/postrequest";

function Services(props) {
  const [addedService, addedServicesError, addService] = useUpdateData();

  const [item, setItem] = useState("")

  const [
    addedGenericService,
    addedGenericServicesError,
    addGenericService,
  ] = useUpdateData();

  const [removedService, removedServicesError, removeService] = useUpdateData();

  const [
    removedGenericService,
    removedGenericServicesError,
    removeGenericService,
  ] = useUpdateData();

  const [serviceId, setServiceId] = useState([]);

  const decreaseCount = (item) => {
    if (localStorage.getItem("userId")) {
      if (item.cartService.count > 1) {
        addService({
          url: `${process.env.REACT_APP_API_URL}/app/cart/item/add?service=${item._id
            }&count=${item.cartService.count - 1}&itemType=service&serviceType=${props.filterName === "SALON AT HOME"
              ? "At Home"
              : props.filterName === "SALON"
                ? "At Salon"
                : ""
            }`,
          //headers: authJsonHeaders,
          headers: {
            "Content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: {},
        });
      } else {
        removeService({
          url: `${process.env.REACT_APP_API_URL}/app/cart/item/remove?service=${item._id
            }&itemType=service&serviceType=${props.filterName === "SALON AT HOME"
              ? "At Home"
              : props.filterName === "SALON"
                ? "At Salon"
                : ""
            }`,
          //headers: authJsonHeaders,
          headers: {
            "Content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: {},
        });
      }
    }
    if (!localStorage.getItem("userId")) {
      if (item.cartService.count > 1) {
        addGenericService({
          url: `${process.env.REACT_APP_API_URL
            }/app/genericCart/${localStorage.getItem(
              "cartId"
            )}/item/add?service=${item._id}&count=${item.cartService.count -
            1}&itemType=service&serviceType=${props.filterName === "SALON AT HOME"
              ? "At Home"
              : props.filterName === "SALON"
                ? "At Salon"
                : ""
            }`,
          headers: headers,
          body: {},
        });
      } else {
        removeGenericService({
          url: `${process.env.REACT_APP_API_URL
            }/app/genericCart/${localStorage.getItem(
              "cartId"
            )}/item/remove?service=${item._id}&itemType=service`,
          headers: headers,
          body: {},
        });
      }
    }
  };

  const increaseCount = (item) => {

    setItem(item)
    if (localStorage.getItem("userId")) {
      addService({
        url: `${process.env.REACT_APP_API_URL}/app/cart/item/add?service=${item._id
          }&count=${item.cartService ? item.cartService.count + 1 : 1
          }&itemType=service&serviceType=${props.filterName === "SALON AT HOME"
            ? "At Home"
            : props.filterName === "SALON"
              ? "At Salon"
              : ""
          }`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: {},
      });
    }
    if (!localStorage.getItem("userId")) {
      addGenericService({
        url: `${process.env.REACT_APP_API_URL
          }/app/genericCart/${localStorage.getItem("cartId")}/item/add?service=${item._id
          }&count=${item.cartService ? item.cartService.count + 1 : 1
          }&itemType=service&serviceType=${props.filterName === "SALON AT HOME"
            ? "At Home"
            : props.filterName === "SALON"
              ? "At Salon"
              : ""
          }`,
        headers: headers,
        body: {},
      });
    }
  };

  const createCart = async () => {
    const res = await postRequest({
      url: `${process.env.REACT_APP_API_URL}/app/genericCart`,
      body: { salon: localStorage.getItem("salonId") },
    })

    return Promise.resolve(res)
  }

  useEffect(() => {
    if (
      (addedService != undefined &&
        addedService != null &&
        addedService != "") ||
      (removedService != undefined &&
        removedService != null &&
        removedService != "")
    )
      props.getCartList({
        url: `${process.env.REACT_APP_API_URL
          }/app/cart?user=${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  }, [addedService, removedService]);

  useEffect(() => {
    if (
      (addedGenericService != undefined &&
        addedGenericService != null &&
        addedGenericService != "") ||
      (removedGenericService != undefined &&
        removedGenericService != null &&
        removedGenericService != "")
    )
      props.getGenericCart({
        url: `${process.env.REACT_APP_API_URL
          }/app/genericCart/${localStorage.getItem("cartId")}`,
        headers: headers,
      });
  }, [addedGenericService, removedGenericService]);

  const handleClickForMessage = () => {
    props.setPopUpMessage("Sorry! This salon is not accepting home orders.");
    props.setIsPopUp(true);
  };

  const handleClickReadLess = (id) => {
    setServiceId(
      serviceId.filter((item) => {
        return item != id;
      })
    );
  };

  useEffect(() => {
    if (
      (addedGenericServicesError)
    )
      if (addedGenericServicesError.response.data && addedGenericServicesError.response.data.message == "Cart does not exist") {
        const createCartRes = createCart()

        createCartRes.then((res) => {
          if (res) {
            localStorage.setItem("cartId", res.data.genericCart._id)

            addGenericService({
              url: `${process.env.REACT_APP_API_URL
                }/app/genericCart/${localStorage.getItem("cartId")}/item/add?service=${item.id ? item.id : ""
                }&count=${item.cartService ? item.cartService.count + 1 : 1
                }&itemType=service&serviceType=${props.filterName === "SALON AT HOME"
                  ? "At Home"
                  : props.filterName === "SALON"
                    ? "At Salon"
                    : ""
                }`,
              headers: headers,
              body: {},
            });
          }
        })
      }
  }, [addedGenericServicesError]);

  const handleClickForDeleteCart = (oldsalon, newsalon) => {
    props.setPopUpMessage(
      `Your cart already have items of ${oldsalon}.` +
      " " +
      ` Do you want to remove and add the service of ${newsalon} salon?`
    );
    props.openDeleteCart();
  };

  return (
    <div
      style={{ paddingBottom: `${props.isProceed ? "51px" : "0px"}` }}
      className="services-container "
    >
      {props.isSearchServices ? (
        ""
      ) : (
        <div className="title-container">
          <p className="mb-0">
            {props.filterName === "SALON AT HOME" ? (
              props.categoryAtHome
            ) : (
              <>{props.filterName === "SALON" ? props.categoryAtSalon : ""}</>
            )}
          </p>
        </div>
      )}
      <div className="services">
        {props.services && props.services.length > 0 ? (
          <>
            {props.services.map((item) => {
              return (
                <div
                  key={item._id}
                  className="service d-flex justify-content-between aling-items-center"
                >
                  <div className="service-info d-flex flex-column">
                    <p className="service-name mb-0">{item.name}</p>

                    <p className="timing mb-0">
                      <AiOutlineClockCircle /> {item.duration} mins
                    </p>

                    <p className="category-name mb-0">
                      {item.category.name}
                      {item.brandUsed ? ` - ${item.brandUsed}` : ""}
                    </p>
                    {item.noOfPeople > 0 &&
                      item.totalRating / item.noOfPeople > 0 ? (
                      <div className="star-rating-container">
                        <Rating
                          rating={Math.round(
                            item.totalRating / item.noOfPeople
                          )}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="prices-container d-flex align-items-center">
                      {props.filterName === "SALON AT HOME" ? (
                        <>
                          {item.homeDiscountPrice > 0 &&
                            item.homeMrp != item.homeDiscountPrice ? (
                            <>
                              <p className="price mb-0">
                                {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
                                {item.homeDiscountPrice}
                              </p>
                              <p className="cut mb-0">
                                {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
                                {item.homeMrp}
                              </p>
                            </>
                          ) : (
                            <p className="price mb-0">
                              {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
                              {item.homeMrp}
                            </p>
                          )}
                        </>
                      ) : props.filterName === "SALON" ? (
                        <>
                          {item.salonDiscountPrice > 0 &&
                            item.salonMrp != item.salonDiscountPrice ? (
                            <>
                              <p className="price mb-0">
                                {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
                                {item.salonDiscountPrice}
                              </p>
                              <p className="cut mb-0">
                                {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
                                {item.salonMrp}
                              </p>
                            </>
                          ) : (
                            <p className="price mb-0">
                              {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
                              {item.salonMrp}
                            </p>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {item.description ? (
                      <p className="description mb-0">
                        {item.description.length > 60 ? (
                          <>
                            {serviceId.includes(item._id) ? (
                              <>
                                {item.description}
                                <button
                                  onClick={() => {
                                    handleClickReadLess(item._id);
                                  }}
                                  className="border-0"
                                >
                                  ....read less
                                </button>
                              </>
                            ) : (
                              <>
                                {item.description.substring(0, 60)}
                                <button
                                  onClick={() => {
                                    setServiceId((prev) => [...prev, item._id]);
                                  }}
                                  className="border-0"
                                >
                                  ....read more
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                          item.description
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="service-image">
                    {item.image ? (
                      <div>
                        <img
                          src={
                            item.image
                              ? `${process.env.REACT_APP_IMAGE_URL}${item.image}`
                              : "/assets/common/serviceproduct.png"
                          }
                          alt="Service"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {item.cartService ? (
                      <div
                        style={{ bottom: item.image ? "15px" : "0px" }}
                        className="add-remove-service d-flex justify-content-between align-items-center"
                      >
                        <button
                          onClick={() => {
                            decreaseCount(item);
                          }}
                          className="border-0"
                        >
                          -
                        </button>
                        <p className="number-of-service mb-0">
                          {item.cartService.count}
                        </p>
                        <button
                          onClick={() => {
                            increaseCount(item);
                          }}
                          className="border-0"
                        >
                          +
                        </button>
                      </div>
                    ) : (
                      <button
                        style={{ bottom: item.image ? "15px" : "0px" }}
                        className="add-service"
                        onClick={() => {
                          {
                            props.filterName === "SALON AT HOME"
                              ? props.salon &&
                                props.salon.data &&
                                props.salon.data.salon.isAcceptingOrder
                                ? props.gotGenericCart &&
                                  props.gotGenericCart.data &&
                                  props.gotGenericCart.data.genericCart &&
                                  props.gotGenericCart.data.genericCart
                                    .cartCalculations.overall.totalItems > 0
                                  ? props.gotGenericCart.data.genericCart
                                    .serviceType &&
                                    props.gotGenericCart.data.genericCart
                                      .serviceType === "At Salon" &&
                                    props.filterName === "SALON AT HOME"
                                    ? handleClickForDeleteCart(
                                      props.gotGenericCart.data.genericCart
                                        .salon.name,
                                      props.salon.data.salon.name
                                    )
                                    : props.gotGenericCart &&
                                      props.gotGenericCart.data.genericCart &&
                                      props.gotGenericCart.data.genericCart
                                        .serviceType &&
                                      props.gotGenericCart.data.genericCart
                                        .serviceType === "At Home" &&
                                      props.filterName === "SALON"
                                      ? handleClickForDeleteCart(
                                        props.gotGenericCart.data.genericCart
                                          .salon.name,
                                        props.salon.data.salon.name
                                      )
                                      : increaseCount(item)
                                  : props.gotCartList &&
                                    props.gotCartList.data &&
                                    props.gotCartList.data.cart &&
                                    props.gotCartList.data.cart.cartCalculations
                                      .overall.totalItems > 0 &&
                                    props.gotCartList &&
                                    props.gotCartList.data &&
                                    props.gotCartList.data.cart &&
                                    props.gotCartList.data.cart.serviceType &&
                                    props.gotCartList.data.cart.serviceType ===
                                    "At Salon" &&
                                    props.filterName === "SALON AT HOME"
                                    ? handleClickForDeleteCart(
                                      props.gotCartList.data.cart.salon.name,
                                      props.salon.data.salon.name
                                    )
                                    : props.gotCartList &&
                                      props.gotCartList.data &&
                                      props.gotCartList.data.cart &&
                                      props.gotCartList.data.cart.serviceType &&
                                      props.gotCartList.data.cart.serviceType ===
                                      "At Home" &&
                                      props.filterName === "SALON"
                                      ? handleClickForDeleteCart(
                                        props.gotCartList.data.cart.salon.name,
                                        props.salon.data.salon.name
                                      )
                                      : increaseCount(item)
                                : handleClickForMessage()
                              : props.gotGenericCart &&
                                props.gotGenericCart.data &&
                                props.gotGenericCart.data.genericCart &&
                                props.gotGenericCart.data.genericCart
                                  .cartCalculations.overall.totalItems > 0
                                ? props.gotGenericCart.data &&
                                  props.gotGenericCart.data.genericCart &&
                                  props.gotGenericCart.data.genericCart
                                    .serviceType &&
                                  props.gotGenericCart.data.genericCart
                                    .serviceType === "At Salon" &&
                                  props.filterName === "SALON AT HOME"
                                  ? handleClickForDeleteCart(
                                    props.gotGenericCart.data.genericCart.salon
                                      .name,
                                    props.salon.data.salon.name
                                  )
                                  : props.gotGenericCart.data &&
                                    props.gotGenericCart.data.genericCart &&
                                    props.gotGenericCart.data.genericCart
                                      .serviceType &&
                                    props.gotGenericCart.data.genericCart
                                      .serviceType === "At Home" &&
                                    props.filterName === "SALON"
                                    ? handleClickForDeleteCart(
                                      props.gotGenericCart.data.genericCart.salon
                                        .name,
                                      props.salon.data.salon.name
                                    )
                                    : increaseCount(item)
                                : props.gotCartList &&
                                  props.gotCartList.data &&
                                  props.gotCartList.data.cart &&
                                  props.gotCartList.data.cart.cartCalculations
                                    .overall.totalItems > 0 &&
                                  props.gotCartList &&
                                  props.gotCartList.data &&
                                  props.gotCartList.data.cart &&
                                  props.gotCartList.data.cart.serviceType &&
                                  props.gotCartList.data.cart.serviceType ===
                                  "At Salon" &&
                                  props.filterName === "SALON AT HOME"
                                  ? handleClickForDeleteCart(
                                    props.gotCartList.data.cart.salon.name,
                                    props.salon.data.salon.name
                                  )
                                  : props.gotCartList &&
                                    props.gotCartList.data &&
                                    props.gotCartList.data.cart &&
                                    props.gotCartList.data.cart.serviceType &&
                                    props.gotCartList.data.cart.serviceType ===
                                    "At Home" &&
                                    props.filterName === "SALON"
                                    ? handleClickForDeleteCart(
                                      props.gotCartList.data.cart.salon.name,
                                      props.salon.data.salon.name
                                    )
                                    : increaseCount(item);
                          }
                        }}
                      >
                        ADD+
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="message">
            <p className="mb-0">No Services</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Services;
