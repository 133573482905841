import React, { useEffect, useState } from "react";

function PaymentDetails(props) {

  const [total, setTotal] = useState(0)

  const [tax, setTax] = useState(0)

  useEffect(() => {
    if (props.gotCartList && props.gotCartList.data.cart) {
      setTotal(props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.grandTotal / 1.18)


      setTax(props.gotCartList.data.cart.cartCalculations.overall.grandTotal - props.gotCartList.data.cart.cartCalculations.overall.grandTotal / 1.18)
    }

    if (props.gotGenericCart && props.gotGenericCart.data.genericCart) {
      setTotal(props.gotGenericCart && props.gotGenericCart.data.genericCart && props.gotGenericCart.data.genericCart.cartCalculations.overall.grandTotal / 1.18)

      setTax(props.gotGenericCart.data.genericCart.cartCalculations.overall.grandTotal - props.gotGenericCart.data.genericCart.cartCalculations.overall.grandTotal / 1.18)
    }

  }, [props])

  return (
    <div className="payment-details-container bg-white">
      <div className="title-container text-center">
        <p className="mb-0">Payment Details</p>
      </div>
      {props.isPaymentFormembership == 'true' ? "" :
        <>
          <div className="subtotal d-flex justify-content-between align-items-center">
            <p className="mb-0">Subtotal</p>

            <p className="mb-0">{String.fromCharCode(props.gotCartList && props.gotCartList.data.cart.salon.country.htmlSymbol)}{Math.round(props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.total)}</p>

          </div>

          {props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.deliveryCharges > 0 ?
            <div className="delivery-charges d-flex justify-content-between align-items-center">
              <p className="mb-0">Delivery Charges:</p>
              <p className="mb-0">{String.fromCharCode(props.gotCartList && props.gotCartList.data.cart.salon.country.htmlSymbol)}{props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.deliveryCharges}</p>
            </div> : ""}

          <div className="discount d-flex justify-content-between align-items-center">
            <p className="mb-0">Discount:</p>
            <p className="mb-0">{String.fromCharCode(props.gotCartList && props.gotCartList.data.cart.salon.country.htmlSymbol)}{props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.discount}</p>
          </div>

          {props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.membershipDiscount > 0 ?
            <div className="membership d-flex justify-content-between align-items-center">
              <p className="mb-0">Membership Discount:</p>
              <p className="mb-0">

                {String.fromCharCode(props.gotCartList && props.gotCartList.data.cart.salon.country.htmlSymbol)}{Math.round(props.gotCartList.data.cart.cartCalculations.overall.membershipDiscount)}

              </p>
            </div> : ""}

          <div className="coupon-discount d-flex justify-content-between align-items-center">
            <p className="mb-0">Coupon Discount:</p>
            <p className="mb-0">
              {String.fromCharCode(props.gotCartList && props.gotCartList.data.cart.salon.country.htmlSymbol)}{props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.couponDiscount}
            </p>
          </div>

          <div className="total d-flex justify-content-between align-items-center">
            <p className="mb-0">Total:</p>
            <p className="mb-0">

              {
                props.gotCartList ? <>{String.fromCharCode(props.gotCartList && props.gotCartList.data.cart.salon.country.htmlSymbol)}
                  {total.toFixed(2)}</> : ""
              }

            </p>
          </div>

          <div className="tax d-flex justify-content-between align-items-center">
            <p className="mb-0">Tax (GST):</p>
            <p className="mb-0">

              {
                props.gotCartList ? <>{String.fromCharCode(props.gotCartList && props.gotCartList.data.cart.salon.country.htmlSymbol)}
                  {tax.toFixed(2)}</> : ""
              }

            </p>
          </div>
        </>}

      <div className="grand-total d-flex justify-content-between align-items-center">
        <p className="mb-0">Grand Total:</p>
        {props.isPaymentFormembership == 'true' ? <p className="mb-0">{props.planInfo.price}</p> :
          <p className="mb-0">
            {String.fromCharCode(props.gotCartList && props.gotCartList.data.cart.salon.country.htmlSymbol)}{Math.round(props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.grandTotal)}
          </p>}
      </div>
    </div >
  );
}

export default PaymentDetails