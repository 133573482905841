import moment from "moment";
import React, { useEffect, useState } from "react";
import { authJsonHeaders } from "../../constants";
import useGetData from "../../customhooks/getdata";
import Header from "./header";
import { gtm } from "../gtm";

function Wallet() {
  const [walletHistory, setWalletHistory] = useState([]);

  const [gotUserWallet, userWalletError, getUserWallet] = useGetData();

  useEffect(() => {
    getUserWallet({
      url: `${
        process.env.REACT_APP_API_URL
      }/app/wallet/user/search?user=${localStorage.getItem("userId")}`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }, []);

  useEffect(() => {
    if (gotUserWallet) {
      setWalletHistory(gotUserWallet.data.userWallets);
    }
  }, [gotUserWallet]);

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <div className="wallet-page">
      <Header />

      <div className="wallet-container d-flex justify-content-between align-items-center bg-white">
        <div className="d-flex align-items-center">
          <div className="wallet-image d-grid justify-content-center align-items-center">
            <img src="assets/common/wallet.svg" alt="Wallet" />
          </div>
          <p className="name mb-0">G Cash</p>
        </div>
        <div>
          <p className="balance mb-0">
            &#8377;
            {gotUserWallet && gotUserWallet.data
              ? gotUserWallet.data.userWallets.length > 0
                ? gotUserWallet.data.userWallets[0].user.wallet
                : 0
              : ""}
          </p>
        </div>
      </div>

      <div className="order-details-section">
        <div className="title">
          <p className="mb-0">Wallet Activity</p>
        </div>
        <div className="order-details-container">
          {gotUserWallet && gotUserWallet.data ? (
            gotUserWallet.data.userWallets.length > 0 ? (
              <>
                {walletHistory.map((item) => {
                  return (
                    <div
                      key={item._id}
                      className="order-detail bg-white d-flex justify-content-between align-items-center"
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="wallet-image d-grid justify-content-center align-items-center">
                          <img src="assets/common/wallet.svg" alt="Wallet" />
                        </div>
                        <div className="wallet-info">
                          <p className="date mb-0">
                            {moment(item.createdAt).format("DD MMM YYYY")}
                          </p>
                          <p className="name">
                            {item.entryTitle
                              ? item.entryTitle
                              : item.customOrderId
                              ? `Paid to Order: ${item.customOrderId}`
                              : item.customMembershipOrderId
                              ? `Paid to Membership Order: ${item.customMembershipOrderId}`
                              : ""}
                          </p>

                          {item.entryType == "CR" ? (
                            <p className="expire-time mb-0">
                              {" "}
                              {item.entryType == "CR" ? (
                                <>
                                  Expiring on{" "}
                                  {moment(item.expiresOn).format("DD MMM YYYY")}
                                </>
                              ) : (
                                ""
                              )}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="balance">
                        <p
                          style={{
                            color:
                              item.entryType == "DR" ? "#F7635B" : "#13BF6D",
                          }}
                          className="mb-0"
                        >
                          {item.entryType == "DR" ? "-" : "+"}₹{item.amount}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <p className="mb-0 message">No activity</p>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Wallet;
