import React, { useCallback, useEffect, useState } from "react";

import useGetData from "../../customhooks/getdata";
import useIsLoader from "../../customhooks/useisloader";
import Loader from "../../components/loader";
import UserAddress from "./useraddress";

import SalonAddress from "./salonaddress";
import Address from "../../components/address";

import { AiOutlineClockCircle } from "react-icons/ai";
import SlotTime from "./slottime";
import SlotDate from "./slotdate";
import Header from "./header";

import PopUp from "../../components/popup";

import useIsPopUp from "../../customhooks/ispopup";

import Payment from "../payment";

import { authJsonHeaders } from "../../constants";
import moment from "moment";
import { getCartList, getAddress } from "../../functions";
import getRequest from "../../functions/apicall/getrequest";
import { useNavigate } from "react-router-dom";
import { gtm } from "../gtm";

function Slot(props) {
  const navigate = useNavigate();

  const [isLoader, setIsLoader] = useIsLoader(true);

  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const [popUpMessage, setPopUpMessage] = useState("");

  const [isProceed, setIsProceed] = useState(false);

  const [address, setAddress] = useState([]);

  const [isOpenAddressBook, setIsOpenAddressBook] = useState(false);

  const [cart, setCart] = useState(null);

  const [userAddress, setUserAddress] = useState(null);

  const [salonSlots, setSalonSlots] = useState(null);

  const getSlot = (date) => {
    const res = getRequest(
      `${process.env.REACT_APP_API_URL}/app/slotv2/getSlots/${cart &&
        cart.data &&
        cart.data.cart &&
        cart.data.cart.salon &&
        cart.data.cart.salon._id}?date=${date}`
    );

    return Promise.resolve(res);
  };

  useEffect(() => {
    const cartResProm = getCartList();

    const addressResProm = getAddress();

    Promise.all([cartResProm, addressResProm]).then((res) => {
      const [cartResRef, addressResRef] = res;

      if (addressResRef) {
        setUserAddress(addressResRef);
      }

      if (cartResRef) {
        setCart(cartResRef);
      }
    });
  }, []);

  const openAddressPage = () => {
    document.body.style.overflow = "hidden";

    setIsOpenAddressBook(true);
  };

  const closeAddressPage = () => {
    document.body.style.overflow = "unset";

    setIsOpenAddressBook(false);
  };

  const proceed = () => {
    if (cart.data.cart.serviceType === "At Salon") {
      if (date && time) {
        sessionStorage.setItem(
          "dateTime",
          JSON.stringify({ date: date, time: time })
        );
        navigate("/payment/false");
      } else {
        setIsPopUp(true);
        setPopUpMessage("Please select slot Date and Time.");
      }
    } else {
      let isUserAddress = false;

      if (userAddress.data) {
        userAddress.data.userAddresses.forEach((item) => {
          if (item.isDefault) {
            isUserAddress = true;
          }
        });
      }

      if (!isUserAddress) {
        openAddressPage();
      } else {
        if (date && time) {
          sessionStorage.setItem(
            "dateTime",
            JSON.stringify({ date: date, time: time })
          );
          navigate("/payment/false");
        } else {
          setIsPopUp(true);
          setPopUpMessage("Please select slot Date and Time.");
        }
      }
    }
  };

  const handleClickOnProceed = () => {
    const day = moment(date).format("dddd");

    if (date && time) {
      if (cart.data.cart.appliedCoupon) {
        if (cart.data.cart.appliedCoupon.applicableOnWeekends) {
          proceed();
        } else if (day != "Saturday" && day != "Sunday") {
          proceed();
        } else {
          setPopUpMessage(
            "Sorry! Coupon is not applicable on weekend. Please remove or add another coupon."
          );
          setIsPopUp(true);
        }
      } else {
        proceed();
      }
    } else {
      setIsPopUp(true);
      setPopUpMessage("Please select slot Date and Time.");
    }
  };

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <>
      {isProceed ? (
        ""
      ) : (
        <div className="slot-page d-grid flex-column justify-content-between">
          <Header setIsProceed={props.setIsProceed} gotCartList={props.cart} />
          <div className="info-container d-grid">
            <div className="sub-info-container d-grid">
              {cart ? (
                <>
                  {cart &&
                  cart.data &&
                  cart.data.cart.serviceType === "At Salon" ? (
                    <SalonAddress gotCartList={cart} />
                  ) : (
                    <UserAddress
                      gotAddress={userAddress}
                      openAddressPage={openAddressPage}
                    />
                  )}
                </>
              ) : (
                ""
              )}

              <div className="slot-section bg-white d-grid">
                <div className="title-date-container">
                  <div className="title-container d-flex align-items-center">
                    <AiOutlineClockCircle />
                    <p className="mb-0">Time Slot</p>
                  </div>
                  <SlotDate
                    setIsLoader={setIsLoader}
                    cart={cart}
                    getSlot={getSlot}
                    setSalonSlots={setSalonSlots}
                    setDate={setDate}
                  />
                </div>

                {isLoader ? (
                  <Loader width={60} height={60} color={"#772286"} />
                ) : (
                  <SlotTime
                    gotCartList={cart}
                    gotSalonSlots={salonSlots}
                    setTime={setTime}
                  />
                )}
              </div>
            </div>

            <div className="save-proceed">
              <button
                onClick={() => {
                  handleClickOnProceed();
                }}
                className="border-0"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}

      {isOpenAddressBook ? (
        <Address
          setUserAddress={setUserAddress}
          getAddress={getAddress}
          closeAddressPage={closeAddressPage}
        />
      ) : (
        ""
      )}

      {isPopUp ? <PopUp setIsPopUp={setIsPopUp} message={popUpMessage} /> : ""}

      {isProceed ? (
        <Payment
          setTime={setTime}
          gotCartList={cart}
          time={time}
          date={date}
          setIsProceed={setIsProceed}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Slot;
