import React from "react";
import { useNavigate } from "react-router-dom";

function Proceed(props) {

  const navigate = useNavigate()

  const proceed = () => {

    if (localStorage.getItem("userId")) {
      let isProductOutOfStock = false;
      let isProductInActive = false;
      let isServiceInActive = false;
      let isSalonAcceptOrder = true
      let isUserAddress = false

      if (props.gotCartList.data.cart.serviceType != null) {
        if (props.gotCartList.data.cart.serviceType == "At Home") {

          if (props.gotCartList.data.cart.salon.minOrderValue <= props.gotCartList.data.cart.cartCalculations.overall.grandTotal) {

            if (props.gotCartList.data.cart.salon.isAcceptingOrder) {

              if (props.gotAddress.data) {
                props.gotAddress.data.userAddresses.forEach((item) => {
                  if (item.isDefault) {
                    isUserAddress = true
                  }
                })
              }

              if (props.gotCartList.data.cart.products.length > 0) {
                props.gotCartList.data.cart.products.forEach((item) => {
                  if (item.count > item.product.currentStock) {
                    isProductOutOfStock = true;
                    props.setIsPopUp(true)
                    props.setPopUpMessage("Sorry! You can't proceed because product is outofStock")
                  }
                })
              }

              if (props.gotCartList.data.cart.products.length > 0) {
                props.gotCartList.data.cart.products.forEach((item) => {
                  if (item.product.status != 1) {
                    isProductInActive = true
                    props.setIsPopUp(true)
                    props.setPopUpMessage("Product is not available")
                  }
                })
              }

              if (props.gotCartList.data.cart.services.length > 0) {
                props.gotCartList.data.cart.services.forEach((item) => {
                  if (item.service.status != 1) {
                    isServiceInActive = true
                    props.setIsPopUp(true)
                    props.setPopUpMessage("Service is not available")
                  }
                })
              }

              if (!isUserAddress && !isProductOutOfStock && !isProductInActive && !isServiceInActive && isSalonAcceptOrder) {
                props.openAddressPage();
              }
              if (
                isUserAddress && props.gotCartList.data.cart.services.length > 0 && !isProductOutOfStock && !isProductInActive && !isServiceInActive && isSalonAcceptOrder) {
                props.setIsProceed(true)
              }
            }
            else {
              props.setIsPopUp(true)
              props.setPopUpMessage("This salon is not accepting home orders.")
            }

          }

          else {
            props.setIsPopUp(true)
            props.setPopUpMessage("Sorry! You have to order minimum of" + " " + `${String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol)}` + props.gotCartList.data.cart.salon.minOrderValue)
          }

        }
        else {
          if (props.gotCartList.data.cart.products.length > 0) {
            props.gotCartList.data.cart.products.forEach((item) => {
              if (item.count > item.product.currentStock) {
                isProductOutOfStock = true;
                props.setIsPopUp(true)
                props.setPopUpMessage("Sorry! You can't proceed because product is outofStock")
              }
            })
          }

          if (props.gotCartList.data.cart.products.length > 0) {
            props.gotCartList.data.cart.products.forEach((item) => {
              if (item.product.status != 1) {
                isProductInActive = true
                props.setIsPopUp(true)
                props.setPopUpMessage("Product is not available")
              }
            })
          }

          if (props.gotCartList.data.cart.services.length > 0) {
            props.gotCartList.data.cart.services.forEach((item) => {
              if (item.service.status != 1) {
                isServiceInActive = true
                props.setIsPopUp(true)
                props.setPopUpMessage("Service is not available")
              }
            })
          }

          if (
            props.gotCartList.data.cart.services.length > 0 && !isProductOutOfStock && !isProductInActive && !isServiceInActive && isSalonAcceptOrder) {
            props.setIsProceed(true)
          }
          if (props.gotCartList.data.cart.products.length > 0 && props.gotCartList.data.cart.services.length == 0 && !isProductOutOfStock && !isProductInActive && !isServiceInActive && isSalonAcceptOrder) {
            props.setIsProceed(true)
          }
        }
      }


      else {

        if (props.gotAddress.data) {
          props.gotAddress.data.userAddresses.forEach((item) => {
            if (item.isDefault) {
              isUserAddress = true
            }
          })
        }

        if (props.gotCartList.data.cart.products.length > 0) {
          props.gotCartList.data.cart.products.forEach((item) => {
            if (item.count > item.product.currentStock) {
              isProductOutOfStock = true;
              props.setIsPopUp(true)
              props.setPopUpMessage("Sorry! You can't proceed because product is outofStock")
            }
          })
        }

        if (props.gotCartList.data.cart.products.length > 0) {
          props.gotCartList.data.cart.products.forEach((item) => {
            if (item.product.status != 1) {
              isProductInActive = true
              props.setIsPopUp(true)
              props.setPopUpMessage("Product is not available")
            }
          })
        }

        if (props.gotCartList.data.cart.services.length > 0) {
          props.gotCartList.data.cart.services.forEach((item) => {
            if (item.service.status != 1) {
              isServiceInActive = true
              props.setIsPopUp(true)
              props.setPopUpMessage("Service is not available")
            }
          })
        }

        if (sessionStorage.getItem("isQrCode") == "true") {
          isUserAddress = true
        }
        else {
          if (!isUserAddress && !isProductOutOfStock && !isProductInActive && !isServiceInActive && isSalonAcceptOrder) {
            props.openAddressPage();
          }
        }

        if (props.gotCartList.data.cart.products.length > 0 && props.gotCartList.data.cart.services.length == 0 && isUserAddress && !isProductOutOfStock && !isProductInActive && !isServiceInActive && isSalonAcceptOrder) {
          props.setIsProceed(true)
        }
      }


    }

    if (!localStorage.getItem("userId")) {
      navigate("/login")
    }

  };

  return (
    <div onClick={() => {
      proceed();
    }} className="proceed-container d-flex justify-content-between align-items-center">
      <div>
        <p className="grand-total">Grand Total</p>
        <p className="price mb-0">
          {
            props.gotCartList ? <>{String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol)}{Math.round(props.gotCartList.data.cart.cartCalculations.overall.grandTotal)}</> : props.gotGenericCart ? <>{String.fromCharCode(props.gotGenericCart.data.genericCart.salon.country.htmlSymbol)}{Math.round(props.gotGenericCart.data.genericCart.cartCalculations.overall.grandTotal)}</> : ""
          }
        </p>
      </div>
      <div className="proceed d-flex justify-content-center align-items-center">
        <span>Proceed</span>
        <img src="/assets/common/white_right.svg" alt="Right" />
      </div>
    </div>
  );
}

export default Proceed;
