import { Browser } from "@capacitor/browser";
import React, { useCallback, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useGetData from "../../customhooks/getdata";
import Congratulations from "./congratulations";
import Header from "./header";
import { gtm } from "../gtm";

function OrderSuccess() {
  useEffect(() => {
    window.close();
    Browser.close();
  }, []);

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <div className="payment-success-page">
      <div className="girl-image-container">
        <img src="/assets/paymentsuccess/girl.svg" />
      </div>
      <Congratulations />
    </div>
  );
}

export default OrderSuccess;
