import React, { useEffect, useState } from "react";

import useGetData from "../../customhooks/getdata";
import Categories from "./categories";
import Header from "./header";
import OfferDeal from "./offerdeal";

import Loader from "../../components/loader";

import useIsLoader from "../../customhooks/useisloader";
import ShowOfferInformation from "./showofferinformatio";
import NotFound from "../../components/notfound";
import { authJsonHeaders } from "../../constants";
import { gtm } from "../gtm";

function OffersDeals() {
  const [offerDealsCategory, setOfferDealsCategory] = useState([]);
  const [offerDeals, setOfferDeals] = useState([]);

  const [couponCodeDesc, setCouponCodeDesc] = useState({
    couponCode: "",
    description: "",
  });

  const [categories, setCategories] = useState([]);

  const [filterValue, setFilterValue] = useState([]);

  const [isShowOfferInformation, setIsShowOfferInformation] = useState(false);

  const [isCopiedCouponCode, setIsCopiedCouponCode] = useState(false);

  const [gotOfferDeals, offerDealsError, getOfferDeals] = useGetData();

  const [
    gotOfferDealsCategory,
    offerDealsCategoryError,
    getOfferDealsCategory,
  ] = useGetData();

  const [isLoader, setIsLoader] = useIsLoader(true);

  const categoriesSet = new Set();

  useEffect(() => {
    getOfferDeals({
      url: `${process.env.REACT_APP_API_URL}/app/offerAndDeal/search?status=1&sort_by=order&sort_order=desc`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    getOfferDealsCategory({
      url: `${process.env.REACT_APP_API_URL}/app/offerAndDealCategory/list?status=1`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }, []);

  useEffect(() => {
    if (
      gotOfferDeals != undefined &&
      gotOfferDeals != "" &&
      gotOfferDeals != null &&
      gotOfferDealsCategory != undefined &&
      gotOfferDealsCategory != "" &&
      gotOfferDealsCategory != null
    ) {
      setIsLoader(false);
      setOfferDeals(gotOfferDeals.data.offersAndDeals);

      gotOfferDeals.data.offersAndDeals.forEach((item) => {
        if (!categoriesSet.has(item.category.name)) {
          categoriesSet.add(item.category.name);
          setCategories((prev) => {
            return [...prev, item.category];
          });
          setOfferDealsCategory((prev) => {
            return [...prev, item.category];
          });
        }
      });
    }
  }, [gotOfferDeals, gotOfferDealsCategory]);

  useEffect(() => {
    if (filterValue.length > 0) {
      setOfferDeals(
        gotOfferDeals.data.offersAndDeals.filter((item) => {
          for (let i = 0; i <= filterValue.length - 1; i++) {
            if (item.category.name == filterValue[i]) {
              return item;
            }

            setCategories(
              gotOfferDealsCategory.data.offerAndDealCatgeories.filter(
                (categories) => {
                  if (filterValue.includes(categories.name)) {
                    return categories;
                  }
                }
              )
            );
          }
        })
      );
    } else {
      if (gotOfferDeals) {
        categoriesSet.clear();
        setCategories([]);
        gotOfferDeals.data.offersAndDeals.forEach((item) => {
          if (!categoriesSet.has(item.category.name)) {
            categoriesSet.add(item.category.name);
            setCategories((prev) => {
              return [...prev, item.category];
            });
          }
        });
        setOfferDeals(gotOfferDeals.data.offersAndDeals);
      }
    }
  }, [filterValue]);

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <>
      <div className="offersdeals-page d-grid">
        <Header />

        {isLoader ? (
          <Loader height={60} width={60} color={"#772286"} />
        ) : (
          <div>
            <Categories
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              offerDealsCategory={offerDealsCategory}
            />

            {gotOfferDeals ? (
              <>
                {gotOfferDeals.data.offersAndDeals.length > 0 ? (
                  <OfferDeal
                    categories={categories}
                    setIsCopiedCouponCode={setIsCopiedCouponCode}
                    setCouponCodeDesc={setCouponCodeDesc}
                    setIsShowOfferInformation={setIsShowOfferInformation}
                    offerDeals={offerDeals}
                  />
                ) : (
                  <NotFound />
                )}
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>

      {isCopiedCouponCode ? (
        <div className="copied-coupon-code-alert">
          <p className="mb-0">Copied</p>
        </div>
      ) : (
        ""
      )}

      {isShowOfferInformation ? (
        <ShowOfferInformation
          couponCodeDesc={couponCodeDesc}
          setIsShowOfferInformation={setIsShowOfferInformation}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default OffersDeals;
