import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Header(props) {
  return (
    <>
      <div className="home-page-header header d-flex justify-content-between align-items-center">
        <div className="user-location-container d-flex align-items-center">
          <div className="location-icon-container">
            <img src="/assets/home/location.svg" alt="Location" />
          </div>
          <button
            onClick={() => {
              props.openSelectLocation();
            }}
            className="location-container"
          >
            <div className="city border-0 d-flex justify-content-start align-items-center">
              <span>{props.city}</span>
              <span>
                <img src="/assets/common/down.svg" alt="Down" />
              </span>
            </div>
            <p className="address mb-0">
              {props.userAddress &&
                props.userAddress.data.results[0].formatted_address.substring(
                  0,
                  35
                )}
            </p>
          </button>
        </div>

        <div className="user-image-container">
          <Link className="d-block" to="/myprofile">
            {localStorage.getItem("userId") ? (
              <>
                {props.userProfile &&
                props.userProfile.data &&
                props.userProfile.data.user &&
                props.userProfile.data.user.image ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}${props.userProfile.data.user.image}`}
                    alt="User"
                  />
                ) : (
                  <img src="/assets/common/user.svg" />
                )}
              </>
            ) : (
              <img src="/assets/common/user.svg" />
            )}
          </Link>
        </div>
      </div>
    </>
  );
}

export default Header;
