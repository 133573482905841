import React, { useEffect, useState } from "react";
import useIsLoader from "../../../customhooks/useisloader";
import Loader from "../../loader";
import NotFound from "../../notfound";
import DeleteAddress from "./deleteaddress";

import { RWebShare } from "react-web-share";

import getRequest from "../../../functions/apicall/getrequest";
import putRequest from "../../../functions/apicall/putrequest";

function BookAddress(props) {
  const [slideBookAddress, setSlideBookAddress] = useState({});

  const [isDeleteAddress, setIsDeleteAddress] = useState(false);

  const [isLoader, setIsLoader] = useIsLoader(true);

  const [userAddress, setUserAddress] = useState(null);

  const getAddress = () => {
    const res = getRequest(
      `${
        process.env.REACT_APP_API_URL
      }/app/userAddress/search?user=${localStorage.getItem("userId")}`
    );

    return Promise.resolve(res);
  };

  const updateAddress = (addressId) => {
    const res = putRequest({
      url: `${process.env.REACT_APP_API_URL}/app/userAddress/${addressId}`,
      body: {
        status: 1,
        isDefault: true,
      },
    });

    return Promise.resolve(res);
  };

  const [addressId, setAddressId] = useState("");

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      setIsLoader(true);

      const addressResProm = getAddress();

      addressResProm.then((res) => {
        if (res) {
          setIsLoader(false);
          setUserAddress(res);

          if (props.getAddress) {
            const addressResRef = props.getAddress();

            addressResRef.then((res) => {
              if (res) {
                props.setUserAddress(res);
              }
            });
          }
        } else {
          setIsLoader(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    setSlideBookAddress({
      transform: "translateX(0%)",
    });
  }, []);

  const back = () => {
    setSlideBookAddress({
      transform: "translateX(100%)",
    });
    setTimeout(() => {
      props.closeAddressPage();
    }, 1000);
  };

  const openChooseLocation = () => {
    props.setIsAddressForEdit(false);
    props.setIsOpenChooseLocation(true);

    setSlideBookAddress({
      transform: "translateX(100%)",
    });
    setTimeout(() => {
      props.setIsOpenBookAddress(false);
    }, 1000);
  };

  const handleClickEditAddress = (address) => {
    props.getEditAddress(address);
    props.setIsOpenChooseLocation(true);

    setSlideBookAddress({
      transform: "translateX(100%)",
    });
    setTimeout(() => {
      props.setIsOpenBookAddress(false);
    }, 1000);
  };

  const handleClickSetDeliveryAddress = (addressId) => {
    const updateResProm = updateAddress(addressId);

    updateResProm.then((res) => {
      if (res) {
        const addressResProm = getAddress();

        addressResProm.then((res) => {
          if (res) {
            setIsLoader(false);
            setUserAddress(res);

            if (props.getAddress) {
              const addressResRef = props.getAddress();

              addressResRef.then((res) => {
                if (res) {
                  props.setUserAddress(res);
                }
              });
            }

            setTimeout(() => {
              back();
            }, 1000);
          } else {
            setIsLoader(false);
          }
        });
      }
    });
  };

  const handleClickDeleteAddress = (addId) => {
    setAddressId(addId);
    setIsDeleteAddress(true);
  };

  return (
    <>
      <div style={slideBookAddress} className="book-address d-grid">
        <div className="">
          <div className="header-container d-flex align-items-center">
            <div>
              <button
                onClick={() => {
                  back();
                }}
                className="border-0 bg-white"
              >
                <img src="/assets/common/back.svg" alt="Down" />
              </button>
            </div>
            <div className="w-100 text-center">
              <p className="mb-0">Address Book</p>
            </div>
          </div>

          <div className="add-address">
            <button
              onClick={() => {
                openChooseLocation();
              }}
              className="d-flex align-items-center"
            >
              <img src="/assets/common/plus.svg" alt="Plus" />
              <p className="mb-0">Add Address</p>
            </button>
          </div>
        </div>
        {isLoader ? (
          <Loader width={60} height={60} color={"#772286"} />
        ) : (
          <>
            {userAddress &&
            userAddress.data &&
            userAddress.data.userAddresses.length > 0 ? (
              <div className="addresses-container">
                {userAddress &&
                  userAddress.data &&
                  userAddress.data.userAddresses.map((item) => {
                    return (
                      <label
                        htmlFor={item.id}
                        key={item.id}
                        className="d-flex input-address-container"
                      >
                        <div className="address-container-section">
                          <div
                            onClick={() => {
                              // handleClickSetDeliveryAddress(item._id);
                            }}
                            className="address-container d-flex"
                          >
                            <div className="address-image">
                              <img
                                src={
                                  item.saveAddressAs == "Home"
                                    ? "/assets/common/home.svg"
                                    : item.saveAddressAs == "Work"
                                    ? "/assets/address/briefcase.svg"
                                    : "/assets/common/home.svg"
                                }
                                alt="Home"
                              />
                            </div>
                            <div className="">
                              <div className="d-flex align-items-center">
                                <p className="address-as mb-0">
                                  {item.saveAddressAs}
                                </p>
                                {item.isDefault ? (
                                  <p className="default mb-0">
                                    {item.isDefault ? " (Default)" : ""}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                              <p className="address">
                                {item.completeAddress
                                  ? `${item.completeAddress}`
                                  : ""}
                                {item.city ? `, ${item.city}` : ""}
                                {item.nearestLandmark
                                  ? `, ${item.nearestLandmark}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <div className="functionality-container d-flex">
                            <button
                              onClick={() => {
                                handleClickEditAddress(item);
                              }}
                              className="d-grid justify-content-center align-items-center"
                            >
                              <img src="/assets/common/edit.svg" alt="Edit" />
                            </button>
                            <button
                              onClick={() => {
                                handleClickDeleteAddress(item._id);
                              }}
                              className="d-grid justify-content-center align-items-center"
                            >
                              <img
                                src="/assets/location/delete.svg"
                                alt="Delete"
                              />
                            </button>
                            <RWebShare
                              data={{
                                text: item.completeAddress,
                                url: "",
                                title: "Share",
                              }}
                            >
                              <button className="d-grid justify-content-center align-items-center">
                                <img
                                  src="/assets/common/share.svg"
                                  alt="Share"
                                />
                              </button>
                            </RWebShare>
                          </div>
                        </div>
                        <div className="input-container">
                          <label className="container">
                            <input
                              id={item.id}
                              checked={item.isDefault ? true : false}
                              name="address"
                              type="radio"
                              onChange={() => {
                                handleClickSetDeliveryAddress(item._id);
                              }}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </label>
                    );
                  })}
              </div>
            ) : (
              <NotFound />
            )}
          </>
        )}
      </div>

      {isDeleteAddress ? (
        <DeleteAddress
          addressId={addressId}
          getAddress={getAddress}
          userAddress={props.userAddress}
          fromParent={props.getAddress ? props.getAddress : ""}
          setIsDeleteAddress={setIsDeleteAddress}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default BookAddress;
