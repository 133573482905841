import React from "react"

import Payroll from "./payroll"
import DigitalCatalog from "./digitalcatalog"
import Ecommerce from "./ecommerce"
import Feedback from "./feedback"
import Marketing from "./marketing"
import InventoryManagement from "./inventorymanagement"
import OnlineAppointment from "./onlineappointment"
import Analytics from "./analytics"

function Products() {
    return <div className="products-section">
        <div className="header-title">
            <h2>Our Products</h2>
        </div>
        <div className="products-container">
            <DigitalCatalog/>
            <OnlineAppointment/>
            <Analytics/>
            <Marketing/>
            <Ecommerce/>
            <Payroll/>
            <InventoryManagement/>
            <Feedback/>
        </div>
    </div>
}

export default Products