import React, { useEffect, useState } from "react";
import { VscStarFull } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";

import useDeleteData from "../../../customhooks/deletedata";
import usePostData from "../../../customhooks/postdata";
import useGetData from "../../../customhooks/getdata";
import { RWebShare } from "react-web-share";

import { Link } from "react-router-dom";

import { Carousel } from "react-responsive-carousel";

import { HiOutlineDotsVertical } from "react-icons/hi";

import moment from "moment";
import Amenities from "../../../components/amenities";

import { authJsonHeaders } from "../../../constants";

function SalonInfo(props) {
  const [salon, setSalon] = useState();

  const navigate = useNavigate();

  const [postedLike, likeerror, postData] = usePostData();

  const [deletedLike, deleteerror, deleteData] = useDeleteData();

  const [likedSalons, likedSalonsError, getLikedSalons] = useGetData();

  const [isLiked, setIsLiked] = useState(false);

  const [isShowMore, setIsShowMore] = useState(false);

  const [arrowStyles, setArrowStyles] = useState({
    position: "absolute",
    zIndex: 2,
    top: "calc(50%)",
    cursor: "pointer",
    backgroundColor: "white",
    height: "20px",
    width: "20px",
    borderRadius: "100%",
  });

  const [indicatorStyles, setIndicatorStyles] = useState({
    background: "#fff",
    width: 8,
    height: 8,
    borderRadius: "100%",
    display: "inline-block",
    margin: "0 5px",
  });

  const likeUnlikeSalon = (id, isLiked) => {
    if (localStorage.getItem("userId")) {
      if (isLiked) {
        deleteData({
          url: `${process.env.REACT_APP_API_URL}/app/salon/${id}/like`,
          body: {},
          //headers: authJsonHeaders,
          headers: {
            "Content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        props.salon.data.salon.isLiked = false;
      } else {
        postData({
          url: `${process.env.REACT_APP_API_URL}/app/salon/${id}/like`,
          body: {},
          //headers: authJsonHeaders,
          headers: {
            "Content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      }
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getLikedSalons({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/salon/like/search?user=${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      (postedLike != null && postedLike != undefined && postedLike != "") ||
      (deletedLike != null && deletedLike != undefined && deletedLike != "")
    ) {
      getLikedSalons({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/salon/like/search?user=${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [postedLike, deletedLike]);

  useEffect(() => {
    if (
      likedSalons != undefined &&
      likedSalons != null &&
      likedSalons != "" &&
      props.salon
    ) {
      setIsLiked(false);
      likedSalons.data.likes.forEach((likedItem) => {
        if (props.salon.data.salon._id == likedItem.salon) {
          setIsLiked(true);
        }
      });
    }

    setSalon(props.salon);
  }, [props, likedSalons]);

  return (
    <div className="salon-info position-relative">
      <div className="back-dot-main-container position-absolute w-100">
        <div className="back-dot-container d-flex justify-content-between align-items-center">
          <button
            className="border-0 d-grid"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src="/assets/salon/back.svg" alt="Back" />
          </button>
          {/* <button
            onClick={() => {
              props.setIsShowShareLikeSalon(true)
            }}
            className="dot d-grid justify-content-center align-items-center border-0"
          >
            <img
              src={
                isLiked
                  ? "/assets/common/like.svg"
                  : "/assets/common/unlike.svg"
              }
              alt="Heart"
            />
            <img
              src="/assets/salon/dots.svg"
              alt="dot"
            />
          </button> */}
        </div>
        {/* <div className="share-container text-end">
          <RWebShare data={{
            text: "",
            url: "https://www.google.com",
            title: "Share Now",
          }}>
            <button className="border-0 d-grid justify-content-center align-items-center">
              <img src="/assets/common/share.svg" alt="Share" />
            </button>
          </RWebShare>

        </div> */}
      </div>
      <div className="salon-image-container">
        {salon && salon.data.salon ? (
          <>
            {salon && salon.data.salon.images.length > 0 ? (
              <Carousel
                stopOnHover={true}
                preventMovementUntilSwipeScrollTolerance={true}
                autoPlay
                infiniteLoop={true}
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                  if (isSelected) {
                    return (
                      <li
                        style={{ ...indicatorStyles, background: "#772286" }}
                        aria-label={`Selected: ${label} ${index + 1}`}
                        title={`Selected: ${label} ${index + 1}`}
                      />
                    );
                  }
                  return (
                    <li
                      style={indicatorStyles}
                      onClick={onClickHandler}
                      onKeyDown={onClickHandler}
                      value={index}
                      key={index}
                      role="button"
                      tabIndex={0}
                      title={`${label} ${index + 1}`}
                      aria-label={`${label} ${index + 1}`}
                    />
                  );
                }}
                renderArrowNext={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <button
                      className="d-flex justify-content-center align-items-center border-0"
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{ ...arrowStyles, right: 15 }}
                    >
                      <img
                        style={{ width: "12px", height: "12px" }}
                        src="/assets/common/purple_right.svg"
                      />
                    </button>
                  )
                }
                renderArrowPrev={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <button
                      className="d-flex justify-content-center align-items-center border-0"
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{
                        ...arrowStyles,
                        left: 15,
                        transform: "rotate(180deg)",
                      }}
                    >
                      <img
                        style={{ width: "12px", height: "12px" }}
                        src="/assets/common/purple_right.svg"
                      />
                    </button>
                  )
                }
                showThumbs={false}
                showStatus={false}
              >
                {salon.data.salon.images.map((salonImage, index) => {
                  return (
                    <img
                      key={index}
                      src={`${process.env.REACT_APP_IMAGE_URL}${salonImage}`}
                      alt="Salon"
                    />
                  );
                })}
              </Carousel>
            ) : (
              <img src="/assets/common/salon.png" alt="Salon" />
            )}
          </>
        ) : (
          ""
        )}
      </div>

      <div className="name-address-rating-container d-flex justify-content-between align-items-center">
        <div>
          <p className="name mb-0">{salon && salon.data.salon.name}</p>
          <p className="address mb-0">
            {salon && salon.data.salon.address.line_1},{" "}
            {salon && salon.data.salon.address.line_2},{" "}
            {salon && salon.data.salon.address.city},{" "}
            {salon && salon.data.salon.address.state},{" "}
            {salon && salon.data.salon.address.country},{" "}
            {salon && salon.data.salon.address.pincode}
          </p>
        </div>

        <div>
          {salon && salon.data.salon.rating > 0 ? (
            <Link
              to={`/reviewrating/${salon.data.salon.name
                .toLowerCase()
                .split(" ")
                .join("-")}-${salon.data.salon._id}`}
              className="rating-review "
            >
              <div className="rating d-flex justify-content-center align-items-center">
                <span>{salon && salon.data.salon.rating.toFixed(1)}</span>
                <VscStarFull />
              </div>
              <div className="review-container d-flex flex-column justify-content-center align-items-center">
                <p className="mb-0 number-of-review">
                  {Number(salon && salon.data.salon.numberOfReviews) > 1000 ? (
                    <>
                      {Number(salon && salon.data.salon.numberOfReviews) / 1000}
                      K
                    </>
                  ) : (
                    Number(salon && salon.data.salon.numberOfReviews)
                  )}
                </p>
                <p className="mb-0 review">Reviews</p>
              </div>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="salon-type mt-1">
        <p className="mb-0">
          {salon ? (
            <>
              {salon.data.salon.type.charAt(0).toUpperCase() +
                salon.data.salon.type.slice(1)}{" "}
            </>
          ) : (
            ""
          )}{" "}
          Salon
        </p>
      </div>

      <div className="open-close-rupees-container d-flex align-items-center justify-content-between">
        {moment(new Date(), "LT").isBetween(
          moment(
            salon &&
              salon.data.salon.timings[moment(new Date()).day()].split("-")[0],
            "LT"
          ),
          moment(
            salon &&
              salon.data.salon.timings[moment(new Date()).day()].split("-")[1],
            "LT"
          )
        ) ? (
          <div className="open d-flex align-items-center">
            <img src="/assets/salon/open.svg" />
            <p className="mb-0">Open Now</p>
          </div>
        ) : (
          <div className="close d-flex align-items-center">
            <img src="/assets/salon/close.svg" />
            <p className="mb-0">Closed Now</p>
          </div>
        )}
        <div className="rupees-container">
          <p className="mb-0 d-flex flex-column align-items-center justify-content-center">
            {/* {props.salon && props.salon.data.salon.priceRating == "1"
              ? "₹"
              : ""}
            {props.salon && props.salon.data.salon.priceRating == "2"
              ? "₹₹"
              : ""}
            {props.salon && props.salon.data.salon.priceRating == "3"
              ? "₹₹₹"
              : ""} */}
            {props.salon && props.salon.data.salon.classType}
          </p>
        </div>
        {/* <p className="mb-0 classtype">
          {props.salon && props.salon.data.salon.classType}
        </p> */}
      </div>

      <div className="timing">
        <p className="mb-0">
          Timing: {salon && salon.data.salon.timings[moment(new Date()).day()]}
        </p>
      </div>

      <Amenities
        setIsShowAmenitiesInfo={props.setIsShowAmenitiesInfo}
        salon={props.salon}
        setAmenitiesInfo={props.setAmenitiesInfo}
      />

      {salon && salon.data.salon.description ? (
        <div className="description-container">
          <p className="mb-0">
            {salon.data.salon.description.length > 200 ? (
              <>
                {isShowMore
                  ? salon.data.salon.description
                  : salon.data.salon.description.substring(0, 200)}
              </>
            ) : (
              salon && salon.data.salon.description
            )}
            <>
              {salon && salon.data.salon.description.length > 200 ? (
                <>
                  {isShowMore ? (
                    <button
                      onClick={() => {
                        setIsShowMore(false);
                      }}
                      className="border-0 bg-white"
                    >
                      ...Show Less
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setIsShowMore(true);
                      }}
                      className="border-0 bg-white"
                    >
                      ...Show More
                    </button>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default SalonInfo;
