import React, { useEffect, useState } from "react";

import useGetData from "../../customhooks/getdata";
import useUpdateData from "../../customhooks/updatedata";
import useIsLoader from "../../customhooks/useisloader";
import Loader from "../../components/loader";
import Header from "./header";
import Coupon from "./coupon";
import ApplyCoupon from "./applycoupon";

import PopUp from "../../components/popup";
import useIsPopUp from "../../customhooks/ispopup";
import NotFound from "../../components/notfound";

import { authJsonHeaders } from "../../constants";
import moment from "moment";
import { gtm } from "../gtm";

function Coupons(props) {
  const [isLoader, setIsLoader] = useIsLoader(true);

  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [appliedCoupon, appliedCouponError, applyCoupon] = useUpdateData();

  const [gotCoupon, couponError, getCoupon] = useGetData();

  const [popUpMessage, setPopUpMessage] = useState("");

  useEffect(() => {
    if (props.gotCartList) {
      getCoupon({
        url: `${process.env.REACT_APP_API_URL}/app/coupon/listOfAvailableCoupons?q=salon:${props.gotCartList.data.cart.salon._id}&sort_by=order&sort_order=desc&status=1&isActive=true`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [props]);

  useEffect(() => {
    if (gotCoupon != undefined && gotCoupon != "" && gotCoupon != null) {
      setIsLoader(false);
    }
  }, [gotCoupon]);

  const handleApplyCoupon = (item, couponCode) => {
    let couponUsedByUser = 0;

    if (
      props.gotCartList.data.cart.cartCalculations.overall.grandTotal >
      item.minValue
    ) {
      couponUsedByUser = 0;

      item.usedByUsers.forEach((usedUser) => {
        if (localStorage.getItem("userId") == usedUser) {
          couponUsedByUser += 1;
        }
      });

      if (item.allowedUsage > item.usedByUsers.length) {
        if (item.singleUserLimit > couponUsedByUser) {
          applyCoupon({
            url: `${process.env.REACT_APP_API_URL}/app/coupon/applyCoupon?code=${couponCode}`,
            //headers: authJsonHeaders,
            headers: {
              "Content-type": "application/json",
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: {},
          });
        } else {
          setPopUpMessage("Sorry! You have already applied.");
          setIsPopUp(true);
        }
      } else {
        setPopUpMessage("Sorry! This Coupon don't have usage.");
        setIsPopUp(true);
      }
    } else {
      setPopUpMessage(
        "Sorry! Your grand total should be greater than" +
          " " +
          `${
            props.gotCartList.data.cart.services.length > 0
              ? String.fromCharCode(
                  props.gotCartList.data.cart.services[0].service.currencySymbol
                )
              : props.gotCartList.data.cart.products.length > 0
              ? String.fromCharCode(
                  props.gotCartList.data.cart.products[0].product.currencySymbol
                )
              : ""
          }` +
          item.minValue
      );
      setIsPopUp(true);
    }
  };

  const handleClick = (couponCode) => {
    // console.log(moment("01/22/2023").format('dddd'))

    const day = moment("01/21/2023").format("dddd");

    let isMatchedCode = false;

    gotCoupon.data.coupons.forEach((item) => {
      if (item.code === couponCode) {
        handleApplyCoupon(item, couponCode);

        isMatchedCode = true;
      }
    });

    if (!isMatchedCode) {
      setPopUpMessage("Coupon code in invalid");
      setIsPopUp(true);
    }
  };

  useEffect(() => {
    if (
      appliedCoupon != undefined &&
      appliedCoupon != null &&
      appliedCoupon != ""
    ) {
      const cartResRef = props.getCartList();

      cartResRef.then((res) => {
        if (res) {
          props.setCartList(res);
        }
      });
      document.body.style.overflow = "hidden";
      props.setIsAppliedCoupon(true);
      props.setIsOpenCoupon(false);
    }
  }, [appliedCoupon]);

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <>
      <div className="coupons-page d-grid">
        <div>
          <Header setIsOpenCoupon={props.setIsOpenCoupon} />

          <ApplyCoupon handleClick={handleClick} />
        </div>
        {/* {isLoader ? (
          <Loader width={60} height={60} color={"#772286"} />
        ) : gotCoupon && gotCoupon.data ? (
          <>
            {gotCoupon.data.coupons.length > 0 ? (
              <Coupon
                gotCoupon={gotCoupon}
                handleClick={handleClick}
                getCartList={props.getCartList}
                setIsOpenCoupon={props.setIsOpenCoupon}
              />
            ) : (
              <NotFound />
            )}
          </>
        ) : (
          ""
        )} */}
      </div>

      {isPopUp ? <PopUp setIsPopUp={setIsPopUp} message={popUpMessage} /> : ""}
    </>
  );
}

export default Coupons;
