import React from "react";
import { Link } from "react-router-dom";

function Banners(props) {

  return (
    <>
      <div className="banner-container d-flex align-items-center">

        {props.banners.map((item) => {
          return <Link to={`${item.link}`} key={item._id}>
            <img src={item.image ? `${process.env.REACT_APP_IMAGE_URL}${item.image}` : "/assets/home/banner.png"} alt="Banner" />
          </Link>
        })}
      </div>
    </>
  );
}

export default Banners