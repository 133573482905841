import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

function Oops(props) {

  return (
    <div className="oops-section">
      <div className="payment-success-image-container">
        <img src="/assets/paymentfailed/paymentfailed.svg" />
      </div>
      <div className="oops-failed-id-container">
        <p className="mb-0 oops">Oops! Something went</p>
        <p className="mb-0 payment-failed">{props.gotOrderDetails && props.gotOrderDetails.data.order.transactions[0].razorPayDetails.paymentId ? "Payment Failed !" : "Payment Cancelled !"}</p>

        {props.gotOrderDetails && props.gotOrderDetails.data.order.transactions[0].razorPayDetails.paymentId ?
          <p className="mb-0 payment-id">Payment ID: {props.gotOrderDetails && props.gotOrderDetails.data.order.transactions[0].razorPayDetails.paymentId}, {moment(props.gotOrderDetails && props.gotOrderDetails.data.order.transactions[0].createdAt).format("ddd, DD MMM, hh:mm A")}</p> : <p className="mb-0 payment-id">{moment(props.gotOrderDetails && props.gotOrderDetails.data.order.transactions[0].createdAt).format("ddd, DD MMM, hh:mm A")}</p>}
      </div>
    </div>
  );
}

export default Oops;
