import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { AiOutlineClockCircle } from "react-icons/ai";
import { VscStarFull } from "react-icons/vsc";

import usePostData from "../../customhooks/postdata";
import useDeleteData from "../../customhooks/deletedata";
import useGetData from "../../customhooks/getdata";

import { Carousel } from "react-responsive-carousel";

import { authJsonHeaders } from "../../constants";
import Banners from "../banner";

function Salon(props) {
  const navigate = useNavigate();

  const params = useParams();

  const [likedSalonsId, setLikedSalonsId] = useState([]);

  const [type, setType] = useState("");

  const [name, setName] = useState("");

  const [postedLike, likeerror, postData] = usePostData();

  const [deletedLike, deleteerror, deleteData] = useDeleteData();

  const [likedSalons, likedSalonsError, getLikedSalons] = useGetData();

  // const [gotUserId, userIdError, getUserId] = useGetData();

  const [arrowStyles, setArrowStyles] = useState({
    position: "absolute",
    zIndex: 2,
    top: "calc(50%)",
    cursor: "pointer",
    backgroundColor: "white",
    height: "20px",
    width: "20px",
    borderRadius: "100%",
  });

  const [indicatorStyles, setIndicatorStyles] = useState({
    background: "#fff",
    width: 8,
    height: 8,
    borderRadius: "100%",
    display: "inline-block",
    margin: "0 5px",
  });

  useEffect(() => {
    if (params && params.type && params.name) {
      setType(params.type);

      setName(
        params.name.charAt(0).toUpperCase() +
          params.name
            .slice(1)
            .split("-")
            .join(" ")
      );


    }
  }, [params]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getLikedSalons({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/salon/like/search?user=${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, []);

  const handleClickLike = (item) => {
    if (localStorage.getItem("userId")) {
      postData({
        url: `${process.env.REACT_APP_API_URL}/app/salon/${item._id}/like`,
        body: {},
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } else {
      navigate("/login");
    }
  };

  const handleClickUnlike = (item) => {
    deleteData({
      url: `${process.env.REACT_APP_API_URL}/app/salon/${item._id}/like`,
      body: {},
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  };

  useEffect(() => {
    if (
      (postedLike != null && postedLike != undefined && postedLike != "") ||
      (deletedLike != null && deletedLike != undefined && deletedLike != "")
    ) {
      getLikedSalons({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/salon/like/search?user=${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (props.getLikedSalons) {
        props.setIsLoader(true);
        props.getLikedSalons({
          url: `${
            process.env.REACT_APP_API_URL
          }/app/salon/like/search?user=${localStorage.getItem("userId")}`,
          //headers: authJsonHeaders,
          headers: {
            "Content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      }
    }
  }, [postedLike, deletedLike]);

  useEffect(() => {
    setLikedSalonsId([]);
    likedSalons &&
      likedSalons.data.likes.forEach((likedItem) => {
        setLikedSalonsId((prev) => [...prev, likedItem.salon]);
      });
  }, [likedSalons]);

  useEffect(() => {
    if (
      props.salons != undefined &&
      props.salons != "" &&
      props.salons != null
    ) {
      const element = document.getElementsByClassName("slider-wrapper");
      for (let i = 0; i <= element.length - 1; i++) {
        element[i].style.borderRadius = "8px 8px 0px 0px";
      }
    }
  }, [props]);

  return (
    <>
      {props.salons.map((item, index) => {
        return (
          <div key={item._id} className="d-flex flex-column gap-3">
            <div
              id={item._id}
              key={item._id}
              className="salon position-relative"
            >
              <div className="like d-flex justify-content-between align-items-center position-absolute w-100">
                {item.discount > 0 ? (
                  <div className="offers">
                    <p className="mb-0">{item.discount}% Discount</p>
                  </div>
                ) : (
                  ""
                )}
                {likedSalonsId.includes(item._id) ? (
                  <button
                    onClick={() => {
                      handleClickUnlike(item);
                    }}
                    className="border-0 d-grid justify-content-center align-items-center"
                  >
                    <img src="/assets/common/like.svg" alt="Like" />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      handleClickLike(item);
                    }}
                    className="border-0 d-grid justify-content-center align-items-center"
                  >
                    <img src="/assets/common/unlike.svg" alt="Like" />
                  </button>
                )}
              </div>
              <div>
                <div className="position-relative">
                  <div className="salon-image">
                    {
                      <Carousel
                        stopOnHover={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        infiniteLoop={true}
                        showIndicators={false}
                        autoPlay
                        // renderIndicator={(onClickHandler, isSelected, index, label) => {
                        //     if (isSelected) {
                        //         return (
                        //             <li
                        //                 style={{ ...indicatorStyles, background: '#772286' }}
                        //                 aria-label={`Selected: ${label} ${index + 1}`}
                        //                 title={`Selected: ${label} ${index + 1}`}
                        //             />
                        //         );
                        //     }
                        //     return (
                        //         <li
                        //             style={indicatorStyles}
                        //             onClick={onClickHandler}
                        //             onKeyDown={onClickHandler}
                        //             value={index}
                        //             key={index}
                        //             role="button"
                        //             tabIndex={0}
                        //             title={`${label} ${index + 1}`}
                        //             aria-label={`${label} ${index + 1}`}
                        //         />
                        //     );
                        // }}
                        renderArrowNext={(onClickHandler, hasPrev, label) =>
                          hasPrev && (
                            <button
                              className="d-flex justify-content-center align-items-center border-0"
                              type="button"
                              onClick={onClickHandler}
                              title={label}
                              style={{ ...arrowStyles, right: 15 }}
                            >
                              <img
                                style={{ width: "12px", height: "12px" }}
                                src="/assets/common/purple_right.svg"
                              />
                            </button>
                          )
                        }
                        renderArrowPrev={(onClickHandler, hasNext, label) =>
                          hasNext && (
                            <button
                              className="d-flex justify-content-center align-items-center border-0"
                              type="button"
                              onClick={onClickHandler}
                              title={label}
                              style={{
                                ...arrowStyles,
                                left: 15,
                                transform: "rotate(180deg)",
                              }}
                            >
                              <img
                                style={{ width: "12px", height: "12px" }}
                                src="/assets/common/purple_right.svg"
                              />
                            </button>
                          )
                        }
                        showThumbs={false}
                        showStatus={false}
                        onClickItem={() => {
                          navigate(
                            `/${
                              sessionStorage.getItem("isQrCode") == "true"
                                ? "qr"
                                : "app"
                            }/salon/${item.name
                              .toLowerCase()
                              .split(" ")
                              .join("-")}-${item._id}`
                          );
                        }}
                      >
                        {item.images.length > 0 ? (
                          item.images.map((salonImage, index) => {
                            return (
                              <img
                                key={index}
                                src={`${process.env.REACT_APP_IMAGE_URL}${salonImage}`}
                                alt="Salon"
                              />
                            );
                          })
                        ) : (
                          <img src="/assets/common/salon.png" alt="Salon" />
                        )}
                      </Carousel>
                    }
                  </div>
                  <div className="starting-price-time-container d-flex justify-content-between align-items-center w-100 position-absolute">
                    {item.startingPrice ? (
                      <div className="starting-price">
                        <p className="mb-0">
                          @ ₹{item.startingPrice}
                          {/* {type == "servicecategory" ||
                          type == "productcategory"
                            ? `${
                                name.length > 3
                                  ? `${name.substring(0, 3)}...`
                                  : name
                              } starting from ₹${item.startingPrice}`
                            : type == "service" || type == "product"
                            ? `${
                                name.length > 8
                                  ? `${name.substring(0, 8)}...`
                                  : name
                              } at ₹${item.startingPrice}`
                            : ""} */}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="time-distance d-flex justify-content-center align-items-center">
                      <AiOutlineClockCircle />
                      <p className="mb-0">
                        {item.appointmentIn == 10000
                          ? "Next Day"
                          : item.appointmentIn / 60 > 2
                          ? "2hrs+"
                          : Math.round(item.appointmentIn) + " mins"}{" "}
                        |{" "}
                        {item.distanceInKm.toFixed(1).split(".")[1] == "0"
                          ? item.distanceInKm.toFixed(1).split(".")[0]
                          : item.distanceInKm.toFixed(1)}{" "}
                        Kms
                      </p>
                    </div>
                  </div>
                </div>
                <Link
                  to={`/${
                    sessionStorage.getItem("isQrCode") == "true" ? "qr" : "app"
                  }/salon/${item.name
                    .toLowerCase()
                    .split(" ")
                    .join("-")}-${item._id}`}
                  className="salon-info d-block"
                >
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <p className="name mb-0">{item.name}</p>
                    <p className="rating d-flex justify-content-center align-items-center mb-0">
                      <span>{item.rating.toFixed(1)}</span>
                      <VscStarFull />
                    </p>
                  </div>
                  <div className="address-type">
                    <div className="address d-grid justify-content-between align-items-center">
                      <p className="mb-0">
                        {item.address.line_1.substring(0, 30)},{" "}
                        {item.address.city.substring(0, 15)}
                      </p>
                      {/* <p className="salon-class mb-0 d-flex flex-column align-items-center justify-content-center">
                      {item.priceRating == "1" ? "₹" : ""}
                      {item.priceRating == "2" ? "₹₹" : ""}
                      {item.priceRating == "3" ? "₹₹₹" : ""}
                    </p> */}
                      <p className="salon-class mb-0">{item.classType}</p>
                    </div>

                    <div>
                      <p className="salon-type mb-0 mt-1">
                        {item.type.charAt(0).toUpperCase() + item.type.slice(1)}{" "}
                        Salon
                      </p>
                    </div>
                  </div>

                  <div className="book d-flex align-items-center">
                    <img src="/assets/component/salon/flash.svg" alt="Flash" />
                    <p className="mb-0">
                      {item.completedOrders
                        ? 50 +
                          item.completedOrders.product +
                          item.completedOrders.service
                        : 50}
                      + appointments booked from here recently
                    </p>
                  </div>
                </Link>
              </div>
            </div>

            {props.middleBanners ? (
              <>
                {props.middleBanners.length > 0 && props.salons.length >= 5 ? (
                  <>
                    {index == 4 ? (
                      <Banners banners={props.middleBanners} />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {props.bottomBanners ? (
              <>
                {props.bottomBanners.length > 0 && props.salons.length >= 9 ? (
                  <>
                    {index == 9 ? (
                      <Banners banners={props.bottomBanners} />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </>
  );
}

export default Salon;
