import React, { useCallback, useEffect } from "react";
import Oops from "./oops";
import Header from "./header";
import { Link, useLocation, useParams } from "react-router-dom";
import useGetData from "../../customhooks/getdata";
import { gtm } from "../gtm";

function PaymentFailed(props) {

  const location = useLocation()

  const callback = useCallback(()=>{
    document.body.style.overflow = "unset"
  }, [])

  useEffect(()=>{
    return callback
  }, [])

  const orderId = useParams();

  const [gotOrderDetails, orderDetailsError, getOrderDetails] = useGetData();

  useEffect(() => {
    if (orderId != undefined && orderId != null && orderId != "") {
      getOrderDetails({
        url: `${process.env.REACT_APP_API_URL}/app/order/${orderId.orderId}`,
        headers: {
          "Content-type": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [orderId]);

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <div className="payment-failed-page">
      <Header/>
      <div className="girl-image-container">
        <img src="/assets/paymentfailed/girl.svg" />
      </div>
      <Oops gotOrderDetails = {gotOrderDetails}/>

      <div className="retry-payment-cancel-container">
        <Link to="/cart" className="retry-payment">Retry Payment</Link>
        <Link to="/" className="cancel">Cancel</Link>
      </div>
    </div>
  );
}

export default PaymentFailed;
