import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "../pages/login";
import Home from "../pages/home";
import Mapview from "../pages/mapview";
import Salon from "../pages/salon";
import MyProfile from "../pages/myprofile";
import Cart from "../pages/cart";
import ViewProfile from "../pages/viewprofile";
import BookingsOrders from "../pages/bookingorder";
import Favourites from "../pages/favourites";
import Wallet from "../pages/wallet";
import OrderDetails from "../pages/orderdetails";
import OffersDeals from "../pages/offersdeals";
import Faqs from "../pages/faqs";
import Salons from "../pages/salonsbyfooter";
import SalonsByCategory from "../pages/salonsbycategory";
import DynamicPages from "../pages/dynamicpages";
import Privateroutes from "./privateroutes";
import ReviewRating from "../pages/showreviewrating";
import SalonInfo from "../pages/saloninfo";
import SalonsBySearch from "../pages/salonsbysearch";
import SalonList from "../pages/salonlist";

import Membership from "../pages/membership";

import IsQrCode from "./isqrcode";
import PaymentSuccess from "../pages/paymentsuccess";
import PaymentFailed from "../pages/paymentfailed";
import MembershipPaymentSuccess from "../pages/membershipsuccess";
import MembershipPaymentFailed from "../pages/membershipfailed";

import Business from "../pages/business";
import NotFoundPage from "../pages/notfoundpage";
import DemoFormSubmittedPrivateroutes from "./demoformsubmittedprivateroutes";
import OrderSuccess from "../pages/ordersuccess";
import OrderFailed from "../pages/orderfailed";
// import BusinessFormSubmitted from "../pages/business_form_submitted";
import Slot from "../pages/slot";
import Payment from "../pages/payment";
import SlotPaymentPrivateroutes from "./slotpaymentauthentication";
import FavouritesAuthenticate from "./favouritesauthenticate";
import BusinessManager from "../pages/businessmanager";

function Router() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/notfound" element={<NotFoundPage />}></Route>
          <Route path="/login" element={<Login />}></Route>

          <Route path="/mapview" element={<Mapview />} />
          <Route path="qr/salon/:salonNameId/" element={<Salon />} />
          <Route path="qr/salon/:salonNameId/list" element={<SalonList />} />
          <Route path="app/salon/:salonNameId/" element={<Salon />} />
          <Route path="qr/salon/:salonNameId/:origin" element={<Salon />} />
          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="/offersdeals" element={<OffersDeals />} />
          <Route path="/viewprofile" element={<ViewProfile />} />

          <Route path="/faqs" element={<Faqs />} />
          <Route path="/salons/:params" element={<Salons />} />
          <Route path="/saloninfo/:salonNameId" element={<SalonInfo />} />

          <Route
            path="/salonscategory/:params"
            element={<SalonsByCategory />}
          />
          <Route path="/:title" element={<DynamicPages />} />

          <Route path="/cart" element={<Cart />} />
          <Route
            path="/reviewrating/:salonNameSalonId"
            element={<ReviewRating />}
          />

          <Route
            path="/searchsalons/:type/:name"
            element={<SalonsBySearch />}
          />

          <Route path="/business" element={<Business />} />
          <Route path="/business-manager" element={<BusinessManager />} />

          {/* <Route element={<DemoFormSubmittedPrivateroutes />}>
            <Route
              path="/business/form-submitted"
              element={<BusinessFormSubmitted />}
            />
          </Route> */}

          <Route
            path="/:itemType/orderdetails/:orderId"
            element={<OrderDetails />}
          />

          <Route element={<Privateroutes />}>
            {/* <Route path="/membership" element={<Membership />} /> */}

            <Route path="/bookingsorders" element={<BookingsOrders />} />

            <Route element={<FavouritesAuthenticate />}>
              <Route path="/favourites" element={<Favourites />} />
            </Route>

            <Route path="/wallet" element={<Wallet />} />

            <Route
              path="/paymentsuccess/:orderId"
              element={<PaymentSuccess />}
            />
            <Route path="/paymentfailed/:orderId" element={<PaymentFailed />} />

            {/* <Route
              path="/membershippaymentsuccess/:orderId"
              element={<MembershipPaymentSuccess />}
            />
            <Route
              path="/membershippaymentfailed/:orderId"
              element={<MembershipPaymentFailed />}
            /> */}

            <Route element={<SlotPaymentPrivateroutes />}>
              <Route path="/slot" element={<Slot />} />
              <Route path="/payment/:isMembership" element={<Payment />} />
            </Route>
          </Route>

          <Route path="/ordersuccess" element={<OrderSuccess />} />

          <Route path="/orderfailed" element={<OrderFailed />} />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Router;
