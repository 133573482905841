import React, { useCallback, useEffect, useState } from "react";

import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";

function ViewMoreCategories(props) {
  const [animate, setAnimate] = useState({});

  useEffect(() => {
    setAnimate({
      transform: "translateY(0%)",
    });
  }, []);

  const callback = useCallback(() => {
    document.body.style.overflow = "unset";
  });

  useEffect(() => {
    return callback;
  }, []);

  const handleClickOnBack = () => {
    setAnimate({
      transform: "translateY(100%)",
    });
    setTimeout(() => {
      props.setIsShowViewMoreSection(false);
    }, 1000);
  };

  return (
    <div
      style={animate}
      className="view-more-section-page d-flex align-items-end justify-content-center"
    >
      <div className="w-100">
        <div className="category-section bg-white text-center">
          <div className="category-container d-flex flex-column">
            {props.categories.map((item) => {
              return (
                <Link key={item._id} to={`/salonscategory/${item._id}`}>
                  <img
                    src={
                      item.image
                        ? `${process.env.REACT_APP_IMAGE_URL}${item.image}`
                        : "/assets/common/serviceproduct.png"
                    }
                    alt="Category"
                  />
                  <p className="mb-0">{item.name}</p>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="close">
          <button
            onClick={() => {
              handleClickOnBack();
            }}
            className="border-0 d-flex align-items-center"
          >
            <CgClose />
            <p className="mb-0">Close</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ViewMoreCategories;
