import React from "react";

import { useNavigate } from "react-router-dom";

import putRequest from "../../../functions/apicall/putrequest";

function Instruction(props) {
  const navigate = useNavigate();

  const removeInstruction = async () => {
    const res = await putRequest({
      url: `${process.env.REACT_APP_API_URL}/app/cart`,
      body: {
        instructions: null,
      },
    });

    return Promise.resolve(res);
  };

  const handleClickRemove = () => {
    const removeInstRef = removeInstruction();

    removeInstRef.then((couponRes) => {
      if (couponRes) {
        const cartResRef = props.getCartList();

        cartResRef.then((res) => {
          if (res) {
            props.setCartList(res);
          }
        });
      }
    });
  };

  const handleClickAdd = () => {
    if (localStorage.getItem("userId")) {
      props.openAddInstruction();
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login");
    }
  };

  return (
    <>
      {props.gotCartList &&
      props.gotCartList.data &&
      props.gotCartList.data.cart.instructions ? (
        <div className="add-wipes-container d-grid align-items-center bg-white">
          <div className="notbook d-flex">
            <img src="/assets/cart/notebook.svg" alt="Notebook" />
          </div>
          <div className="instructions-add-container d-flex justify-content-between align-items-center">
            <div>
              <p className="mb-0">{props.gotCartList.data.cart.instructions}</p>
            </div>
            <div className="add">
              <button
                onClick={() => {
                  handleClickRemove();
                }}
                className="bg-white border-0"
              >
                remove
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="add-wipes-container d-grid align-items-center bg-white">
          <div className="notbook d-flex">
            <img src="/assets/cart/notebook.svg" alt="Notebook" />
          </div>
          <div className="instructions-add-container d-flex justify-content-between align-items-center">
            <div>
              <p className="mb-0">Do you want to add any instructions?</p>
            </div>
            <div className="add">
              <button
                onClick={() => {
                  handleClickAdd();
                }}
                className="bg-white border-0"
              >
                ADD
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Instruction;
