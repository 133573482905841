import React, { useCallback, useEffect, useState } from "react";

function ShowOfferInformation(props) {

    const [animate, setAnimate] = useState({})

    useEffect(() => {
        setAnimate({
            transform: "translateY(0%)",
        });
    }, []);

    const callback = useCallback(() => {
        document.body.style.overflow = "unset"
    })

    useEffect(() => {
        document.body.style.overflow = "hidden"

        return callback
    }, [])

    const handleClickOnBack = () => {
        setAnimate({
            transform: "translateY(100%)"
        })
        setTimeout(() => {
            props.setIsShowOfferInformation(false)
        }, 1000)
    }

    return <div style={animate} onClick={() => { handleClickOnBack() }} className="show-offer-information-page vh-100 d-flex justify-content-center align-items-center">
        <div className="show-offer-information bg-white">
            <div className="header d-flex justify-content-between align-items-center">
                <p className="mb-0">{props.couponCodeDesc.couponCode}</p>
                <button onClick={() => { handleClickOnBack() }} className="bg-white border-0"><img src="/assets/common/cross.svg" /></button>
            </div>
            <div>
                <p className="description mb-0">{props.couponCodeDesc.description}</p>
            </div>
        </div>
    </div>

}

export default ShowOfferInformation