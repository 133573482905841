import axios from "axios";
import { toast } from "react-hot-toast";

const deleteRequest = async (params) => {
  try {
    const res = await axios.delete(
      params.url,
      {
        headers: {
          "content-type": "Application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
      params.body
    );

    return Promise.resolve(res);
  } catch (err) {
    console.log(err);
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err && err.response && err.response.data && err.response.data.message);
    }
    // return Promise.reject(err);
  }
};

export default deleteRequest;
