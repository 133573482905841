import React, { useState } from "react";

import { CgClose } from "react-icons/cg";
import { VscStarFull } from "react-icons/vsc"

function Categories(props) {

      const handleClickOnFilter = (item) => {
        props.setFilterValue([...props.filterValue, item])
      }

      const handleClickOnRemove = (item) => {
        props.setFilterValue(props.filterValue.filter((filterValue) => {
          if (filterValue != item) {
            return filterValue
          }
        }))
      }

    return (
        <div className="categories d-flex">
            {props.filterValue.includes(5) ? <div className="cancel-categories d-flex justify-content-between align-items-center">
                <p className="mb-0">Rating 5 <VscStarFull /></p>
                <button
                    onClick={() => {
                        handleClickOnRemove(5)
                    }}
                    className="border-0 d-grid"
                >
                    <CgClose />
                </button>
            </div> :
                <div className="category">
                    <button onClick={() => { handleClickOnFilter(5) }}>Rating 5 <VscStarFull /></button>
                </div>}

            {props.filterValue.includes(4) ? <div className="cancel-categories d-flex justify-content-between align-items-center">
                <p className="mb-0">Rating 4 <VscStarFull /></p>
                <button
                    onClick={() => {
                        handleClickOnRemove(4)
                    }}
                    className="border-0 d-grid"
                >
                    <CgClose />
                </button>
            </div> :
                <div className="category">
                    <button onClick={() => { handleClickOnFilter(4) }}>Rating 4 <VscStarFull /></button>
                </div>}


            {props.filterValue.includes(3) ? <div className="cancel-categories d-flex justify-content-between align-items-center">
                <p className="mb-0">Rating 3 <VscStarFull /></p>
                <button
                    onClick={() => {
                        handleClickOnRemove(3)
                    }}
                    className="border-0 d-grid"
                >
                    <CgClose />
                </button>
            </div> :
                <div className="category">
                    <button onClick={() => { handleClickOnFilter(3) }}>Rating 3 <VscStarFull /></button>
                </div>}

            {props.filterValue.includes(2) ? <div className="cancel-categories d-flex justify-content-between align-items-center">
                <p className="mb-0">Rating 2 <VscStarFull /></p>
                <button
                    onClick={() => {
                        handleClickOnRemove(2)
                    }}
                    className="border-0 d-grid"
                >
                    <CgClose />
                </button>
            </div> :
                <div className="category">
                    <button onClick={() => { handleClickOnFilter(2) }}>Rating 2 <VscStarFull /></button>
                </div>}

            {props.filterValue.includes(1) ? <div className="cancel-categories d-flex justify-content-between align-items-center">
                <p className="mb-0">Rating 1 <VscStarFull /></p>
                <button
                    onClick={() => {
                        handleClickOnRemove(1)
                    }}
                    className="border-0 d-grid"
                >
                    <CgClose />
                </button>
            </div> :
                <div className="category">
                    <button onClick={() => { handleClickOnFilter(1) }}>Rating 1 <VscStarFull /></button>
                </div>}
        </div>
    );
}

export default Categories;
