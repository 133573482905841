import React from "react";

import { VscStarFull } from "react-icons/vsc";
import { useState } from "react";

import ConfirmReviewRating from "../confirmreviewrating";
import { useNavigate } from "react-router-dom";

function ReviewRating(props) {

  const [active, setActive] = useState(0);

  const [rating, setRating] = useState("");
  const [review, setReview] = useState("");

  const navigate = useNavigate()

  const [isConfirmReviewRating, setIsConfirmReviewRating] = useState(false)

  const [isShowMessage, setIsShowMessage] = useState(false)

  const handleClickRating = (value) => {
    setActive(value);
    setRating(value);
  };

  const handleClickSubmit = () => {


    if (!localStorage.getItem("userId")) {
      navigate("/login")
    }
    else{
      if (rating != "") {
        document.body.style.overflow = "hidden";
        setIsConfirmReviewRating(true)
      }
      else {
        setIsShowMessage(true)
      }
    }

    
  };

  return (
    <>
      <div className="salon-rating-section">
        <div>
          <div className="title">
            {isShowMessage ? <p className="mb-0 message">Please give some rating</p> : ""}
            <p className="mb-0 text-start">Rate this order</p>
          </div>

          <div className="salon-ratings-container d-flex">
            <button
              onClick={() => {
                handleClickRating(1);
              }}
              className={
                active >= 1
                  ? "active d-flex justify-content-center align-items-center"
                  : "d-flex justify-content-center align-items-center"
              }
            >
              1<VscStarFull />
            </button>
            <button
              onClick={() => {
                handleClickRating(2);
              }}
              className={
                active >= 2
                  ? "active d-flex justify-content-center align-items-center"
                  : "d-flex justify-content-center align-items-center"
              }
            >
              2<VscStarFull />
            </button>
            <button
              onClick={() => {
                handleClickRating(3);
              }}
              className={
                active >= 3
                  ? "active d-flex justify-content-center align-items-center"
                  : "d-flex justify-content-center align-items-center"
              }
            >
              3<VscStarFull />
            </button>
            <button
              onClick={() => {
                handleClickRating(4);
              }}
              className={
                active >= 4
                  ? "active d-flex justify-content-center align-items-center"
                  : "d-flex justify-content-center align-items-center"
              }
            >
              4<VscStarFull />
            </button>
            <button
              onClick={() => {
                handleClickRating(5);
              }}
              className={
                active >= 5
                  ? "active d-flex justify-content-center align-items-center"
                  : "d-flex justify-content-center align-items-center"
              }
            >
              5<VscStarFull />
            </button>
          </div>

          <div className="salon-review-container">
            <textarea
              onChange={(e) => {
                setReview(e.target.value);
              }}
              type="text"
              placeholder="Write a review for this order."
            ></textarea>
          </div>

          <div className="submit-review">
            <button
              onClick={() => {
                handleClickSubmit();
              }}
              className="border-0 d-grid justify-content-center align-items-center"
            >
              Submit Review
            </button>
          </div>
        </div>
      </div>

      {isConfirmReviewRating ? <ConfirmReviewRating getOrderDetails={props.getOrderDetails} review={review} rating={rating} setIsConfirmReviewRating={setIsConfirmReviewRating} gotOrderDetails={props.gotOrderDetails} /> : ""}
    </>
  );
}

export default ReviewRating;
