import React from "react";

import { Outlet, Navigate } from "react-router-dom";

function FavouritesAuthenticate() {
  return (
    <>
      {sessionStorage.getItem("city") &&
      sessionStorage.getItem("country") &&
      sessionStorage.getItem("lat") &&
      sessionStorage.getItem("lng") ? (
        <>
          <Outlet />
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
}

export default FavouritesAuthenticate;
