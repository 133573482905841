export const headers = {
    "Content-Type": "application/json",
    'X-Requested-With': 'XMLHttpRequest'
}

export const authJsonHeaders = {
    "Content-type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
}


export const authFormHeaders = {
    "Content-type": "multipart/form-data",
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
}


