import React, { useEffect, useState } from "react";

function PaymentDetails(props) {


  const [total, setTotal] = useState(0)

  const [tax, setTax] = useState(0)

  useEffect(() => {
    if (props.orderIdItemType && props.gotOrderDetails) {
      setTotal(props.orderIdItemType && props.orderIdItemType.itemType == "service" ? props.gotOrderDetails && props.gotOrderDetails.data.order.cartCalculations.services.grandTotal / 1.18 : props.gotOrderDetails && props.gotOrderDetails.data.order.cartCalculations.products.grandTotal / 1.18)


      setTax(props.orderIdItemType && props.orderIdItemType.itemType == "service" ? props.gotOrderDetails && props.gotOrderDetails.data.order.cartCalculations.services.grandTotal - props.gotOrderDetails.data.order.cartCalculations.services.grandTotal / 1.18 : props.gotOrderDetails && props.gotOrderDetails.data.order.cartCalculations.products.grandTotal - props.gotOrderDetails.data.order.cartCalculations.products.grandTotal / 1.18)
    }

  }, [props])

  return (
    <div className="payment-details-container bg-white">
      <div className="title-container text-center">
        <p className="mb-0">Payment Details</p>
      </div>
      <div className="subtotal d-flex justify-content-between align-items-center">
        <p className="mb-0">Subtotal</p>
        <p className="mb-0">
          {String.fromCharCode(props.gotOrderDetails && props.gotOrderDetails.data.order.salon.country.htmlSymbol)}{props.orderIdItemType.itemType &&
            props.orderIdItemType.itemType == "service" ? props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.services.total) : props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.products.total)}
        </p>
      </div>
      {props.gotOrderDetails && props.gotOrderDetails.data.order.cartCalculations.overall.deliveryCharges ?
        <div className="delivery-charges d-flex justify-content-between align-items-center">
          <p className="mb-0">Delivery charges:</p>
          <p className="mb-0">
            {String.fromCharCode(props.gotOrderDetails && props.gotOrderDetails.data.order.salon.country.htmlSymbol)}
            {props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.overall.deliveryCharges)}
          </p>
        </div> : ""}
      <div className="discount d-flex justify-content-between align-items-center">
        <p className="mb-0">Discount:</p>
        <p className="mb-0">
          {String.fromCharCode(props.gotOrderDetails && props.gotOrderDetails.data.order.salon.country.htmlSymbol)}{props.orderIdItemType.itemType &&
            props.orderIdItemType.itemType == "service" ? props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.services.discount) : props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.products.discount)}
        </p>
      </div>
      {props.gotOrderDetails && props.gotOrderDetails.data.order.cartCalculations.products.productsMembershipDiscount > 0 || props.gotOrderDetails && props.gotOrderDetails.data.order.cartCalculations.services.servicesMembershipDiscount > 0}
      <div className="membership d-flex justify-content-between align-items-center">
        <p className="mb-0">Membership Discount:</p>
        <p className="mb-0">
          {String.fromCharCode(props.gotOrderDetails && props.gotOrderDetails.data.order.salon.country.htmlSymbol)}{props.orderIdItemType.itemType &&
            props.orderIdItemType.itemType == "service" ? props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.services.servicesMembershipDiscount) : props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.products.productsMembershipDiscount)}
        </p>
      </div>

      <div className="coupon-discount d-flex justify-content-between align-items-center">
        <p className="mb-0">Coupon Discount:</p>
        <p className="mb-0">
          {String.fromCharCode(props.gotOrderDetails && props.gotOrderDetails.data.order.salon.country.htmlSymbol)}{props.orderIdItemType.itemType &&
            props.orderIdItemType.itemType == "service" ? props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.services.couponDiscount) : props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.products.couponDiscount)}
        </p>
      </div>

      <div className="total d-flex justify-content-between align-items-center">
        <p className="mb-0">Total:</p>
        <p className="mb-0">

          {String.fromCharCode(props.gotOrderDetails && props.gotOrderDetails.data.order.salon.country.htmlSymbol)}
          {total.toFixed(2)}

        </p>
      </div>

      <div className="tax d-flex justify-content-between align-items-center">
        <p className="mb-0">Tax (GST):</p>
        <p className="mb-0">

          {String.fromCharCode(props.gotOrderDetails && props.gotOrderDetails.data.order.salon.country.htmlSymbol)}
          {tax.toFixed(2)}

        </p>
      </div>

      <div className="grand-total d-flex justify-content-between align-items-center">
        <p className="mb-0">Grand Total:</p>
        <p className="mb-0">
          {String.fromCharCode(props.gotOrderDetails && props.gotOrderDetails.data.order.salon.country.htmlSymbol)}{props.orderIdItemType.itemType &&
            props.orderIdItemType.itemType == "service" ? props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.services.grandTotal) : props.gotOrderDetails && Math.round(props.gotOrderDetails.data.order.cartCalculations.products.grandTotal)}
        </p>
      </div>
    </div>
  );
}

export default PaymentDetails;