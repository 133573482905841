import React from "react";

function Subscription() {
    return <div className="subscription-section">
        <div className="subscription-sub-section">
            <div className="our-risk-free d-flex">
                <div className="image-container">
                    <img src="https://www.zylu.co/wp-content/uploads/2022/12/7-DAY-3-150x150.png" />
                </div>

                <div className="d-flex flex-column our-risk-free-info">
                    <h2 className="mb-0">Our Risk-Free Subscription!</h2>

                    <p className="mb-0">The guarantee covers 7 days, and during that time, we will do everything possible to keep you happy. we will happily refund 100% subscription cost.</p>

                    <p className="mb-0">
                        <strong>Satisfaction guaranteed!</strong>
                    </p>
                </div>
            </div>
            <div className="d-grid plans-container">
                <div className="text-center plan-container basic-plan">
                    <h1 className="mb-0">Basic</h1>
                    <div className="d-flex justify-content-center align-items-center">
                        <h2 className="mb-0">₹0/</h2>
                        <p className="mb-0">Month</p>
                    </div>

                    {/* <p className="mb-0">Billed annually</p> */}
                    {/* <p className="mb-0">+ One time onboarding fee ₹999 </p> */}

                    <div className="progress-image-container">
                        <img src="/assets/business/progress.svg"/>
                    </div>

                    <p className="mb-0">Web/iOS/Android App</p>
                    <p className="mb-0">QR Digital Standees</p>
                    <p className="mb-0">Salon listing</p>
                    <p className="mb-0">Appointment Management</p>
                    <p className="mb-0">Calendar View</p>
                    
                    {/* <p className="mb-0">Marketplace (3Kms Store Visibility)</p> */}
                    {/* <p className="mb-0">150 Bookings Per Month</p> */}
                    {/* <p className="mb-0">Social Media Integration</p> */}
                    {/* <p className="mb-0">Reports and Analytics</p> */}
                    
                    {/* <p className="mb-0">3% + GST (Online Payment Transaction Fee)</p> */}
                    {/* <p className="mb-0">Technical Support​ (Chat Only)</p> */}

                    <a href="#create-free-account">Get Started</a>
                </div>

                <div className="text-center plan-container standard-plan">
                    <h1 className="mb-0">Standard</h1>
                    <div className="d-flex justify-content-center align-items-center">
                        <h2 className="mb-0">₹499/</h2>
                        <p className="mb-0">Month</p>
                    </div>
                    <p className="mb-0">Billed annually</p>
                    <p className="mb-0">+ One time onboarding fee ₹999</p>

                    <div className="progress-image-container">
                        <img src="/assets/business/progress.svg"/>
                    </div>  
                    <p className="mb-0">Everything in basic</p>
                    <p className="mb-0">Analytics</p>
                    <p className="mb-0">Staff Management & Pay Roll</p>
                    <p className="mb-0">Feedback Management</p>
                    <p className="mb-0">Inventory Management</p>
                    <p className="mb-0">Clients Data</p>
                    <p className="mb-0">Multi-location for chains</p>
                    
                    {/* <p className="mb-0">Marketplace (20kms Store Visibility)</p>
                    <p className="mb-0">Unlimited Bookings</p>
                    <p className="mb-0">Discount Codes</p>
                    <p className="mb-0">Trackable SMS Links</p>
                    <p className="mb-0">Trackable QR Codes</p>
                    <p className="mb-0">Point-Of-Sale (POS)</p>
                    <p className="mb-0">Inventory</p>
                    <p className="mb-0">Technical Support​- Telephone/Chat</p> */}

                    <a href="#create-free-account">Get Started</a>
                </div>
            </div>
        </div>

    </div>
}

export default Subscription