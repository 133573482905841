import React from "react";

function ProductCategories(props) {

  return (
    <div className="category-container d-grid justify-content-between">
      <button onClick={() => { props.setProductCategoryName("Recommended"); props.setIsShowBrowseSection(false) }} className={
        props.productCategoryName == "Recommended" ? "active bg-white" : "bg-white"
      }>
        <img src="/assets/common/recommended.jpg" />
        <p className="mb-0">Recommended</p>

      </button>
      {
        props.productsCategories.map((item) => {
          return <button className={
            props.productCategoryName == `${item.name}` ? "active bg-white" : "bg-white"
          } key={item._id} onClick={() => { props.setProductCategoryName(item.name); props.setIsShowBrowseSection(false) }}>
            <img src={item.image ? `${process.env.REACT_APP_IMAGE_URL}${item.image}` : "/assets/common/serviceproduct.png"} />
            <p className="mb-0">{item.name}</p></button>
        })
      }
    </div >
  );
}

export default ProductCategories;
