import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useUpdateData from "../../../customhooks/updatedata";
import Loader from "../../../components/loader";
import useIsLoader from "../../../customhooks/useisloader";

import {
  isName,
  isEmail,
  isMobile,
  isAlternateMobile,
  isDate,
} from "../../../functions";
import moment from "moment";
import { authFormHeaders } from "../../../constants";
import useGetData from "../../../customhooks/getdata";

function Profile(props) {
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    mobile: "",
    dob: "",
    alternateNumber: "",
    image: "",
    url: "",
  });

  const navigate = useNavigate();

  const [isLoader, setIsLoader] = useIsLoader(false);

  const [imageMessage, setImageMessage] = useState("");
  const [nameMessage, setNameMessage] = useState("");
  const [mobileMessage, setMobileMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [dobMessage, setDobMessage] = useState("");
  const [alternateNumberMessage, setAlternateNumberMessage] = useState("");

  const [updatedData, updateError, updateData] = useUpdateData();

  const formData = new FormData();

  formData.append("name", userInfo.name);
  formData.append("email", userInfo.email);
  formData.append("mobile", userInfo.mobile);
  formData.append("dob", userInfo.dob);
  formData.append("alternateNumber", userInfo.alternateNumber);
  formData.append("image", userInfo.image && userInfo.image[0]);

  const updateUserInfo = async () => {
    const res = await updateData({
      url: `${process.env.REACT_APP_API_URL}/app/user/${localStorage.getItem(
        "userId"
      )}/form`,
      //headers: authFormHeaders,
      headers: {
        "Content-type": "multipart/form-data",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    });


    return Promise.resolve(res);
  };

  useEffect(() => {
    if (props)
      setUserInfo(() => {
        return { ...userInfo, mobile: props.mobile };
      });
  }, [props]);

  const checkValidation = () => {
    let nameTobeCheck = userInfo.name.split(" ").join("-");

    let validated = true;

    if (userInfo.name == "") {
      validated = false;
      setNameMessage("Please provide name.");
    }

    if (isName(userInfo.name.split(" ").join(""))) {
      setNameMessage("");
      setUserInfo({ ...userInfo, name: nameTobeCheck.split("-").join(" ") });
    } else {
      validated = false;
      setNameMessage("Please enter valid name");
    }

    if (userInfo.email.length > 0) {
      if (isEmail(userInfo.email)) {
        setEmailMessage("");
        setUserInfo({ ...userInfo, email: userInfo.email });
      } else {
        validated = false;
        setEmailMessage("Please enter valid email");
      }
    }

    if (userInfo.mobile.length > 0) {
      if (isMobile(userInfo.mobile)) {
        setMobileMessage("");
        setUserInfo({ ...userInfo, mobile: userInfo.mobile });
      } else {
        validated = false;
        setMobileMessage("Please enter valid mobile");
      }
    }

    if (userInfo.alternateNumber.length > 0) {
      if (isAlternateMobile(userInfo.alternateNumber)) {
        setAlternateNumberMessage("");
        setUserInfo({ ...userInfo, alternateNumber: userInfo.alternateNumber });
      } else {
        validated = false;
        setAlternateNumberMessage("Please enter valid alternate mobile");
      }
    }

    if (userInfo.dob.length > 0) {
      if (moment().isAfter(moment(userInfo.dob).format("YYYY/MM/DD"))) {
        if (isDate(moment(userInfo.dob).format("YYYY/MM/DD"))) {
          setDobMessage("");
          setUserInfo({ ...userInfo, dob: userInfo.dob });
        } else {
          validated = false;
          setDobMessage("Please enter valid DOB.");
        }
      } else {
        validated = false;
        setDobMessage("Please enter valid DOB.");
      }
    }

    return validated;
  };

  const handleClick = () => {
    if (checkValidation()) {
      const p1 = updateUserInfo();

      p1.then((res) => {



        if (res) {
          if (res.status === 200) {
            localStorage.setItem("userId", props.userId);
            navigate(-1);
          }
        }
      });
    }
  };

  const handleOnChangeImage = (e) => {
    setImageMessage("");

    let valid = true;

    const files = Array.from(e.target.files);

    if (e.target.files) {
      files.map((file) => {
        const size = file.size / 1024;
        if (size > 100) {
          valid = false;
          setImageMessage("Please provide maximum 100kb size");
          setUserInfo({
            ...userInfo,
            image: "",
            url: "",
          });
        }
        if (
          !["application/jpg", "image/jpeg", "image/png"].includes(file.type)
        ) {
          valid = false;
          setImageMessage(
            "Please provide correct file type from these jpg, jpeg, png"
          );
          setUserInfo({
            ...userInfo,
            image: "",
            url: "",
          });
        }
      });
    }

    if (valid) {
      setUserInfo({
        ...userInfo,
        image: files,
        url: URL.createObjectURL(files[0]),
        updateImage: "null",
      });
    }
  };

  const handleOnChangeName = (e) => {
    let nameTobeCheck = e.target.value.split(" ").join("-");

    if (e.target.value.length == 0) {
      setNameMessage("");
      setUserInfo({ ...userInfo, name: "" });
    } else if (e.target.value.length > 0) {
      if (isName(e.target.value.split(" ").join(""))) {
        setNameMessage("");
        setUserInfo({ ...userInfo, name: nameTobeCheck.split("-").join(" ") });
      } else {
        setNameMessage("Please enter valid name");
      }
    }
  };

  const handleOnChangeMobile = (e) => {
    if (e.target.value.length == 0) {
      setMobileMessage("");
      setUserInfo({ ...userInfo, mobile: "" });
    } else if (e.target.value.length > 0) {
      if (isMobile(e.target.value)) {
        setMobileMessage("");
        setUserInfo({ ...userInfo, mobile: e.target.value });
      } else {
        setMobileMessage("Please enter valid mobile");
      }
    }
  };

  const handleOnChangeAlternateMobile = (e) => {
    if (e.target.value.length == 0) {
      setAlternateNumberMessage("");
      setUserInfo({ ...userInfo, alternateNumber: "" });
    } else if (e.target.value.length > 0) {
      if (isAlternateMobile(e.target.value)) {
        setAlternateNumberMessage("");
        setUserInfo({ ...userInfo, alternateNumber: e.target.value });
      } else {
        setAlternateNumberMessage("Please enter valid alternate mobile");
      }
    }
  };

  const handleOnChangeDob = (e) => {
    if (e.target.value.length == 0) {
      setDobMessage("");
      setUserInfo({ ...userInfo, dob: "" });
    } else if (e.target.value.length > 0) {
      if (moment().isAfter(moment(e.target.value).format("YYYY/MM/DD"))) {
        if (isDate(moment(e.target.value).format("YYYY/MM/DD"))) {
          setDobMessage("");
          setUserInfo({ ...userInfo, dob: e.target.value });
        } else {
          setDobMessage("Please enter valid DOB.");
        }
      } else {
        setDobMessage("Please enter valid DOB.");
      }
    }
  };

  return (
    <div className="profile-page d-flex flex-column justify-content-between">
      <div>
        <div className="header text-center d-grid justify-content-center align-items-center">
          <p className="mb-0">Set up Profile</p>
        </div>

        <div className="input-fields-container d-flex flex-column justify-content-center">
          <div className="user-image-container position-relative text-center">
            <div className="user-image">
              {userInfo.url ? (
                <img src={userInfo.url} alt="User" />
              ) : (
                <img src="/assets/common/user.svg" />
              )}
            </div>
            <div className="user-image-input-container d-flex justify-content-center align-items-center m-auto">
              <label>
                <input
                  className="border-0"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => {
                    handleOnChangeImage(e);
                  }}
                />
                <div className="camera-container d-flex justify-content-center align-items-center">
                  <img src="assets/common/camera.svg" alt="Camera" />
                </div>
              </label>
            </div>
          </div>

          <div className="mobile-container">
            {imageMessage ? <p className="message">{imageMessage}</p> : ""}

            <div className="d-flex justify-content-between">
              <p className="mb-0">Name*</p>
              {nameMessage ? <p className="message">{nameMessage}</p> : ""}
            </div>
            <input
              value={userInfo.name}
              type="text"
              onChange={(e) => {
                handleOnChangeName(e);
              }}
              className="border-0"
            />
          </div>
          <div className="mobile-container">
            <div className="d-flex justify-content-between">
              <p className="mb-0">Mobile No.</p>
              {mobileMessage ? <p className="message">{mobileMessage}</p> : ""}
            </div>
            <input
              disabled
              type="text"
              onChange={(e) => {
                handleOnChangeMobile(e);
              }}
              maxLength={15}
              value={userInfo.mobile}
              className="border-0 bg-white"
            />
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">Email id</p>
              {emailMessage ? <p className="message">{emailMessage}</p> : ""}
            </div>
            <input
              type="text"
              value={userInfo.email}
              onChange={(e) => {
                setUserInfo({ ...userInfo, email: e.target.value });
              }}
              className="border-0"
            />
          </div>
          <div className="birthday-input-container">
            <div className="d-flex justify-content-between">
              <p className="mb-0">Your Birthday</p>
              {dobMessage ? <p className="message">{dobMessage}</p> : ""}
            </div>
            <input
              type="date"
              onChange={(e) => {
                handleOnChangeDob(e);
              }}
              value={moment(userInfo.dob).format("YYYY-MM-DD")}
              className="border-0"
            />
            <p className="mb-0 birthday-text">
              (Please provide your correct birthday and year. We have free
              services and offers on Birthdays)
            </p>
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <p className="mb-0">Alternate No.</p>
              {alternateNumberMessage ? (
                <p className="message">{alternateNumberMessage}</p>
              ) : (
                ""
              )}
            </div>
            <input
              type="text"
              maxLength={15}
              onChange={(e) => {
                handleOnChangeAlternateMobile(e);
              }}
              value={userInfo.alternateNumber}
              className="border-0"
            />
          </div>
          <div className="proceed-button-container">
            <button
              onClick={() => {
                handleClick();
              }}
              className="border-0"
            >
              {isLoader ? (
                <Loader color={"#FFFFFF"} height={16} width={16} />
              ) : (
                "Proceed"
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="building-image-container">
        <img src="/assets/common/building.png" alt="Building" />
      </div>
    </div>
  );
}

export default Profile;
