import React, { useState } from "react";

import { CgClose } from "react-icons/cg";

function Categories(props) {

  const [active, setActive] = useState([])

  const handleClickOnFilter = (item) => {
    props.setFilterValue([...props.filterValue, item.name])
    setActive([...active, item.name])
  }

  const handleClickOnRemove = (item) => {
    props.setFilterValue(active.filter((filterValue) => {
      if (filterValue != item.name) {
        return filterValue
      }
    }))

    setActive(active.filter((activeValue) => {
      if (activeValue != item.name) {
        return activeValue
      }
    }))
  }

  return (
    <div className="categories d-flex">
      {props.offerDealsCategory.map((item) => {
        return <div key={item._id}>
          {active.includes(item.name) ? <div className="cancel-categories d-flex justify-content-between align-items-center">
            <p className="mb-0">{item.name}</p>
            <button
              onClick={() => {
                handleClickOnRemove(item)
              }}
              className="border-0 d-grid"
            >
              <CgClose />
            </button>
          </div> :
            <div>
              <button onClick={() => { handleClickOnFilter(item) }}>{item.name}</button>
            </div>}
        </div>

      })}
    </div>
  );
}

export default Categories;
