import validator from "validator";
import getRequest from "./apicall/getrequest";
import putRequest from "./apicall/putrequest";

export const isEmail = (email) => {
  return validator.isEmail(email);
};

export const isMobile = (mobile) => {
  return validator.isNumeric(mobile);
};

export const isAlternateMobile = (mobile) => {
  return validator.isNumeric(mobile);
};

export const isName = (name) => {
  return validator.isAlpha(name);
};

export const isCity = (city) => {
  return validator.isAlpha(city);
};

export const isBusinessName = (businessName) => {
  return validator.isAlpha(businessName);
};

export const isDate = (date) => {
  return validator.isDate(date);
};

// cart

export const getCartList = () => {
  const res = getRequest(
    `${process.env.REACT_APP_API_URL}/app/cart?user=${localStorage.getItem(
      "userId"
    )}`
  );

  return res;
};

export const increaseItem = (params) => {
  const res = putRequest(params);

  return res;
};

export const decreaseItem = (params) => {
  const res = putRequest(params);

  return res;
};

// address

export const getAddress = () => {
  const res = getRequest(
    `${
      process.env.REACT_APP_API_URL
    }/app/userAddress/search?user=${localStorage.getItem("userId")}`
  );

  return res;
};
