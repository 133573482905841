import React, { useEffect, useState } from "react";
import PopUp from "../../components/popup";
import useIsPopUp from "../../customhooks/ispopup";
import Banner from "./banner";
import Customer from "./customer";
import Faqs from "./faqs";
import Footer from "./footer";

import Header from "./header";
import Products from "./products";
import Subscription from "./subscription";
import { gtm } from "../gtm";

function BusinessManager() {
  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [popUpMessage, setPopUpMessage] = useState("");

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <div className="business-page-container">
      <div className="business-page w-100">
        <Header />
        <Banner />
        {/* <Customer /> */}
        <Products />
        <Subscription />
        <Faqs />
        <Footer setIsPopUp={setIsPopUp} setPopUpMessage={setPopUpMessage} />
      </div>

      {isPopUp ? (
        <PopUp
          // isReloadpage={true}
          setPopUpMessage={setPopUpMessage}
          setIsPopUp={setIsPopUp}
          message={popUpMessage}
        />
      ) : (
        ""
      )}

      {window.location.pathname == "/business/form-submitted" ? (
        <PopUp
          // isGoToBusineesPage={true}
          isReloadpage={false}
          setPopUpMessage={setPopUpMessage}
          setIsPopUp={setIsPopUp}
          message="Your request has been submitted. We will contact you as soon as possible."
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default BusinessManager;
