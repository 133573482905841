import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

function Oops() {

  return (
    <div className="oops-section">
      <div className="payment-success-image-container">
        <img src="/assets/paymentfailed/paymentfailed.svg" />
      </div>
      <div className="oops-failed-id-container">
        <p className="mb-0 oops">Oops! Something went</p>
        <p className="mb-0 payment-failed">Order Failed !"</p>
      </div>
    </div>
  );
}

export default Oops;
