import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import VerfiyOtp from "./verifyotp";
import Loader from "../../components/loader";
import useIsLoader from "../../customhooks/useisloader";

import usePostData from "../../customhooks/postdata";

import { isMobile } from "../../functions";

import { isName } from "../../functions";

import { authJsonHeaders } from "../../constants";
import useGetData from "../../customhooks/getdata";
import Profile from "./profile";
import { gtm } from "../gtm";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io"
import SelectCountry from "../../components/selectcountry";
import getRequest from "../../functions/apicall/getrequest";
import { toast } from "react-hot-toast";
import axios from "axios";

function Mobile() {
  const [isOtp, setIsOtp] = useState(false);
  const [dataFromMobile, setDataFromMobile] = useState("");

  const [isOpenCountriesDropDown, setIsOpenCountriesDropDown] = useState(false)

  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");

  const [userId, setUserId] = useState(null);

  const [countries, setCountries] = useState([])

  const [countriesRes, setCountriesRes] = useState([])

  const [countryId, setCountryId] = useState(null)

  const [countryCode, setCountryCode] = useState(null)

  const [phoneCode, setPhoneCode] = useState(null)

  const [isLoader, setIsLoader] = useIsLoader(false);

  const [postedData, postError, postData] = usePostData();

  const [fetchedData, fetchDataError, fetchData] = useGetData();

  const [isUserProfile, setIsUserProfile] = useState(false);

  const navigate = useNavigate();

  const handleOnChangeMobile = (e) => {
    if (e.target.value.length == 0) {
      setMobile("");
    }

    if (isMobile(e.target.value)) {
      setMobile(e.target.value);
    } else {
      toast.error("Please enter valid mobile number.");
    }
  };

  const handleOnChangeName = (e) => {
    let nameTobeCheck = e.target.value.split(" ").join("-");

    if (e.target.value.length == 0) {
      setName("");
    } else if (e.target.value.length > 0) {
      if (isName(e.target.value.split(" ").join(""))) {
        setName(nameTobeCheck.split("-").join(" "));
      } else {
        toast.error("Please enter valid name");
      }
    }
  };

  const getUserId = async (token) => {
    const res = fetchData({
      url: `${process.env.REACT_APP_API_URL}/user/loggedInUser/getId`,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    });

    return Promise.resolve(res);
  };

  const getUserProfile = async (userId) => {
    const res = await fetchData({
      url: `${process.env.REACT_APP_API_URL}/app/user/${userId}`,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return Promise.resolve(res);
  };

  const handlegetOtp = async () => {
    let body = {};

    let url = "";

    let headers = {};

    if (sessionStorage.getItem("origin")) {
      body = {
        name: name,
        mobile: mobile,
        country: countryId,
        salon : sessionStorage.getItem("salonId")
      };

      url = `${process.env.REACT_APP_API_URL}/auth/portal/login/end-user`;

      headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${sessionStorage.getItem("portalToken")}`,
      };
    } else {
      body = {
        mobile: mobile,
        country: countryId
      };

      url = `${process.env.REACT_APP_API_URL}/auth/send-otp`;

      headers = {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      };
    }

    const res = await postData({
      url: url,
      body: body,
      headers: headers,
    });

    return Promise.resolve(res);
  };

  const handleClick = () => {
    if (sessionStorage.getItem("origin")) {
      if (!isMobile(mobile)) {
        toast.error("Please enter valid mobile number.");
      }
      else if (!countryId) {
        toast.error("Please select country code.");
      }
      else if (!isName(name.split(" ").join(""))) {
        toast.error("Please enter valid name.");
      } else if (isName(name.split(" ").join("")) && isMobile(mobile)) {

        setIsLoader(true);

        const p1 = handlegetOtp();

        p1.then((res) => {
          if (res) {
            setIsLoader(false);

            if (res.data.role == "end-user") {
              localStorage.setItem("token", res.data.token);

              const p1 = getUserId(res.data.token);

              p1.then((res) => {
                if (res) {
                  localStorage.setItem("userId", res.data.id);

                  const p2 = getUserProfile(res.data.id);

                  p2.then((res) => {
                    if (res) {
                      if (res.data.user.name) {
                        navigate(-1);
                      } else {
                        setIsUserProfile(true);
                      }
                    }
                  });
                }
              });
            } else {
              toast.error("Please use customer mobile number.");
            }
          } else {
            setIsLoader(false);
            toast.error("Something went wrong.");
            setIsOtp(false);
          }
        });
      }
    } else {
      if (!isMobile(mobile)) {
        toast.error("Please enter valid mobile number.");
      }
      else if (!countryId) {
        toast.error("Please select country code.");
      }
      else {
        setIsLoader(true);
        const p1 = handlegetOtp();

        p1.then((res) => {

          if (res) {
            setDataFromMobile({
              otp: res.data.otp,
              hash: res.data.hash,
              mobile: mobile,
              country: countryId,
              phoneCode: phoneCode
            });
            setIsOtp(true);
            setIsLoader(false);
          } else {
            setIsLoader(false);
            toast.error("Something went wrong.");
            setIsOtp(false);
          }
        });
      }
    }
  };

  const getCountries = async () => {

    const countryProRes = await getRequest(`${process.env.REACT_APP_API_URL}/country/search?sort_order=asc`)

    return Promise.resolve(countryProRes)

  };

  const createFilterArray = (value) => {
    if (value === "") {
      setCountries(countriesRes);
    } else {

      setCountries(countriesRes.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      }));
    }
  };

  const handleToggleOpenCountryDropDown = () => {
    if (isOpenCountriesDropDown) {
      setIsOpenCountriesDropDown(false)
    }
    else {
      setCountries(countriesRes)
      setIsOpenCountriesDropDown(true)
    }
  }

  const getFlagEmoji = (countryCode) => {
    const codePoints = countryCode
      .toUpperCase()
      .split('')
      .map(char => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  }

  useEffect(() => {
    getCountries().then((res) => {
      if (res) {

        let indiaInfo = null
        let isIndia = false

        res.data.countries.forEach((countriesItem) => {
          if (countriesItem.acronym == "IN") {
            isIndia = true
            indiaInfo = countriesItem
          }
        })

        if (isIndia) {
          setCountryId(indiaInfo._id);
          setPhoneCode(indiaInfo.phoneCode)
          setCountryCode(indiaInfo.acronym)
        }

        else {
          setCountryId(res.data.countries[0]._id);
          setPhoneCode(res.data.countries[0].phoneCode)
          setCountryCode(res.data.countries[0].acronym)
        }

        setCountriesRes(res.data.countries)
        setCountries(res.data.countries)

        // setIsOpenCountriesDropDown(true)

      }
    })
  }, [])

  useEffect(() => {
    gtm.page();
  }, [])

  return (
    <>

      <div className="login h-100">
        {isOtp ? (
          <VerfiyOtp
            mobile={mobile}
            setMobile={setMobile}
            setIsOtp={setIsOtp}
            getOTP={handlegetOtp}
            dataFromMobile={dataFromMobile}
          />
        ) : (
          ""
        )}

        {isOtp || isUserProfile ? (
          ""
        ) : (
          <div className="mobile d-flex flex-column justify-content-between vh-100">
            <div className="back-container">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="border-0 bg-white"
              >
                <img src="/assets/common/back.svg" alt="Back" />
              </button>
            </div>
            <div className="logo-input-container d-flex flex-column justify-content-center h-100">
              <div className="logo text-center">
                <img src="assets/common/logo.svg" alt="logo" />
              </div>
              <div className="input-button-conatiner">
                {sessionStorage.getItem("origin") ? (
                  <div className="text-center name-input-container">
                    <input
                      type="text"
                      className="w-100"
                      value={name}
                      placeholder="Enter name"
                      onChange={(e) => {
                        handleOnChangeName(e);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="text-center position-relative">
                  <div className="country-flag-code-mobile-container d-grid">
                    <div
                      className="open-countries d-grid gap-1 align-items-center"
                    >
                      <button className="border-0 bg-white d-flex align-items-center justify-content-between" onClick={() => handleToggleOpenCountryDropDown()}>
                        <p className="mb-0">{countryCode ? getFlagEmoji(countryCode) : ""}</p>
                        {isOpenCountriesDropDown ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                      </button>
                    </div>

                    <div className="phone-code-mobile-number-input-container d-flex align-items-center">
                      {
                        phoneCode ? <div className="phone-code border-0">
                          +{phoneCode}
                        </div> : ""
                      }

                      <input
                        type="tel"
                        className="w-100 ps-2"
                        value={mobile}
                        placeholder="Enter mobile number"
                        maxLength={15}
                        onChange={(e) => {
                          handleOnChangeMobile(e);
                        }}
                      />
                    </div>
                  </div>

                  {isOpenCountriesDropDown ? (
                    <SelectCountry
                      setIsOpenCountriesDropDown={setIsOpenCountriesDropDown}
                      countries={countries}
                      setCountryId={setCountryId}
                      setPhoneCode={setPhoneCode}
                      createFilterArray={createFilterArray}
                      getFlagEmoji={getFlagEmoji}
                      setCountryCode={setCountryCode}
                    />
                  ) : ""}
                </div>


                <div className="text-center">
                  <button
                    className="w-100 continue-button"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    {isLoader ? (
                      <Loader color={"#FFFFFF"} height={16} width={16} />
                    ) : (
                      "Continue"
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="building-image-conatiner">
              <p className="terms-condition text-center mb-0">
                By signing in I agree to{" "}
                <Link to="/term-and-condition">Term & Conditions</Link>
              </p>
              <img src="assets/common/building.png" alt="building" />
            </div>
          </div>
        )}

        {isUserProfile ? <Profile mobile={mobile} userId={userId} /> : ""}
      </div >
    </>
  );
}

export default Mobile;
