import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import useGetData from "../../../customhooks/getdata";
import usePostData from "../../../customhooks/postdata";
import useIsLoader from "../../../customhooks/useisloader";
import Loader from "../../../components/loader";
import Profile from "../profile";
import { toast } from "react-hot-toast";

function VerfiyOtp(props) {
  const [message, setMessage] = useState("");
  const [isUserProfile, setIsUserProfile] = useState(true);

  const [hash, setHash] = useState("");

  const navigate = useNavigate();

  const [isLoader, setIsLoader] = useIsLoader(false);

  const [enteredOtp, setEnteredOtp] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });

  const [otpTime, setOtpTime] = useState({
    minute: 0,
    second: 0,
  });

  const otpForVerify =
    enteredOtp.first + enteredOtp.second + enteredOtp.third + enteredOtp.fourth;

  // const [verifiedOtp, verifyOtpError, verifyOtp] = usePostData();

  // const [gotUserId, userIdError, getUserId] = useGetData();

  // const [gotUserProfile, userProfileError, getUserProfile] = useGetData();

  const [fetchedData, fetchDataError, fetchData] = useGetData();

  const [postedData, postError, postData] = usePostData();

  const verifyOtp = async () => {
    const res = await postData({
      url: `${process.env.REACT_APP_API_URL}/auth/verify-otp`,
      body: {
        mobile: props.dataFromMobile.mobile,
        country : props.dataFromMobile.country,
        hash: hash,
        role: "end-user",
        otp: otpForVerify,
      },
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        // Authorization: `Bearer ${props.dataFromMobile.token}`,
      },
    });

    return Promise.resolve(res);
  };

  const getUserId = async (token) => {
    const res = fetchData({
      url: `${process.env.REACT_APP_API_URL}/user/loggedInUser/getId`,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    });

    return Promise.resolve(res);
  };

  const getUserProfile = async (userId) => {
    const res = fetchData({
      url: `${process.env.REACT_APP_API_URL}/app/user/${userId}`,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return Promise.resolve(res);
  };

  const handleKeydown = (e) => {
    //deleting input
    if (e.which === 8 || e.which === 46) {
      if (e.target.value) {
        e.target.value = "";
      } else if (e.target.value === "" && e.target.parentNode.previousSibling) {
        e.target.parentNode.previousSibling.firstChild.focus();
      }
    }
  };

  const handleChange = (e) => {
    setMessage("");

    //going to next input
    if (e.target.value.length === 1 && e.target.parentNode.nextSibling) {
      e.target.parentNode.nextSibling.firstChild.focus();
    }
  };

  const handleClickVerifyOtp = () => {
    setIsLoader(true);
    const p1 = verifyOtp();

    p1.then((res) => {
      if (res) {
        if (res.data.role == "end-user") {
          localStorage.setItem("token", res.data.token);

          const p2 = getUserId(res.data.token);

          p2.then((idRes) => {
            if (idRes) {
              const p3 = getUserProfile(idRes.data.id);

              p3.then((res) => {
                if (res) {
                  localStorage.setItem("userId", idRes.data.id);

                  if (
                    res.data.user.name === null
                    // gotUserProfiresle.data.user.email === null ||
                    // gotUserProfile.data.user.dob === null
                  ) {
                    setIsUserProfile(false);
                  } else {
                    navigate(-1);
                  }
                }
              });
            }
          });
        } else {
          props.setIsOtp(false);
          toast.error("Please use customer mobile number.");
        }
      } else {
        setIsLoader(false);
        setMessage("Something went wrong.");
      }
    });
  };

  const handleClickResendOtp = () => {
    const otpPromise = props.getOTP();

    otpPromise.then((res) => {
      if (res) {
        setHash(res.data.hash);
      }
    });

    generateOtpTime(5);
  };

  const back = () => {
    props.setMobile("");
    props.setIsOtp(false);
  };

  const generateOtpTime = (otpTime) => {
    let minute = otpTime == 1 ? 0 : otpTime - 1;
    let second = 59;

    setOtpTime(() => {
      return {
        minute: minute,
        second: second,
      };
    });

    const closeInterval = setInterval(() => {
      if (second != 0) {
        second -= 1;
      }

      if (second == 0 && minute != 0) {
        minute = minute - 1;
        second = 60;
      }

      setOtpTime(() => {
        return {
          minute: minute,
          second: second,
        };
      });

      if (minute == 0 && second == 0) {
        clearInterval(closeInterval);
      }
    }, 1000);
  };

  useEffect(() => {
    setHash(props.dataFromMobile.hash);

    generateOtpTime(5);
  }, []);

  console.log("OTP is :", props.dataFromMobile.otp);

  return (
    <>
      {isUserProfile ? (
        <div className="verify-otp d-flex flex-column justify-content-between h-100">
          <div className="back-container">
            <button
              onClick={() => {
                back();
              }}
              className="border-0 bg-white"
            >
              <img src="/assets/common/back.svg" alt="Back" />
            </button>
          </div>
          <div className="mobile-number-inputs-container d-flex flex-column justify-content-center h-100">
            <div>
              <p className="otp-sent-message text-center mb-0">
                OTP has been sent on your mobile numer
              </p>
              <div className="mobile-number-container d-flex justify-content-center">
                <p className="mobile-number mb-0">
                  +{props.dataFromMobile.phoneCode}{" "}{props.dataFromMobile.mobile}{" "}
                </p>
                <button
                  className="edit-mobile-number border-0"
                  onClick={() => {
                    props.setIsOtp(false);
                  }}
                >
                  <img src="assets/common/edit.svg" alt="Edit" />
                </button>
              </div>
            </div>
            <div className="inputs-button-container">
              <div className="enter-otp">
                <p className="text-center mb-0">Enter OTP</p>
              </div>
              {message ? (
                <div>
                  <p className="message text-center text-danger">{message}</p>
                </div>
              ) : (
                ""
              )}
              <div className="inputs-container d-flex justify-content-center align-item-center">
                <div>
                  <input
                    onChange={(e) => {
                      setEnteredOtp({ ...enteredOtp, first: e.target.value });
                      handleChange(e);
                    }}
                    onKeyDown={(e) => {
                      handleKeydown(e);
                    }}
                    // value={enteredOtp.first}
                    type="tel"
                    maxLength={1}
                    className="text-center"
                  />
                </div>
                <div>
                  <input
                    onChange={(e) => {
                      setEnteredOtp({ ...enteredOtp, second: e.target.value });
                      handleChange(e);
                    }}
                    onKeyDown={(e) => {
                      handleKeydown(e);
                    }}
                    type="tel"
                    maxLength={1}
                    // value={enteredOtp.second}
                    className="text-center"
                  />
                </div>
                <div>
                  <input
                    onChange={(e) => {
                      setEnteredOtp({ ...enteredOtp, third: e.target.value });
                      handleChange(e);
                    }}
                    onKeyDown={(e) => {
                      handleKeydown(e);
                    }}
                    type="tel"
                    maxLength={1}
                    // value={enteredOtp.third}
                    className="text-center"
                  />
                </div>
                <div>
                  <input
                    onChange={(e) => {
                      setEnteredOtp({ ...enteredOtp, fourth: e.target.value });
                      handleChange(e);
                    }}
                    onKeyDown={(e) => {
                      handleKeydown(e);
                    }}
                    type="tel"
                    maxLength={1}
                    className="text-center"
                  />
                </div>
              </div>
              <div className="verify-container text-center">
                <button
                  onClick={() => {
                    handleClickVerifyOtp();
                  }}
                  className="border-0 w-100"
                >
                  {isLoader ? (
                    <Loader color={"#FFFFFF"} height={16} width={16} />
                  ) : (
                    "Verify"
                  )}
                </button>
              </div>
              <div className="resend-container d-flex justify-content-center align-items-center">
                {otpTime.minute == 0 && otpTime.second == 0 ? (
                  <button
                    onClick={() => {
                      handleClickResendOtp();
                    }}
                    className="text-container border-0"
                  >
                    Resend OTP
                  </button>
                ) : (
                  <p className="message text-center mb-0">
                    Resend OTP in {otpTime.minute + " : " + otpTime.second}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="building-image-conatiner">
            <img src="assets/common/building.png" alt="building" />
          </div>
        </div>
      ) : (
        <Profile
          mobile={props.dataFromMobile.mobile}
          userId={localStorage.getItem("userId")}
        />
      )}
    </>
  );
}

export default VerfiyOtp;
