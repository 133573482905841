import React from "react";

import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import Salon from "../../components/salon";
import useIsLoader from "../../customhooks/useisloader";
import useGetData from "../../customhooks/getdata";
import FilterSalons from "../../components/filtersalons";

import { useParams } from "react-router-dom";
import Loader from "../../components/loader";

import SearchSalons from "../../components/searchsalons";

import Search from "./search";
import moment from "moment";

import { authJsonHeaders } from "../../constants";
import { gtm } from "../gtm";

function SalonsByCategory() {
  const categoryId = useParams();

  const [isLoader, setIsLoader] = useIsLoader(true);

  const [salonsAround, setSalonsAround] = useState([]);

  let [perpage, setPerpage] = useState(10);

  let [page, setPage] = useState(1);

  const [salonsByCategory, setSalonsByCategory] = useState(null);

  // const [gotSalonsByCategory, categoryError, getSalonsByCategory] =
  //   useGetData();

  const [fetchedData, fetchError, fetchData] = useGetData();

  const [earliest, setEarliest] = useState("");
  // const [men, setMen] = useState("");
  // const [women, setWomen] = useState("");
  const [gender, setGender] = useState(null);
  const [rating, setRating] = useState("");

  const [isSearchSalons, setIsSearchSalons] = useState(false);

  const [isScrollLoader, setIsScrollLoader] = useIsLoader();

  const getSalonsByCategory = async () => {
    const res = await fetchData({
      url:
        `${
          process.env.REACT_APP_API_URL
        }/app/salon/search?nearBy=${sessionStorage.getItem(
          "city"
        )},${sessionStorage.getItem("country")}&lat=${sessionStorage.getItem(
          "lat"
        )}&lng=${sessionStorage.getItem(
          "lng"
        )}&status=1&salonStatus=verified&isActive=true&earliest=${
          moment()
            .format("YYYY-MM-DDTHH:mm")
            .split("T")[1]
        }&sort_by=${
          earliest ? "appointmentIn" : "distanceInKm"
        }&per_page=${perpage}&page=${page}&category=${categoryId.params}` +
        `${
          gender ? `&type=${gender}` : ""
          // men && women
          //   ? `&type=unisex`
          //   : men
          //   ? `&type=male`
          //   : women
          //   ? `&type=female`
          //   : ``
        }` +
        `${rating ? `&ratingGt=4` : ``}`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return Promise.resolve(res);
  };

  useEffect(() => {
    setIsLoader(true);

    const p1 = getSalonsByCategory();

    setTimeout(() => {
      p1.then((res) => {
        if (res) {
          setSalonsAround(res.data.salons);

          setSalonsByCategory(res);
        } else {
          setSalonsAround([]);

          setSalonsByCategory(null);
        }

        setIsLoader(false);
      });
    }, 1000);
  }, [gender, rating, earliest, categoryId]);

  const increasePage = () => {
    if (
      salonsByCategory &&
      salonsByCategory.data.totalResults > perpage * page
    ) {
      setPage((page += 1));

      setIsScrollLoader(true);

      const p1 = getSalonsByCategory();

      p1.then((res) => {
        if (res) {
          if (res.data.totalResults > 0) {
            setSalonsAround((prev) => [...prev, ...res.data.salons]);
          } else {
            setSalonsAround([]);
            setSalonsByCategory(null);
          }

          setIsScrollLoader(false);

          setIsLoader(false);
        }
      });
    }
  };

  useEffect(()=>{
    gtm.page();
  }, [])


  return (
    <>
      <div className="salons-by-category-page d-grid">
        <div className="search-filter-container">
          <Search setIsSearchSalons={setIsSearchSalons} />
          <FilterSalons
            setPage={setPage}
            setEarliest={setEarliest}
            // setWomen={setWomen}
            // setMen={setMen}
            setGender={setGender}
            gender={gender}
            setRating={setRating}
          />
        </div>

        {isLoader ? (
          <Loader height={60} width={60} color={"#772286"} />
        ) : (
          <>
            {salonsByCategory && salonsAround.length > 0 ? (
              <div className="salons-around-container">
                <div className="title">
                  <p className="mb-0">
                    {salonsByCategory.data.totalResults} Salons Around You
                  </p>
                </div>
                <div className="salons">
                  <InfiniteScroll
                    dataLength={salonsAround.length}
                    next={increasePage}
                    hasMore={true}
                    loader={
                      isScrollLoader ? (
                        <Loader width={60} height={60} color={"#772286"} />
                      ) : (
                        ""
                      )
                    }
                    scrollThreshold={0.5}
                  >
                    <Salon salons={salonsAround} />
                  </InfiniteScroll>
                </div>
              </div>
            ) : (
              <div className="not-found text-center d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/assets/salonsbysearch/not_found.png"
                  alt="Not found"
                />
                <p className="mb-0">Sorry! No results found</p>
              </div>
            )}
          </>
        )}
      </div>

      {isSearchSalons ? (
        <SearchSalons setIsSearchSalons={setIsSearchSalons} />
      ) : (
        ""
      )}
    </>
  );
}

export default SalonsByCategory;
