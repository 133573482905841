import React from "react";
import { useState, useEffect } from "react";

import Header from "./header";

import Location from "../../components/location";

import useGetData from "../../customhooks/getdata";

import useIsLoader from "../../customhooks/useisloader";

import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import Salon from "../../components/salon";
import SalonInfo from "./saloninfo";
import ShareLikeSalon from "./sharelike";
import Amenitiesinfo from "./amenitiesinfo";
import Salons from "./salons";
import useIsPopUp from "../../customhooks/ispopup";
import PopUp from "../../components/popup";

import { authJsonHeaders, headers } from "../../constants";

function SalonList() {

  const navigate = useNavigate()

  const appOrQr = window.location.pathname.split("/")[1]

  const salonNameId = useParams()

  const [isSkeleton, setIsSkeleton] = useIsLoader(true);

  const [salonsAround, setSalonsAround] = useState([]);

  let [perpage, setPerpage] = useState(10);

  const [salons, salonsError, getSalons] = useGetData();

  const [latlng, setLatlng] = useState({
    lat: "",
    lat: "",
  });

  const [city, setCity] = useState("");

  const [country, setCountry] = useState("");

  const [men, setMen] = useState("");
  const [women, setWomen] = useState("");
  const [rating, setRating] = useState("");

  const [salon, setSalon] = useState("")

  const [isOpenSelectLocation, setIsOpenSelectLocation] = useState(false);

  const [gotAddress, AddressError, getAddress] = useGetData();

  const [isShowAmenitiesInfo, setIsShowAmenitiesInfo] = useState(false);

  const [amenitiesInfo, setAmenitiesInfo] = useState({
    img: "",
    amenityName: "",
    amenityDescription: ""
  })

  const [isShowShareLikeSalon, setIsShowShareLikeSalon] = useState(false);

  const [salonId, setSalonId] = useState("")

  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [popUpMessage, setPopUpMessage] = useState("");

  const [gotUserProfile, userProfileError, getUserProfile] = useGetData();

  const [gotUserId, userIdError, getUserId] = useGetData();

  useEffect(() => {
    if (salonNameId) {
      const array = salonNameId.salonNameId.split("-")
      setSalonId(array[array.length - 1])
    }
  }, [salonNameId])

  useEffect(() => {
    if (appOrQr) {
      if (appOrQr == "qr") {
        sessionStorage.setItem("isQrCode", true)
      }
      else {
        if (appOrQr == "app") {
          sessionStorage.setItem("isQrCode", false)
        }
      }
    }
  }, [appOrQr])

  useEffect(() => {
    getUserId({
      url: `${process.env.REACT_APP_API_URL}/user/loggedInUser/getId`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }, []);

  useEffect(() => {
    if (userIdError) {
      localStorage.setItem("userId", "")
    }
  }, [userIdError]);

  useEffect(() => {
    if (gotUserId) {
      localStorage.setItem("userId", gotUserId.data.id)
    }
  }, [gotUserId]);

  useEffect(() => {
    if (latlng.lng && latlng.lng) {
      getAddress({
        url: `https://maps.googleapis.com/maps/api/geocode/json?address=${latlng.lat},${latlng.lng}&key=${process.env.REACT_APP_MAP_APIKEY}`,
        headers: {},
      });
    }
  }, [latlng.lat && latlng.lng]);

  useEffect(() => {
    if (gotUserId) {
      getUserProfile({
        url: `${process.env.REACT_APP_API_URL}/app/user/${gotUserId.data.id
          }?status=1`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [gotUserId])

  useEffect(() => {
    if (salonId) {
      if (appOrQr == "qr") {
        sessionStorage.setItem("salonId", salonId)
        sessionStorage.setItem("salonNameId", salonNameId.salonNameId)
      }
    }

  }, [salonId, appOrQr])

  useEffect(() => {

    setSalonsAround([])

    if (latlng.lat && latlng.lng && city && country && salonId) {
      getSalons({
        url: `${process.env.REACT_APP_API_URL}/app/salon/master-and-child/list/${salonId}?nearBy=${city},${country}&lat=${latlng.lat}&lng=${latlng.lng}&status=1&salonStatus=verified&isActive=true}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [latlng.lat && latlng.lng, city, country, men, women, rating, salonId]);

  useEffect(() => {

    if (salons != null && salons != "" && salons != undefined) {

      salons.data.salons.forEach((item) => {
        if (item.masterSalon == null) {
          setSalon(item)
        }
      })

      setSalonsAround(salons.data.salons);
    }

  }, [salons]);

  const getcurrentlocation = () => {
    window.navigator.geolocation.getCurrentPosition(
      (coords) => {
        setLatlng({
          lat: coords.coords.latitude,
          lng: coords.coords.longitude,
        });

      },
      (err) => {
        console.log(err);

        setPopUpMessage("Please allow location from setting")
        setIsPopUp(true)

        setLatlng({
          lat: 12.9715987,
          lng: 77.5945627,
        });
      }
    );
  };

  useEffect(() => {

    if (!sessionStorage.getItem("isConfirm")) {
      getcurrentlocation();
    }

    else {
      setLatlng({
        lat: sessionStorage.getItem("lat"),
        lng: sessionStorage.getItem("lng"),
      });
    }

  }, []);


  const getLatlng = (latlng) => {
    setLatlng({
      lat: latlng.latlng.lat,
      lng: latlng.latlng.lng,
    });
  };

  const openSelectLocation = () => {
    document.body.style.overflow = "hidden";

    setIsOpenSelectLocation(true);
  };

  const closeSelectLocation = () => {
    document.body.style.overflow = "unset";

    setIsOpenSelectLocation(false);
  };

  useEffect(() => {
    if (gotAddress != undefined && gotAddress != null && gotAddress != "") {

      gotAddress.data.results &&
        gotAddress.data.results.forEach((addresses) => {
          addresses.address_components.forEach((address) => {
            address.types.forEach((item) => {
              if (item === "locality") {
                setCity(address.long_name);
                sessionStorage.setItem("city", address.long_name);
              }

              if (item === "country") {
                sessionStorage.setItem("country", address.long_name);
                sessionStorage.setItem("lat", latlng.lat);
                sessionStorage.setItem("lng", latlng.lng);
                setCountry(address.long_name)
              }
            });
          });
        });
    }
  }, [gotAddress]);

  useEffect(() => {
    if ((gotUserProfile) || (gotAddress && salons)) {
      setIsSkeleton(false)
    }
  }, [gotUserProfile, gotAddress, salons])

  return (
    <>
      <div className="salons-list-page">
        {isSkeleton ?
          <div className="d-flex justify-content-between align-items-center p-3">
            <Skeleton width={100} />
            <Skeleton height={36} width={36} circle={true} />
          </div>
          :
          <Header gotUserId={gotUserId} userIdError={userIdError} gotUserProfile={gotUserProfile} city={city} gotAddress={gotAddress} openSelectLocation={openSelectLocation} />

        }

        {isSkeleton ?
          <div>
            <Skeleton className={"salon-image-skelton"} height={200} />
            <div className="d-flex justify-content-between align-items-center pt-3 px-3">
              <Skeleton height={23} width={50} />
              <Skeleton height={23} width={23} />
            </div>
          </div> :

          <SalonInfo setIsShowShareLikeSalon={setIsShowShareLikeSalon} setAmenitiesInfo={setAmenitiesInfo} setIsShowAmenitiesInfo={setIsShowAmenitiesInfo} salon={salon} />
        }

        {/* <div className="salons-container">
          <div className="title">
            <p class="mb-0">{salons && salons.data.totalResults} Child salons around you</p>
          </div>
          <Salon
            salons={salonsAround}
          />
        </div> */}

        <Salons salons={salonsAround} />

      </div>

      {isOpenSelectLocation ? (
        <Location
          setPopUpMessage={setPopUpMessage}
          setIsPopUp={setIsPopUp}
          getLatlng={getLatlng}
          closeSelectLocation={closeSelectLocation}
        />
      ) : (
        ""
      )}

      {isShowAmenitiesInfo ?
        <Amenitiesinfo amenitiesInfo={amenitiesInfo} setIsShowAmenitiesInfo={setIsShowAmenitiesInfo} /> : ""
      }

      {
        isShowShareLikeSalon ? <ShareLikeSalon userIdError={userIdError} gotUserId={gotUserId} salon={salon} setIsShowShareLikeSalon={setIsShowShareLikeSalon} /> : ""
      }

      {isPopUp ? <PopUp setPopUpMessage={setPopUpMessage} setIsPopUp={setIsPopUp} message={popUpMessage} /> : ""}
    </>
  );
}

export default SalonList;
