import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

function Congratulations(props) {

  const navigate = useNavigate()

  return (
    <div className="congratulations-section">
      <div className="payment-success-image-container">
        <img src="/assets/paymentsuccess/paymentsuccess.svg" />
      </div>
      <div className="congrats-success-id-container">
        <p className="mb-0 congratulation">Congratulations.</p>
        <p className="mb-0 payment-successful">Payment Successful !</p>
        <p className="mb-0 payment-id">Payment ID: {props.gotOrderDetails && props.gotOrderDetails.data.order.transactions[0].razorPayDetails.paymentId}, {moment(props.gotOrderDetails && props.gotOrderDetails.data.order.transactions[0].createdAt).format("ddd, DD MMM, hh:mm A")}</p>
      </div>
    </div>
  );
}

export default Congratulations;
