import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { RWebShare } from "react-web-share";

import usePostData from "../../../customhooks/postdata";
import useDeleteData from "../../../customhooks/deletedata";
import useGetData from "../../../customhooks/getdata";

function ShareLikeSalon(props) {
  const callback = useCallback(() => {
    document.body.style.overflow = "unset";
  });

  const navigate = useNavigate();

  const [animate, setAnimate] = useState({});

  useEffect(() => {
    setAnimate({
      transform: "translateY(0%)",
    });
  }, []);

  const [postedLike, likeerror, postData] = usePostData();

  const [deletedLike, deleteerror, deleteData] = useDeleteData();

  const [likedSalons, likedSalonsError, getLikedSalons] = useGetData();

  const [isLiked, setIsLiked] = useState(false);

  const likeUnlikeSalon = (id, isLiked) => {
    if (localStorage.getItem("userId")) {
      if (isLiked) {
        deleteData({
          url: `${process.env.REACT_APP_API_URL}/app/salon/${id}/like`,
          body: {},
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        props.salon.data.salon.isLiked = false;
      } else {
        postData({
          url: `${process.env.REACT_APP_API_URL}/app/salon/${id}/like`,
          body: {},
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      }
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getLikedSalons({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/salon/like/search?user=${localStorage.getItem("userId")}`,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }

    return callback;
  }, []);

  useEffect(() => {
    if (
      (postedLike != null && postedLike != undefined && postedLike != "") ||
      (deletedLike != null && deletedLike != undefined && deletedLike != "")
    ) {
      getLikedSalons({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/salon/like/search?user=${localStorage.getItem("userId")}`,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [postedLike, deletedLike]);

  useEffect(() => {
    if (
      likedSalons != undefined &&
      likedSalons != null &&
      likedSalons != "" &&
      props.salon
    ) {
      setIsLiked(false);
      likedSalons.data.likes.forEach((likedItem) => {
        if (props.salon.data.salon._id == likedItem.salon) {
          setIsLiked(true);
        }
      });
    }
  }, [props, likedSalons]);

  const handleClickOnBack = () => {
    setAnimate({
      transform: "translateY(100%)",
    });
    setTimeout(() => {
      props.setIsShowShareLikeSalon(false);
    }, 1000);
  };

  return (
    <div style={animate} className="share-like-salon-page d-grid">
      <div
        onClick={() => handleClickOnBack()}
        className="cancel-container d-flex align-items-end justify-content-center"
      >
        <div className="cancel d-flex align-items-center justify-content-center">
          <img src="/assets/common/cancel.svg" />
        </div>
      </div>
      <div className="share-like-info-container bg-white">
        <div className="salon-name">
          <p className="mb-0">{props.salon && props.salon.data.salon.name}</p>
        </div>
        <div className="like-container">
          <button
            onClick={() => {
              likeUnlikeSalon(
                props.salon && props.salon.data.salon._id,
                isLiked
              );
            }}
            className="w-100 bg-white d-flex align-items-center"
          >
            <img
              src={
                isLiked
                  ? "/assets/common/like.svg"
                  : "/assets/common/unlike.svg"
              }
              alt="Heart"
            />
            <p className="mb-0">Like</p>
          </button>
        </div>
        <div className="share-container">
          <RWebShare
            data={{
              text: "",
              url: "",
              title: "Share",
            }}
          >
            <button className="w-100 bg-white d-flex align-items-center">
              <img src="/assets/common/share.svg" />
              <p className="mb-0">Share</p>
            </button>
          </RWebShare>
        </div>
        <div className="salon-info-container pb-0">
          <Link
            to={`/saloninfo/${props.salon &&
              props.salon.data.salon.name
                .toLowerCase()
                .split(" ")
                .join("-")}-${props.salon && props.salon.data.salon.id}`}
            className="w-100 border-0 bg-white d-flex align-items-center"
          >
            <img src="/assets/common/info.svg" />
            <p className="mb-0">Info</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ShareLikeSalon;
