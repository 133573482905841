import React from "react";
import { useNavigate } from "react-router-dom";

function Header(props) {

  const navigate = useNavigate()

  return (
    <div className="header-container">
      {/* <button
        onClick={() => {
          navigate(-1)
        }}
        className="border-0"
      >
        <img src="/assets/common/back.svg" alt="Back" />
      </button> */}
    </div>
  );
}

export default Header;
