import React from "react";

function Payroll() {
  return (
    <div id="payroll" className="product d-grid">
      <div className="desktop-image-container image-container">
        <img src="assets/business/payroll.png" />
      </div>
      <div className="info-container">
        <h3 className="title mb-0 ">Payroll</h3>

        <h4 className="statement mb-0">
          Payroll Leverage Customer Data. Build Relationships.
        </h4>

        <p className="paragraph mb-0">
          Groome helps to manage the employees pay-rolling and attendance.
          Groome automatically track the payrolling and calculate their payouts
          based on it.
        </p>

        <a href="#create-free-account" className="border-0">
          Create Free Account
        </a>
      </div>

      <div className="mobile-image-container image-container">
        <img src="assets/business/payroll.png" />
      </div>
    </div>
  );
}

export default Payroll;
