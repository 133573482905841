import React from "react";

import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const useGetData = () => {
  const [data, setData] = useState();
  const [error, setError] = useState();

  const navigate = useNavigate();

  const handleInvalidAlgorithm = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };

  const getData = async (params) => {
    try {
      const gotData = await axios.get(params.url, { headers: params.headers });

      setData(gotData);

      return gotData;
    } catch (err) {
      // console.log(error.response.data.message)

      if (
        err.response &&
        err.response.data &&
        err.response.data.message &&
        err.response.data.message == "invalid algorithm"
      ) {
        handleInvalidAlgorithm();
      }

      if (
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        setError(err);
        if (err && err.response && err.response.data && err.response.data.message) {
          toast.error(err && err.response && err.response.data && err.response.data.message);
        }
      }

      // return error
    }
  };

  return [data, error, getData];
};

export default useGetData;
