import React from "react";
import { useNavigate } from "react-router-dom";

function AppliedCoupon(props) {
  const navigate = useNavigate();

  const handleClickOpenCoupon = () => {
    if (localStorage.getItem("userId")) {
      props.setIsOpenCoupon(true);
    }
    if (!localStorage.getItem("userId")) {
      navigate("/login");
    }
  };

  const handleClickRemove = () => {
    const removeResRef = props.handleRemoveCoupon();

    removeResRef.then((couponRes) => {
      if (couponRes) {
        const cartResRef = props.getCartList();

        cartResRef.then((res) => {
          if (res) {
            props.setCartList(res);
          }
        });
      }
    });
  };

  return (
    <>
      {props.gotCartList &&
        props.gotCartList.data &&
        props.gotCartList.data.cart.appliedCoupon ? (
        <div className="used-coupon-container bg-white">
          <div className="used-coupon d-flex justify-content-between align-items-center">
            <div className="image-text-container d-flex justify-content-start align-items-center">
              <img src="/assets/cart/coupon.svg" alt="Plus" />
              <p className="mb-0">Used Coupon</p>
            </div>
            <div className="remove-offer">
              <button
                onClick={() => {
                  handleClickRemove();
                }}
                className="border-0 bg-white"
              >
                Remove coupon
              </button>
            </div>
          </div>
          <div className="code-applied d-flex justify-content-between align-items-center">
            <div className="image-text-container d-flex justify-content-start align-items-center">
              <img src="/assets/cart/check.svg" alt="Plus" />
              <p className="mb-0">
                Code {props.gotCartList.data.cart.appliedCoupon.code} applied!
              </p>
            </div>
            <div className="price">
              <p className="mb-0">
                -{" "}
                {String.fromCharCode(props.gotCartList.data.cart.salon.country.htmlSymbol)}
                {
                  props.gotCartList.data.cart.cartCalculations.overall
                    .couponDiscount
                }
              </p>
            </div>
          </div>

          <div className="change-offer">
            <button
              onClick={() => {
                props.setIsOpenCoupon(true);
              }}
              className="border-0 bg-white"
            >
              Change offer
            </button>
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            handleClickOpenCoupon();
          }}
          className="use-coupon-container d-flex justify-content-between align-items-center bg-white"
        >
          <div className="image-text-container d-flex justify-content-start align-items-center">
            <img src="/assets/cart/coupon.svg" alt="Plus" />
            <p className="mb-0">Use Coupon</p>
          </div>
          <div className="forward-container">
            <img src="assets/common/purple_right.svg" alt="Right" />
          </div>
        </div>
      )}
    </>
  );
}

export default AppliedCoupon;
