import axios from "axios";
import { toast } from "react-hot-toast";

const postRequest = async (params) => {
  try {
    const res = await axios.post(params.url, params.body, {
      headers: {
        "content-type": "Application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return Promise.resolve(res);
  } catch (err) {
    console.log(err);
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err && err.response && err.response.data && err.response.data.message);
    }
    // return Promise.reject(err);
  }
};

export default postRequest;
