import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import moment from "moment";

import Search from "./search";
import useGetData from "../../customhooks/getdata";
import useIsLoader from "../../customhooks/useisloader";
import Loader from "../../components/loader";

import NotFound from "../../components/notfound";

import PopUp from "../../components/popup";

import useIsPopUp from "../../customhooks/ispopup";

import { authJsonHeaders } from "../../constants";

import getRequest from "../../functions/apicall/getrequest";

import { IoLogoWhatsapp } from "react-icons/io";
import { gtm } from "../gtm";

function BookingsOrders() {
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);

  const [isLoader, setIsLoader] = useIsLoader(true);

  // const [gotOrderList, orderListError, getOrderList] = useGetData();

  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [popUpMessage, setPopUpMessage] = useState("");

  const [serviceStatus, setServiceStatus] = useState([
    "Pending",
    "Confirmed",
    "In transit",
    "Started",
    "Ended",
    "Cancelled",
  ]);

  const [productStatus, setProductStatus] = useState([
    "Pending",
    "Confirmed",
    "Shipped",
    "Delivered",
    "Cancelled",
  ]);

  const getOrderList = async (searchQuery) => {
    setIsLoader(true);

    const res = await getRequest(
      `${process.env.REACT_APP_API_URL}/app/order/search?${sessionStorage.getItem("isQrCode") == "true"
        ? `salon._id=${sessionStorage.getItem("salonId")}&`
        : ""
      }user._id=${localStorage.getItem("userId")}&status=1${searchQuery
        ? `&q=${isNaN(searchQuery)
          ? sessionStorage.getItem("isQrCode") == "true"
            ? ""
            : `salon.name:${searchQuery}`
          : `orderId:${searchQuery}`
        }`
        : ""
      }`
    );

    if (res) {
      setOrders(
        res.data.orders.filter((item) => {
          if (sessionStorage.getItem("isQrCode") == "true") {
            return item.qrCode == true;
          } else {
            return item.qrCode == false;
          }
        })
      );
    }

    setIsLoader(false);
  };

  useEffect(() => {
    getOrderList();

    gtm.page();

  }, []);


  const handleClickOnViewSalon = (item) => {
    if (item.salon.status == 1) {
      navigate(
        `${sessionStorage.getItem("isQrCode") == "true" ? "/qr" : "/app"
        }/salon/${item.salon &&
        item.salon.name
          .toLowerCase()
          .split(" ")
          .join("-")}-${item.salon && item.salon._id}`
      );
    } else {
      setIsPopUp(true);
      setPopUpMessage("Salon is not active.");
    }
  };

  return (
    <>
      <div className="booking-order-page d-grid">
        <div className="header-search-container position-sticky">
          <div className="header-container d-flex align-items-center justify-content-between">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="border-0 bg-white"
            >
              <img src="/assets/common/back.svg" alt="Down" />
            </button>
            <p className="mb-0">Bookings & Orders</p>
            <div className="whatsapp-image">
              <a href="//api.whatsapp.com/send?phone=918884877662&text=">
                <IoLogoWhatsapp />
              </a>
            </div>
          </div>

          <Search getOrderList={getOrderList} />
        </div>

        {isLoader ? (
          <Loader width={60} height={60} color={"#772286"} />
        ) : (
          <>
            {orders.length > 0 ? (
              <div className="booking-salons-container d-flex flex-column">
                {orders.map((item) => {
                  return (
                    <div key={item._id} className="booking-salon-container">
                      <div className="booking-salon d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                          <div className="booking-salon-image">
                            <img
                              src={
                                item.salon && item.salon.logo
                                  ? `${process.env.REACT_APP_IMAGE_URL
                                  }${item.salon && item.salon.logo}`
                                  : "/assets/common/serviceproduct.png"
                              }
                              alt="Salon"
                            />
                          </div>
                          <div className="booking-salon-info d-flex flex-column justify-content-center">
                            <p className="name mb-0">
                              {item.salon && item.salon.name}
                            </p>
                            <p className="address mb-0">
                              {item.salon && item.salon.address.city}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <button
                            onClick={() => {
                              handleClickOnViewSalon(item);
                            }}
                            className="view-salon border-0"
                          >
                            View Salon
                          </button>
                        </div>
                      </div>

                      <div className="order-id-section d-flex justify-content-between">
                        <div className="order-id-container">
                          <p className="order-id">Order# {item.orderId}</p>
                          <p className="total-amount mb-0">
                            Total Amount -{" "}
                            {String.fromCharCode(item.salon.country.htmlSymbol)}
                            {Math.round(
                              item.cartCalculations.overall &&
                              item.cartCalculations.overall.grandTotal
                            )}{" "}
                          </p>
                        </div>
                        {/* <div>
                          <p className="confirmed d-grid justify-content-center align-items-center border-0 mb-0">
                            Confirmed
                          </p>
                        </div> */}
                      </div>

                      {item.productOrders ? (
                        <div className="product-name-status-container">
                          <div className="product-name-container d-flex justify-content-between align-items-center">
                            <p className="mb-0">
                              {item.productOrders.products.length} x Products
                            </p>
                            <Link
                              to={`/product/orderdetails/${item._id}`}
                              className="view-details border-0 bg-white"
                            >
                              View Details
                            </Link>
                          </div>
                          <div className="status-container d-flex justify-content-between align-items-center">
                            {/* <p className="delivery-status mb-0">Delivery Status:</p> */}
                            <p
                              className={
                                Number(
                                  item.productOrders.productsProcessingStatus
                                ) == 1
                                  ? "confirmed status mb-0"
                                  : "status mb-0"
                              }
                            >
                              {
                                productStatus[
                                Number(
                                  item.productOrders.productsProcessingStatus
                                )
                                ]
                              }
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {item.serviceOrders ? (
                        <div className="service-name-status-container">
                          <div className="service-name-container d-flex justify-content-between align-items-center">
                            <p className="mb-0">
                              {item.serviceOrders.services.length} x Services
                            </p>
                            <Link
                              to={`/service/orderdetails/${item._id}`}
                              className="view-details border-0 bg-white"
                            >
                              View Details
                            </Link>
                          </div>
                          <div className="status-container d-flex justify-content-between align-items-center">
                            {/* <p className="delivery-status mb-0">Status:</p> */}
                            <p
                              className={
                                Number(
                                  item.serviceOrders.servicesProcessingStatus
                                ) == 1
                                  ? "confirmed status mb-0"
                                  : "status mb-0"
                              }
                            >
                              {
                                serviceStatus[
                                Number(
                                  item.serviceOrders.servicesProcessingStatus
                                )
                                ]
                              }
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="payment-mode-section d-flex justify-content-between align-items-center">
                        <div className="date-container">
                          <p className="date mb-0">
                            {moment(item.orderDate).format(
                              "ddd, DD MMM, hh:mm A"
                            )}
                          </p>
                        </div>
                        <div className="payment-mode-container">
                          <p className="mb-0">
                            {item.transactions &&
                              item.transactions.length > 0 &&
                              item.transactions[0].paymentMode &&
                              item.transactions[0].paymentMode}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <NotFound />
            )}
          </>
        )}
      </div>

      {isPopUp ? <PopUp setIsPopUp={setIsPopUp} message={popUpMessage} /> : ""}
    </>
  );
}

export default BookingsOrders;
