import React from "react";
import { useEffect, useState } from "react";

import Rating from "../../../components/rating";
import useUpdateData from "../../../customhooks/updatedata";

import { authJsonHeaders, headers } from "../../../constants";
import postRequest from "../../../functions/apicall/postrequest";

function Products(props) {
  const [addedProduct, addedProductError, addProduct] = useUpdateData();

  const [removedProduct, removedProductError, removeProduct] = useUpdateData();

  const [item, setItem] = useState("")

  const [
    addedGenericProduct,
    addedGenericProductError,
    addGenericProduct,
  ] = useUpdateData();

  const [
    removedGenericProduct,
    removedGenericProductError,
    removeGenericProduct,
  ] = useUpdateData();

  const [productId, setProductId] = useState([]);

  const createCart = async () => {
    const res = await postRequest({
      url: `${process.env.REACT_APP_API_URL}/app/genericCart`,
      body: { salon: localStorage.getItem("salonId") },
    })

    return Promise.resolve(res)
  }

  const decreaseCount = (item) => {
    if (localStorage.getItem("userId")) {
      if (item.cartProduct.count > 1) {
        addProduct({
          url: `${process.env.REACT_APP_API_URL}/app/cart/item/add?product=${item._id
            }&count=${item.cartProduct.count - 1}&itemType=product`,
          //headers: authJsonHeaders,
          headers: {
            "Content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: {},
        });
      } else {
        removeProduct({
          url: `${process.env.REACT_APP_API_URL}/app/cart/item/remove?product=${item._id}&itemType=product`,
          //headers: authJsonHeaders,
          headers: {
            "Content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: {},
        });
      }
    }
    if (!localStorage.getItem("userId")) {
      if (item.cartProduct.count > 1) {
        addGenericProduct({
          url: `${process.env.REACT_APP_API_URL
            }/app/genericCart/${localStorage.getItem(
              "cartId"
            )}/item/add?product=${item._id}&count=${item.cartProduct.count -
            1}&itemType=product`,
          headers: headers,
          body: {},
        });
      } else {
        removeGenericProduct({
          url: `${process.env.REACT_APP_API_URL
            }/app/genericCart/${localStorage.getItem(
              "cartId"
            )}/item/remove?product=${item._id}&itemType=product`,
          headers: headers,
          body: {},
        });
      }
    }
  };

  const increaseCount = (item) => {

    setItem(item)

    if (localStorage.getItem("userId")) {
      addProduct({
        url: `${process.env.REACT_APP_API_URL}/app/cart/item/add?product=${item._id
          }&count=${item.cartProduct ? item.cartProduct.count + 1 : 1
          }&itemType=product`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: {},
      });
    }
    if (!localStorage.getItem("userId")) {
      addGenericProduct({
        url: `${process.env.REACT_APP_API_URL
          }/app/genericCart/${localStorage.getItem("cartId")}/item/add?product=${item._id
          }&count=${item.cartProduct ? item.cartProduct.count + 1 : 1
          }&itemType=product`,
        headers: headers,
        body: {},
      });
    }
  };

  useEffect(() => {
    if (
      (addedProduct != undefined &&
        addedProduct != null &&
        addedProduct != "") ||
      (removedProduct != undefined &&
        removedProduct != null &&
        removedProduct != "")
    )
      props.getCartList({
        url: `${process.env.REACT_APP_API_URL
          }/app/cart?user=${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  }, [addedProduct, removedProduct]);

  useEffect(() => {
    if (
      (addedGenericProduct != undefined &&
        addedGenericProduct != null &&
        addedGenericProduct != "") ||
      (removedGenericProduct != undefined &&
        removedGenericProduct != null &&
        removedGenericProduct != "")
    )
      props.getGenericCart({
        url: `${process.env.REACT_APP_API_URL
          }/app/genericCart/${localStorage.getItem("cartId")}`,
        headers: headers,
      });
  }, [addedGenericProduct, removedGenericProduct]);

  const handleClickForMessage = (value) => {
    props.setPopUpMessage(value);
    props.setIsPopUp(true);
  };

  const handleClickReadLess = (id) => {
    setProductId(
      productId.filter((item) => {
        return item != id;
      })
    );
  };


  useEffect(() => {
    if (
      (addedGenericProductError)
    )
      if (addedGenericProductError.response.data && addedGenericProductError.response.data.message == "Cart does not exist") {
        const createCartRes = createCart()

        createCartRes.then((res) => {
          if (res) {
            localStorage.setItem("cartId", res.data.genericCart._id)

            addGenericProduct({
              url: `${process.env.REACT_APP_API_URL
                }/app/genericCart/${localStorage.getItem("cartId")}/item/add?product=${item._id ? item._id : ""
                }&count=${item.cartProduct ? item.cartProduct.count + 1 : 1
                }&itemType=product`,
              headers: headers,
              body: {},
            });
          }
        })
      }
  }, [addedGenericProductError]);


  return (
    <div
      style={{ paddingBottom: `${props.isProceed ? "51px" : "0px"}` }}
      className="products-container"
    >
      {props.isSearchProducts ? (
        ""
      ) : (
        <>
          {props.productCategoryName ? (
            <div className="title-container">
              <p className="mb-0">{props.productCategoryName}</p>
            </div>
          ) : (
            ""
          )}
        </>
      )}

      <div className="products d-grid justify-content-between">
        {props.products.length > 0 ? (
          <>
            {props.products.map((item) => {
              return (
                <div className="product" key={item._id}>
                  {item.image ? (
                    <div className="image-container">
                      <img
                        src={
                          item.image
                            ? `${process.env.REACT_APP_IMAGE_URL}${item.image}`
                            : "/assets/common/serviceproduct.png"
                        }
                        alt="Product"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="product-info">
                    <p className="name mb-0">{item.name}</p>
                    <div className="quantity-add-container d-flex justify-content-between align-items-cenjter">
                      <div>
                        <p className="quantity mb-0">{item.quantity}</p>
                        <>
                          {item.discountPrice > 0 &&
                            item.mrp != item.discountPrice ? (
                            <>
                              <p className="price mb-0">
                                {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
                                {item.discountPrice}
                              </p>
                              <p className="cut mb-0">
                                {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
                                {item.mrp}
                              </p>
                            </>
                          ) : (
                            <p className="price mb-0">
                              {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
                              {item.mrp}
                            </p>
                          )}
                        </>
                      </div>

                      {item.cartProduct ? (
                        <div className="add-remove-product d-flex justify-content-between align-items-center">
                          <button
                            onClick={() => {
                              decreaseCount(item);
                            }}
                            className="border-0"
                          >
                            -
                          </button>
                          <p className="number-of-product mb-0">
                            {item.cartProduct.count}
                          </p>
                          <button
                            onClick={() => {
                              {
                                item.currentStock <= item.cartProduct.count
                                  ? handleClickForMessage(
                                    "Sorry! This product is not in stock."
                                  )
                                  : increaseCount(item);
                              }
                            }}
                            className="border-0"
                          >
                            +
                          </button>
                        </div>
                      ) : (
                        <button
                          disabled={item.currentStock == 0}
                          style={{
                            backgroundColor: `${item.currentStock == 0 ? "#ebebeb" : ""
                              }`,
                          }}
                          className="add-product"
                          onClick={() => {
                            {
                              item.currentStock <= item.cartProduct.count
                                ? handleClickForMessage(
                                  "Sorry! This product is not in stock."
                                )
                                : increaseCount(item);
                            }
                          }}
                        >
                          ADD+
                        </button>
                      )}
                    </div>
                    {item.noOfPeople > 0 &&
                      item.totalRating / item.noOfPeople > 0 ? (
                      <div className="star-rating-container">
                        <Rating
                          rating={Math.round(
                            item.totalRating / item.noOfPeople
                          )}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {item.description ? (
                      <div>
                        <p className="description mb-0">
                          {item.description.length > 60 ? (
                            <>
                              {productId.includes(item._id) ? (
                                <>
                                  {item.description}
                                  <button
                                    onClick={() => {
                                      handleClickReadLess(item._id);
                                    }}
                                    className="border-0"
                                  >
                                    ....read less
                                  </button>
                                </>
                              ) : (
                                <>
                                  {item.description.substring(0, 60)}
                                  <button
                                    onClick={() => {
                                      setProductId((prev) => [
                                        ...prev,
                                        item._id,
                                      ]);
                                    }}
                                    className="border-0"
                                  >
                                    ....read more
                                  </button>
                                </>
                              )}
                            </>
                          ) : (
                            item.description
                          )}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="message">
            <p className="mb-0">No Products</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Products;
