import React from "react";

function Ecommerce() {
  return (
    <div id="e-commerce" className="product d-grid">
      <div className="info-container">
        <h3 className="title mb-0 ">E-commerce and Home Salon</h3>

        <h4 className="statement mb-0">Create Your Very Own E-Store</h4>

        <p className="paragraph mb-0">
          Groome salon and spa software is the perfect solution to sell salon
          products and services directly to your clients. With our easy-to-use
          dashboard, you can list unlimited products, set prices, and promote
          special offers. Now capitalize on our fast and integrated payment
          gateway and ensure your clients experience a smooth checkout process.
        </p>

        <a href="#create-free-account" className="border-0">
          Create Free Account
        </a>
      </div>

      <div className="image-container">
        <img src="/assets/business/ecommerce.png" />
      </div>
    </div>
  );
}

export default Ecommerce;
