import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { VscStarFull } from "react-icons/vsc";

import ReviewRating from "./reviewrating";

import moment from "moment";

import useGetData from "../../customhooks/getdata";
import { useEffect } from "react";
import Header from "./header";
import Salon from "./salon";
import Service from "./service";
import Product from "./product";
import PaymentDetails from "./paymentdetails";
import Address from "./address";
import SalonAddress from "./salonaddress";

import useIsPopUp from "../../customhooks/ispopup";
import PopUp from "../../components/popup";

import { authJsonHeaders } from "../../constants";
import { gtm } from "../gtm";

function OrderDetails() {
  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [popUpMessage, setPopUpMessage] = useState("");

  const [isUberDirection, setIsUberDirection] = useState(false);

  const orderIdItemType = useParams();

  const navigate = useNavigate();

  const location = useLocation();

  const [gotOrderDetails, orderDetailsError, getOrderDetails] = useGetData();

  useEffect(() => {
    if (
      orderIdItemType != undefined &&
      orderIdItemType != null &&
      orderIdItemType != ""
    ) {
      getOrderDetails({
        url: `${process.env.REACT_APP_API_URL}/app/order/${orderIdItemType.orderId}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [orderIdItemType]);

  const [serviceStatus, setServiceStatus] = useState([
    "Pending",
    "Confirmed",
    "In transit",
    "Started",
    "Ended",
    "Cancelled",
  ]);

  const [productStatus, setProductStatus] = useState([
    "Pending",
    "Confirmed",
    "Shipped",
    "Delivered",
    "Cancelled",
  ]);

  useEffect(() => {
    if (
      gotOrderDetails &&
      gotOrderDetails.data.order.serviceOrders &&
      gotOrderDetails.data.order.serviceOrders.serviceType == "At Salon" &&
      gotOrderDetails &&
      gotOrderDetails.data.order.serviceOrders.servicesProcessingStatus != "4"
    ) {
      setIsUberDirection(true);
    } else {
      setIsUberDirection(false);
    }
  }, [gotOrderDetails]);

  useEffect(() => {
    gtm.page();
  }, [])

  return (
    <>
      <div className="order-details-page">
        <div
          style={{ paddingBottom: isUberDirection ? "62px" : "1px" }}
          className="info-container"
        >
          <Header
            orderIdItemType={orderIdItemType}
            gotOrderDetails={gotOrderDetails}
          />

          <div className="order-placed-payment-status-container bg-white">
            <div className="title d-flex justify-content-between align-items-center">
              <p className="mb-0">Order Placed</p>
              <p className="mb-0">Payment Mode</p>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="date-time mb-0">
                {moment(
                  gotOrderDetails && gotOrderDetails.data.order.orderDate
                ).format("ddd, DD MMM, hh:mm A")}
              </p>
              <p className="status mb-0">
                {gotOrderDetails &&
                  gotOrderDetails.data &&
                  gotOrderDetails.data.order.transactions &&
                  gotOrderDetails.data.order.transactions.length > 0 &&
                  gotOrderDetails.data.order.transactions[0].paymentMode &&
                  gotOrderDetails.data.order.transactions[0].paymentMode}
              </p>
              {/* <p className="status mb-0">{gotOrderDetails && gotOrderDetails.data && gotOrderDetails.data.order.transactions.length > 0 && gotOrderDetails.data.order.transactions[0].paymentStatus}</p> */}
            </div>
          </div>

          <Salon gotOrderDetails={gotOrderDetails} />
          <div className="order-details-section bg-white">
            <div className="order-details-title d-flex justify-content-between align-items-center">
              <p className="mb-0">Order Details</p>
              {orderIdItemType.itemType &&
                orderIdItemType.itemType == "service" ? (
                <p
                  className={
                    Number(
                      gotOrderDetails &&
                      gotOrderDetails.data.order.serviceOrders
                        .servicesProcessingStatus
                    ) == 1
                      ? "confirmed status mb-0"
                      : "status mb-0"
                  }
                >
                  {
                    serviceStatus[
                    Number(
                      gotOrderDetails &&
                      gotOrderDetails.data.order.serviceOrders
                        .servicesProcessingStatus
                    )
                    ]
                  }
                </p>
              ) : (
                ""
              )}
              {orderIdItemType.itemType &&
                orderIdItemType.itemType == "product" ? (
                <p
                  className={
                    Number(
                      gotOrderDetails &&
                      gotOrderDetails.data.order.productOrders
                        .productsProcessingStatus
                    ) == 1
                      ? "confirmed status mb-0"
                      : "status mb-0"
                  }
                >
                  {
                    productStatus[
                    Number(
                      gotOrderDetails &&
                      gotOrderDetails.data.order.productOrders
                        .productsProcessingStatus
                    )
                    ]
                  }
                </p>
              ) : (
                ""
              )}
            </div>

            {orderIdItemType.itemType &&
              orderIdItemType.itemType == "service" ? (
              <Service gotOrderDetails={gotOrderDetails} />
            ) : (
              <Product gotOrderDetails={gotOrderDetails} />
            )}
          </div>

          <PaymentDetails
            orderIdItemType={orderIdItemType}
            gotOrderDetails={gotOrderDetails}
          />

          {/* <div className="payment-method d-flex justify-content-between align-items-center bg-white">
            <p className="mb-0">Payment Mode</p>
            <p className="mb-0">{gotOrderDetails && gotOrderDetails.data && gotOrderDetails.data.order.transactions
              && gotOrderDetails.data.order.transactions.length > 0 && gotOrderDetails.data.order.transactions
              [0].paymentMode && gotOrderDetails.data.order.transactions
              [0].paymentMode}</p>
          </div> */}

          {gotOrderDetails ? (
            <>
              {gotOrderDetails.data.order.serviceOrders &&
                gotOrderDetails.data.order.serviceOrders.services.length > 0 &&
                gotOrderDetails.data.order.productOrders &&
                gotOrderDetails.data.order.productOrders.products.length > 0 ? (
                <>
                  {gotOrderDetails.data.order.orderReviewAndRating == null &&
                    gotOrderDetails.data.order.serviceOrders
                      .servicesProcessingStatus == "4" &&
                    gotOrderDetails.data.order.productOrders
                      .productsProcessingStatus == "3" ? (
                    <ReviewRating
                      orderIdItemType={orderIdItemType}
                      gotOrderDetails={gotOrderDetails}
                      getOrderDetails={getOrderDetails}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {(orderIdItemType.itemType == "service" &&
                    gotOrderDetails &&
                    gotOrderDetails.data.order.serviceOrders &&
                    gotOrderDetails.data.order.serviceOrders
                      .servicesProcessingStatus == "4" &&
                    gotOrderDetails.data.order.orderReviewAndRating === null) ||
                    (orderIdItemType.itemType == "product" &&
                      gotOrderDetails &&
                      gotOrderDetails.data.order.productOrders &&
                      gotOrderDetails.data.order.productOrders
                        .productsProcessingStatus == "3" &&
                      gotOrderDetails.data.order.orderReviewAndRating ===
                      null) ? (
                    <ReviewRating
                      orderIdItemType={orderIdItemType}
                      gotOrderDetails={gotOrderDetails}
                      getOrderDetails={getOrderDetails}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          ) : (
            ""
          )}

          {gotOrderDetails &&
            gotOrderDetails.data.order.orderReviewAndRating &&
            gotOrderDetails.data.order.orderReviewAndRating.status == 1 ? (
            <div className="salon-rating-section">
              <div className="rated-section d-flex justify-content-betwen align-items-center">
                <p className="thank-you mb-0">
                  Thank you for rating! You rated
                </p>
                <p className="rated mb-0 d-flex justify-content-center align-items-center">
                  {Math.round(
                    gotOrderDetails &&
                    gotOrderDetails.data.order.orderReviewAndRating.rating
                  )}
                  <VscStarFull />
                </p>
              </div>
            </div>
          ) : (
            ""
          )}

          {gotOrderDetails &&
            gotOrderDetails.data &&
            gotOrderDetails.data.order.serviceOrders &&
            gotOrderDetails.data.order.serviceOrders.services.length > 0 &&
            gotOrderDetails.data.order.serviceOrders.serviceType == "At Salon" ? (
            <SalonAddress gotOrderDetails={gotOrderDetails} />
          ) : (
            <Address gotOrderDetails={gotOrderDetails} />
          )}

          <div>
            <a href="//api.whatsapp.com/send?phone=918884877662&text=" className="need-help-section d-flex align-items-center">
              <div className="image d-grid justify-content-center align-items-center">
                <img
                  src="/assets/orderdetails/customer_service.svg"
                  alt="CustomerService"
                />
              </div>
              <div>
                <p className="need-help">Need help with your booking?</p>
                <p className="chat-with mb-0">
                  Chat with our support executive
                </p>
              </div>
            </a>
          </div>
        </div>

        {gotOrderDetails &&
          gotOrderDetails.data.order.serviceOrders &&
          gotOrderDetails.data.order.serviceOrders.serviceType == "At Salon" &&
          gotOrderDetails &&
          gotOrderDetails.data.order.serviceOrders.servicesProcessingStatus !=
          "4" ? (
          <div className="book-uber-direction-section">
            <a
              href={`https://m.uber.com/ul/?action=setPickup&client_id=5agw65_lfcVPm6XOX-ZW4gq-QtcOpcsk&action=setPickup&pickup[latitude]=${sessionStorage.getItem(
                "lat"
              )}&pickup[longitude]=${sessionStorage.getItem(
                "lng"
              )}&dropoff[latitude]=${gotOrderDetails &&
              gotOrderDetails.data.order.salon.location
                .lat}&dropoff[longitude]=${gotOrderDetails &&
                gotOrderDetails.data.order.salon.location.lng}`}
              className="d-flex justify-content-center align-items-center"
            >
              <img src="/assets/orderdetails/uber.svg" alt="uber" />
              <p className="mb-0">Book Uber</p>
            </a>
            <a
              className="d-flex justify-content-center align-items-center"
              href={`https://www.google.com/maps/dir/?api=1&origin=${sessionStorage.getItem(
                "lat"
              )},${sessionStorage.getItem(
                "lng"
              )}&destination=${gotOrderDetails &&
              gotOrderDetails.data.order.salon.location
                .lat},${gotOrderDetails &&
                gotOrderDetails.data.order.salon.location
                  .lng}&travelmode=driving`}
            >
              <img src="/assets/orderdetails/direction.svg" alt="direction" />
              <p className="mb-0">Get Direction</p>
            </a>
          </div>
        ) : (
          ""
        )}
      </div>

      {isPopUp ? <PopUp setIsPopUp={setIsPopUp} message={popUpMessage} /> : ""}
    </>
  );
}

export default OrderDetails;
