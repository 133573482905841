import React from "react";

import { VscStarFull } from "react-icons/vsc"

import Categories from "./categories";
import StarRatings from "react-star-ratings";

import moment from "moment";

function ReviewRating(props) {

    return <div className="show-review-rating-section">

        <div className="rating-review">
            <div className="review-rating-text">
                <p>Rating & Reviews</p>
            </div>
            <div className="sub-rating-review d-flex align-items-center">
                <div className="rating d-flex justify-content-center align-items-center">
                    <span>{props.gotReviewRating && props.gotReviewRating.data.orderReviewsAndRatings.length>0 ? props.gotReviewRating && props.gotReviewRating.data.orderReviewsAndRatings.length>0 && props.gotReviewRating.data.orderReviewsAndRatings[0].salon.rating.toFixed(1) : ""}</span>
                    <VscStarFull />
                </div>
                <div className="review-container">
                    <p className="mb-0 number-of-review">{props.gotReviewRating && props.gotReviewRating.data.orderReviewsAndRatings.length>0 ? <>{Number(props.gotReviewRating && props.gotReviewRating.data.orderReviewsAndRatings.length>0 && props.gotReviewRating.data.orderReviewsAndRatings[0].salon.numberOfReviews)>1000 ? <>{Number(props.gotReviewRating && props.gotReviewRating.data.orderReviewsAndRatings.length>0 && props.gotReviewRating.data.orderReviewsAndRatings[0].salon.numberOfReviews)/1000}K</> : Number(props.gotReviewRating && props.gotReviewRating.data.orderReviewsAndRatings.length>0 && props.gotReviewRating.data.orderReviewsAndRatings[0].salon.numberOfReviews)}</>  : ""} Reviews</p>
                </div>
            </div>
        </div>

        <Categories filterValue={props.filterValue} setFilterValue={props.setFilterValue} />

        <div className="user-infos-container">
            {props.reviewAndRating.map((item) => {
                return <div key={item._id} className="user-info-container">
                    <div className="d-flex align-items-center">
                        <div className="user-image">
                            {item.user.image ? <img src={`${process.env.REACT_APP_IMAGE_URL}${item.user.image}`} alt="User" /> : <img src="/assets/common/user.svg" />}
                        </div>
                        <div className="user-info">
                            <p className="name">{item.user.name}</p>
                            <div className="star-rating-container">
                                <StarRatings
                                    rating={Math.round(item.rating)}
                                    starRatedColor="#FFCE00"
                                    numberOfStars={Math.round(item.rating)}
                                    name="rating"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100">
                        {item.review ?
                            <div className="review-container">
                                <p className="mb-0 review">{item.review}</p>
                            </div> : ""}
                        <p className="time mb-0">{moment(item.updatedAt).format('LT')}, {moment(item.updatedAt).format('L')}</p>
                    </div>
                </div>
            })}

        </div>
    </div>
}

export default ReviewRating