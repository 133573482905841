import React from "react"

function Banner() {
    return <div id="banner" className="business-banner-container">
        <div className="business-banner d-grid">
            <div className="create-free-account-section d-flex flex-column">
                <h1 className="mb-0">
                    All-in-One Salon and Spa Management Software
                </h1>

                <p className="mb-0">
                    Groome for Salons is a user-friendly, cloud-based salon management software helping salons and spas to reach out to customers through integrated marketing, alongside streamlining customer appointments and stylist workload. This feature-rich salon and spa software offers a wide range of easy-to-use products like Salon POS, Online Appointments, Reports, Feedback, CRM, Inventory, E-Commerce, Digital Catalog, and WhatsApp marketing integration to maximize revenue and profits.
                </p>

                <a href="#create-free-account" className="border-0">
                    Create Free Account
                </a>

            </div>
            <div className="action-section d-flex flex-column">
                <a href="#digital-catalog" className="border-0 digital-catalog">
                    <p className="mb-0 ">DIGITAL CATALOG</p>
                    <img src="/assets/business/digitalcatalog.svg" />
                </a>
                <a href="#marketing" className="border-0 marketing">
                    <p className="mb-0 ">MARKETING</p>
                    <img src="/assets/business/marketing.svg" />
                </a>
                <a href="#analytics" className="border-0 pos">
                    <p className="mb-0 ">ANALYTICS</p>
                    <img src="/assets/business/analytics.svg" />
                </a>
                <a href="#online-appointment" className="border-0 appointment">
                    <p className="mb-0 ">APPOINTMENTS</p>
                    <img src="/assets/business/appointments.svg" />
                </a>
                <a href="#e-commerce" className="border-0 ecommerce">
                    <p className="mb-0 ">ECOMMERCE</p>
                    <img src="/assets/business/ecommerce.svg" />
                </a>
                <a href="#payroll" className="border-0 crm">
                    <p className="mb-0 ">PAYROLL</p>
                    <img src="/assets/business/payroll.svg" />
                </a>
                <a href="#inventory" className="border-0 membership">
                    <p className="mb-0 ">INVENTORY</p>
                    <img src="/assets/business/inventory.svg" />
                </a>
                <a href="#feedback" className="border-0 feedback">
                    <p className="mb-0 ">FEEDBACK</p>
                    <img src="/assets/business/feedback.svg" />
                </a>

            </div>
        </div>
    </div>


}

export default Banner