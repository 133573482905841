import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import SalonInfo from "./saloninfo";
import Filter from "./filter";
import Search from "./search";
import ToggleServicesPackages from "./toggleservicespackages";
import Services from "./services";
import Products from "./products";
import Proceed from "./proceed";

import PopUp from "../../components/popup";

import SearchProduct from "./searchproduct";
import SearchService from "./searchservice";

import useIsLoader from "../../customhooks/useisloader";

import useGetData from "../../customhooks/getdata";

import useIsPopUp from "../../customhooks/ispopup";

import usePostData from "../../customhooks/postdata";

import useDeleteData from "../../customhooks/deletedata";
import OfferDeals from "./offerdeals";
import ShowOfferDealDetails from "./showofferdealdetails";
import Skeleton from "react-loading-skeleton";
import BrowseSection from "./browsesection";

import { FiPhone } from "react-icons/fi";
import ShareLikeSalon from "./sharelike";
import { RiMenu2Fill } from "react-icons/ri";
import ProductMaleFemale from "./productmalefemale";
import Amenitiesinfo from "./amenitiesinfo";
import axios from "axios";
import useUpdateData from "../../customhooks/updatedata";
import DeleteCart from "./deletecart";

import { authJsonHeaders, headers } from "../../constants";

import { useSearchParams } from "react-router-dom";
import NoSalonActive from "./nosalonactive";
import { gtm } from "../gtm";

function Salon() {
  const salonNameId = useParams();

  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);

  const appOrQr = window.location.pathname.split("/")[1];

  const [isLoader, setIsLoader] = useIsLoader(true);

  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [popUpMessage, setPopUpMessage] = useState("");

  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);
  const [salon, setSalon] = useState();

  const [servicesCategories, setServicesCategories] = useState([]);

  const [productsCategories, setProductsCategories] = useState([]);

  const [isProducts, setIsProducts] = useState(false);

  const [categoryAtSalon, setCategoryAtSalon] = useState("Recommended");
  const [categoryAtHome, setCategoryAtHome] = useState("Recommended");
  const [filterName, setFilterName] = useState("");
  const [isServices, setIsServices] = useState(false);
  const [isPackages, setIsPackages] = useState(false);
  const [searchQuery, setsearchQuery] = useState("");
  const [isMale, setIsMale] = useState(false);
  const [isFemale, setIsFemale] = useState(false);

  const [filterToBeActive, setFilterToBeActive] = useState("");

  const [isSalon, setIsSalon] = useState(false);
  const [isHome, setIsHome] = useState(false);

  const [isProductMale, setIsProductMale] = useState(false);
  const [isProductFemale, setIsProductFemale] = useState(false);

  const [isSearchProducts, setIsSearchProducts] = useState(false);

  const [isSearchServices, setIsSearchServices] = useState(false);

  const [isDeleteCart, setIsDeleteCart] = useState(false);

  const [productCategoryName, setProductCategoryName] = useState("Recommended");

  const [offerDetails, setOfferDetails] = useState({
    title: "",
    description: "",
    offerDetail: "",
  });

  const [isShowShareLikeSalon, setIsShowShareLikeSalon] = useState(false);

  const [isShowOfferDetails, setIsShowOfferDetails] = useState(false);

  const [isShowBrowseSection, setIsShowBrowseSection] = useState(false);

  const [isShowAmenitiesInfo, setIsShowAmenitiesInfo] = useState(false);

  const [amenitiesInfo, setAmenitiesInfo] = useState({
    img: "",
    amenityName: "",
    amenityDescription: "",
  });

  const [isProceed, setIsProceed] = useState(false);

  const [salonId, setSalonId] = useState("");

  const [gotSalon, gotSalonError, getSalon] = useGetData();

  const [gotServices, serviceError, getServices] = useGetData();

  const [gotProducts, productsError, getProducts] = useGetData();

  const [gotCartList, cartListError, getCartList] = useGetData();

  const [createdCartId, createCartError, createCart] = usePostData();

  const [gotGenericCart, genericCartError, getGenericCart] = useGetData();

  const [
    movedGenericCart,
    movedGenericCartError,
    moveGenericCart,
  ] = useDeleteData();

  const [createdClick, createClickError, createClick] = usePostData();

  const [addedProduct, addedProductError, addProduct] = useUpdateData();

  const [removedProduct, removedProductError, removeProduct] = useUpdateData();

  const [
    addedGenericProduct,
    addedGenericProductError,
    addGenericProduct,
  ] = useUpdateData();

  const [
    removedGenericProduct,
    removedGenericProductError,
    removeGenericProduct,
  ] = useUpdateData();

  const [gotUserProfile, userProfileError, getUserProfile] = useGetData();

  useEffect(() => {
    if (queryParams.get("id")) {
      sessionStorage.setItem("portalToken", queryParams.get("id"));

      if (!sessionStorage.getItem("isComeFromPage")) {
        sessionStorage.setItem("isComeFromPage", true);
        localStorage.removeItem("userId");
      }
    }
  }, [queryParams]);

  useEffect(() => {
    if (salonNameId) {
      const array = salonNameId.salonNameId.split("-");
      setSalonId(array[array.length - 1]);
      localStorage.setItem("salonId", array[array.length - 1])

      if (salonNameId.origin) {
        sessionStorage.setItem("origin", salonNameId.origin);
        if (!queryParams.get("id")) {
          navigate(`${window.location.pathname.split("/portal")[0]}`);
        }
      } else {
        sessionStorage.removeItem("origin");
      }
    }
  }, [salonNameId]);

  useEffect(() => {
    if (appOrQr) {
      if (appOrQr == "qr") {
        sessionStorage.setItem("isQrCode", true);
      } else {
        if (appOrQr == "app") {
          sessionStorage.setItem("isQrCode", false);
        }
      }
    }
  }, [appOrQr]);

  useEffect(() => {
    document.body.style.overflow = "unset";
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getUserProfile({
        url: `${process.env.REACT_APP_API_URL}/app/user/${localStorage.getItem(
          "userId"
        )}?status=1`,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (gotSalon) {
      if (!localStorage.getItem("userId") && !localStorage.getItem("cartId")) {
        // || (localStorage.getItem("cartId") && salonId != localStorage.getItem("salonCartId")



        createCart({
          url: `${process.env.REACT_APP_API_URL}/app/genericCart`,
          headers: headers,
          body: { salon: gotSalon.data.salon._id },
        });


      }
    }
  }, [gotSalon]);

  useEffect(() => {
    if (
      createdCartId
    ) {

      localStorage.setItem("cartId", createdCartId.data.genericCart._id);
      // localStorage.setItem("salonCartId", salonId);
    }
  }, [createdCartId]);

  useEffect(() => {
    if (
      !localStorage.getItem("userId") ||
      localStorage.getItem("userId") ||
      createdCartId
    ) {
      if (
        (!localStorage.getItem("userId") || localStorage.getItem("userId")) && localStorage.getItem("cartId")
      ) {

        getGenericCart({
          url: `${process.env.REACT_APP_API_URL}/app/genericCart/${localStorage.getItem("cartId")
            ? localStorage.getItem("cartId")
            : createdCartId.data.genericCart._id
            }`,
          headers: headers,
        });
      } else {
        if (localStorage.getItem("userId")) {
          getCartList({
            url: `${process.env.REACT_APP_API_URL
              }/app/cart?user=${localStorage.getItem("userId")}`,
            //headers: authJsonHeaders,
            headers: {
              "Content-type": "application/json",
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
        }
      }
    }
  }, [createdCartId]);

  useEffect(() => {
    if (salonId) {
      getSalon({
        url: `${process.env.REACT_APP_API_URL}/app/salon/${salonId}`,
        headers: headers,
      });
    }
  }, [salonId]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      if (
        !localStorage.getItem("userId") &&
        localStorage.getItem("userId") &&
        salonId
      ) {
        createClick({
          url: `${process.env.REACT_APP_API_URL}/app/salon/click`,
          headers: headers,
          body: {
            salon: salonId,
            user: localStorage.getItem("userId"),
          },
        });
      }
    } else {
      if (salonId && !localStorage.getItem("userId")) {
        createClick({
          url: `${process.env.REACT_APP_API_URL}/app/salon/click`,
          headers: headers,
          body: {
            salon: salonId,
          },
        });
      }
    }
  }, [salonId]);

  useEffect(() => {
    if (salonId) {
      getProducts({
        url:
          `${process.env.REACT_APP_API_URL}/app/product/search?owner=salon&productType=Retail&status=1&isActive=true&salon=${salonId}&sort_by=order&sort_order=desc` +
          `${isProductMale ? "&genderType=Male" : ""}` +
          `${isProductFemale ? "&genderType=Female" : ""}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [salonId, isProductFemale, isProductMale]);

  useEffect(() => {
    if (salonId) {
      getServices({
        url:
          `${process.env.REACT_APP_API_URL}/app/service/search?status=1&isActive=true&salon=${salonId}&sort_by=order&sort_order=desc` +
          `${isMale ? "&genderType=Male" : ""}` +
          `${isFemale ? "&genderType=Female" : ""}` +
          `${isServices ? "&isService=true" : ""}` +
          `${isPackages ? "&isPackage=true" : ""}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [isMale, isFemale, isServices, isPackages, salonId, isHome, isSalon]);

  useEffect(() => {
    if (gotSalon != undefined && gotSalon != null && gotSalon != "") {
      setSalon(gotSalon);
      if (gotSalon.data.salon.status != 1) {
        setIsPopUp(true);
        setPopUpMessage("Salon is not live. Please use another salon.");
      }
      if (appOrQr == "qr") {
        sessionStorage.setItem("salonId", gotSalon.data.salon._id);
        sessionStorage.setItem("salonNameId", salonNameId.salonNameId);
      }
    }
  }, [gotSalon]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      if (
        gotGenericCart
      ) {
        if (
          gotGenericCart &&
          gotGenericCart.data.genericCart &&
          gotGenericCart.data.genericCart.cartCalculations.overall.totalItems >
          0
        ) {
          moveGenericCart({
            url: `${process.env.REACT_APP_API_URL
              }/app/genericCart/${localStorage.getItem("cartId")}/move`,
            //headers: authJsonHeaders,
            headers: {
              "Content-type": "application/json",
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: {},
          });
        } else {
          getCartList({
            url: `${process.env.REACT_APP_API_URL
              }/app/cart?user=${localStorage.getItem("userId")}`,
            //headers: authJsonHeaders,
            headers: {
              "Content-type": "application/json",
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
        }
      }
    }
  }, [gotGenericCart]);

  useEffect(() => {
    if (
      movedGenericCart
    ) {
      getCartList({
        url: `${process.env.REACT_APP_API_URL
          }/app/cart?user=${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      getGenericCart({
        url: `${process.env.REACT_APP_API_URL
          }/app/genericCart/${localStorage.getItem("cartId")}`,
        headers: headers,
      });

      localStorage.removeItem("cartId");
    }
  }, [movedGenericCart]);

  useEffect(() => {
    const serviceCategoryId = new Set();

    const serviceCategory = [];

    if (
      gotServices &&
      gotSalon
    ) {
      if (filterName === "SALON AT HOME" || filterName === "SALON") {
        setIsProducts(false);

        setServices(
          gotServices.data.services.filter((item) => {
            item.filterName = filterName;
            item.cartService = "";

            gotCartList &&
              gotCartList.data &&
              gotCartList.data.cart &&
              gotCartList.data.cart.services.forEach((cartItem) => {
                if (item._id === cartItem.service._id) {
                  item.cartService = cartItem;
                }
              });

            gotGenericCart &&
              gotGenericCart.data &&
              gotGenericCart.data.genericCart &&
              gotGenericCart.data.genericCart.services.forEach((cartItem) => {
                if (item._id === cartItem.service._id) {
                  item.cartService = cartItem;
                }
              });

            if (filterName === "SALON AT HOME") {
              // setServicesCategories(
              //   gotSalon.data.salon.serviceAtHomeCategory.sort((prev, after) => {

              //     if (item.category._id == prev._id) {
              //       return after.order - prev.order
              //     }

              //   })
              // );

              if (item.isHome) {
                if (!serviceCategoryId.has(item.category._id)) {
                  serviceCategoryId.add(item.category._id);
                  serviceCategory.push(item.category);
                }

                if (categoryAtHome === "Recommended") {
                  return item;
                }
                if (categoryAtHome === item.category.name) {
                  return item;
                }
              }
            }

            if (filterName === "SALON") {
              // setServicesCategories(
              //   gotSalon.data.salon.serviceAtSalonCategory.sort((prev, after) => {
              //     return after.order - prev.order
              //   })
              // );

              if (item.isSalon) {
                if (!serviceCategoryId.has(item.category._id)) {
                  serviceCategoryId.add(item.category._id);
                  serviceCategory.push(item.category);
                }

                if (categoryAtSalon === "Recommended") {
                  return item;
                }
                if (categoryAtSalon === item.category.name) {
                  return item;
                }
              }
            }
          })
        );
      }
    }

    setServicesCategories(
      serviceCategory.sort((prev, after) => {
        return after.order - prev.order;
      })
    );
  }, [
    filterName,
    categoryAtSalon,
    categoryAtHome,
    gotServices,
    gotCartList,
    gotGenericCart,
    gotSalon,
  ]);

  useEffect(() => {
    if (gotCartList) {
      if (gotCartList.data.cart) {
        if (gotCartList.data.cart.products.length > 0) {
          gotCartList.data.cart.products.forEach((item) => {
            if (item.count > item.product.currentStock) {
              if (item.product.currentStock == 0) {
                removeProduct({
                  url: `${process.env.REACT_APP_API_URL}/app/cart/item/remove?product=${item.product._id}&itemType=product`,
                  headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  body: {},
                });
              } else {
                addProduct({
                  url: `${process.env.REACT_APP_API_URL}/app/cart/item/add?product=${item.product._id}&count=${item.product.currentStock}&itemType=product`,
                  headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                  body: {},
                });
              }
            }
          });
        }
      }
    }
  }, [gotCartList]);

  useEffect(() => {
    if (gotGenericCart) {
      if (gotGenericCart.data.genericCart) {
        if (gotGenericCart.data.genericCart.products.length > 0) {
          gotGenericCart.data.genericCart.products.forEach((item) => {
            if (item.count > item.product.currentStock) {
              if (item.product.currentStock == 0) {
                removeGenericProduct({
                  url: `${process.env.REACT_APP_API_URL
                    }/app/genericCart/${localStorage.getItem(
                      "cartId"
                    )}/item/remove?product=${item.product._id}&itemType=product`,
                  headers: headers,
                  body: {},
                });
              } else {
                addGenericProduct({
                  url: `${process.env.REACT_APP_API_URL
                    }/app/genericCart/${localStorage.getItem(
                      "cartId"
                    )}/item/add?product=${item.product._id}&count=${item.product.currentStock
                    }&itemType=product`,
                  headers: headers,
                  body: {},
                });
              }
            }
          });
        }
      }
    }
  }, [gotGenericCart]);

  useEffect(() => {
    if (addedProduct || removedProduct) {
      getCartList({
        url: `${process.env.REACT_APP_API_URL
          }/app/cart?user=${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [addedProduct, removedProduct]);

  useEffect(() => {
    if (addedGenericProduct || removedGenericProduct) {
      getGenericCart({
        url: `${process.env.REACT_APP_API_URL
          }/app/genericCart/${localStorage.getItem("cartId")}`,
        headers: headers,
      });
    }
  }, [addedGenericProduct, removedGenericProduct]);

  useEffect(() => {
    if (
      gotProducts &&
      gotSalon
    ) {
      if (filterName === "PRODUCTS") {
        setIsProducts(true);

        setProducts(
          gotProducts.data.products.filter((item) => {
            setProductsCategories(
              gotSalon.data.salon.productCategory.sort((prev, after) => {
                return after.order - prev.order;
              })
            );

            item.cartProduct = "";
            gotCartList &&
              gotCartList.data.cart &&
              gotCartList.data.cart.products.forEach((cartItem) => {
                if (item._id === cartItem.product._id) {
                  item.cartProduct = cartItem;
                }
              });

            gotGenericCart &&
              gotGenericCart.data.genericCart &&
              gotGenericCart.data.genericCart.products.forEach((cartItem) => {
                if (item._id === cartItem.product._id) {
                  item.cartProduct = cartItem;
                }
              });

            if (productCategoryName === "Recommended") {
              return item;
            } else if (productCategoryName === item.category.name) {
              return item;
            }
          })
        );
      }
    }
  }, [
    gotSalon,
    filterName,
    productCategoryName,
    searchQuery,
    gotCartList,
    gotGenericCart,
    gotProducts,
  ]);

  useEffect(() => {
    if (
      gotSalon &&
      gotServices &&
      gotProducts &&
      gotGenericCart
    ) {
      setIsLoader(false);
    }
  }, [gotSalon && gotServices && gotProducts && gotGenericCart]);

  useEffect(() => {
    if (
      gotSalon &&
      gotServices &&
      gotProducts &&
      gotCartList
    ) {
      setIsLoader(false);
    }
  }, [gotSalon && gotServices && gotProducts && gotCartList]);

  useEffect(() => {
    if (
      (gotCartList &&
        gotCartList.data.cart &&
        gotCartList.data.cart.cartCalculations.overall.totalItems > 0) ||
      (gotGenericCart &&
        gotGenericCart.data.genericCart &&
        gotGenericCart.data.genericCart.cartCalculations.overall.totalItems > 0)
    ) {
      setIsProceed(true);
    } else {
      setIsProceed(false);
    }
  }, [gotCartList, gotGenericCart]);

  const openDeleteCart = () => {
    setIsDeleteCart(true);
    document.body.style.overflow = "hidden";
  };

  const closeDeleteCart = () => {
    document.body.style.overflow = "unset";
    setIsDeleteCart(false);
  };

  useEffect(() => {
    gtm.page();
  }, [])

  return (
    <>
      {isLoader ? (
        <>
          <div className="salon-page d-flex flex-column justify-content-between h-100">
            <>
              <div>
                <Skeleton className={"salon-image-skelton"} height={200} />
                <div className="d-flex justify-content-between align-items-center pt-3 px-3">
                  <Skeleton height={23} width={50} />
                  <Skeleton height={23} width={23} />
                </div>
              </div>

              <div className="p-3 pb-0">
                <Skeleton />
              </div>

              <div className="p-3 pb-0">
                <Skeleton />
              </div>

              <div className="p-3 pb-0">
                <Skeleton />
              </div>

              <div className="d-flex justify-content-between align-items-center p-3">
                <div className="service-name-skeleton d-flex flex-column">
                  <Skeleton height={23} width={70} />
                  <Skeleton height={23} width={70} />
                  <Skeleton height={23} width={70} />
                </div>
                <div>
                  <Skeleton width={125} height={125} />
                </div>
              </div>
            </>

            <Skeleton height={62} />
          </div>
        </>
      ) : (
        <>
          {gotSalon && gotSalon.data.salon.status == 1 ? (
            <>
              <div className="salon-page d-flex flex-column justify-content-between h-100">
                <div>
                  <SalonInfo
                    gotUserProfile={gotUserProfile}
                    setAmenitiesInfo={setAmenitiesInfo}
                    setIsShowAmenitiesInfo={setIsShowAmenitiesInfo}
                    setIsShowShareLikeSalon={setIsShowShareLikeSalon}
                    salon={salon}
                  />

                  {salon && salon.data && salon.data.salon.offers.length > 0 ? (
                    <OfferDeals
                      isLoader={isLoader}
                      salon={salon}
                      setIsShowOfferDetails={setIsShowOfferDetails}
                      setOfferDetails={setOfferDetails}
                    />
                  ) : (
                    ""
                  )}

                  <div className="filter-category-search-toggle-container">
                    {gotCartList || gotGenericCart ? (
                      <Filter
                        filterName={filterName}
                        setFilterToBeActive={setFilterToBeActive}
                        openDeleteCart={openDeleteCart}
                        setIsSalon={setIsSalon}
                        setIsHome={setIsHome}
                        isLoader={isLoader}
                        salon={salon}
                        gotGenericCart={gotGenericCart}
                        setPopUpMessage={setPopUpMessage}
                        gotCartList={gotCartList}
                        setFilterName={setFilterName}
                      />
                    ) : (
                      ""
                    )}

                    <>
                      <div
                        style={{ paddingBottom: isProducts ? "15px" : "0px" }}
                      >
                        <Search
                          isLoader={isLoader}
                          setIsSearchServices={setIsSearchServices}
                          setIsSearchProducts={setIsSearchProducts}
                          filterName={filterName}
                          setsearchQuery={setsearchQuery}
                          placeholder={
                            isProducts
                              ? "Search Salon Products"
                              : "Search Salon Services"
                          }
                        />
                      </div>

                      {isProducts ? (
                        <ProductMaleFemale
                          salon={salon}
                          setIsProductMale={setIsProductMale}
                          setIsProductFemale={setIsProductFemale}
                        />
                      ) : (
                        <ToggleServicesPackages
                          salon={salon}
                          setIsMale={setIsMale}
                          setIsFemale={setIsFemale}
                          setIsServices={setIsServices}
                          setIsPackages={setIsPackages}
                        />
                      )}
                    </>
                  </div>

                  <>
                    {isProducts ? (
                      <Products
                        isSearchProducts={isSearchProducts}
                        isProceed={isProceed}
                        isLoader={isLoader}
                        getGenericCart={getGenericCart}
                        setIsPopUp={setIsPopUp}
                        setPopUpMessage={setPopUpMessage}
                        salon={salon}
                        getCartList={getCartList}
                        productCategoryName={productCategoryName}
                        products={products}
                      />
                    ) : (
                      <Services
                        isSearchServices={isSearchServices}
                        isProceed={isProceed}
                        isLoader={isLoader}
                        getGenericCart={getGenericCart}
                        setIsPopUp={setIsPopUp}
                        setPopUpMessage={setPopUpMessage}
                        salon={salon}
                        getCartList={getCartList}
                        filterName={filterName}
                        categoryAtHome={categoryAtHome}
                        categoryAtSalon={categoryAtSalon}
                        services={services}
                        gotGenericCart={gotGenericCart}
                        gotCartList={gotCartList}
                        openDeleteCart={openDeleteCart}
                      />
                    )}
                  </>

                  {isShowBrowseSection ? (
                    ""
                  ) : (
                    <div
                      style={{ bottom: `${isProceed ? "69px" : "15px"}` }}
                      className="browse-menu d-flex align-items-center justify-content-center"
                    >
                      <button
                        onClick={() => {
                          setIsShowBrowseSection(true);
                          document.body.style.overflow = "hidden";
                        }}
                        className="border-0 d-flex align-items-center justify-content-center"
                      >
                        <RiMenu2Fill />
                        <p className="mb-0">Browse Menu</p>
                      </button>
                    </div>
                  )}
                </div>

                <>
                  {(gotCartList &&
                    gotCartList.data.cart &&
                    gotCartList.data.cart.cartCalculations.overall.totalItems >
                    0) ||
                    (gotGenericCart &&
                      gotGenericCart.data.genericCart &&
                      gotGenericCart.data.genericCart.cartCalculations.overall
                        .totalItems > 0) ? (
                    <Proceed
                      salon={salon}
                      gotCartList={gotCartList}
                      gotGenericCart={gotGenericCart}
                    />
                  ) : (
                    ""
                  )}
                </>
              </div>
              <>
                {isSearchProducts ? (
                  <SearchProduct
                    gotProducts={gotProducts}
                    isSearchProducts={isSearchProducts}
                    getGenericCart={getGenericCart}
                    setIsPopUp={setIsPopUp}
                    setPopUpMessage={setPopUpMessage}
                    salon={salon}
                    gotCartList={gotCartList}
                    gotGenericCart={gotGenericCart}
                    setIsSearchProducts={setIsSearchProducts}
                    getCartList={getCartList}
                    products={products}
                    setIsProductMale={setIsProductMale}
                    setIsProductFemale={setIsProductFemale}
                    isProceed={isProceed}
                  />
                ) : isSearchServices ? (
                  <SearchService
                    getGenericCart={getGenericCart}
                    setIsPopUp={setIsPopUp}
                    setPopUpMessage={setPopUpMessage}
                    salon={salon}
                    gotCartList={gotCartList}
                    gotGenericCart={gotGenericCart}
                    setIsSearchServices={setIsSearchServices}
                    getCartList={getCartList}
                    filterName={filterName}
                    setIsMale={setIsMale}
                    setIsFemale={setIsFemale}
                    setIsServices={setIsServices}
                    setIsPackages={setIsPackages}
                    categoryAtHome={categoryAtHome}
                    categoryAtSalon={categoryAtSalon}
                    gotServices={gotServices}
                    isSearchServices={isSearchServices}
                    isProceed={isProceed}
                    openDeleteCart={openDeleteCart}
                  />
                ) : (
                  ""
                )}
              </>

              {isShowAmenitiesInfo ? (
                <Amenitiesinfo
                  amenitiesInfo={amenitiesInfo}
                  setIsShowAmenitiesInfo={setIsShowAmenitiesInfo}
                />
              ) : (
                ""
              )}

              {isShowBrowseSection ? (
                <BrowseSection
                  categoryAtSalon={categoryAtSalon}
                  categoryAtHome={categoryAtHome}
                  setCategoryAtSalon={setCategoryAtSalon}
                  setCategoryAtHome={setCategoryAtHome}
                  filterName={filterName}
                  productCategoryName={productCategoryName}
                  isProducts={isProducts}
                  setProductCategoryName={setProductCategoryName}
                  productsCategories={productsCategories}
                  servicesCategories={servicesCategories}
                  setIsShowBrowseSection={setIsShowBrowseSection}
                />
              ) : (
                ""
              )}

              {isShowShareLikeSalon ? (
                <ShareLikeSalon
                  salon={salon}
                  setIsShowShareLikeSalon={setIsShowShareLikeSalon}
                />
              ) : (
                ""
              )}

              {isPopUp ? (
                <PopUp
                  isSearchServices={isSearchServices}
                  isSearchProducts={isSearchProducts}
                  setIsPopUp={setIsPopUp}
                  message={popUpMessage}
                />
              ) : (
                ""
              )}

              {isDeleteCart ? (
                <DeleteCart
                  setFilterName={setFilterName}
                  filterToBeActive={filterToBeActive}
                  getCartList={getCartList}
                  getGenericCart={getGenericCart}
                  popUpMessage={popUpMessage}
                  closeDeleteCart={closeDeleteCart}
                />
              ) : (
                ""
              )}

              {isShowOfferDetails ? (
                <ShowOfferDealDetails
                  setIsShowOfferDetails={setIsShowOfferDetails}
                  offerDetails={offerDetails}
                />
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <NoSalonActive />
              {isPopUp ? (
                <PopUp
                  isSearchServices={isSearchServices}
                  isSearchProducts={isSearchProducts}
                  setIsPopUp={setIsPopUp}
                  message={popUpMessage}
                />
              ) : (
                ""
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default Salon;
