import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// import InfiniteScroll from "react-infinite-scroller";

import Salon from "../../../components/salon";
import Loader from "../../../components/loader";
import NotFound from "../../../components/notfound";

function SalonsAround(props) {
  const [styleOnSalonsAround, setStyleOnSalonsAround] = useState({
    paddingBottom: "64px",
  });

  // const increasePage = useCallback(() => {
  //   props.increasePage();
  // }, []);

  // const handleOnScroll = useCallback(() => {
  //   const salonscontainer = document.getElementById("salons-container");

  //   let { scrollHeight } = salonscontainer;

  //   if (scrollHeight && scrollHeight != null && scrollHeight != undefined) {
  //     if (window.scrollY + window.innerHeight >= scrollHeight) {
  //       console.log(window.scrollY, window.innerHeight, scrollHeight);

  //       props.increasePage();
  //       console.log(props.salons)
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (
      (props.cart &&
        props.cart.data.cart &&
        props.cart.data.cart.cartCalculations.overall.totalItems > 0) ||
      (props.gotGenericCart &&
        props.gotGenericCart.data.genericCart &&
        props.gotGenericCart.data.genericCart.cartCalculations.overall
          .totalItems > 0)
    ) {
      setStyleOnSalonsAround({
        paddingBottom: "116px",
      });
    } else {
      setStyleOnSalonsAround({
        paddingBottom: "64px",
      });
    }
  }, [props]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleOnScroll);
  // }, []);

  // useEffect(()=>{
  //   return ()=>{window.removeEventListener("scroll", handleOnScroll)}
  // }, [])

  // useEffect(() => {
  //   // const salonscontainer = document.getElementById("salons-container");

  //   // console.log(salonscontainer)

  //   console.log(document.getElementById("salons-container"))

  //   // let { scrollHeight } = salonscontainer;

  //   // if (scrollHeight && scrollHeight != null && scrollHeight != undefined) {
  //   //   if (window.scrollY + window.innerHeight >= scrollHeight) {
  //   //     console.log(window.scrollY, window.innerHeight, scrollHeight);

  //   //     // props.increasePage();
  //   //     // console.log(props.salons);

  //   //     handleOnScroll()
  //   //   }
  //   // }
  // }, []);

  // const handleOnScroll = () => {

  //   const scrollTop = document.documentElement.scrollTop;
  //   const scrollHeight = document.documentElement.scrollHeight;
  //   const clientHeight = document.documentElement.clientHeight;

  //   console.log(scrollTop, clientHeight, scrollHeight)

  //   if (scrollTop + clientHeight+5 >= scrollHeight) {

  //     props.increasePage()
  //   }

  // console.log(true);

  // const salonscontainer = document.getElementById("salons-container");

  // // console.log(salonscontainer)

  // console.log(document.getElementById("salons-container"));

  // let { scrollHeight } = salonscontainer;

  // if (scrollHeight && scrollHeight != null && scrollHeight != undefined) {

  // console.log(window.scrollY, window.innerHeight, window.scrollHeight);
  // if (window.scrollY + window.innerHeight >= window.scrollHeight) {
  //   // props.increasePage();
  //   console.log(props.salons);

  //   // handleOnScroll()
  //   // }
  // }
  // };

  // useEffect(() => {
  //   // const elem = document.documentElement;

  //   // elem.addEventListener("scroll", handleOnScroll)
  //   // console.log(elem)
  //   window.addEventListener("scroll", handleOnScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleOnScroll);
  //   };
  // }, [props.salonsAround]);

  return (
    <div style={styleOnSalonsAround} className="salons-around-container">
      <div className="title">
        <p className="mb-0">
          {props.salons && props.salons.data && props.salons.data.totalResults}{" "}
          Salons Around You
        </p>
      </div>

      {props.salons && props.salons.data ? (
        <>
          {props.salons.data.totalResults > 0 ? (
            <div className="salons">
              <InfiniteScroll
                dataLength={props.salonsAround.length}
                next={props.increasePage}
                hasMore={true}
                loader={
                  props.isScrollLoader ? (
                    <Loader width={60} height={60} color={"#772286"} />
                  ) : (
                    ""
                  )
                }
                scrollThreshold={0.5}
              >
                <Salon
                  salons={props.salonsAround}
                  bottomBanners={props.bottomBanners}
                  middleBanners={props.middleBanners}
                />
              </InfiniteScroll>
            </div>
          ) : (
            <NotFound />
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default SalonsAround;
