import React, { useState } from "react";
import { useEffect, useCallback } from "react";

import useUpdateData from "../../../customhooks/updatedata";

import { authJsonHeaders, headers } from "../../../constants";

function Discard(props) {

  const [empited, emptyError, emptyData] = useUpdateData();

  const [animate, setAnimate] = useState({});

  useEffect(() => {
    setAnimate({
      transform: "translateY(0%)",
    });
  }, []);

  const callback = useCallback(() => {
    document.body.style.overflow = "unset";
  });

  const handleClickOnDiscard = () => {
    if (localStorage.getItem("userId")) {
      const emptyCart = async () => {
        const res = await emptyData({
          url: `${process.env.REACT_APP_API_URL}/app/cart/empty`,
          //headers: authJsonHeaders,
          headers: {
            "Content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: {},
        });

        return Promise.resolve(res);
      };

      const p1 = emptyCart();

      p1.then((res) => {
        if (res) {

          const p1 = props.getCartList();

          p1.then((res) => {
            if (res) {
              props.setCart(res)
            }
          });

          props.closeDiscard();
        }
      });
    } else {
      const emptyGenericCart = async () => {
        const res = emptyData({
          url: `${
            process.env.REACT_APP_API_URL
          }/app/genericCart/${localStorage.getItem("cartId")}/empty`,
          headers: headers,
          body: {},
        });

        return Promise.resolve(res);
      };

      const p1 = emptyGenericCart();

      p1.then((res) => {
        if (res) {
          props.closeDiscard();
          const p1 = props.getGenericCart();

          p1.then((res) => {
            if (res) {
              props.setGenericCart(res)
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    return callback;
  }, []);

  const handleClickOnBack = () => {
    setAnimate({
      transform: "translateY(100%)",
    });
    setTimeout(() => {
      props.closeDiscard();
    }, 1000);
  };

  return (
    <div
      style={animate}
      className="discard-section-container d-flex justify-content-center align-items-center"
    >
      <div className="discard-section d-flex flex-column justify-content-center align-items-center">
        <p className="mb-0 message">Discard all the items in your cart?</p>
        <div className="d-flex justify-content-between align-items-center w-100">
          <button
            onClick={() => {
              handleClickOnBack();
            }}
            className="cancel"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleClickOnDiscard();
            }}
            className="discard"
          >
            Discard
          </button>
        </div>
      </div>
    </div>
  );
}

export default Discard;
