import React, { useState } from "react";

import { AiFillCaretRight } from 'react-icons/ai'

function Faqs() {

    const [isShowDescription, setIsShowDescription] = useState([])

    const [applyStyleOnImage, setApplyStyleOnImage] = useState({
        transform: "rotate(90deg)"
    })

    const defaultStyleOnImage = {
        transform: "rotate(0deg)"
    }

    const handleClickOnFaq = (faq) => {

        if (isShowDescription.includes(faq)) {
            setIsShowDescription(
                isShowDescription.filter((item) => {
                    if (item != faq) {
                        return item
                    }

                })
            )
        }
        else {
            setIsShowDescription((prev) => [...prev, faq])
        }

    }

    return <div className="faqs-sub-section">
        <div className="faqs-section">
            <div className="title">
                <h3>FAQs</h3>
            </div>
            <div className="faqs-container">
                <div>
                    <button onClick={() => { handleClickOnFaq(1) }} className="border-0">
                        <div style={isShowDescription.includes(1) ? applyStyleOnImage : defaultStyleOnImage} className="image-container">
                            <AiFillCaretRight />
                        </div>
                        <p className="mb-0">What is a Salon Management Software?</p>
                    </button>
                    {
                        isShowDescription.includes(1) ? <div className="description">
                            <p className="mb-0">Salon management softwares is an effective tool for salon owners to manage their business operations efficiently, especially during peak hours. Salon software helps salon professionals to easily reschedule, cancel or take in new appointments, manage revenue, create a digital catalog and market their services with minimum human resources.</p>
                        </div> : ""
                    }
                </div>
                <div>
                    <button onClick={() => { handleClickOnFaq(2) }} className="border-0">
                        <div style={isShowDescription.includes(2) ? applyStyleOnImage : defaultStyleOnImage} className="image-container">
                            <AiFillCaretRight />
                        </div>
                        <p className="mb-0">How do I choose a good salon and spa software?</p>
                    </button>
                    {
                        isShowDescription.includes(2) ? <div className="description">
                            <p className="mb-0">Before purchasing a salon and spa management software, you should primarily ensure that it provides a user-friendly experience for you and your staff. In addition, it is important to check the availability of customer support, an easy-to-use POS and digital catalog, and whether or not the salon software provides a central online booking and appointment system.</p>
                        </div> : ""
                    }
                </div>
                <div>
                    <button onClick={() => { handleClickOnFaq(3) }} className="border-0">
                        <div style={isShowDescription.includes(3) ? applyStyleOnImage : defaultStyleOnImage} className="image-container">
                            <AiFillCaretRight />
                        </div>
                        <p className="mb-0">I am not good with technology, will I be able to operate Groome Salon and Spa Management Software?</p>
                    </button>
                    {
                        isShowDescription.includes(3) ? <div className="description">
                            <p className="mb-0">With the salon industry becoming more tech-oriented, salon management software are the need of the hour. They help manage your salons and spas more efficiently and effectively, helping save time and money while improving overall operational quality.</p>
                        </div> : ""
                    }
                </div>
                <div>
                    <button onClick={() => { handleClickOnFaq(4) }} className="border-0">
                        <div style={isShowDescription.includes(4) ? applyStyleOnImage : defaultStyleOnImage} className="image-container">
                            <AiFillCaretRight />
                        </div>
                        <p className="mb-0">Is it necessary to use Salon management software in 2023?</p>
                    </button>
                    {
                        isShowDescription.includes(4) ? <div className="description">
                            <p className="mb-0">With the salon industry becoming more tech-oriented, salon management software are the need of the hour. They help manage your salons and spas more efficiently and effectively, helping save time and money while improving overall operational quality.</p>
                        </div> : ""
                    }
                </div>

                <div>
                    <button onClick={() => { handleClickOnFaq(5) }} className="border-0">
                        <div style={isShowDescription.includes(5) ? applyStyleOnImage : defaultStyleOnImage} className="image-container">
                            <AiFillCaretRight />
                        </div>
                        <p className="mb-0">How can Groome Salon and Spa software help me increase my revenue?</p>
                    </button>
                    {
                        isShowDescription.includes(5) ? <div className="description">
                            <p className="mb-0">With Groome, you’ll have access to every feature you need to run a successful salon or spa business. With our suite of powerful salon software tools, you can directly endorse your products and services, offer loyalty promotions, and run marketing campaigns on WhatsApp – all critical factors for a profitable salon business. Take a free demo and see it for yourself.</p>
                        </div> : ""
                    }
                </div>

            </div>
        </div>
    </div>
}

export default Faqs