import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import usePostData from "../../../../customhooks/postdata";
import useIsLoader from "../../../../customhooks/useisloader";

import Loader from "../../../../components/loader";

import {
  isName,
  isMobile,
  isCity,
  isEmail,
  isBusinessName,
} from "../../../../functions";

import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

// import Analytics from "analytics";

// import googleTagManager from "@analytics/google-tag-manager";
import { gtm } from "../../../gtm";
import postRequest from "../../../../functions/apicall/postrequest";

function ScheduleForm(props) {
  const captcha = createRef();

  const navigate = useNavigate();

  const [nameMessage, setNameMessage] = useState("");
  const [phoneMessage, setPhoneMessage] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [BusinessNameMessage, setBusinessNameMessage] = useState("");
  const [cityMessage, setCityMessage] = useState("");

  const [captchaMessage, setCaptchaMessage] = useState("");

  const [userInfo, setUserInfo] = useState({
    name: "",
    phone: "",
    email: "",
    businessName: "",
    city: "",
  });

  const [isNameTrue, setIsNameTrue] = useState(false);
  const [isPhoneTrue, setIsPhoneTrue] = useState(false);
  const [isBusinessNameTrue, setIsBusinessNameTrue] = useState(false);
  const [isCityTrue, setIsCityTrue] = useState(false);

  const [isLoader, setIsloader] = useIsLoader(false);

  const [createdLead, leadError, createLead] = usePostData();

  const handleOnChangeName = (e) => {
    let nameTobeCheck = e.target.value.split(" ").join("-");

    if (e.target.value.length == 0) {
      setIsNameTrue(false);
      setNameMessage("");
      setUserInfo({ ...userInfo, name: "" });
    } else if (e.target.value.length > 0) {
      if (isName(e.target.value.split(" ").join(""))) {
        setIsNameTrue(true);
        setNameMessage("");
        setUserInfo({ ...userInfo, name: nameTobeCheck.split("-").join(" ") });
      } else {
        setNameMessage("Please enter valid Name");
        setIsNameTrue(false);
      }
    }
  };

  const handleOnChangeBusinessName = (e) => {
    let nameTobeCheck = e.target.value.split(" ").join("-");

    if (e.target.value.length == 0) {
      setIsBusinessNameTrue(false);
      setBusinessNameMessage("");
      setUserInfo({ ...userInfo, businessName: "" });
    } else if (e.target.value.length > 0) {
      if (isBusinessName(e.target.value.split(" ").join(""))) {
        setIsBusinessNameTrue(true);
        setBusinessNameMessage("");
        setUserInfo({
          ...userInfo,
          businessName: nameTobeCheck.split("-").join(" "),
        });
      } else {
        setIsBusinessNameTrue(false);
        setBusinessNameMessage("Please enter valid Business Name");
      }
    }
  };

  const handleOnChangeCity = (e) => {
    let cityTobeCheck = e.target.value.split(" ").join("-");

    if (e.target.value.length == 0) {
      setIsCityTrue(false);
      setCityMessage("");
      setUserInfo({ ...userInfo, city: "" });
    } else if (e.target.value.length > 0) {
      if (isCity(e.target.value.split(" ").join(""))) {
        setIsCityTrue(true);
        setCityMessage("");
        setUserInfo({ ...userInfo, city: cityTobeCheck.split("-").join(" ") });
      } else {
        setIsCityTrue(false);
        setCityMessage("Please enter valid City");
      }
    }
  };

  const handleOnChangePhone = (e) => {
    if (e.target.value.length == 0) {
      setIsPhoneTrue(false);
      setPhoneMessage("");
      setUserInfo({ ...userInfo, phone: "" });
    } else if (e.target.value.length > 0) {
      if (isMobile(e.target.value)) {
        setIsPhoneTrue(true);
        setPhoneMessage("");
        setUserInfo({ ...userInfo, phone: e.target.value });
      } else {
        setIsPhoneTrue(false);
        setPhoneMessage("Please enter valid Phone");
      }
    }
  };

  const handleClickOnSubmit = async () => {

    // if (captcha.current.getValue()) {
    if (!isNameTrue) {
      setNameMessage("Please enter valid Name");
    } else {
      setNameMessage("");
    }
    if (!isPhoneTrue) {
      setPhoneMessage("Please enter valid Phone");
    } else {
      setPhoneMessage("");
    }
    if (!isEmail(userInfo.email)) {
      setEmailMessage("Please enter valid Email");
    } else {
      setEmailMessage("");
    }
    if (!isBusinessNameTrue) {
      setBusinessNameMessage("Please enter valid Business Name");
    } else {
      setBusinessNameMessage("");
    }
    if (!isCityTrue) {
      setCityMessage("Please enter valid City");
    } else {
      setCityMessage("");
    }
    if (
      isNameTrue &&
      isPhoneTrue &&
      isEmail(userInfo.email) &&
      isBusinessNameTrue &&
      isCityTrue
    ) {
      setIsloader(true);

      const res = await postRequest({
        url: `${process.env.REACT_APP_API_URL}/app/lead`,
        body: userInfo,
      });

      if (res) {
        setIsNameTrue(false);
        setIsPhoneTrue(false);
        setIsBusinessNameTrue(false);
        setIsCityTrue(false);

        setUserInfo({
          name: "",
          phone: "",
          email: "",
          businessName: "",
          city: "",
        });

        gtm.track("buttonClick");

        // sessionStorage.setItem("form-submitted", true);

        // navigate("/business/form-submitted");

        props.setIsPopUp(true);
        props.setPopUpMessage(
          "Your request has been submitted. We will contact you as soon as possible."
        );
      }

      setIsloader(false);
    }
    // } else {
    //   setCaptchaMessage("Please check captcha");
    // }
  };

  return (
    <div id="create-free-account" className="create-free-account-form-section">
      <div className="schedule-form-sub-section d-flex justify-content-end">
        <div className="schedule-form">
          <div className="title-paragraph-container">
            <h4 className="mb-0 title">Create Free Account</h4>

            <p className="paragraph">
              Fill this form and we will verify your details and connect with
              you within in 24 hours for your account.
            </p>
          </div>

          <div className="inputs-container d-flex flex-column">
            <div>
              {nameMessage ? (
                <p className="message">{nameMessage}</p>
              ) : (
                <p className="message"></p>
              )}
              <input
                onChange={(e) => {
                  handleOnChangeName(e);
                }}
                value={userInfo.name}
                type="text"
                placeholder="Name"
                required
              />
            </div>

            <div>
              {phoneMessage ? (
                <p className="message">{phoneMessage}</p>
              ) : (
                <p className="message"></p>
              )}
              <input
                onChange={(e) => {
                  handleOnChangePhone(e);
                }}
                value={userInfo.phone}
                type="text"
                placeholder="Phone"
                maxLength={12}
                required
              />
            </div>

            <div>
              {emailMessage ? (
                <p className="message">{emailMessage}</p>
              ) : (
                <p className="message"></p>
              )}
              <input
                onChange={(e) => {
                  setUserInfo({ ...userInfo, email: e.target.value });
                  setEmailMessage("");
                }}
                value={userInfo.email}
                type="text"
                placeholder="Email"
                required
              />
            </div>

            <div>
              {BusinessNameMessage ? (
                <p className="message">{BusinessNameMessage}</p>
              ) : (
                <p className="message"></p>
              )}
              <input
                onChange={(e) => {
                  handleOnChangeBusinessName(e);
                }}
                value={userInfo.businessName}
                type="text"
                placeholder="Business Name"
                required
              />
            </div>

            <div>
              {cityMessage ? (
                <p className="message">{cityMessage}</p>
              ) : (
                <p className="message"></p>
              )}
              <input
                onChange={(e) => {
                  handleOnChangeCity(e);
                }}
                value={userInfo.city}
                type="text"
                placeholder="City"
              />
            </div>



            {/* onChange={onChange} */}

            {/* <div>

              {captchaMessage ? (
                <p className="message">{captchaMessage}</p>
              ) : (
                <p className="message"></p>
              )}
              <ReCAPTCHA
                sitekey="6Lf-NX0kAAAAAL2ZCJhigmEE1YBSVKl4T43M8krD"
                ref={captcha}
              />
            </div> */}

            {isLoader ? (
              <button className="loader-button">
                <Loader color="white" width={20} height={20} />
              </button>
            ) : (
              <button
                className="submit-button-business-page"
                onClick={() => {
                  handleClickOnSubmit();
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleForm;
