import React from "react";

function Amenities(props) {

    const handleClickOnAmenity = (item) => {
        props.setIsShowAmenitiesInfo(true);
        props.setAmenitiesInfo({
            img: item.img,
            amenityName: item.amenityName,
            amenityDescription: item.amenityDescription
        })
        document.body.style.overflow = "hidden"
    }

    return (
        <>{props.salon && (props.salon.data.salon.amenities.isAC ||
            props.salon.data.salon.amenities.isParkingAvailable ||
            props.salon && props.salon.data.salon.amenities.isToilets ||
            props.salon.data.salon.amenities.isCardPayment) ?
            <div className="icons-container d-flex">
                {props.salon && props.salon.data.salon.amenities.isAC ?
                    <button onClick={() => {
                        handleClickOnAmenity({
                            img: "/assets/salon/ac.svg",
                            amenityName: "AC",
                            amenityDescription: "Air Conditioned"
                        })
                    }}>
                        <img src="/assets/salon/ac.svg" alt="ac" />
                    </button> : ""}

                {props.salon && props.salon.data.salon.amenities.isCardPayment ?
                    <button onClick={() => {
                        handleClickOnAmenity({
                            img: "/assets/salon/card.svg",
                            amenityName: "Card",
                            amenityDescription: "Accept Card Payments"
                        })
                    }}>
                        <img src="/assets/salon/card.svg" alt="card" />
                    </button> : ""}

                {props.salon && props.salon.data.salon.amenities.isParkingAvailable ?
                    <button onClick={() => {
                        handleClickOnAmenity({
                            img: "/assets/salon/parking.svg",
                            amenityName: "Parking",
                            amenityDescription: "Parking Available"
                        })
                    }}>
                        <img src="/assets/salon/parking.svg" alt="parking" />
                    </button> : ""}

                {props.salon && props.salon.data.salon.amenities.isToilets ?
                    <button onClick={() => {
                        handleClickOnAmenity({
                            img: "/assets/salon/washroom.svg",
                            amenityName: "Washroom",
                            amenityDescription: "Toilets Available"
                        })
                    }}>
                        <img src="/assets/salon/washroom.svg" alt="washroom" />
                    </button> : ""}
                {props.salon && props.salon.data.salon.amenities.isUber ?
                    <button onClick={() => {
                        handleClickOnAmenity({
                            img: "/assets/common/uber.svg",
                            amenityName: "Uber",
                            amenityDescription: "FREE Uber ride for Salon appointments. Get upto 20% from your bill amount on showing your Uber Bill."
                        })
                    }}>
                        <img src="/assets/common/uber.svg" alt="uber" />
                    </button> : ""}

                {/* {props.salon && props.salon.data.salon.amenities.isUber ?
                    <a href="https://www.uber.com/">
                        <img src="/assets/common/uber.svg" alt="uber" />
                    </a> : ""} */}

            </div> : ""}</>
    );
}

export default Amenities;
