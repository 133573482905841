import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Rating from "../../../components/rating";

function Salons(props) {

    return (
        <>
            <div className="salons-container d-flex flex-column">
                <p className="mb-0 title">Explore All Outlets</p>
                {props.salons.map((item) => {
                    return <Link to={`/qr/salon/${item.name.toLowerCase().replace(" ", "-")}-${item._id}`} key={item._id} className="salon-container">

                        <div className="salon-sub-container d-flex">
                            {item.logo ? <div className="image-container">
                                <img
                                    src={item.logo ? `${process.env.REACT_APP_IMAGE_URL}${item.logo}` : "/assets/common/serviceproduct.png"}
                                    alt="Salon"
                                />
                            </div> : ""}

                            <div className="info-container d-flex flex-column align-items-start justify-content-center">
                                <p className="mb-0 name">{item.name}</p>
                                <p className="mb-0 address">{item.address.city}, {item.address.state}</p>
                                <p className="mb-0 timing">Timing: {item.timings[moment(new Date()).day()]}</p>
                                {item.rating > 0 ?
                                    <div className="star-rating-container">
                                        <Rating rating={Math.round(item.rating)} />
                                    </div> : ""}
                            </div>
                        </div>
                        <div className="distance-container d-flex align-items-center">
                            <img src="/assets/common/marker.svg" />
                            <p className="mb-0">Approx distance from you is {parseInt(item.distanceInKm)} Km</p>
                        </div>
                    </Link>
                })}
            </div>
        </>
    );
}

export default Salons
