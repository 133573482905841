import React from "react";

import { Outlet, Navigate } from "react-router-dom";

function SlotPaymentPrivateroutes() {
  return (
    <>
      {sessionStorage.getItem("slotPayment") ? (
        <>
          <Outlet />
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
}

export default SlotPaymentPrivateroutes;
