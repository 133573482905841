import React, { useState } from "react";
import { useEffect, useCallback } from "react";

import useUpdateData from "../../../customhooks/updatedata";

import { authJsonHeaders, headers } from "../../../constants";

function DeleteCart(props) {
  const [empited, emptyError, emptyCart] = useUpdateData();

  const [
    empitedGenericCart,
    emptyGenericCartError,
    emptyGenericCart,
  ] = useUpdateData();

  const [animate, setAnimate] = useState({});

  useEffect(() => {
    setAnimate({
      transform: "translateY(0%)",
    });
  }, []);

  const callback = useCallback(() => {
    document.body.style.overflow = "unset";
  });

  const handleClickOnDiscard = () => {
    if (localStorage.getItem("userId")) {
      emptyCart({
        url: `${process.env.REACT_APP_API_URL}/app/cart/empty`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: {},
      });
    }

    if (!localStorage.getItem("userId")) {
      emptyGenericCart({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/genericCart/${localStorage.getItem("cartId")}/empty`,
        headers: headers,
        body: {},
      });
    }
  };

  useEffect(() => {
    if (empited != undefined && empited != "" && empited != "") {
      if (props.filterToBeActive == "SALON AT HOME") {
        props.setFilterName("SALON AT HOME");
      }
      if (props.filterToBeActive == "SALON") {
        props.setFilterName("SALON");
      }

      props.closeDeleteCart();
      props.getCartList({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/cart?user=${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }

    return callback;
  }, [empited]);

  useEffect(() => {
    if (
      empitedGenericCart != undefined &&
      empitedGenericCart != "" &&
      empitedGenericCart != ""
    ) {
      if (props.filterToBeActive == "SALON AT HOME") {
        props.setFilterName("SALON AT HOME");
      }
      if (props.filterToBeActive == "SALON") {
        props.setFilterName("SALON");
      }

      props.closeDeleteCart();
      props.getGenericCart({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/genericCart/${localStorage.getItem("cartId")}`,
        headers: headers,
      });
    }

    return callback;
  }, [empitedGenericCart]);

  const handleClickOnBack = () => {
    setAnimate({
      transform: "translateY(100%)",
    });
    setTimeout(() => {
      props.closeDeleteCart();
    }, 1000);
  };

  return (
    <div
      style={animate}
      className="delete-cart-section-container d-flex justify-content-center align-items-center"
    >
      <div className="delete-cart-section d-flex flex-column justify-content-center align-items-center">
        <p className="mb-0 message">{props.popUpMessage}</p>
        <div className="d-flex justify-content-between align-items-center w-100">
          <button
            onClick={() => {
              handleClickOnBack();
            }}
            className="cancel"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleClickOnDiscard();
            }}
            className="delete-cart"
          >
            remove
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteCart;
