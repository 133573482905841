import React from "react";
import { useNavigate } from "react-router-dom";

import { IoLogoWhatsapp } from "react-icons/io";

function Header(props) {
  const navigate = useNavigate();

  // const handleClick = () => {
  //   if (props.isPaymentFormembership) {
  //     props.setIsPayment(false)
  //   }
  //   else {
  //     props.setIsProceed(false)

  //     if (props.setTime) {
  //       props.setTime("")
  //     }
  //   }

  // }

  return (
    <div className="header-container d-flex align-items-center justify-content-between">
      <button
        onClick={() => {
          navigate(-1);
        }}
        className="border-0 bg-white"
      >
        <img src="/assets/common/back.svg" alt="Back" />
      </button>
      <p className="mb-0">
        {props.isPaymentFormembership == "true" ? (
          <>Payment({props.planInfo.price})</>
        ) : (
          <>
            Payment(
            {props.gotCartList &&
            props.gotCartList.data.cart &&
            props.gotCartList.data.cart.services.length > 0
              ? String.fromCharCode(
                  props.gotCartList &&
                    props.gotCartList.data.cart &&
                    props.gotCartList.data.cart.services[0].service
                      .currencySymbol
                )
              : props.gotCartList &&
                props.gotCartList.data.cart &&
                props.gotCartList.data.cart.products.length > 0
              ? String.fromCharCode(
                  props.gotCartList &&
                    props.gotCartList.data.cart &&
                    props.gotCartList.data.cart.products[0].product
                      .currencySymbol
                )
              : ""}
            {Math.round(
              props.gotCartList &&
                props.gotCartList.data.cart &&
                props.gotCartList.data.cart.cartCalculations.overall.grandTotal
            )}
            )
          </>
        )}
      </p>
      <div className="whatsapp-image">
        <a href="//api.whatsapp.com/send?phone=918884877662&text=">
          <IoLogoWhatsapp />
        </a>
      </div>
    </div>
  );
}

export default Header;
