import React, { useEffect, useCallback, useState } from "react";

function Amenitiesinfo(props) {

    const callback = useCallback(()=>{
        document.body.style.overflow = "unset"
    })


    const [animate, setAnimate] = useState({})

    useEffect(() => {
      setAnimate({
        transform: "translateY(0%)",
      });
    }, []);

    useEffect(() => {
        return callback
    }, []);


    const handleClickOnBack = () => {
        setAnimate({
            transform: "translateY(100%)"
        })
        setTimeout(() => {
            props.setIsShowAmenitiesInfo(false)
        }, 1000)
    }

    return (
        <div style={animate} className="amenities-info-page d-grid">
            <div onClick={() => handleClickOnBack()} className="cancel-container d-flex align-items-end justify-content-center">
                <div className="cancel d-flex align-items-center justify-content-center">
                    <img src="/assets/common/cancel.svg" />
                </div>
            </div>
            <div className="amenities-info-container bg-white">
                <div className="amenity-image-name d-flex align-items-center">
                    <img src={`${props.amenitiesInfo.img}`}/>
                    <p className="mb-0">{props.amenitiesInfo.amenityName}</p>
                </div>
                <div className="amenity-description">
                    <p className="mb-0">{props.amenitiesInfo.amenityDescription}</p>
                </div>
            </div>
        </div>
    );
}

export default Amenitiesinfo;
