import { useCallback, useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";

function Logout(props) {

  const navigate = useNavigate()

  const callback = useCallback(() => {
    document.body.style.overflow = "unset";
  })

  const [animate, setAnimate] = useState({})

  useEffect(() => {
    setAnimate({
      transform: "translateY(0%)",
    });
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return callback
  })

  const handleClickOnLogout = () => {
    if (sessionStorage.getItem("isQrCode") == "true") {
      localStorage.clear()
      sessionStorage.removeItem("lat")
      sessionStorage.removeItem("lng")
      sessionStorage.removeItem("city")
      sessionStorage.removeItem("country")
    }
    else {
      sessionStorage.clear()
      localStorage.clear()
    }

    document.body.style.overflow = "unset";
    props.setIsShowLogout(false);
    navigate("/login")
  };

  const handleClickOnBack = () => {
    setAnimate({
      transform: "translateY(100%)"
    })
    setTimeout(() => {
      props.setIsShowLogout(false);
    }, 1000)
  }


  return (
    <div style={animate} className="logout-section-container d-flex justify-content-center align-items-center">
      <div className="logout-section d-flex flex-column justify-content-center align-items-center">
        <p className="mb-0 message">Are you sure you want to Logout?</p>
        <div className="d-flex justify-content-between align-items-center w-100">
          <button
            onClick={() => {
              handleClickOnBack()
            }}
            className="cancel"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              handleClickOnLogout();
            }}
            className="logout"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default Logout;
