import React, { useEffect, useRef, useState } from "react";

const SelectCountry = (props) => {

    const [searchValue, setSearchValue] = useState("")

    const ref = useRef()

    useEffect(()=>{
        ref.current.focus()
    },[])

    return (
        <>
            <div className="select-country-section">
                <div className="country-search-container d-flex align-items-center">
                    <img src="/assets/common/search_icon.svg" />
                    <input ref={ref} onChange={(e) => { props.createFilterArray(e.target.value); setSearchValue(e.target.value) }} placeholder="Search country" />
                </div>
                {props.countries.map((item, i) => {
                    return (
                        <div
                            className="country"
                            key={i}

                        >
                            <button className="bg-white border-0 d-flex align-items-center" onClick={() => {
                                props.setCountryId(item._id);
                                props.setPhoneCode(item.phoneCode)
                                props.setIsOpenCountriesDropDown(false)
                                props.setCountryCode(item.acronym)
                            }}>
                                <p className="mb-0">
                                    {props.getFlagEmoji(item.acronym)}
                                </p>
                                <p className="mb-0 ps-2">{item.name}</p>
                                <p className="mb-0 ps-2">+{item.phoneCode}</p>
                            </button>
                            
                        </div>
                    )
                })}

                {searchValue.length > 0 && props.countries.length == 0 ? <p className="message mb-0">No country found</p> : ""}
            </div>
        </>
    );
};
export default SelectCountry;