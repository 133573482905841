import React, { useCallback, useEffect } from "react";
import Oops from "./oops";
import Header from "./header";
import { Link, useLocation, useParams } from "react-router-dom";
import { Browser } from "@capacitor/browser";
import { gtm } from "../gtm";

function OrderFailed(props) {
  useEffect(() => {
    window.close();
    Browser.close();
  }, []);

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <div className="payment-failed-page">
      <div className="girl-image-container">
        <img src="/assets/paymentfailed/girl.svg" />
      </div>
      <Oops />
    </div>
  );
}

export default OrderFailed;
