import React from "react";

import { useNavigate } from "react-router-dom";

function Header(props) {
  const navigate = useNavigate();

  return (
    <div className="header-container">
      <div className="header d-flex align-items-center">
        <div>
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="border-0 bg-white"
          >
            <img src="/assets/common/back.svg" alt="Down" />
          </button>
        </div>
        <div className="w-100 text-center">
          <p className="mb-0">


            {props.gotOrderDetails &&
              props.gotOrderDetails.data.order.salon &&
              props.gotOrderDetails.data.order.salon.name}
          </p>
        </div>
      </div>
      <div className="order-id-container d-flex justify-content-between align-items-center">
        <div className="order-id d-flex align-items-center">
          <p className="mb-0">Order#:</p>
          <p className="mb-0">
            {props.orderIdItemType.itemType && props.orderIdItemType.itemType == "service" ? props.gotOrderDetails && props.gotOrderDetails.data.order.serviceOrders.orderId : props.orderIdItemType.itemType && props.orderIdItemType.itemType == "product" ? props.gotOrderDetails && props.gotOrderDetails.data.order.productOrders.orderId : ""}
          </p>
        </div>
        <div>
          <p className="amount mb-0">

            {String.fromCharCode(props.gotOrderDetails && props.gotOrderDetails.data.order.salon.country.htmlSymbol)}{props.orderIdItemType.itemType && props.orderIdItemType.itemType == "service" ? Math.round(props.gotOrderDetails && props.gotOrderDetails.data.order.cartCalculations.services.grandTotal) : Math.round(props.gotOrderDetails && props.gotOrderDetails.data.order.cartCalculations.products.grandTotal)}
          </p>
        </div> 
      </div>
    </div>
  );
}

export default Header;
