import React, { useCallback, useEffect, useState } from "react";

import { CgClose } from "react-icons/cg"

import AtHomeCategories from "./athomecategories";
import AtSalonCategories from "./atsaloncategories";
import ProductCategories from "./productcategories";

function BrowseSection(props) {

    const [animate, setAnimate] = useState({})

    useEffect(() => {
      setAnimate({
        transform: "translateY(0%)",
      });
    }, []);

    const callback = useCallback(()=>{
        document.body.style.overflow = "unset"
    })

    useEffect(() => {
        return callback
    }, []);
  
    const handleClickOnBack = () => {
      setAnimate({
        transform: "translateY(100%)"
      })
      setTimeout(() => {
        props.setIsShowBrowseSection(false)
      }, 1000)
    }

    return (
        <div style={animate} className="browse-section-page d-flex align-items-end justify-content-center">
            <div className="w-100">
                <div className="category-section bg-white text-center">
                    {/* <div className="title"><p className="mb-0">Menu</p></div> */}

                    {props.isProducts ? (
                        <ProductCategories
                            setProductCategoryName={props.setProductCategoryName}
                            productsCategories={
                                props.productsCategories
                            }
                            productCategoryName={props.productCategoryName}
                            setIsShowBrowseSection={props.setIsShowBrowseSection}
                        />
                    ) : props.filterName === "SALON AT HOME" ? (
                        <AtHomeCategories
                            categoryAtHome={props.categoryAtHome}
                            setCategoryAtHome={props.setCategoryAtHome}
                            categories={props.servicesCategories}
                            setIsShowBrowseSection={props.setIsShowBrowseSection}
                        />
                    ) : props.filterName === "SALON" ? (
                        <AtSalonCategories
                            categoryAtSalon={props.categoryAtSalon}
                            setCategoryAtSalon={props.setCategoryAtSalon}
                            categories={props.servicesCategories}
                            setIsShowBrowseSection={props.setIsShowBrowseSection}
                        />
                    ) : (
                        ""
                    )}
                </div>
                <div className="close">
                    <button onClick={() => { handleClickOnBack() }} className="border-0 d-flex align-items-center"><CgClose /><p className="mb-0">Close</p></button>
                </div>
            </div>
        </div>
    );
}

export default BrowseSection;
