import React, { useEffect, useState } from "react";
import { VscStarFull } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";

import useDeleteData from "../../../customhooks/deletedata";
import usePostData from "../../../customhooks/postdata";
import useGetData from "../../../customhooks/getdata";
import { RWebShare } from "react-web-share";

import { Link } from "react-router-dom";

import { Carousel } from "react-responsive-carousel";

import { HiOutlineDotsVertical } from "react-icons/hi"

import moment from "moment";
import Amenities from "../../../components/amenities";

import { authJsonHeaders } from "../../../constants";

function SalonInfo(props) {

  const [salon, setSalon] = useState();

  const navigate = useNavigate();

  const [postedLike, likeerror, postData] = usePostData();

  const [deletedLike, deleteerror, deleteData] = useDeleteData();

  const [likedSalons, likedSalonsError, getLikedSalons] = useGetData();

  const [isLiked, setIsLiked] = useState(false);

  const [isShowMore, setIsShowMore] = useState(false)

  const [arrowStyles, setArrowStyles] = useState({
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50%)',
    cursor: 'pointer',
    backgroundColor: "white",
    height: "20px",
    width: "20px",
    borderRadius: "100%"
  })

  const [indicatorStyles, setIndicatorStyles] = useState({
    background: '#fff',
    width: 8,
    height: 8,
    borderRadius: "100%",
    display: 'inline-block',
    margin: '0 5px',
  })

  const likeUnlikeSalon = (id, isLiked) => {
    if (props.gotUserId) {
      if (isLiked) {
        deleteData({
          url: `${process.env.REACT_APP_API_URL}/app/salon/${id}/like`,
          body: {},
          //headers: authJsonHeaders,
headers: {
    "Content-type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
},
        });

        props.salon.data.salon.isLiked = false;
      } else {
        postData({
          url: `${process.env.REACT_APP_API_URL}/app/salon/${id}/like`,
          body: {},
          //headers: authJsonHeaders,
headers: {
    "Content-type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
},
        });
      }
    }
    if (props.userIdError) {
      navigate("/login")
    }
  };

  useEffect(() => {
    if (props.gotUserId) {
      getLikedSalons({
        url: `${process.env.REACT_APP_API_URL}/app/salon/like/search?user=${props.gotUserId.data.id}`,
        //headers: authJsonHeaders,
headers: {
    "Content-type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
},
      });
    }
  }, []);

  useEffect(() => {
    if (
      (postedLike != null && postedLike != undefined && postedLike != "") ||
      (deletedLike != null && deletedLike != undefined && deletedLike != "")
    ) {
      getLikedSalons({
        url: `${process.env.REACT_APP_API_URL}/app/salon/like/search?user=${props.gotUserId.data.id}`,
        //headers: authJsonHeaders,
headers: {
    "Content-type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    Authorization: `Bearer ${localStorage.getItem("token")}`,
},
      });
    }
  }, [postedLike, deletedLike]);

  useEffect(() => {
    if (likedSalons != undefined && likedSalons != null && likedSalons != "" && props.salon) {
      setIsLiked(false);
      likedSalons.data.likes.forEach((likedItem) => {
        if (props.salon.data.salon._id == likedItem.salon) {
          setIsLiked(true);
        }
      });


    }
    setSalon(props.salon);
  }, [props, likedSalons]);

  const handleClickOnDot = () => {
    props.setIsShowShareLikeSalon(true)
    document.body.style.overflow = "hidden"
  }

  return (
    <div className="parent-salon-info">
      <p className="mb-0 name">{salon && salon.name}</p>
      <p className="mb-0 address">{salon && salon.address.city}</p>
      {/* <p className="mb-0 timing">Timing: {salon && salon.timings[moment(new Date()).day()]}</p> */}
    </div>
  );
}

export default SalonInfo;
