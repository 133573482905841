import React from "react";

import { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

const useDeleteData = () => {
  const [data, setData] = useState("");
  const [error, setError] = useState("");

  const deleteData = async (params) => {
    try {
      const deletedData = await axios.delete(
        params.url,
        {
          headers: params.headers,
        },
        params.body
      );

      setData(deletedData);

      return deleteData
    } catch (err) {
      setError(err);
      console.log(err);
      if (err && err.response && err.response.data && err.response.data.message) {
        toast.error(err && err.response && err.response.data && err.response.data.message);
      }

      // return error
    }
  };

  return [data, error, deleteData];
};

export default useDeleteData;
