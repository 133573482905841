import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineClockCircle } from "react-icons/ai";

function BookAgain(props) {
  const navigate = useNavigate();

  const handleClickOnBookingOrder = () => {
    if (localStorage.getItem("userId")) {
      navigate("/bookingsorders");
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {props.orders.length > 0 ? (
        <div className="book-again-container">
          <div className="title-container d-flex justify-content-between align-items-center">
            <p className="title mb-0">Book Again</p>
            <button
              onClick={() => {
                handleClickOnBookingOrder();
              }}
              to="/bookingsorders"
            >
              View Order History
            </button>
          </div>

          <div className="book-again-salons-container d-flex">
            {props.orders.slice(0, 4).map((item) => {
              return (
                <div>
                  <Link
                    to={
                      item.status == 1
                        ? `/app/salon/${item.name
                            .toLowerCase()
                            .split(" ")
                            .join("-")}-${item._id}`
                        : ""
                    }
                    key={item._id}
                    className="book-again-salon d-flex"
                  >
                    <div className="salon-image">
                      <img
                        src={
                          item.logo
                            ? `${process.env.REACT_APP_IMAGE_URL}${item.logo}`
                            : "/assets/common/serviceproduct.png"
                        }
                        alt="Salon"
                      />
                    </div>
                    <div className="book-again-salon-info d-flex flex-column justify-content-center align-items-start">
                      <p className="salon-name mb-0">{item.name}</p>
                      {/* <p className="time d-flex align-items-center">
                      <AiOutlineClockCircle />
                      <span>30 mins</span>
                    </p> */}
                      <p className="address mb-0">{item.address.city}</p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default BookAgain;
