import React, { useEffect, useCallback } from "react";

function Amenitiesinfo(props) {

    const callback = useCallback(()=>{
        document.body.style.overflow = "unset"
    })

    useEffect(() => {
        return callback
    }, []);

    return (
        <div className="amenities-info-page d-grid">
            <div onClick={() => props.setIsShowAmenitiesInfo(false)} className="cancel-container d-flex align-items-end justify-content-center">
                <div className="cancel d-flex align-items-center justify-content-center">
                    <img src="/assets/common/cancel.svg" />
                </div>
            </div>
            <div className="amenities-info-container bg-white">
                <div className="amenity-image-name d-flex align-items-center">
                    <img src={`${props.amenitiesInfo.img}`}/>
                    <p className="mb-0">{props.amenitiesInfo.amenityName}</p>
                </div>
                <div className="amenity-description">
                    <p className="mb-0">{props.amenitiesInfo.amenityDescription}</p>
                </div>
            </div>
        </div>
    );
}

export default Amenitiesinfo;
