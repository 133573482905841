import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";

function Congratulations(props) {

  const navigate = useNavigate()

  return (
    <div className="congratulations-section">
      <div className="payment-success-image-container">
        <img src="/assets/paymentsuccess/paymentsuccess.svg" />
      </div>
      <div className="congrats-success-id-container">
        <p className="mb-0 congratulation">Congratulations.</p>
        <p className="mb-0 payment-successful">Order Successful !</p>
      </div>
    </div>
  );
}

export default Congratulations;
