import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-loading-skeleton/dist/skeleton.css";

import "./style/common/style.css";
import "./style/login/style.css";
import "./style/profile/style.css";
import "./style/home/style.css";
import "./style/mapview/style.css";
import "./style/salon/style.css";
import "./style/myprofile/style.css";
import "./style/cart/style.css";
import "./style/slot/style.css";
import "./style/payment/style.css";
import "./style/viewprofile/style.css";
import "./style/location/style.css";
import "./style/bookingorder/style.css";
import "./style/favourites/style.css";
import "./style/wallet/style.css";
import "./style/orderdetails/style.css";
import "./style/offersdeals/style.css";
import "./style/faqs/style.css";
import "./style/coupons/style.css";
import "./style/address/style.css";
import "./style/salonsbyfooter/style.css";
import "./style/searchsalonspage/style.css";
import "./style/salonsbycategory/style.css";
import "./style/dynamicpage/style.css";
import "./style/reviewrating/style.css";
import "./style/saloninfo/style.css";
import "./style/salonsbysearch/style.css";
import "./style/salonlist/style.css";
import "./style/membership/style.css";
import "./style/paymentsuccess/style.css";
import "./style/paymentfailed/style.css";
import "./style/business/style.css";

import "./style/responsive/style.css";

import ReactGA from "react-ga";

import ReactGA4 from "react-ga4";

import Analytics from "analytics";

import googleTagManager from "@analytics/google-tag-manager";

// ga
ReactGA.initialize("UA-260298694-1");
ReactGA.pageview(window.location.pathname + window.location.search);


// ga-4
ReactGA4.initialize([
  {
    trackingId: "G-FHGE600E95",
    // gaOptions: "", // optional
    // gtagOptions: "", // optional
  },
]);

ReactGA4.send({ hitType: "pageview", page: window.location.pathname});

// ReactGA.event({
//   category: "your category",
//   action: "your action",
//   label: "your label", // optional
//   value: 99, // optional, must be a number
//   nonInteraction: true, // optional, true/false
//   transport: "xhr", // optional, beacon/xhr/image
// });

// gtm

// const analytics = Analytics({
//   app: "Groome",
//   plugins: [
//     googleTagManager({
//       containerId: "GTM-PKMVNVB",
//     }),
//   ],
// });

// gtm.page();

/* Track a custom event */
// analytics.track("cartCheckout", {
//   item: "pink socks",
//   price: 20,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
