import { useEffect, useState } from "react";
import ChooseLocation from "./chooselocation";
import SelectLocation from "./selectlocation";
import React from "react";

import { GoogleApiWrapper } from "google-maps-react";

function Location(props) {
  const [isOpenChooseLocation, setIsOpenChooseLocation] = useState(false);

  const [isOpenSelectLocation, setIsOpenSelectLocation] = useState(true);

  const [isOpenChange, setIsOpenChange] = useState(false);

  const [latlng, setLatlng] = useState({
    lat: "",
    lng: "",
  });

  const getLatlng = (lat, lng) => {
    setLatlng({
      lat: lat,
      lng: lng,
    });
  };

  useEffect(() => {
    props.setIsToBeHiddeOverflow(true)
  }, [])

  return (
    <>
      <div className="location-page h-100">
        {isOpenChooseLocation ? (
          <ChooseLocation
            setIsPopUp={props.setIsPopUp}
            setPopUpMessage={props.setPopUpMessage}
            getLatlng={props.getLatlng}
            latlng={latlng}
            setIsOpenChange={setIsOpenChange}
            setIsOpenSelectLocation={setIsOpenSelectLocation}
            closeSelectLocation={props.closeSelectLocation}
            setIsOpenChooseLocation={setIsOpenChooseLocation}
          />
        ) : (
          ""
        )}
        {isOpenSelectLocation ? (
          <SelectLocation
            setIsPopUp={props.setIsPopUp}
            setPopUpMessage={props.setPopUpMessage}
            getLatlng={getLatlng}
            setIsOpenSelectLocation={setIsOpenSelectLocation}
            setIsOpenChooseLocation={setIsOpenChooseLocation}
            closeSelectLocation={props.closeSelectLocation}
          />
        ) : (
          ""
        )}

        {isOpenChange ? (
          <div className="change-location-page">
            <SelectLocation
              setIsPopUp={props.setIsPopUp}
              setPopUpMessage={props.setPopUpMessage}
              getLatlng={getLatlng}
              setIsOpenChange={setIsOpenChange}
              isOpenChange={isOpenChange}
              setIsOpenSelectLocation={setIsOpenSelectLocation}
              setIsOpenChooseLocation={setIsOpenChooseLocation}
              closeSelectLocation={props.closeSelectLocation}
            />
          </div>
        ) : (
          ""
        )}
      </div>

    </>
  );
}

const LoadingContainer = () => <div></div>;

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_MAP_APIKEY}`,
  LoadingContainer: LoadingContainer,
})(Location);
