import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function PopUp(props) {
  const [animate, setAnimate] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    setAnimate({
      transform: "translateY(0%)",
    });
  }, []);

  const callback = useCallback(() => {
    if (
      props.isSearchServices ||
      props.isSearchProducts ||
      props.isToBeHiddeOverflow
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    if (props.setPopUpMessage) {
      props.setPopUpMessage("");
    }

    sessionStorage.removeItem("form-submitted");
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return callback;
  }, []);

  const handleClickOnBack = () => {
    if (props.isGoToBusineesPage) {
      sessionStorage.removeItem("form-submitted");
      navigate("/business");
    }

    setAnimate({
      transform: "translateY(100%)",
    });

    setTimeout(() => {
      props.setIsPopUp(false);

      if (props.isSearchServices || props.isSearchProducts) {
        document.body.style.overflow = "hidden";
      }
      if (props.setPopUpMessage) {
        props.setPopUpMessage("");
      }
    }, 1000);
  };

  return (
    <div
      style={animate}
      className="popup-page d-flex justify-content-center align-items-center"
    >
      <div className="popup d-flex flex-column justify-content-center align-items-center">
        <p className="mb-0 text-center">{props.message}</p>
        <button
          onClick={() => {
            handleClickOnBack();
          }}
        >
          Ok
        </button>
      </div>
    </div>
  );
}

export default PopUp;
