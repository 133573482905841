import React from "react";
import { useState, useEffect } from "react";

import MapOfSalons from "./mapofsalons";
import Footer from "../../components/footer";
// import Salon from "../../components/salon";
import Salon from "./salon";
import Loader from "../../components/loader";

import useIsLoader from "../../customhooks/useisloader";
import useGetData from "../../customhooks/getdata";

import NotFound from "../../components/notfound";

import useIsPopUp from "../../customhooks/ispopup";
import PopUp from "../../components/popup";
import moment from "moment";
import { gtm } from "../gtm";

function Mapview() {
  const [isLoader, setIsLoader] = useIsLoader();

  const [salonsAround, setSalonsAround] = useState([]);

  const [salons, setSalons] = useState(null);

  const [data, dataError, fetchData] = useGetData();

  const [perPage, setPerpage] = useState(10);

  let [page, setPage] = useState(1);

  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [popUpMessage, setPopUpMessage] = useState("");

  const handleGetSalons = async () => {
    const res = await fetchData({
      url: `${
        process.env.REACT_APP_API_URL
      }/app/salon/search?nearBy=${sessionStorage.getItem(
        "city"
      )},${sessionStorage.getItem("country")}&lat=${sessionStorage.getItem(
        "lat"
      )}&lng=${sessionStorage.getItem(
        "lng"
      )}&status=1&salonStatus=verified&earliest=${
        moment()
          .format("YYYY-MM-DDTHH:mm")
          .split("T")[1]
      }&sort_by=distanceInKm&isActive=true&per_page=${perPage}&page=${page}`,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return Promise.resolve(res);
  };

  useEffect(() => {
    setIsLoader(true);

    const p1 = handleGetSalons();

    p1.then((results) => {
      if (results) {
        setSalonsAround(results.data.salons);

        setSalons(results);
        setIsLoader(false);
      } else {
        setSalonsAround([]);

        setSalons(null);
        setIsLoader(false);
      }
    });
  }, []);

  const handleOnScroll = (e) => {
    if (
      !isLoader &&
      e.target.scrollLeft + e.target.clientWidth >= e.target.scrollWidth - 1
    ) {
      if (salons && salons.data.totalResults > perPage * page) {
        setPage((page += 1));

        setIsLoader(true);

        const promRes = handleGetSalons();

        promRes.then((res) => {
          if (res) {
            if (salons.data.totalResults > 0) {
              setSalonsAround((prev) => [...prev, ...res.data.salons]);
              setSalons(res);
              setIsLoader(false);
            } else {
              setSalonsAround([]);
              setSalons(null);
              setIsLoader(false);
            }
          } else {
            setSalonsAround([]);
            setSalons(null);
            setIsLoader(false);
          }
        });
      }
    }
  };

  const scrolltosalon = (id) => {
    const salons = document.getElementsByClassName("salon");

    for (let loop = 0; loop <= salons.length - 1; loop++) {
      if (salons[loop].id === id) {
        window.scrollTo(0, 506);
        const salonscontainer = document.getElementById("salons-container");
        let clientwidth = salons[loop].clientWidth + 20;
        salonscontainer.scrollTo(loop * clientwidth, 0);
      }
    }
  };

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <>
      <div className="mapview-page d-grid h-100">
        <MapOfSalons
          setIsPopUp={setIsPopUp}
          setPopUpMessage={setPopUpMessage}
          scrolltosalon={scrolltosalon}
          location={salonsAround}
        />

        <div
          onScroll={(e) => {
            handleOnScroll(e);
          }}
          id="salons-container"
          className="salons-container d-flex"
        >
          <Salon salons={salonsAround} />

          {isLoader ? <Loader height={60} width={60} color={"#772286"} /> : ""}
          {!isLoader && salonsAround.length == 0 ? <NotFound /> : ""}
        </div>
        <Footer />
      </div>

      {isPopUp ? (
        <PopUp
          setPopUpMessage={setPopUpMessage}
          setIsPopUp={setIsPopUp}
          message={popUpMessage}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Mapview;
