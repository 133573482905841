import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SalonInfo from "./saloninfo";

import useIsLoader from "../../customhooks/useisloader";

import useGetData from "../../customhooks/getdata";

import useIsPopUp from "../../customhooks/ispopup";

import usePostData from "../../customhooks/postdata";

import useDeleteData from "../../customhooks/deletedata";
import Skeleton from "react-loading-skeleton";
import Functionality from "./functionality";
import ReviewRating from "./showreviewrating";
import Amenitiesinfo from "./amenitiesinfo";
import {headers } from "../../constants";
import { gtm } from "../gtm";

function Salon() {

    const salonNameId = useParams()

    const [isLoader, setIsLoader] = useIsLoader(true);

    const [isPopUp, setIsPopUp] = useIsPopUp(false);

    const [popUpMessage, setPopUpMessage] = useState("");

    const [services, setServices] = useState([]);
    const [products, setProducts] = useState([]);
    const [salon, setSalon] = useState();

    const [servicesCategories, setServicesCategories] = useState([]);

    const [productsCategories, setProductsCategories] = useState([]);

    const [isProducts, setIsProducts] = useState(false);

    const [categoryName, setCategoryName] = useState("");
    const [filterName, setFilterName] = useState("");
    const [isServices, setIsServices] = useState("");
    const [isPackages, setIsPackages] = useState("");
    const [searchQuery, setsearchQuery] = useState("");

    const [salonId, setSalonId] = useState("")

    const [isSearchProducts, setIsSearchProducts] = useState(false);

    const [isSearchServices, setIsSearchServices] = useState(false);

    const [productCategoryName, setProductCategoryName] = useState("");

    const [offerDetails, setOfferDetails] = useState({
        title: "",
        description: "",
        offerDetail: ""
    })

    const [isShowAmenitiesInfo, setIsShowAmenitiesInfo] = useState(false);

    const [amenitiesInfo, setAmenitiesInfo] = useState({
        img: "",
        amenityName: "",
        amenityDescription: ""
    })

    const [isShowShareLikeSalon, setIsShowShareLikeSalon] = useState(false);

    const [isShowOfferDetails, setIsShowOfferDetails] = useState(false);

    const [gotSalon, gotSalonError, getSalon] = useGetData();

    const [gotServices, serviceError, getServices] = useGetData();

    const [gotProducts, productsError, getProducts] = useGetData();

    const [gotCartList, cartListError, getCartList] = useGetData();

    const [createdCartId, createCartError, createCart] = usePostData();

    const [gotGenericCart, genericCartError, getGenericCart] = useGetData();

    const [movedGenericCart, movedGenericCartError, moveGenericCart] = useDeleteData();

    useEffect(() => {
        document.body.style.overflow = "unset"
    }, [])

    const [reviewAndRating, setReviewAndRating] = useState([])

    const [gotReviewRating, ReviewRatingError, getReviewRating] = useGetData();

    const [filterValue, setFilterValue] = useState([])

    useEffect(() => {
        if (salonNameId) {
            const array = salonNameId.salonNameId.split("-")
            setSalonId(array[array.length - 1])
        }
    }, [salonNameId])

    useEffect(() => {
        if (salonId) {
            getReviewRating({
                url: `${process.env.REACT_APP_API_URL}/app/review-and-rating/search?salon=${salonId}`,
                //headers: authJsonHeaders,
                headers: {
                    "Content-type": "application/json",
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
        }

    }, [salonId]);

    useEffect(() => {
        if (gotReviewRating) {
            setReviewAndRating(gotReviewRating.data.orderReviewsAndRatings)
        }
    }, [gotReviewRating])

    useEffect(() => {
        if (gotReviewRating) {
            if (filterValue.length > 0) {
                // setReviewAndRating([])
                setReviewAndRating(gotReviewRating.data.orderReviewsAndRatings.filter((item) => {
                    for (let i = 0; i <= filterValue.length - 1; i++) {
                        if (item.rating <= filterValue[i]) {
                            return item
                        }
                    }
                }))
            }
            else {
                setReviewAndRating(gotReviewRating.data.orderReviewsAndRatings)
            }
        }

    }, [filterValue])

    useEffect(() => {
        if (salonId) {
            getSalon({
                url: `${process.env.REACT_APP_API_URL}/app/salon/${salonId}`,
                headers: headers,
            });
        }
    }, [salonId])

    useEffect(() => {
        if (gotSalon != undefined && gotSalon != null && gotSalon != "") {
            setSalon(gotSalon);
        }
    }, [gotSalon]);

    useEffect(() => {
        if (
            gotSalon != undefined &&
            gotSalon != null &&
            gotSalon != ""

        ) {
            setIsLoader(false);
        }
    }, [
        gotSalon
    ]);

    useEffect(() => {
        gtm.page();
    }, [])

    return (
        <div className="salon-info-page">
            {
                isLoader ? <div>
                    <Skeleton className={"salon-image-skelton"} height={200} />
                    <div className="d-flex justify-content-between align-items-center pt-3 px-3">
                        <Skeleton height={23} width={50} />
                        <Skeleton height={23} width={23} />
                    </div>
                </div> : <SalonInfo setAmenitiesInfo={setAmenitiesInfo} setIsShowAmenitiesInfo={setIsShowAmenitiesInfo} setIsShowShareLikeSalon={setIsShowShareLikeSalon} salon={salon} />
            }

            <Functionality gotSalon={gotSalon} />

            {gotReviewRating && gotReviewRating.data.orderReviewsAndRatings.length > 0 ?

                <ReviewRating gotReviewRating={gotReviewRating} filterValue={filterValue} setFilterValue={setFilterValue} reviewAndRating={reviewAndRating} />
                : ""}
            {isShowAmenitiesInfo ?
                <Amenitiesinfo amenitiesInfo={amenitiesInfo} setIsShowAmenitiesInfo={setIsShowAmenitiesInfo} /> : ""
            }
        </div>
    );
}

export default Salon;
