import React from "react";

import { useEffect, useState } from "react";

import InfiniteScroll from "react-infinite-scroll-component";

import Salon from "../../components/salon";
import useIsLoader from "../../customhooks/useisloader";
import useGetData from "../../customhooks/getdata";
import SearchSalons from "../../components/searchsalons";
import Search from "./search";
import Header from "./header";
import Loader from "../../components/loader";

import NotFound from "../../components/notfound";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import { gtm } from "../gtm";

function Favourites() {

  const navigate = useNavigate()

  const [isLoader, setIsLoader] = useIsLoader(true);

  const [isScrollLoader, setIsScrollLoader] = useState(false);

  const [salonsAround, setSalonsAround] = useState([]);

  let [perpage, setPerpage] = useState(10);

  const [totalSalons, setTotalSalons] = useState([]);

  let [page, setPage] = useState(1);

  const [salons, salonsError, getSalons] = useGetData();

  const [likedSalons, likedSalonsError, getLikedSalons] = useGetData();

  const [isSearchSalons, setIsSearchSalons] = useState(false);

  useEffect(() => {
    setIsScrollLoader(true);
    if (
      sessionStorage.getItem("city") &&
      sessionStorage.getItem("country") &&
      sessionStorage.getItem("lat") &&
      sessionStorage.getItem("lng")
    ) {
      getSalons({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/salon/search?nearBy=${sessionStorage.getItem(
          "city"
        )},${sessionStorage.getItem("country")}&lat=${sessionStorage.getItem(
          "lat"
        )}&lng=${sessionStorage.getItem(
          "lng"
        )}&status=1&salonStatus=verified&isActive=true&earliest=${
          moment()
            .format("YYYY-MM-DDTHH:mm")
            .split("T")[1]
        }&sort_by=distanceInKm&per_page=${perpage}&page=${page}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    } else {
      setIsLoader(false);
    }
  }, [page]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getLikedSalons({
        url: `${
          process.env.REACT_APP_API_URL
        }/app/salon/like/search?user=${localStorage.getItem("userId")}`,
        //headers: authJsonHeaders,
        headers: {
          "Content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      salons != undefined &&
      salons != null &&
      salons != "" &&
      likedSalons != undefined &&
      likedSalons != null &&
      likedSalons != ""
    ) {
      if (salons.data.totalResults > 0) {
        setTotalSalons((prev) => [...prev, ...salons.data.salons]);
      } else {
        setTotalSalons([]);
      }

      setIsScrollLoader(false);
      setIsLoader(false);
    }
  }, [salons, likedSalons]);

  useEffect(() => {
    if (
      totalSalons != undefined &&
      totalSalons != null &&
      totalSalons != "" &&
      likedSalons != undefined &&
      likedSalons != null &&
      likedSalons != ""
    ) {
      setSalonsAround(
        totalSalons.filter((item) => {
          for (let i = 0; i <= likedSalons.data.likes.length - 1; i++) {
            if (item._id == likedSalons.data.likes[i].salon) {
              return item;
            }
          }
        })
      );
    }
  }, [totalSalons, likedSalons]);

  const increasePage = () => {
    if (salons && salons.data.totalResults > perpage * page) {
      setPage((page += 1));
    }
  };

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <>
      <div className="favourites-page d-grid">
        <div className="header-search-container">
          <Header />
          <Search setIsSearchSalons={setIsSearchSalons} />
        </div>
        {isLoader ? (
          <Loader width={60} height={60} color={"#772286"} />
        ) : (
          <>
            {salonsAround.length > 0 ? (
              <div className="favourites-salons-container">
                <InfiniteScroll
                  dataLength={salonsAround.length}
                  next={increasePage}
                  hasMore={true}
                  loader={
                    isScrollLoader ? (
                      <Loader width={60} height={60} color={"#772286"} />
                    ) : (
                      ""
                    )
                  }
                  scrollThreshold={0.5}
                >
                  <Salon
                    setIsLoader={setIsLoader}
                    getLikedSalons={getLikedSalons}
                    salons={salonsAround}
                  />
                </InfiniteScroll>
              </div>
            ) : (
              <NotFound />
            )}
          </>
        )}
      </div>

      {isSearchSalons ? (
        <SearchSalons setIsSearchSalons={setIsSearchSalons} />
      ) : (
        ""
      )}
    </>
  );
}

export default Favourites;
