import React from "react";

import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import Salon from "../../components/salon";
import useIsLoader from "../../customhooks/useisloader";
import useGetData from "../../customhooks/getdata";
import FilterSalons from "../../components/filtersalons";

import { useParams } from "react-router-dom";
import Loader from "../../components/loader";

import Search from "./search";

import SearchSalons from "../../components/searchsalons";
import moment from "moment";

import { authJsonHeaders } from "../../constants";
import { gtm } from "../gtm";

function SalonsBySearch() {
  const params = useParams();

  const [isLoader, setIsLoader] = useIsLoader(true);

  const [salonsAround, setSalonsAround] = useState([]);

  let [perpage, setPerpage] = useState(10);

  let [page, setPage] = useState(1);

  const [salons, setSalons] = useState(null);

  const [fetchedData, fetchError, fetchData] = useGetData();

  const [earliest, setEarliest] = useState("");
  // const [men, setMen] = useState("");
  // const [women, setWomen] = useState("");
  const [gender, setGender] = useState(null);
  const [rating, setRating] = useState("");

  const [isSearchSalons, setIsSearchSalons] = useState(false);

  const [isScrollLoader, setIsScrollLoader] = useIsLoader();

  useEffect(() => {
    document.body.style.overflow = "scroll";
  }, []);

  const getSalons = async () => {
    const query = encodeURIComponent(
      params.name
        .toLowerCase()
        .split("-")
        .join(" ")
    );

    const res = await fetchData({
      url:
        `${process.env.REACT_APP_API_URL}/app/search/salon/${
          params.type
        }?name=${query}&nearBy=${sessionStorage.getItem(
          "city"
        )},${sessionStorage.getItem("country")}&lat=${sessionStorage.getItem(
          "lat"
        )}&lng=${sessionStorage.getItem(
          "lng"
        )}&status=1&salonStatus=verified&isActive=true&earliest=${
          moment()
            .format("YYYY-MM-DDTHH:mm")
            .split("T")[1]
        }&sort_by=${
          earliest ? "appointmentIn" : "distanceInKm"
        }&per_page=${perpage}&page=${page}` +
        `${
          gender ? `&type=${gender}` : ""
          // men && women
          //   ? `&type=unisex`
          //   : men
          //   ? `&type=male`
          //   : women
          //   ? `&type=female`
          //   : ``
        }` +
        `${rating ? `&ratingGt=4` : ``}`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return Promise.resolve(res);
  };



  useEffect(() => {
    setIsLoader(true);

    setTimeout(() => {
      const p1 = getSalons();

      p1.then((res) => {
        if (res) {
          setSalonsAround(res.data.salons);

          setSalons(res);
        } else {
          setSalonsAround([]);

          setSalons(null);
        }

        setIsLoader(false);
      });
    }, 1000);
  }, [params, gender, rating, earliest]);

  const increasePage = () => {
    if (salons && salons.data.totalResults > perpage * page) {
      setPage((page += 1));

      setIsScrollLoader(true);

      const p1 = getSalons();

      p1.then((res) => {
        if (res) {
          if (res.data.totalResults > 0) {
            setSalonsAround((prev) => [...prev, ...res.data.salons]);
          } else {
            setSalonsAround([]);
            setSalons(null);
          }

          setIsScrollLoader(false);

          setIsLoader(false);
        }
      });
    }
  };

  useEffect(() => {
    gtm.page();
  }, []);

  return (
    <>
      <div className="salons-by-search-page d-grid">
        <div className="search-filter-container">
          <Search setIsSearchSalons={setIsSearchSalons} />
          <FilterSalons
            setPage={setPage}
            setEarliest={setEarliest}
            // setWomen={setWomen}
            // setMen={setMen}
            setGender={setGender}
            gender={gender}
            setRating={setRating}
          />
        </div>

        {isLoader ? (
          <Loader height={60} width={60} color={"#772286"} />
        ) : (
          <>
            {salons && salonsAround.length > 0 ? (
              <div className="salons-around-container">
                <div className="title">
                  <p className="mb-0">
                    {params.type == "service" || params.type == "product" ? (
                      <>
                        {params.name.charAt(0).toUpperCase() +
                          params.name
                            .slice(1)
                            .split("-")
                            .join(" ")}{" "}
                        {params.type} found in {salonsAround.length} Salons
                      </>
                    ) : (
                      <>{salonsAround.length} Salons Around You</>
                    )}
                  </p>
                </div>
                <div className="salons">
                  <InfiniteScroll
                    dataLength={salonsAround.length}
                    next={increasePage}
                    hasMore={true}
                    loader={
                      isScrollLoader ? (
                        <Loader width={60} height={60} color={"#772286"} />
                      ) : (
                        ""
                      )
                    }
                    scrollThreshold={0.5}
                  >
                    <Salon salons={salonsAround} />
                  </InfiniteScroll>
                </div>
              </div>
            ) : (
              <div className="not-found text-center d-flex flex-column justify-content-center align-items-center">
                <img
                  src="/assets/salonsbysearch/not_found.png"
                  alt="Not found"
                />
                <p className="mb-0">Sorry! No results found</p>
              </div>
            )}
          </>
        )}
      </div>

      {isSearchSalons ? (
        <SearchSalons setIsSearchSalons={setIsSearchSalons} />
      ) : (
        ""
      )}
    </>
  );
}

export default SalonsBySearch;
