import React, { useEffect, useState } from "react";

function Filter(props) {
  const [filterStyle, setFilterStyle] = useState("");

  const filter = (value) => {
    props.setFilterName(value);
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      if (
        props.gotCartList &&
        props.gotCartList.data.cart &&
        props.gotCartList.data.cart.serviceType === "At Salon" &&
        props.salon.data.salon.isSalon
      ) {
        filter("SALON");
      } else if (
        props.gotCartList &&
        props.gotCartList.data.cart &&
        props.gotCartList.data.cart.serviceType === "At Home" &&
        props.salon.data.salon.isHome
      ) {
        filter("SALON AT HOME");
      } else if (props.salon.data.salon.isSalon) {
        filter("SALON");
      } else if (props.salon.data.salon.isHome) {
        filter("SALON AT HOME");
      } else {
        filter("PRODUCTS");
      }
    }

    if (!localStorage.getItem("userId")) {
      if (
        props.gotGenericCart &&
        props.gotGenericCart.data.genericCart &&
        props.gotGenericCart.data.genericCart.serviceType === "At Salon" &&
        props.salon.data.salon.isSalon
      ) {
        filter("SALON");
      } else if (
        props.gotGenericCart &&
        props.gotGenericCart.data.genericCart &&
        props.gotGenericCart.data.genericCart.serviceType === "At Home" &&
        props.salon.data.salon.isHome
      ) {
        filter("SALON AT HOME");
      } else if (props.salon.data.salon.isSalon) {
        filter("SALON");
      } else if (props.salon.data.salon.isHome) {
        filter("SALON AT HOME");
      } else {
        filter("PRODUCTS");
      }
    }
  }, [props.salon]);

  useEffect(() => {
    if (props.salon) {
      if (props.salon.data.salon.isSalon) {
        setFilterStyle("auto");
      }
      if (props.salon.data.salon.isHome) {
        setFilterStyle("auto");
      }
      if (props.salon.data.salon.isProduct) {
        setFilterStyle("auto");
      }

      if (props.salon.data.salon.isSalon && props.salon.data.salon.isHome) {
        setFilterStyle("50% 50%");
      }
      if (props.salon.data.salon.isSalon && props.salon.data.salon.isProduct) {
        setFilterStyle("50% 50%");
      }
      if (props.salon.data.salon.isProduct && props.salon.data.salon.isHome) {
        setFilterStyle("50% 50%");
      }

      if (
        props.salon.data.salon.isSalon &&
        props.salon.data.salon.isHome &&
        props.salon.data.salon.isProduct
      ) {
        setFilterStyle("auto auto auto");
      }
    }
  }, [props.salon]);

  const handleClickForMessage = (value) => {
    props.setPopUpMessage(
      "You have to remove the items from cart to add services for at" +
        " " +
        value +
        " Do you want to remove?"
    );
    props.openDeleteCart();
    if (value == "Salon.") {
      props.setFilterToBeActive("SALON");
    }
    if (value == "Home.") {
      props.setFilterToBeActive("SALON AT HOME");
    }
  };

  return (
    <>
      <div className="filter-container">
        <div
          className="filters d-grid align-items-center"
          style={{ gridTemplateColumns: filterStyle }}
        >
          {props.salon && props.salon.data && props.salon.data.salon.isSalon ? (
            <button
              onClick={() => {
                props.gotGenericCart &&
                props.gotGenericCart.data.genericCart &&
                props.gotGenericCart.data.genericCart.cartCalculations.overall
                  .totalItems > 0
                  ? props.gotGenericCart.data.genericCart.serviceType &&
                    props.gotGenericCart.data.genericCart.serviceType ===
                      "At Home"
                    ? handleClickForMessage("Salon.")
                    : filter("SALON")
                  : props.gotCartList &&
                    props.gotCartList.data.cart &&
                    props.gotCartList.data.cart.cartCalculations.overall
                      .totalItems > 0 &&
                    props.gotCartList.data.cart.serviceType &&
                    props.gotCartList.data.cart.serviceType === "At Home"
                  ? handleClickForMessage("Salon.")
                  : filter("SALON");
              }}
              className={
                props.filterName === "SALON"
                  ? "active border-0 h-100"
                  : "border-0 h-100"
              }
            >
              SALON SERVICES
            </button>
          ) : (
            ""
          )}

          {props.salon && props.salon.data && props.salon.data.salon.isHome ? (
            <button
              onClick={() => {
                props.gotGenericCart &&
                props.gotGenericCart.data.genericCart &&
                props.gotGenericCart.data.genericCart.cartCalculations.overall
                  .totalItems > 0
                  ? props.gotGenericCart.data.genericCart.serviceType &&
                    props.gotGenericCart.data.genericCart.serviceType ===
                      "At Salon"
                    ? handleClickForMessage("Home.")
                    : filter("SALON AT HOME")
                  : props.gotCartList &&
                    props.gotCartList.data.cart &&
                    props.gotCartList.data.cart.cartCalculations.overall
                      .totalItems > 0 &&
                    props.gotCartList.data.cart.serviceType &&
                    props.gotCartList.data.cart.serviceType === "At Salon"
                  ? handleClickForMessage("Home.")
                  : filter("SALON AT HOME");
              }}
              className={
                props.filterName === "SALON AT HOME"
                  ? "active border-0 h-100"
                  : "border-0 h-100"
              }
            >
              HOME SERVICES
            </button>
          ) : (
            ""
          )}

          {props.salon &&
          props.salon.data &&
          props.salon.data.salon.isProduct ? (
            <button
              onClick={() => {
                filter("PRODUCTS");
              }}
              className={
                props.filterName === "PRODUCTS"
                  ? "active border-0 h-100"
                  : "border-0 h-100"
              }
            >
              PRODUCTS
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default Filter;
