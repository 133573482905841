import React from "react";

import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";

function CompanyInfo() {
  return (
    <div className="company-info-section">
      <div className="company-info-sub-section d-flex justify-content-between">
        <div className="d-flex company-info-our-products-container">
          <div className="company-info">
            <div className="logo-container">
              <img src="/assets/business/whitelogo.svg" />
            </div>
            <p>
              Groome is one for all platform to increase footfall and maximize
              your revenue. It helps with marketing, online appointments, POS,
              sales, reports, and feedback. Book your FREE Demo now!
            </p>
          </div>

          {/* <div className="our-products d-flex flex-column">
            <h5 className="mb-0">Our Products</h5>

            <button>Salon Spa</button>

            <button>
                        Skin Clinic
                    </button>
          </div> */}
        </div>
        <div className="our-products d-flex flex-column">
          <h5 className="mb-0">Our Products</h5>

          <a href="#banner">Salon Spa</a>

          <h5 className="mb-0">Follow us on</h5>

          <div className="d-flex social-media-container">
            <a href="https://www.facebook.com/Groome.in">
              <BsFacebook />
            </a>
            <a href="https://www.facebook.com/Groome.in">
              <BsInstagram />
            </a>
            <a href="https://www.facebook.com/Groome.in">
              <BsYoutube />
            </a>
          </div>


        </div>

        <div
          id="contact"
          className="contact-information-section d-flex flex-column"
        >
          <h5 className="mb-0">Contact Information</h5>

          <p className="mb-0">
            <strong>Address:</strong> L 142, 5th Main, HSR Layout Sector 6
            Bangalore 560102.
          </p>

          <p className="mb-0">
            <strong>Contact:</strong> +918884877662
          </p>

          <p className="mb-0">
            <strong>Email:</strong> groome.in@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompanyInfo;
