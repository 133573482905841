import Analytics from "analytics";

import googleTagManager from "@analytics/google-tag-manager";

const gtm = Analytics({
  app: "Groome",
  plugins: [
    googleTagManager({
      containerId: "GTM-PKMVNVB",
    }),
  ],
});

export { gtm };
