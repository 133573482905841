import React from "react";

import { useState, useEffect, useCallback } from "react";

import Search from "./search";
import Services from "../services";
import ToggleServicesPackages from "../toggleservicespackages";
import Proceed from "../proceed";

import { CgClose } from "react-icons/cg";

function SearchService(props) {
  const [services, setServices] = useState([]);

  const [searchQuery, setsearchQuery] = useState("");

  const callback = useCallback(() => {
    document.body.style.overflow = "unset";
    props.setIsMale(false);
  });

  useEffect(() => {
    return callback;
  }, []);

  const cancel = () => {
    props.setIsSearchServices(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    if (searchQuery != "") {
      setServices(
        props.gotServices.data.services.filter((item) => {
          if (props.filterName === "SALON AT HOME") {
            if (item.isHome) {
              if (item.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                return item;
              }
            }
          }

          if (props.filterName === "SALON") {
            if (item.isSalon) {
              if (item.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                return item;
              }
            }
          }
        })
      );
    } else {
      setServices([]);
    }
  }, [props, searchQuery]);

  return (
    <div className="search-service-page d-flex flex-column justify-content-between h-100">
      <div>
        <div className="header-search-container">
          <div className="header d-flex justify-content-between align-items-center">
            <p className="title mb-0">Search</p>
            <button
              onClick={() => {
                cancel();
              }}
              className="back"
            >
              <CgClose />
            </button>
          </div>
          <ToggleServicesPackages
            salon={props.salon}
            setIsMale={props.setIsMale}
            setIsFemale={props.setIsFemale}
            setIsServices={props.setIsServices}
            setIsPackages={props.setIsPackages}
          />
          <Search setsearchQuery={setsearchQuery} />
        </div>

        {services.length > 0 ? (
          <Services
            isSearchServices={props.isSearchServices}
            getGenericCart={props.getGenericCart}
            setIsPopUp={props.setIsPopUp}
            setPopUpMessage={props.setPopUpMessage}
            salon={props.salon}
            getCartList={props.getCartList}
            filterName={props.filterName}
            categoryAtHome={props.categoryAtHome}
            categoryAtSalon={props.categoryAtSalon}
            services={services}
            isProceed={props.isProceed}
            gotGenericCart={props.gotGenericCart}
            gotCartList={props.gotCartList}
            openDeleteCart={props.openDeleteCart}
          />
        ) : (
          <>
            {searchQuery.length > 0 ? <p className="message">No result</p> : ""}
          </>
        )}
      </div>
      {(props.gotCartList &&
        props.gotCartList.data.cart &&
        props.gotCartList.data.cart.cartCalculations.overall.totalItems > 0) ||
      (props.gotGenericCart &&
        props.gotGenericCart.data.genericCart &&
        props.gotGenericCart.data.genericCart.cartCalculations.overall
          .totalItems > 0) ? (
        <Proceed
          gotGenericCart={props.gotGenericCart}
          gotCartList={props.gotCartList}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default SearchService;
