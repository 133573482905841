import React, { useCallback, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useGetData from "../../customhooks/getdata";
import Congratulations from "./congratulations";
import Header from "./header";
import { gtm } from "../gtm";

function PaymentSuccess(props) {

  const location = useLocation()

  const callback = useCallback(() => {
    document.body.style.overflow = "unset"
  }, [])

  useEffect(() => {
    return callback
  }, [])

  const orderId = useParams();

  const [gotOrderDetails, orderDetailsError, getOrderDetails] = useGetData();

  useEffect(() => {
    if (orderId != undefined && orderId != null && orderId != "") {
      getOrderDetails({
        url: `${process.env.REACT_APP_API_URL}/app/order/${orderId.orderId}`,
        headers: {
          "Content-type": "application/json",
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [orderId]);

  useEffect(()=>{
    gtm.page();
  }, [])


  return (
    <div className="payment-success-page">
      <Header />
      <div className="girl-image-container">
        <img src="/assets/paymentsuccess/girl.svg" />
      </div>
      <Congratulations gotOrderDetails={gotOrderDetails} />

      <div className="view-order-details-done-container">
        <Link to="/bookingsorders" className="view-order-details">View Order Details</Link>
        <Link to="/" className="done">Done</Link>
      </div>
    </div>
  );
}

export default PaymentSuccess;
