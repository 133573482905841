import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Header(props) {

  return (
    <>
      <div className="home-page-header header d-flex justify-content-between align-items-center">
        <div className="user-location-container d-flex align-items-center">

          <div className="location-icon-container">
            <img src="/assets/home/location.svg" alt="Location" />
          </div>
          <div>
            <button
              onClick={() => {
                props.openSelectLocation();
              }}
              className="select-location border-0 d-flex justify-content-start align-items-center"
            >
              <span>{props.city}</span>
              <span>
                <img src="/assets/common/down.svg" alt="Down" />
              </span>
            </button>
            <p className="location mb-0">
              {props.gotAddress &&
                props.gotAddress.data.results[0].formatted_address.substring(0, 35)}
            </p>
          </div>
        </div>

        <div className="user-image-container">
          <Link className="d-block" to="/myprofile">

            {
              props.userIdError ? <img src="/assets/common/user.svg" /> : ""
            }

            {props.gotUserProfile && props.gotUserProfile.data ? <>{props.gotUserProfile.data.user && props.gotUserProfile.data.user.image ? <img src={`${process.env.REACT_APP_IMAGE_URL}${props.gotUserProfile.data.user.image}`} alt="User" /> : <img src="/assets/common/user.svg" />}</> : ""}


          </Link>


        </div>
      </div>
    </>
  );
}

export default Header;
