import React, { useCallback, useEffect, useState } from "react";

import { CgClose } from "react-icons/cg"

function ToggleServicesPackages(props) {
  // const [isService, setIsService] = useState(true);
  // const [isPackages, setIsPackages] = useState(false);
  const [isMaleFemale, setIsMaleFemale] = useState("");
  const [isServicePackages, setIsServicePackages] = useState("");

  const callback = useCallback(() => {
    props.setIsMale(false)
  })

  useEffect(() => {
    if (props.salon && props.salon.data.salon.type == "unisex") {
      setIsMaleFemale("female")
      props.setIsFemale(true)
      setIsServicePackages("services")
      props.setIsServices(true);
    }
    else {
      setIsServicePackages("services")
      props.setIsServices(true);
    }

    return callback

  }, []);

  return (
    // <div className="toggle-services-packages-container d-flex">
    //   <div className="toggle-services-container d-flex justify-content-center align-items-center">
    //     <label className="switch">
    //       <input
    //         defaultChecked={isService}
    //         type="checkbox"
    //         onChange={(e) => {
    //           props.setIsServices(e.target.checked);
    //           setIsService(e.target.checked);
    //         }}
    //       />
    //       <span className="slider round"></span>
    //     </label>
    //     <p className="mb-0">Services</p>
    //   </div>
    //   <div className="toggle-packages-container d-flex justify-content-center align-items-center">
    //     <label className="switch">
    //       <input
    //         defaultChecked={isProduct}
    //         type="checkbox"
    //         onChange={(e) => {
    //           props.setIsPackages(e.target.checked);
    //           setIsProduct(e.target.checked);
    //         }}
    //       />
    //       <span className="slider round"></span>
    //     </label>
    //     <p className="mb-0">Packages</p>
    //   </div>
    // </div>

    <div className="toggle-services-packages-container d-flex align-items-center">
      {props.salon && props.salon.data.salon.type == "unisex" ? <><div>
        <button
          onClick={() => {
            setIsMaleFemale("female")
            props.setIsFemale(true)
            props.setIsMale(false)
          }}
          className={isMaleFemale == "female" ? "cancel" : "toggle"}
        >
          Female
        </button>
      </div>
        <div>
          <button
            onClick={() => {
              setIsMaleFemale("male")
              props.setIsMale(true)
              props.setIsFemale(false)
            }}
            className={isMaleFemale == "male" ? "cancel" : "toggle"}
          >
            Male
          </button>
        </div></> : ""}
      <div>
        <button
          onClick={() => {
            props.setIsServices(true);
            props.setIsPackages(false);
            setIsServicePackages("services");
          }}
          className={isServicePackages == "services" ? "cancel" : "toggle"}
        >
          Services
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            props.setIsPackages(true);
            props.setIsServices(false);
            setIsServicePackages("packages");
          }}
          className={isServicePackages == "packages" ? "cancel" : "toggle"}
        >
          Packages
        </button>
      </div>

    </div>
  );
}

export default ToggleServicesPackages;
