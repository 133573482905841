import { useEffect, useState, useCallback } from "react";
import ChooseLocation from "./chooselocation";
import SelectLocation from "./selectlocation";
import BookAddress from "./bookaddress";
import React from "react";

import PopUp from "../popup";
import useIsPopUp from "../../customhooks/ispopup";

function Address(props) {
  const [isOpenChooseLocation, setIsOpenChooseLocation] = useState(false);

  const [isOpenBookAddress, setIsOpenBookAddress] = useState(true);

  const [AddressForEdit, setAddressForEdit] = useState("")

  const [isAddressForEdit, setIsAddressForEdit] = useState(false)

  const [isPopUp, setIsPopUp] = useIsPopUp(false);

  const [popUpMessage, setPopUpMessage] = useState("");

  const callback = useCallback(() => {
    document.body.style.overflow = "unset";
  })

  useEffect(() => {
    return callback
  }, [])

  const getEditAddress = (address) => {
    setAddressForEdit(address)
    setIsAddressForEdit(true)
  }

  return (
    <>

      <div className="address-page h-100">
        {isOpenChooseLocation ? (
          <ChooseLocation
            setIsPopUp={setIsPopUp}
            setPopUpMessage={setPopUpMessage}
            AddressForEdit={AddressForEdit}
            isAddressForEdit={isAddressForEdit}
            setIsOpenChooseLocation={setIsOpenChooseLocation}
            setIsOpenBookAddress={setIsOpenBookAddress}
          />
        ) : (
          ""
        )}
        {isOpenBookAddress ? (
          <BookAddress
            setIsProceed={props.setIsProceed}
            setIsAddressForEdit={setIsAddressForEdit}
            getEditAddress={getEditAddress}
            getAddress={props.getAddress}
            setUserAddress = {props.setUserAddress}
            setIsOpenBookAddress={setIsOpenBookAddress}
            closeAddressPage={props.closeAddressPage}
            setIsOpenChooseLocation={setIsOpenChooseLocation}
          />
        ) : (
          ""
        )}

      </div>

      {isPopUp ? <PopUp setPopUpMessage={setPopUpMessage} setIsPopUp={setIsPopUp} message={popUpMessage} /> : ""}</>
  );
}

export default Address;
