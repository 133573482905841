import React, { useEffect, useState } from "react";

function AtHomeCategories(props) {
  return (
    <div className="category-container d-grid justify-content-between">
      <button
        onClick={() => {
          props.setIsShowBrowseSection(false);
          props.setCategoryAtHome("Recommended");
        }}
        className={
          props.categoryAtHome == "Recommended" ? "active bg-white" : "bg-white"
        }
      >
        <img src="/assets/common/recommended.jpg" />
        <p className="mb-0">Recommended</p>
      </button>
      {props.categories.map((item) => {
        return (
          <button
            key={item._id}
            onClick={() => {
              props.setIsShowBrowseSection(false);
              props.setCategoryAtHome(item.name);
            }}
            className={
              props.categoryAtHome == `${item.name}`
                ? "active bg-white"
                : "bg-white"
            }
          >
            <img
              src={
                item.image
                  ? `${process.env.REACT_APP_IMAGE_URL}${item.image}`
                  : "/assets/common/serviceproduct.png"
              }
            />

            <p className="mb-0">{item.name}</p>
          </button>
        );
      })}
    </div>
  );
}

export default AtHomeCategories;
