import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { VscStarFull } from "react-icons/vsc";

import useGetData from "../../customhooks/getdata";
import Categories from "./categories";
import StarRatings from "react-star-ratings";

import moment from "moment";
import { gtm } from "../gtm";

function ReviewRating() {
  const navigate = useNavigate();

  const salonNameSalonId = useParams();

  const [reviewAndRating, setReviewAndRating] = useState([]);

  const [salonId, setSalonId] = useState("");

  const [gotReviewRating, ReviewRatingError, getReviewRating] = useGetData();

  const [filterValue, setFilterValue] = useState([]);

  useEffect(() => {
    if (salonNameSalonId) {
      const array = salonNameSalonId.salonNameSalonId.split("-");
      setSalonId(array[array.length - 1]);
    }
  }, [salonNameSalonId]);

  useEffect(() => {
    if (salonId) {
      getReviewRating({
        url: `${process.env.REACT_APP_API_URL}/app/review-and-rating/search?salon=${salonId}`,
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    }
  }, [salonId]);

  useEffect(() => {
    if (gotReviewRating) {
      setReviewAndRating(gotReviewRating.data.orderReviewsAndRatings);
    }
  }, [gotReviewRating]);

  useEffect(() => {
    if (gotReviewRating) {
      if (filterValue.length > 0) {
        setReviewAndRating([]);
        setReviewAndRating(
          gotReviewRating.data.orderReviewsAndRatings.filter((item) => {
            for (let i = 0; i <= filterValue.length - 1; i++) {
              if (item.rating <= filterValue[i]) {
                return item;
              }
            }
          })
        );
      } else {
        setReviewAndRating(gotReviewRating.data.orderReviewsAndRatings);
      }
    }
  }, [filterValue]);

  useEffect(() => {
    gtm.page();
  }, []);

  return (
    <div className="show-review-rating-page">
      <div className="header d-flex align-items-center">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="border-0"
        >
          <img src="/assets/common/back.svg" />
        </button>
        <p className="mb-0 w-100 text-center">
          {gotReviewRating &&
          gotReviewRating.data.orderReviewsAndRatings.length > 0
            ? gotReviewRating.data.orderReviewsAndRatings[0].salon.name
            : ""}
        </p>
      </div>

      <div className="rating-review">
        <div className="sub-rating-review d-flex align-items-center">
          <div className="rating d-flex justify-content-center align-items-center">
            <span>
              {gotReviewRating &&
              gotReviewRating.data.orderReviewsAndRatings.length > 0
                ? gotReviewRating.data.orderReviewsAndRatings[0].salon.rating.toFixed(
                    1
                  )
                : ""}
            </span>
            <VscStarFull />
          </div>
          <div className="review-container">
            <p className="mb-0 number-of-review">
              {gotReviewRating &&
              gotReviewRating.data.orderReviewsAndRatings.length > 0 ? (
                <>
                  {Number(
                    gotReviewRating.data.orderReviewsAndRatings[0].salon
                      .numberOfReviews
                  ) > 1000 ? (
                    <>
                      {Number(
                        gotReviewRating.data.orderReviewsAndRatings[0].salon
                          .numberOfReviews
                      ) / 1000}
                      K
                    </>
                  ) : (
                    Number(
                      gotReviewRating.data.orderReviewsAndRatings[0].salon
                        .numberOfReviews
                    )
                  )}
                </>
              ) : (
                ""
              )}{" "}
              Reviews
            </p>
          </div>
        </div>
      </div>

      <Categories filterValue={filterValue} setFilterValue={setFilterValue} />

      <div className="user-infos-container">
        {reviewAndRating.map((item) => {
          return (
            <div key={item._id} className="user-info-container">
              <div className="d-flex align-items-center">
                <div className="user-image">
                  {item.user.image ? (
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}${item.user.image}`}
                      alt="User"
                    />
                  ) : (
                    <img src="/assets/common/user.svg" />
                  )}
                </div>
                <div className="user-info">
                  <p className="name">{item.user.name}</p>
                  <div className="star-rating-container">
                    <StarRatings
                      rating={Math.round(item.rating)}
                      starRatedColor="#FFCE00"
                      numberOfStars={Math.round(item.rating)}
                      name="rating"
                    />
                  </div>
                </div>
              </div>
              <div className="w-100">
                {item.review ? (
                  <div className="review-container">
                    <p className="mb-0 review">{item.review}</p>
                  </div>
                ) : (
                  ""
                )}
                <p className="time mb-0">
                  {moment(item.updatedAt).format("LT")},{" "}
                  {moment(item.updatedAt).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ReviewRating;
