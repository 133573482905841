import React from "react";

import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import Salon from "../../components/salon";
import useIsLoader from "../../customhooks/useisloader";
import useGetData from "../../customhooks/getdata";
import FilterSalons from "../../components/filtersalons";
import Search from "./search";
import Footer from "../../components/footer";

import { useParams } from "react-router-dom";

import Loader from "../../components/loader";

import SearchSalons from "../../components/searchsalons";
import moment from "moment";

import { authJsonHeaders } from "../../constants";
import { gtm } from "../gtm";

function Salons() {
  const params = useParams();

  const [isLoader, setIsLoader] = useIsLoader();

  const [salonsAround, setSalonsAround] = useState([]);

  let [perpage, setPerpage] = useState(10);

  let [page, setPage] = useState(1);

  // const [salons, salonsError, getSalons] = useGetData();

  const [salons, setSalons] = useState(null);

  const [fetchedData, fetchError, fetchData] = useGetData();

  const [earliest, setEarliest] = useState("");
  // const [men, setMen] = useState("");
  // const [women, setWomen] = useState("");
  const [gender, setGender] = useState(null);
  const [rating, setRating] = useState("");

  const [isSearchSalons, setIsSearchSalons] = useState(false);

  const [isScrollLoader, setIsScrollLoader] = useIsLoader();

  const getSalons = async () => {
    const res = fetchData({
      url:
        `${
          process.env.REACT_APP_API_URL
        }/app/salon/search?nearBy=${sessionStorage.getItem(
          "city"
        )},${sessionStorage.getItem("country")}&lat=${sessionStorage.getItem(
          "lat"
        )}&lng=${sessionStorage.getItem(
          "lng"
        )}&status=1&salonStatus=verified&isActive=true&earliest=${
          moment()
            .format("YYYY-MM-DDTHH:mm")
            .split("T")[1]
        }&sort_by=${
          earliest ? "appointmentIn" : "distanceInKm"
        }&per_page=${perpage}&page=${page}&${
          params.params == "ishome"
            ? "isHome"
            : params.params == "issalon"
            ? "isSalon"
            : params.params == "isproduct"
            ? "isProduct"
            : ""
        }=true` +
        `${
          gender ? `&type=${gender}` : ""
          // men && women
          //   ? `&type=unisex`
          //   : men
          //   ? `&type=male`
          //   : women
          //   ? `&type=female`
          //   : ``
        }` +
        `${rating ? `&ratingGt=4` : ``}`,
      //headers: authJsonHeaders,
      headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    return Promise.resolve(res);
  };

  useEffect(() => {
    setIsLoader(true);

    setTimeout(() => {
      const p1 = getSalons();

      p1.then((res) => {
        if (res) {
          setSalonsAround(res.data.salons);

          setSalons(res);
        } else {
          setSalonsAround([]);

          setSalons(null);
        }

        setIsLoader(false);
      });
    }, 1000);
  }, [params, gender, rating, earliest]);

  const increasePage = () => {
    if (salons && salons.data.totalResults > perpage * page) {
      setPage((page += 1));

      setIsScrollLoader(true);

      const p1 = getSalons();

      p1.then((res) => {
        if (res) {
          if (res.data.totalResults > 0) {
            setSalonsAround((prev) => [...prev, ...res.data.salons]);
          } else {
            setSalonsAround([]);
            setSalons(null);
          }

          setIsScrollLoader(false);

          setIsLoader(false);
        }
      });
    }
  };

  useEffect(()=>{
    gtm.page();
  }, [])

  return (
    <>
      <div className="salons-by-footer d-grid">
        <div className="header-body-container d-grid">
          <div className="search-filter-container">
            <Search setIsSearchSalons={setIsSearchSalons} />
            <FilterSalons
              setPage={setPage}
              setEarliest={setEarliest}
              // setWomen={setWomen}
              // setMen={setMen}
              setGender={setGender}
              gender={gender}
              setRating={setRating}
            />
          </div>

          {isLoader ? (
            <Loader height={60} width={60} color={"#772286"} />
          ) : (
            <>
              {salons && salonsAround.length > 0 ? (
                <div className="salons-around-container">
                  <div className="title">
                    <p className="mb-0">
                      {salons.data.totalResults} Salons Around You
                    </p>
                  </div>
                  <div className="salons">
                    <InfiniteScroll
                      dataLength={salonsAround.length}
                      next={increasePage}
                      hasMore={true}
                      loader={
                        isScrollLoader ? (
                          <Loader width={60} height={60} color={"#772286"} />
                        ) : (
                          ""
                        )
                      }
                      scrollThreshold={0.5}
                    >
                      <Salon salons={salonsAround} />
                    </InfiniteScroll>
                  </div>
                </div>
              ) : (
                <div className="not-found text-center d-flex flex-column justify-content-center align-items-center">
                  <img
                    src="/assets/salonsbysearch/not_found.png"
                    alt="Not found"
                  />
                  <p className="mb-0">Sorry! No results found</p>
                </div>
              )}
            </>
          )}
        </div>

        <Footer />
      </div>

      {isSearchSalons ? (
        <SearchSalons setIsSearchSalons={setIsSearchSalons} />
      ) : (
        ""
      )}
    </>
  );
}

export default Salons;
