import React, { useCallback, useEffect, useState } from "react";

function ShowOfferDealDetails(props){

    const [animate, setAnimate] = useState({})

    useEffect(() => {
      setAnimate({
        transform: "translateY(0%)",
      });
    }, []);

    const callback = useCallback(()=>{
        document.body.style.overflow = "unset"
    })

    useEffect(()=>{
        document.body.style.overflow = "hidden"

        return callback
    },[])

    const handleClickOnBack = () => {
        setAnimate({
            transform: "translateY(100%)"
        })
        setTimeout(() => {
            props.setIsShowOfferDetails(false)
        }, 1000)
    }

    return <div style={animate} className="show-offer-deal-details-page d-grid">
        <div onClick={()=>{handleClickOnBack()}} className="cancel">

        </div>
        <div className="offer-deal-details-container">
            <div className="header d-flex justify-content-between align-items-center">
                <p className="mb-0">Offer Details</p>
                <button onClick={()=>{handleClickOnBack()}} className="bg-white border-0"><img src="/assets/common/cross.svg"/></button>
            </div>
            <div className="offer-deal-details">
                <p className="title">{props.offerDetails.title}</p>
                <p className="oferdetails mb-0">{props.offerDetails.offerDetail}</p>
            </div>
            <p className="description mb-0">{props.offerDetails.description}
        </p>
        </div>
    </div>
}

export default ShowOfferDealDetails