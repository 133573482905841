import React from "react";
import { Link } from "react-router-dom";

function Proceed(props) {

  return (
    <Link to="/cart" className="proceed-container d-flex justify-content-between align-items-center">
      <div>
        {props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.totalItems > 0 ? <p className="items">
          {props.gotCartList.data.cart.cartCalculations.overall.totalItems} ITEMS

        </p> : props.gotGenericCart && props.gotGenericCart.data.genericCart && props.gotGenericCart.data.genericCart.cartCalculations.overall.totalItems > 0 ? <p className="items">
          {props.gotGenericCart.data.genericCart.cartCalculations.overall.totalItems} ITEMS

        </p> : ""}

        <div className="price-container d-flex align-items-center">

          {props.gotCartList && props.gotCartList.data.cart && props.gotCartList.data.cart.cartCalculations.overall.totalItems > 0 ? <><p className="price mb-0">

            {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
            {Math.round(props.gotCartList.data.cart.cartCalculations.overall.total - props.gotCartList.data.cart.cartCalculations.overall.deliveryCharges)}
          </p>
          </> : props.gotGenericCart && props.gotGenericCart.data.genericCart && props.gotGenericCart.data.genericCart.cartCalculations.overall.totalItems > 0 ? <><p className="price mb-0">

            {String.fromCharCode(props.salon && props.salon.data.salon.country.htmlSymbol)}
            {Math.round(props.gotGenericCart.data.genericCart.cartCalculations.overall.total - props.gotGenericCart.data.genericCart.cartCalculations.overall.deliveryCharges)}
          </p>
          </> : ""}

        </div>
      </div>
      <div className="proceed d-flex justify-content-center align-items-center">
        <span>Proceed</span>
        <img src="/assets/common/white_right.svg" alt="Right" />
      </div>
    </Link>
  );
}

export default Proceed;
